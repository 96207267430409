import React from "react";
import { useTranslation } from "react-i18next";
import Warnning from "../assets/images/emoji_pomkins_exclamation320.png";
import { Box, Dialog, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

function ModalPriceError({ open = false, handleClose }) {
  const { t } = useTranslation();
  return (
    <Dialog open={open}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          m: 3,
          px: [5, 10, 15],
        }}
      >
        <IconButton
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Close sx={{ color: "#FF961B" }} />
        </IconButton>
        <img
          src={Warnning}
          alt="no-menu"
          title="no-menu"
          loading="lazy"
          width="100px"
          height="100px"
          style={{ width: "100px", height: "100px" }}
        />
        <Typography sx={{ fontWeight: 500, my: 2, textAlign: "center" }}>
          {t("merchant.priceError")}
        </Typography>
      </Box>
    </Dialog>
  );
}

export default ModalPriceError;
