import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import MerchantSearchSlider from "../../pages/Merchant/MerchantSearchSlider";
import SearchNotFound from "../../assets/image/SearchNotFound.png";

const SearchFetchSection = ({ searchData }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={{ m: 3, mb: 1, overflow: "hidden" }}>
      {searchData?.length !== 0 ? (
        <MerchantSearchSlider
          data={{
            name: isMobile ? "foods_horizontal" : "foods",
            slides: searchData,
          }}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={SearchNotFound}
            alt="not found img"
            title="not found img"
            loading="lazy"
            width="100px"
            height="auto"
            style={{ width: "100px" }}
          />
          <Typography fontWeight={600} fontSize={"18px"}>
            {t("sorryNotFoundMerchant")}
          </Typography>
        </Box>
      )}
      {/* <GeneralComponent merchantData={searchData} sx={{ mb: 10 }} /> */}
    </Box>
  );
};

export default SearchFetchSection;
