import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Box,
  Collapse,
  CardMedia,
  IconButton,
  CardActionArea,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  AccessTimeOutlined,
  CheckCircleOutline,
  RestaurantMenuOutlined,
  StorefrontOutlined,
} from "@mui/icons-material";
import {
  getMerchantById,
  getServiceBMerchant,
} from "../../libs/merchantAPI/merchantAPI";
import LoadingScreen from "../LoadingScreen";
import dayjs from "dayjs";
function ActiveOrderItem({ order, bMerchantData }) {
  const [showDetails, setShowDetails] = useState(false);
  const [merchantData, setMerchantData] = useState();
  const [isFetch, setIsFetch] = useState(false);
  const [status, setStatus] = useState(order.order_status);
  const [service, setService] = useState([]);
  const type = order.order_type;
  const { t } = useTranslation();
  const navigator = useNavigate();
  const bmerchant = bMerchantData.find(
    (item) => item.name === order.merchant_name
  );
  useEffect(() => {
    setIsFetch(false);
    getMerchantById(bmerchant.merchant_id)
      .then((res) => {
        setMerchantData(res.data);
        getServiceBMerchant(bmerchant.uuid).then(({ data }) => {
          setService(data);
        });
        setIsFetch(true);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (status === "PURCHASED") {
      setStatus(order.cooking_status);
    }
  }, [order.cooking_status, status]);

  const handleToggleDetails = () => {
    setShowDetails(!showDetails);
  };
  // ฟังก์ชันเพื่อกำหนด Icon ตามสถานะ
  const getStatusIcon = (status) => {
    if (order.order_status === "PURCHASED") {
      switch (status) {
        case "PENDING":
          return <RestaurantMenuOutlined />;
        case "SUCCESS":
          return <CheckCircleOutline />;
        case "MERCHANT CONFIRMED":
          return <StorefrontOutlined />;
        case "PURCHASED":
          return <CheckCircleOutline />; // สีสำหรับ Delivered
        // คุณสามารถเพิ่มเงื่อนไขอื่นๆ ตามที่ต้องการ
        default:
          return null; // หรือ Icon ปริยาย
      }
    } else {
      switch (status) {
        case "PENDING":
          return <AccessTimeOutlined />;
        case "SUCCESS":
          return <CheckCircleOutline />;
        case "MERCHANT CONFIRMED":
          return <StorefrontOutlined />;
        case "PURCHASED":
          return <CheckCircleOutline />; // สีสำหรับ Delivered
        // คุณสามารถเพิ่มเงื่อนไขอื่นๆ ตามที่ต้องการ
        default:
          return null; // หรือ Icon ปริยาย
      }
    }
  };

  // ฟังก์ชันเพื่อกำหนดสีตามสถานะ
  const getBackgroundColor = (status) => {
    switch (status) {
      case "MERCHANT CONFIRMED":
        return "#FF961B"; // สีสำหรับ Delivered
      case "PURCHASED":
        return "#00d712";
      case "SUCCESS":
        return "#00d712"; // สีสำหรับ Cooking, ตัวอย่างเป็นสีเขียว
      // เพิ่มเงื่อนไขสำหรับสีอื่นๆ
      case "PENDING":
        return "#00a7f0";
      default:
        return "#f44336"; // สีสำหรับสถานะอื่นๆ
    }
  };

  const deliver_fee = order.order_type === "DELIVERY" ? order.deliver_fee : 0;
  const subtotal = service.vat
    ? (order.total_amount - deliver_fee) / 1.07
    : order.total_amount - deliver_fee;
  const vatValues = service.vat
    ? order.total_amount - deliver_fee - subtotal
    : 0;

  if (!isFetch) {
    return <LoadingScreen />;
  }
  return (
    <Card sx={{ marginBottom: 2, borderRadius: "15px" }}>
      <CardContent>
        <CardActionArea
          onClick={() =>
            navigator(
              `/order-status/${bmerchant.merchant_id}/${bmerchant.id}/${order.order_uuid}`
            )
          }
        >
          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
            {/* Image */}
            <Box
              sx={{
                justifyContent: "end",
                display: "flex",
                alignItems: "start",
              }}
            >
              <CardMedia
                component="img"
                sx={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "10px",
                  objectFit: "cover",
                }}
                image={merchantData.logo}
                alt={order["merchant_name"]}
              />
            </Box>

            {/* Restaurant Details */}
            <Box
              sx={{
                flex: 1,
                width: { xs: "34%", sm: "45%", md: "45%", lg: "32%" },
              }}
            >
              <Typography variant="h7" gutterBottom>
                {order["merchant_name"]}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {t("order.orderOn")}{" "}
                {dayjs(order["date"]).format("DD/MM/YYYY HH:mm")}
              </Typography>

              {type === "PICKUP" && (
                <Typography variant="body2" color="textSecondary">
                  {t("order.pickupOn")} {order["time"]}
                </Typography>
              )}

              <Box sx={{ paddingTop: "16px" }}>
                {order.order_detail.map((item, index) => (
                  <Typography
                    key={index}
                    variant="body2"
                    sx={{ paddingBottom: "8px" }}
                  >
                    {item["quantity"]} x {item["menu_name"]}
                  </Typography>
                ))}
              </Box>
            </Box>

            {/* Total Price and Reorder Button */}
            <Box justifyContent="space-between">
              <Box
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    textAlign: "end",
                    paddingLeft: { sm: "10px", md: "40%" },
                  }}
                >
                  <Typography
                    variant="h7"
                    color="primary"
                    sx={{ alignItems: "space-between", textAlign: "end" }}
                  >
                    {order["total_amount"]}
                  </Typography>
                  {/* Status Block */}
                  <Box
                    sx={{
                      textAlign: "center",
                      backgroundColor: getBackgroundColor(status), // เรียกใช้ฟังก์ชันเพื่อกำหนดสี
                      borderRadius: "5px",
                      padding: "1px 8px",
                      margin: "2px 0",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "12px",
                    }}
                  >
                    {getStatusIcon(status)} {/* แสดง Icon ตามสถานะ */}
                    {status}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardActionArea>
        <Divider sx={{ my: 2 }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 1,
            cursor: "pointer",
          }}
          onClick={handleToggleDetails}
        >
          <Box>
            <Typography variant="h7" color="primary">
              {t("order.viewDetails")}
              {order["order_no"]})
            </Typography>
          </Box>
          <IconButton>
            {showDetails ? (
              <ExpandLessIcon color="primary" />
            ) : (
              <ExpandMoreIcon color="primary" />
            )}
          </IconButton>
        </Box>

        {/* Expanded Details Section */}
        <Collapse in={showDetails}>
          {type === "DELIVERY" && (
            <>
              <Box
                sx={{ marginTop: 2 }}
                display="flex"
                flexDirection="column"
                mt={2}
              >
                <Typography variant="body2">Delivered from :</Typography>
                <Typography variant="body2">
                  {order["merchant_location"]}
                </Typography>
                <Typography variant="body2">Delivered to :</Typography>
                <Typography variant="body2">
                  {order["deliver_address"]}
                </Typography>
              </Box>
              <Divider sx={{ my: 2 }} />
            </>
          )}

          <Box>
            {order.order_detail.map((item, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={1}
              >
                <Typography variant="body2">
                  {item["quantity"]} x {item["menu_name"]}
                </Typography>
                <Typography variant="body2">
                  {item["price"]
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
                  ฿
                </Typography>
              </Box>
            ))}

            <Divider sx={{ my: 2 }} />

            <Box display="flex" justifyContent="space-between">
              <Typography variant="body2">Subtotal</Typography>
              <Typography variant="body2">
                {subtotal
                  .toFixed(2)
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
                ฿
              </Typography>
            </Box>
            {type === "DELIVERY" && (
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body2">Delivery fee</Typography>
                <Typography variant="body2">{order.deliver_fee} ฿</Typography>
              </Box>
            )}

            <Box display="flex" justifyContent="space-between">
              <Typography variant="body2">Discount</Typography>
              <Typography variant="body2">
                {order.discount
                  .toFixed(2)
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
                ฿
              </Typography>
            </Box>

            {service && service.vat && (
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body2">VAT (7%)</Typography>
                <Typography variant="body2">
                  {vatValues
                    .toFixed(2)
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                  ฿
                </Typography>
              </Box>
            )}

            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1" fontWeight={500}>
                Total
              </Typography>
              <Typography variant="body1" fontWeight={500}>
                {order.total_amount
                  .toFixed(2)
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
                ฿
              </Typography>
            </Box>

            {/* <Box display="flex" justifyContent="space-between" mt={1}>
              <Typography variant="body2">Paid with</Typography>
              <Typography variant="body2"></Typography>
            </Box> */}
          </Box>
        </Collapse>
      </CardContent>
    </Card>
  );
}

export default ActiveOrderItem;
