import { axiosInstance } from "../axios";

export const getPhoneQueue = (phone, bmerchant_uuid, onQueue = true) => {
  return axiosInstance.get(`/web/queue/${phone}/${bmerchant_uuid}`, {
    params: {
      on_queue: onQueue,
    },
  });
};

export const deletePhoneQueue = (phone, bmerchant_uuid) => {
  return axiosInstance.delete(`/web/queue/${phone}/${bmerchant_uuid}`);
};
