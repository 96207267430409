import { axiosInstance } from "../axios";

export const getOrders = (uuid) => {
  return axiosInstance.get(`/web/allOrder/${uuid}?page=activity`);
};

export const getOrders_history = (uuid) => {
  return axiosInstance.get(`/web/allOrder/${uuid}?page=history`);
};

export const getOrdersByOrders_uuid = (orderUuid) => {
  return axiosInstance.get(`/web/order/${orderUuid}`);
};

export const updateOrdersStatus = (orderUuid, status) => {
  return axiosInstance.patch(`/web/orders/${orderUuid}`, status);
};

export const getOrderByTableNumber = (merchantUuid, tableNumber) => {
  return axiosInstance.get(
    `/web/order/table-number/${merchantUuid}?tableNumber=${tableNumber}&status=unsuccess&payment_status=PENDING`
  );
};

export const getOrderByMerchantUuid = (merchantUuid) => {
  return axiosInstance.get(`/web/orders/${merchantUuid}`);
};
