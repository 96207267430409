import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import { Close, LocationOn as LocationOnIcon } from "@mui/icons-material";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { getAddressFromCoordinates } from "../utils/convertAddress";
import { useLocationStore } from "../stores/useLocationStore";
import AutoCompleteMapField from "../components/AutoCompleteMapField";
import { useTranslation } from "react-i18next";
import { useAccess } from "../stores/useAccess";
import { useLocation, useNavigate } from "react-router-dom";
// Define your defaultProps for GoogleMapPickerModal
GoogleMapPickerModal.defaultProps = {
  open: false,
};

function GoogleMapPickerModal({ open }) {
  const {
    closeModal,
    currentLocation,
    setCurrentAddress,
    fetchCurrentLocation,
    setCurrentLocation,
  } = useLocationStore();
  const { t } = useTranslation();
  const { setCanAccess } = useAccess();
  const [propsValue, setPropsValue] = React.useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentLocation && open) {
      fetchCurrentLocation();
    }
  }, [currentLocation, fetchCurrentLocation, open]);

  const handleMapClick = async (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setCurrentLocation({ lat, lng });

    const address = await getAddressFromCoordinates({ lat, lng });
    setPropsValue(address);
  };

  const handleConfirm = async () => {
    const address = await getAddressFromCoordinates(currentLocation);
    setCurrentAddress(address);
    localStorage.setItem("address", address);
    const locationString = JSON.stringify(currentLocation);

    localStorage.setItem("location", locationString);
    closeModal();
    setCanAccess(false);
    if (location.pathname === "/") {
      navigate("/food");
    } else {
      window.location.reload();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      maxWidth="sm"
      fullWidth
      sx={{
        maxHeight: "100vh",
        "& .MuiDialog-paper": {
          borderRadius: "20px",
        },
      }}
    >
      <IconButton
        color="inherit"
        onClick={closeModal}
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <Close sx={{ color: "#FF961B" }} />
      </IconButton>
      <DialogTitle>
        {t("title.chooseLocation")}
        <Typography fontSize="14px">{t("map.choseDescription")}</Typography>
      </DialogTitle>

      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            mt: "1rem",
            borderRadius: 4,
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
          }}
        >
          <AutoCompleteMapField
            onClickSearch={handleConfirm}
            propsValue={propsValue}
          />
        </Box>
      </DialogContent>
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "400px" }}
        center={currentLocation}
        zoom={14}
        options={{
          zoomControl: true,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
        onClick={handleMapClick}
      >
        {currentLocation && <Marker position={currentLocation} />}
      </GoogleMap>

      <DialogActions>
        <Button
          variant="contained"
          onClick={handleConfirm}
          fullWidth
          sx={{
            m: 2,
            borderRadius: "20px",
            backgroundColor: "#FF961B",
            color: "#ffffff",
            "&:hover": {
              backgroundColor: "#FF961B",
            },
          }}
          startIcon={<LocationOnIcon />}
          disabled={!currentLocation}
        >
          {t("btn.confirmLocation")}{" "}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function MapPickerModal({ children }) {
  const { isOpen } = useLocationStore();
  return (
    <div>
      {children}
      <GoogleMapPickerModal open={isOpen} />
    </div>
  );
}
