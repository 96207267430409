import { axiosInstance } from "../axios";

export const getMerchant = () => {
  return axiosInstance.get("/merchants");
};

export const getV2Merchant = (
  shopType = null,
  isOpen = false,
  rating = 0,
  distance = false,
  time = false,
  location,
  size = 10,
  page = 1
) => {
  return axiosInstance.post("/v2/merchants", {
    shop_type: shopType,
    is_open: isOpen,
    rating: rating,
    distance: distance,
    time: time,
    location: location,
    size: size,
    page: page,
  });
};

export const getMerchantById = (id) => {
  return axiosInstance.get(`/merchant/${id}`);
};

export const getBMerchant = () => {
  return axiosInstance.get("/bmerchants");
};

export const getBMerchantById = (id) => {
  return axiosInstance.get(`/bmerchant/${id}`);
};

export const getBMerchantByMerchantId = (id) => {
  return axiosInstance.get(`/merchant/${id}/bmerchant`);
};

export const getBMerchantByName = (name) => {
  return axiosInstance.get(`/bmerchant/${name}`);
};

export const getReviewByMerchantUUID = (uuid) => {
  return axiosInstance.get(`/reviews/${uuid}/0`);
};
export const getMenuByBMerchantId = (uuid) => {
  return axiosInstance.get(`/bmerchant/${uuid}/menu?active=true`);
};

export const getCategoryByBMerchantId = (uuid) => {
  return axiosInstance.get(`/bmerchant/${uuid}/category`);
};

export const getBMerchantOptionById = (id) => {
  return axiosInstance.get(`/bmerchant/${id}/option`);
};

export const getPaymentStatusList = (bmerchantUuid) => {
  return axiosInstance.post("/bmerchant/payment/get", {
    uuid: bmerchantUuid,
  });
};

export const searchMerchant = (searchQuery) => {
  return axiosInstance.get("/merchants/name", {
    data: {
      name: searchQuery,
    },
  });
};

export const createReview = (data) => {
  return axiosInstance.post("/reviews", data);
};

export const getRatingByMerchantUUID = (uuid) => {
  return axiosInstance.get(`/rating/${uuid}`);
};

export const getServiceBMerchant = (bmerchantUuid) => {
  return axiosInstance.get(`/bmerchant/service/${bmerchantUuid}`);
};
