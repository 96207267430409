import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
ModalDeleteAccount.defaultProps = {
  open: false,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

function ModalDeleteAccount({ open, handleConfirm, handleClose }) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          width: "90%",
          maxWidth: "400px",
          minHeight: "30vh",
          backgroundColor: "#fff",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "10px",
        },
      }}
    >
      <DialogTitle className="font-bold">
        {t("modal.deleteAccountTitle")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{t("modal.deleteAccountContent")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          {t("btn.cancel")}
        </Button>
        <Button onClick={handleConfirm} variant="contained">
          {t("btn.confirmButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalDeleteAccount;
