import { Box, Button, Typography } from "@mui/material";
import React from "react";
import SuccessImg from "../../assets/images/emoji_pomkins_correct320.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function OrderSuccess({ orderType }) {
  const { t } = useTranslation();
  const navigator = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        p: 2,
        textAlign: "center",
        backgroundColor: "white",
      }}
    >
      <img
        src={SuccessImg}
        alt="Success Img"
        title="Success Img"
        loading="lazy"
        width="100px"
        height="auto"
      />
      {orderType === "reserve" ? (
        <>
          <Typography variant="h5">
            {t("orderStatus.reserceSuccess")}
          </Typography>
          <Typography variant="body1">
            {t("orderStatus.reserveComplete")}
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h5">{t("orderStatus.orderSuccess")}</Typography>
          <Typography variant="body1">
            {t("orderStatus.orderSuccessDescription")}
          </Typography>
        </>
      )}
      <Button variant="outlined" onClick={() => navigator("/food")}>
        {t("btn.orderMore")}
      </Button>
    </Box>
  );
}

export default OrderSuccess;
