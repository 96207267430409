import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import PasswordInput from "../../components/PasswordInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { newPasswordScheme } from "../../libs/authScheme";

function PasswordBox() {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    mode: "all",
    resolver: yupResolver(newPasswordScheme),
  });
  const onSubmit = (data) => {};
  return (
    <Box sx={{ width: ["90%", "70%", "550px"] }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          mt: 4,
        }}
      >
        <Typography fontSize={"18px"} fontWeight={700}>
          {t("profile.password")}
        </Typography>
      </Box>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          mt: 4,
          display: "flex",

          flexDirection: "column",
          gap: 2,
        }}
      >
        <PasswordInput
          label={t("profile.currentPassword")}
          placeholder={t("profile.currenPasswordPlaceholder")}
          error={errors.password?.message}
          {...register("password", { required: true })}
        />
        <PasswordInput
          label={t("profile.newPassword")}
          placeholder={t("profile.newPasswordPlaceholder")}
          error={errors.newPassword?.message}
          {...register("newPassword", { required: true })}
        />
        <Box sx={{ display: "flex", justifyContent: "start", mb: 3 }}>
          <Button variant="contained" className=" bg-primary">
            {t("btn.save")}
          </Button>
        </Box>
        <Divider flexItem />
      </Box>
    </Box>
  );
}

export default PasswordBox;
