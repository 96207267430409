import { Route, Routes } from "react-router-dom";
import { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useMobile } from "./stores/useMobile";
import { useEffect, useRef } from "react";
import LayoutAuth from "./layouts/LayoutAuth";
import CartPage from "./pages/CartPage/CartPage";
import { useTranslation } from "react-i18next";
import { useLanguage } from "./stores/useLanguage";
import ProfilePage from "./pages/Profile/ProfilePage";
import ProtectRoute from "./layouts/ProtectRoute";
import { PresistLogin } from "./hocs/PresistLogin";
import Layout from "./layouts/Layout";
import HomePage from "./pages/Home/HomePage";
import MerchantPage from "./pages/Merchant/MerchantPage";
import QrCodePage from "./pages/QrPage/QrCodePage";
import AuthenPage from "./pages/AuthenPage";
import OrderStatusCard from "./pages/OrderStatus/OrderStatusPage";
import FavoritePage from "./pages/Favorite/FavoritePage";
import OrderPage from "./pages/Order/OrderPage";
import { loadGoogleMapsAPI } from "./utils/loadScript";
import CartListPage from "./pages/Cart/CartListPage";
import AddMenuPage from "./pages/QrPage/AddMenuPage";
import CheckBillPage from "./pages/QrPage/CheckBillPage";
import PaymentPage from "./pages/Order/PaymentPage";
import LayoutTableQr from "./layouts/LayoutTableQr";
import QrMainPage from "./pages/QrPage/QrMainPage";
import QrCreateUser from "./pages/QrPage/QrCreateUser";
import QueuePage from "./pages/Queue/QueuePage";
import { useLocationStore } from "./stores/useLocationStore";
import LoadingScreen from "./components/LoadingScreen";
import NotFoundPage from "./pages/NotFound/NotFoundPage";
import ContactSalesPage from "./pages/ContactSales/ContactSalesPage";
import HelpCenterPage from "./pages/HelpCenter/HelpCenterPage";
import ReviewPage from "./pages/Review/ReviewPage";
import QrPaymentPage from "./pages/QrPage/QrPaymentPage";
import SlideToTop from "./hocs/SlideToTop";
import CheckOutPage from "./pages/CartPage/CheckOutPage";
import Aos from "aos";
import "aos/dist/aos.css";
import NewWelcomePage from "./pages/Welcome/NewWelcomePage";
import TermsPage from "./pages/TermPage/TermsPage";
import PrivacyPage from "./pages/PrivacyPage/PrivacyPage";
import CookiesPolicy from "./components/CookiesPolicy";
import MobileContainer from "./layouts/MobileContainer";
// import "./index.scss";
// import "bootstrap/dist/js/bootstrap";
import { getSelectedLanguageFromCookie } from "./utils/getLanguageCookie";
import CookieConsentDialog from "./hocs/CookieConsentModal";
import HowToUsePage from "./pages/HowToUse/HowToUsePage";
import BillPage from "./pages/Order/BillPage";
import MerchantQrPage from "./pages/MerchantQR/MerchantQrPage";
import QrCartPage from "./pages/MerchantQR/MerchantQrCartPage";
import MerchantQrCheckoutPage from "./pages/MerchantQR/MerchantQrCheckoutPage";
import CheckTableToken from "./hocs/CheckTableToken";
import ExpirePage from "./pages/QrPage/ExpirePage";
import MenuPage from "./pages/Menu/MenuPage";
import SessionTimeoutPage from "./pages/Session/SessionTimeoutPage";
import BillEdc from "./pages/Order/BllEdc";
import TermsMerchantPage from "./pages/TermPage/TermMerchantPage";
import FaqPage from "./pages/FAQ/FaqPage";
import FaqCategoryPage from "./pages/FAQ/FaqCategoryPage";
import FaqSectionPage from "./pages/FAQ/FaqSectionPage";
import FaqArticlesPage from "./pages/FAQ/FaqArticlesPage";
import FaqContainer from "./layouts/FaqContainer";
import PackagePage from "./pages/Package/PackagePage";
import ReactGA from "react-ga4";
import DonwloadRedirectPage from "./pages/DownloadRedirect/DonwloadRedirectPage";
import StorePage from "./pages/Store/StorePage";
import ExplorePage from "./pages/Explore/ExplorePage";
import Optout from "./pages/Optout/Optout";
import OptoutThankyou from "./pages/Optout/OptoutThankyou";
import { getCookie } from "./utils/getToken";
import LayoutFood from "./layouts/LayoutFood";
import SeeAllPage from "./pages/Home/SeeAllPage";
import PaymentFailed from "./pages/Order/PaymentFailed";
import "./App.css";

function App() {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
  const { setIsMobile } = useMobile();
  const [openCookieConsent, setOpenCookieConsent] = useState(false);
  const { selectedLanguage, setSelectedLanguage } = useLanguage();
  const { isLoadScript, setIsLoadScript } = useLocationStore();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { i18n } = useTranslation();
  const loaded = useRef(false);
  useEffect(() => {
    Aos.init({
      duration: 1200,
    });
  }, []);
  useEffect(() => {
    if (typeof window !== "undefined" && !loaded.current) {
      loadGoogleMapsAPI().then(() => {
        loaded.current = true;
        setIsLoadScript(true);
      });
    }
  }, [setIsLoadScript]);

  useEffect(() => {
    const languageFromCookie = getSelectedLanguageFromCookie();
    const cookieSetting = getCookie("cookieSetting");
    if (cookieSetting) {
      setOpenCookieConsent(false);
      setSelectedLanguage(languageFromCookie);
      i18n.changeLanguage(languageFromCookie);
    } else {
      setOpenCookieConsent(true);
      setSelectedLanguage("ไทย");
      i18n.changeLanguage("ไทย");
    }
  }, [i18n, setSelectedLanguage]);

  useEffect(() => {
    document.cookie = `selectedLanguage=${selectedLanguage}; path=/`;
    i18n.changeLanguage(selectedLanguage);
  }, [i18n, selectedLanguage]);

  useEffect(() => {
    setIsMobile(isMobile);
  }, [isMobile, setIsMobile]);

  if (!isLoadScript) {
    return <LoadingScreen />;
  }

  return (
    <>
      <CookieConsentDialog openModal={openCookieConsent} />
      <Routes>
        <Route>
          <Route element={<SlideToTop />}>
            <Route element={<LayoutAuth />}>
              <Route
                path="/menu/:merchant_id/:bmerchant_id/:merchant_name"
                element={<MenuPage />}
              />
              <Route
                path="/merchant-qr/:merchant_id/:bmerchant_id/:merchant_name"
                element={<MerchantQrPage />}
              />
              <Route
                path="/merchant-qr/:merchant_id/:bmerchant_id/cartorder"
                element={<QrCartPage />}
              />
              <Route
                path="/merchant-qr/:merchant_id/:bmerchant_id/checkout"
                element={<MerchantQrCheckoutPage />}
              />
              <Route path="/login/new" element={<AuthenPage />} />
              <Route
                path="/merchant/queue/:merchant_id/:bmerchant_id/:merchant_name/:phone"
                element={<QueuePage />}
              />
            </Route>
            <Route element={<PresistLogin />}>
              <Route element={<Layout />}>
                <Route path="/" element={<NewWelcomePage />} />
                <Route path="/contactus" element={<ContactSalesPage />} />
                <Route path="/helpcenter" element={<HelpCenterPage />} />
                <Route path="/pompkins/package" element={<PackagePage />} />
                <Route path="/pompkins/store" element={<StorePage />} />
                <Route path="/opt-out/details" element={<Optout />} />
                <Route path="/opt-out/thankyou" element={<OptoutThankyou />} />
              </Route>

              <Route element={<LayoutFood />}>
                <Route element={<ProtectRoute />}>
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/favorite" element={<FavoritePage />} />
                </Route>
                <Route path="/order" element={<OrderPage />} />
                <Route path="/food" element={<HomePage />} />
                <Route path="/food/all" element={<SeeAllPage />} />
                <Route path="/cart" element={<CartListPage />} />
                <Route
                  path="/merchant/:merchant_id/:bmerchant_id/:merchant_name"
                  element={<MerchantPage />}
                />
                <Route
                  path="/merchant/:merchant_id/:bmerchant_id/cartorder"
                  element={<CartPage />}
                />
                <Route
                  path="/merchant/:merchant_id/:bmerchant_id/checkout"
                  element={<CheckOutPage />}
                />
                <Route
                  path="/order-status/:merchant_id/:bmerchant_id/:order_uuid"
                  element={<OrderStatusCard />}
                />
                <Route
                  path="/order-status/:merchant_id/:bmerchant_id/payment/:bmerchant_uuid/:order_uuid/:transaction_uuid"
                  element={<PaymentPage />}
                />
                <Route
                  path="/order-status/:merchant_id/:bmerchant_id/payment/:bmerchant_uuid/:order_uuid/:transaction_uuid/failed"
                  element={<PaymentFailed isCancel={true} />}
                />
                <Route
                  path="/review/:bmerchant_uuid/:order_uuid"
                  element={<ReviewPage />}
                />
                <Route
                  path="/order/bill/:merchant_id/:bmerchant_id/:transaction_uuid"
                  element={<BillPage />}
                />
                <Route
                  path="/edc/bill/:merchant_id/:bmerchant_id/:transaction_uuid"
                  element={<BillEdc />}
                />
                <Route path="/explore" element={<ExplorePage />} />
              </Route>
            </Route>
            <Route element={<MobileContainer />}>
              <Route path="/table-merchant/expired" element={<ExpirePage />} />
              <Route element={<CheckTableToken />}>
                <Route
                  path="/table-merchant/:token/:merchant_id/:bmerchant_id/:tableNumber/:transaction_uuid"
                  element={<QrMainPage />}
                />
                <Route
                  path="/table-merchant/:token/:merchant_id/:bmerchant_id/:tableNumber/:transaction_uuid/create-user"
                  element={<QrCreateUser />}
                />

                <Route element={<LayoutTableQr />}>
                  <Route
                    path="/table-merchant/:token/:merchant_id/:bmerchant_id/:tableNumber/:transaction_uuid/menu"
                    element={<QrCodePage />}
                  />
                  <Route
                    path="/table-merchant/:token/:merchant_id/:bmerchant_id/:tableNumber/:transaction_uuid/cartorder"
                    element={<AddMenuPage />}
                  />
                  <Route
                    path="/table-merchant/:token/:merchant_id/:bmerchant_id/:tableNumber/:transaction_uuid/checkbill/:merchant_uuid"
                    element={<CheckBillPage />}
                  />
                  <Route
                    path="/table-merchant/:token/:merchant_id/:bmerchant_id/:tableNumber/:transaction_uuid/payment/:merchant_uuid"
                    element={<QrPaymentPage />}
                  />
                </Route>
              </Route>
            </Route>
            <Route path="/timeout" element={<SessionTimeoutPage />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/terms-and-conditions" element={<TermsPage />} />
            <Route
              path="/terms-and-conditions-merchant"
              element={<TermsMerchantPage />}
            />
            <Route path="/cookies-policy" element={<CookiesPolicy />} />
            <Route path="/privacy-policy" element={<PrivacyPage />} />
          </Route>
        </Route>
        <Route path="/download" element={<DonwloadRedirectPage />} />
        <Route path="/pompkins/instruction" element={<HowToUsePage />} />
        <Route element={<FaqContainer />}>
          <Route path="/pompkins/FAQ" element={<FaqPage />} />
          <Route
            path="/pompkins/FAQ/categories/:categories"
            element={<FaqCategoryPage />}
          />
          <Route
            path="/pompkins/FAQ/categories/:categories/sections/:sections"
            element={<FaqSectionPage />}
          />
          <Route
            path="/pompkins/FAQ/categories/:categories/sections/:sections/:articles"
            element={<FaqArticlesPage />}
          />
        </Route>
      </Routes>
    </>
  );
}

export default App;
