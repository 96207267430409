import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import CancelImg from "../../assets/images/emoji_pomkins_x320.png";
import { Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { getBMerchantById } from "../../libs/merchantAPI/merchantAPI";
import LoadingScreen from "../../components/LoadingScreen";
import { useTranslation } from "react-i18next";
import { updateOrdersStatus } from "../../libs/customerAPI/getOrders";

function PaymentFailed({ isCancel }) {
  const { bmerchant_id, transaction_uuid, order_uuid } = useParams();
  const [bmerchant, setBmerchant] = useState({});
  useEffect(() => {
    getBMerchantById(bmerchant_id).then((res) => {
      setBmerchant(res.data);
    });
  }, [bmerchant_id]);

  useEffect(() => {
    const status = {
      status: "CANCELLED",
      transaction_uuid: transaction_uuid,
    };
    updateOrdersStatus(order_uuid, status);
  }, [order_uuid, transaction_uuid]);

  const navigator = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!bmerchant) {
    return <LoadingScreen />;
  }
  return (
    <Box
      sx={{
        height: "50vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        p: 2,
        textAlign: "center",
      }}
    >
      <img
        src={CancelImg}
        alt="Cancel Img"
        title="Cancel Img"
        loading="lazy"
        width="100px"
        height="auto"
      />
      <Typography variant="h5">คำสั่งซื้อถูกยกเลิก</Typography>
      {isCancel ? (
        <>
          <Typography variant="body1">
            ยกเลิกออเดอร์เสร็จสิ้น {t("orderStatus.orderCancelDescription")}{" "}
            {bmerchant.phone}
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="body1">
            คำสั่งซื้อถูกยกเลิก เนื่องจากคุณไม่ได้ชำระเงินภายในเวลาที่กำหนด{" "}
            {t("orderStatus.orderCancelDescription")} {bmerchant.phone}
          </Typography>
        </>
      )}
      <Button variant="outlined" onClick={() => navigator("/food")}>
        สั่งอาหารใหม่กันเถอะ
      </Button>
    </Box>
  );
}

export default PaymentFailed;
