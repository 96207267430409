import {
  Help,
  LogoutOutlined,
  PermIdentityOutlined,
  ReceiptOutlined,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
// import facebookLogo from "../assets/image/facebook_logo.png";
import googleLogo from "../assets/image/google_logo.png";
import hi1 from "../assets/images/จุดเด่น_01.png";
import hi2 from "../assets/images/จุดเด่น_10.png";
import hi3 from "../assets/images/จุดเด่น_03.png";
import hi4 from "../assets/images/จุดเด่น_04.png";
import hi5 from "../assets/images/จุดเด่น_05.png";
import hi6 from "../assets/images/จุดเด่น_06.png";
import hi7 from "../assets/images/จุดเด่น_07.png";
import hi8 from "../assets/images/จุดเด่น_08.png";
import hi9 from "../assets/images/จุดเด่น_09.png";
import hi11 from "../assets/images/จุดเด่น_11.png";
import hi12 from "../assets/images/จุดเด่น_12.png";
import sv1 from "../assets/GIF/service00_pos.gif";
import sv2 from "../assets/GIF/service01_scanQR.gif";
import sv3 from "../assets/GIF/service02_scancashier.gif";
import sv4 from "../assets/GIF/service03_qrtable.gif";
import sv5 from "../assets/GIF/service04_seporder.gif";
import sv6 from "../assets/GIF/service05_separatebill.gif";
import sv7 from "../assets/GIF/service06_orderonline.gif";
import sv8 from "../assets/GIF/service07_pompedc.gif";

import fe1 from "../assets/images/payment-final.png";
import fe2 from "../assets/images/Queue.png";
import fe3 from "../assets/images/Ordering.png";
import fe4 from "../assets/images/ThaiQr.png";

import {
  ManageAccountsOutlined,
  QrCodeScannerOutlined,
  AddShoppingCartOutlined,
  InventoryOutlined,
} from "@mui/icons-material";

function Options() {
  const { t } = useTranslation();

  const myAccountOptions = [{ label: "Google", img: googleLogo }];

  const languageOptions = [
    { code: "English", label: "English" },
    { code: "ไทย", label: "ไทย" },
  ];

  const accountOptions = [
    { icon: <ReceiptOutlined />, label: t("options.order"), path: "/order" },
    {
      icon: <PermIdentityOutlined />,
      label: t("options.profile"),
      path: "/profile",
    },
    { icon: <Help />, label: t("options.helpCenter"), path: "/helpcenter" },
    { icon: <LogoutOutlined />, label: t("options.logout"), path: "logout" },
  ];

  const guestOptions = [
    { icon: <ReceiptOutlined />, label: t("options.order"), path: "/order" },
    { icon: <Help />, label: t("options.helpCenter"), path: "/helpcenter" },
    { icon: <LogoutOutlined />, label: t("options.logout"), path: "logout" },
  ];

  const highlightComponent = [
    {
      id: 1,
      img: hi1,
      subtitle: t("highlight.subonlineQr"),
      title: t("highlight.onlineQr"),
      label: t("highlight.orderQrDesc"),
    },
    {
      id: 2,
      img: hi2,
      subtitle: t("highlight.subselfKiosk"),
      title: t("highlight.selfKiosk"),
      label: t("highlight.wayToOrderDesc"),
    },
    {
      id: 3,
      img: hi3,
      subtitle: t("highlight.subcompatible"),
      title: t("highlight.compatible"),
      label: t("highlight.compatibleDesv"),
    },
    {
      id: 4,
      img: hi4,
      subtitle: t("highlight.subkitchenView"),
      title: t("highlight.kitchenView"),
      label: t("highlight.orderQrDesc"),
    },
    {
      id: 5,
      img: hi5,
      subtitle: t("highlight.suborderPay"),
      title: t("highlight.orderPay"),
      label: t("highlight.orderPayDesc"),
    },
    {
      id: 6,
      img: hi6,
      subtitle: t("highlight.subanalytic"),
      title: t("highlight.analytic"),
      label: t("highlight.analyticDesc"),
    },
    {
      id: 7,
      img: hi7,
      subtitle: t("highlight.subrole"),
      title: t("highlight.role"),
      label: t("highlight.roleDesc"),
    },
    {
      id: 8,
      img: hi8,
      subtitle: t("highlight.subqueue"),
      title: t("highlight.queue"),
      label: t("highlight.queueDesc"),
    },
    {
      id: 9,
      img: hi9,
      subtitle: t("highlight.subpayment"),
      title: t("highlight.payment"),
      label: t("highlight.paymentDesc"),
    },
    {
      id: 11,
      img: hi11,
      subtitle: t("highlight.subonlineGoogleMaps"),
      title: t("highlight.GoogleMaps"),
      label: t("highlight.orderGoogleMaps"),
    },
    {
      id: 12,
      img: hi12,
      subtitle: t("highlight.Members"),
      title: t("highlight.MembersTitle"),
      label: t("highlight.MembersDesc"),
    },
  ];
  const servicesComponent = [
    {
      id: 1,
      img: sv1,
      title: "Main POS Image",
      delayAnim: "100",
      bg: "rgb(255, 254, 231)",
    },
    {
      id: 2,
      img: sv2,
      title: t("service.orderQr"),
      delayAnim: "200",
      bg: "rgb(251, 241, 255)",
    },
    {
      id: 3,
      img: sv3,
      title: t("service.orderWaiter"),
      delayAnim: "300",
      bg: "rgb(238, 251, 250)",
    },
    {
      id: 4,
      img: sv4,
      title: t("service.orderQrTable"),
      delayAnim: "400",
      bg: "#ffebda",
    },
    {
      id: 5,
      img: sv5,
      title: t("service.orderSeperate"),
      delayAnim: "100",
      bg: "rgb(255, 254, 231)",
    },
    {
      id: 6,
      img: sv6,
      title: t("service.orderQrSeperateBill"),
      delayAnim: "200",
      bg: "rgb(251, 241, 255)",
    },
    {
      id: 7,
      img: sv7,
      title: "Online Order",
      delayAnim: "300",
      bg: "rgb(238, 251, 250)",
    },
    {
      id: 8,
      img: sv8,
      title: "Pomp EDC",
      delayAnim: "400",
      bg: "#ffebda",
    },
  ];
  const featureComponent = [
    {
      id: 1,
      img: fe1,
      title: "ระบบ POS รูปแบบใหม่",
      icon: (
        <ManageAccountsOutlined className="text-primary" sx={{ mt: "8px" }} />
      ),
      description:
        "แอปพลิเคชั่นจัดการร้านอาหารที่ใช้งานง่าย รวมฟีเจอร์ครบครัน ช่วยให้การจัดการร้านอาหารเป็นเรื่องง่ายดายและมีประสิทธิภาพสูงสุด ",
    },
    {
      id: 2,
      img: fe2,
      title: "ระบบคิวร้านอาหาร",
      icon: <InventoryOutlined className="text-primary" sx={{ mt: "8px" }} />,
      description:
        "จัดการคิวลูกค้าอย่างมืออาชีพ ลดเวลาในการรอคอย เพิ่มความพึงพอใจให้กับลูกค้าและเพิ่มประสิทธิภาพในการบริการ",
    },
    {
      id: 3,
      img: fe3,
      title: "ระบบสั่งอาหารออนไลน์ผ่านเว็บไซต์",
      icon: (
        <AddShoppingCartOutlined className="text-primary" sx={{ mt: "8px" }} />
      ),
      description:
        "แพลตฟอร์มสั่งอาหารออนไลน์ที่สะดวกและรวดเร็ว รองรับการสั่งซื้อผ่านเว็บไซต์ เพิ่มช่องทางในการขายและเข้าถึงลูกค้าได้มากขึ้น",
    },
    {
      id: 4,
      img: fe4,
      title: "ระบบ Payment รับชำระเงินอัตโนมัติ",
      icon: (
        <QrCodeScannerOutlined className="text-primary" sx={{ mt: "8px" }} />
      ),
      description:
        "สามารถชำระเงินได้หลายหลายช่องทางผ่านบริการของธนาคารกสิกรไทยพาร์ทเนอร์ของ POMPKINS รวดเร็ว และปลอดภัย",
    },
  ];
  return {
    languageOptions,
    accountOptions,
    myAccountOptions,
    guestOptions,
    highlightComponent,
    servicesComponent,
    featureComponent,
  };
}

export default Options;
