import axios from "axios";
import { getCookie } from "../getToken";
import { jwtDecode } from "jwt-decode";

export async function isFriendship(accessToken) {
  // Cannot use axios in this function
  try {
    const response = await axios.get(
      `https://api.line.me/friendship/v1/status`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data.friendFlag;
  } catch (error) {
    console.error("Error checking friendship:", error);
  }
}

export function getLineUUID() {
  const token = getCookie("token");
  if (!token) return false;
  const decoded = jwtDecode(token);
  if (typeof decoded !== "object" || decoded.type !== "line") return false;
  return decoded.uuid;
}
