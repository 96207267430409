import { useEffect, useState } from "react";
import { useAuthStore } from "../stores/useAuthStore";
import { refreshVerify } from "../libs/authAPI/loginAPI";
import { Outlet } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import { useLocationStore } from "../stores/useLocationStore";
import { useGuestLogin } from "../stores/useGuestLogin";
import { getCookie } from "../utils/getToken";
import { getCustomerByUUID } from "../libs/customerAPI/getCustomer";
import { jwtDecode } from "jwt-decode";

export const PresistLogin = () => {
  const { login, logout, isAuthenticated } = useAuthStore();
  const { setGuest, guest_user } = useGuestLogin();
  const [isLoading, setIsLoading] = useState(true);
  const { setCurrentLocation, setCurrentAddress } = useLocationStore();
  useEffect(() => {
    if (!isAuthenticated) {
      if (isLoading) {
        const token = getCookie("token");
        const guestDataString = localStorage.getItem("guest");
        const guestData = JSON.parse(guestDataString);
        const address = localStorage.getItem("address");
        const locationString = localStorage.getItem("location");
        const location = JSON.parse(locationString);
        if (token) {
          const decoded = jwtDecode(token);
          getCustomerByUUID(decoded.uuid)
            .then((res) => {
              const user = {
                email: res.data.email,
                first_name: res.data.first_name,
                last_name: res.data.last_name,
                phone: res.data.phone,
                uuid: res.data.uuid,
                favorite: res.data.favorite_bmerchants ?? [],
              };
              login(user);
            })
            .catch(() => {
              logout();
            });
          // refreshVerify(token)
          //   .then((res) => {

          //   })
          //   .catch(() => {
          //     logout();
          //   });
        } else if (guestData) {
          setGuest(guestData);
        } else {
          logout();
        }

        setCurrentAddress(address);
        setCurrentLocation(location);

        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [isAuthenticated, isLoading, login, logout]);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <main>
          <Outlet />
        </main>
      )}
    </>
  );
};
