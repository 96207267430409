import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import CartCard from "./CartCard";
import emptyCartImg from "../../assets/image/empty-cart.png";
import { useNavigate } from "react-router-dom";
import { ArrowBackIosNewSharp } from "@mui/icons-material";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

function CartListPage() {
  const { t } = useTranslation();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/cart",
      title: "CartListPage",
    });
  }, []);
  const [allMerchantData, setAllMerchantData] = useState({});
  const navigator = useNavigate();
  useEffect(() => {
    const getAllMerchantsFromLocalStorage = () => {
      let merchants = {};

      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.startsWith("merchant_")) {
          merchants[key] = JSON.parse(localStorage.getItem(key));
        }
      }

      return merchants;
    };

    setAllMerchantData(getAllMerchantsFromLocalStorage());
  }, []);

  const handleDelete = (id) => {
    localStorage.removeItem(`merchant_${id}`);
    const updatedallMerchantData = { ...allMerchantData };
    delete updatedallMerchantData[`merchant_${id}`];
    setAllMerchantData(updatedallMerchantData); // Updated this line
  };

  return (
    <>
      <Helmet>
        <title>
          {t("pompkins")} - {t("cart.list")}
        </title>
        <meta
          name="description"
          content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
        />
        <link
          rel="canonical"
          href={`https://pompkins.com${window.location.pathname}`}
        />
        <meta property="og:url" content="https://pompkins.com/cart" />
      </Helmet>
      <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            textAlign: "start",
            flexDirection: "column",
            py: 4,
            width: ["90%", "80%", "70%"],
          }}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
            {t("cart.list")}
          </Typography>
          {Object.keys(allMerchantData).length > 0 ? (
            Object.entries(allMerchantData).map(([key, merchant]) => (
              <Box key={key} sx={{ my: 1, width: "100%" }}>
                <CartCard
                  item={merchant}
                  id={key.replace("merchant_", "")}
                  handleDelete={handleDelete}
                />
              </Box>
            ))
          ) : (
            <Box
              sx={{
                mt: 4,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
                mb: 2,
              }}
            >
              <img
                src={emptyCartImg}
                alt="empty cart img"
                title="empty cart img"
                loading="lazy"
                width="159px"
                height="auto"
                style={{ width: "159px" }}
              />
              <Typography>{t("cart.noItem")}</Typography>
              <Button
                variant="outlined"
                startIcon={<ArrowBackIosNewSharp sx={{ width: "15px" }} />}
                onClick={() => navigator("/food")}
              >
                {t("merchant.backToHomePage")}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default CartListPage;
