import React, { useCallback, useEffect, useState } from "react";
import {
  getOrdersByOrders_uuid,
  updateOrdersStatus,
} from "../../libs/customerAPI/getOrders";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import OrderItem from "./OrderItem";
import LoadingScreen from "../../components/LoadingScreen";
import QRCodeGenerator from "../../components/QrGen";
import PaymentFailed from "./PaymentFailed";
import { startTimer } from "../../utils/startTimer";
import PaymentSuccess from "./PaymentSuccess";
import useModalLoading from "../../stores/useModalLoading";
import {
  checkKsherPaymentStatus,
  checkPaymentStatus,
  getGatewayPayKSher,
  getKBankOauthToken,
  getPaymentMerchantData,
  getPaymentThaiQR,
} from "../../libs/paymentApi";
import {
  getMerchantById,
  getServiceBMerchant,
} from "../../libs/merchantAPI/merchantAPI";

import thaiQrPayment from "../../assets/images/thai_qr_payment.png";
import OrderCancelled from "../OrderStatus/OrderCancelled";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { paymentData } from "../CartPage/paymentData";
import { checkDeviceOs } from "../../utils/checkOS";
function PaymentPage() {
  const { t } = useTranslation();
  const {
    order_uuid,
    bmerchant_uuid,
    merchant_id,
    bmerchant_id,
    transaction_uuid,
  } = useParams();
  const [orders, setOrders] = useState();
  const [timeLeft, setTimeLeft] = useState(startTimer("payment", order_uuid));
  const [totalAmount, setTotalAmount] = useState(0);
  const [qrString, setQrString] = useState("");
  const [paymentStatus, setPaymentStatus] = useState(false);
  const { openModal, closeModal } = useModalLoading();
  const [partnerTxnUid, setPartnerTxnUid] = useState("");
  const [bankName, setBankName] = useState("");
  const [refId, setRefId] = useState("");
  const navigator = useNavigate();
  const [isCancel, setIsCancel] = useState(false);
  const [service, setService] = useState({});
  const [merchantName, setMerchantName] = useState("");
  const [paymentMerchantId, setPaymentMerchantId] = useState("");
  const [payment, setPayment] = useState();
  const { isIOS, isAndroid } = checkDeviceOs();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/order-status/${merchant_id}/${bmerchant_id}/payment/${bmerchant_uuid}/${order_uuid}/${transaction_uuid}`,
      title: "PaymentPage",
    });
  }, []);

  useEffect(() => {
    getOrdersByOrders_uuid(order_uuid).then((order_res) => {
      // console.log("order_res", order_res);
      // order_res.payment_method -> map channel with paymentData
      const paymentOrder = paymentData.find((payment) => {
        return payment.paymentType === order_res.data.payment_method;
      });
      setPayment(paymentOrder);
      setOrders(order_res.data);
      setTotalAmount(order_res.data.total_amount);

      // console.log("merchant_id", merchant_id);

      // console.log("bmerchant_uuid", bmerchant_uuid);

      // console.log("paymentOrder", paymentOrder?.channel);
      // console.log("order_uuid", order_uuid);

      getPaymentMerchantData(merchant_id, bmerchant_uuid, paymentOrder?.channel)
        .then((res) => {
          // TODO: if res.data.merchant_id === "" or null ?
          // console.log("Payment res", res.data);
          setPaymentMerchantId(res.data.merchant_id);
        })
        .catch((error) => {
          console.error("getPaymentMerchantData", error);
        });
    });

    getServiceBMerchant(bmerchant_uuid).then((res) => {
      setService(res.data);
    });
    getMerchantById(merchant_id).then((res) => {
      setMerchantName(res.data.name);
    });
  }, [bmerchant_uuid, merchant_id]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevRemainingTime) =>
        prevRemainingTime > 0 ? prevRemainingTime - 1 : 0
      );
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const checkPayment = useCallback(() => {
    let data = {
      merchantId: paymentMerchantId,
      partnerTxnUid: partnerTxnUid,
    };
    // console.log("Request data:", data);
    // console.log("Payment object:", payment);
    if (payment.channel === "KBNKQRPP" || payment.channel === "KBNKQRCC") {
      console.log("Checking payment status for KBNKQRPP or KBNKQRCC");
      checkPaymentStatus(data)
        .then((res) => {
          if (res.data === "paid") {
            setPaymentStatus(true);
          } else {
            console.log("Error Payment status is not paid:", res.data);
          }
        })
        .catch((error) => {
          console.error("checkPaymentStatus", error);
        });
    } else if (payment.channel === "KSHRONID") {
      console.log("Checking payment status for KSHRONID");
      checkKsherPaymentStatus(data)
        .then((res) => {
          console.log("res", res);
          if (res.data === "paid") {
            setPaymentStatus(true);
          } else {
            console.log("Payment status is not paid:", res.data);
          }
        })
        .catch((error) => {
          console.error("Error checkKsherPaymentStatus", error);
        });
    }
  }, [partnerTxnUid, payment, paymentMerchantId]);

  useEffect(() => {
    const fetchData = () => {
      if (paymentMerchantId !== "") {
        getOrdersByOrders_uuid(order_uuid)
          .then((res) => {
            checkPayment();
            if (res.data.order_status === "CANCELLED") {
              setIsCancel(true);
            }
            setOrders(res.data);
            if (res.data.payment_status === "PAID") {
              navigator(
                `/order-status/${merchant_id}/${bmerchant_id}/${order_uuid}`
              );
            }
            setTotalAmount(res.data.total_amount);
          })
          .catch((error) => {
            console.error("fetch order", error);
          });
      }
    };

    const intervalId = setInterval(fetchData, 5000); // Fetch data every 5 seconds

    // Initial fetch
    fetchData();

    return () => {
      clearInterval(intervalId); // Cleanup the interval on component unmount
    };
  }, [checkPayment, order_uuid, paymentMerchantId]);

  useEffect(() => {
    // console.log("paymentMerchantId", paymentMerchantId);
    // console.log("totalAmount", totalAmount);
    // console.log("payment", payment);
    if (
      totalAmount !== 0 &&
      paymentMerchantId !== "" &&
      payment.channel === "KBNKQRPP"
    ) {
      // KBANK (unused for online)
      openModal();
      let createTokenBody = {
        client_id: "RZGp1IqFoiqsGi9TKEBa3WA866tRuChQ",
        client_secret: "tn6bOnehYRa7CoTF",
      };
      getKBankOauthToken(createTokenBody).then((tokenRes) => {
        let accessToken = tokenRes.data.access_token;
        let genQrBody = {
          merchantId: paymentMerchantId,
          qrType: "3",
          reference1: "ONLINE",
          reference2: `${transaction_uuid}`,
          reference3: `${bmerchant_uuid}`,
          reference4: paymentMerchantId,
          txnAmount: `${totalAmount}`,
          txnCurrencyCode: "THB",
        };
        getPaymentThaiQR(genQrBody, accessToken).then((genQrRes) => {
          setQrString(genQrRes.data.qrCode);
          setBankName(genQrRes.data.accountName);
          let qrIdRef = genQrRes.data.partnerTxnUid;
          setPartnerTxnUid(genQrRes.data.partnerTxnUid);
          const startIndex = qrIdRef.indexOf("MER") + 3; // Adding 3 to skip "MER"
          const endIndex = qrIdRef.indexOf("T");
          const extractedString = qrIdRef.substring(startIndex, endIndex);
          setRefId(extractedString);
          closeModal();
        });
      });
    } else if (
      totalAmount !== 0 &&
      paymentMerchantId !== "" &&
      payment.channel === "KBNKQRCC"
    ) {
      // Credit QR
      openModal();
      let createTokenBody = {
        client_id: "RZGp1IqFoiqsGi9TKEBa3WA866tRuChQ",
        client_secret: "tn6bOnehYRa7CoTF",
      };
      // console.log("paymentMerchantId", paymentMerchantId);

      getKBankOauthToken(createTokenBody).then((tokenRes) => {
        let accessToken = tokenRes.data.access_token;
        let genQrBody = {
          merchantId: paymentMerchantId,
          qrType: "4",
          reference1: "ONLINE",
          reference2: `${transaction_uuid}`,
          reference3: `${bmerchant_uuid}`,
          reference4: paymentMerchantId,
          txnAmount: `${totalAmount}`,
          txnCurrencyCode: "THB",
        };
        getPaymentThaiQR(genQrBody, accessToken).then((genQrRes) => {
          setQrString(genQrRes.data.qrCode);
          setBankName(genQrRes.data.accountName);
          let qrIdRef = genQrRes.data.partnerTxnUid;
          setPartnerTxnUid(genQrRes.data.partnerTxnUid);
          const startIndex = qrIdRef.indexOf("MER") + 3; // Adding 3 to skip "MER"
          const endIndex = qrIdRef.indexOf("T");
          const extractedString = qrIdRef.substring(startIndex, endIndex);
          setRefId(extractedString);
          closeModal();
        });
      });
    } else if (
      totalAmount !== 0 &&
      paymentMerchantId !== "" &&
      payment.channel === "KSHRONID"
    ) {
      // KSHER We Chat / ALIPAY / Shopee Pay
      openModal();
      const data = {
        appid: paymentMerchantId,
        channel_list: payment.channelName,
        device: isIOS || isAndroid ? "H5" : "PC",
        fee_type: "THB",
        mch_redirect_url: `${process.env.REACT_APP_BASE_WEB_URL}/order-status/${merchant_id}/${bmerchant_id}/${order_uuid}`,
        mch_redirect_url_fail: `${process.env.REACT_APP_BASE_WEB_URL}/order-status/${merchant_id}/${bmerchant_id}/${order_uuid}/failed`,
        product_name: merchantName.replace(/[+&\/$%]/g, ""),
        refer_url: `${
          process.env.REACT_APP_BASE_WEB_URL
        }/${merchant_id}/${bmerchant_id}/${merchantName.replace(/ /g, "-")}`,
        total_fee: totalAmount * 100,
        // total_fee: 0.01 * 100,
        lang: "th",
        attach: JSON.stringify({
          ref1: "ONLINE",
          ref2: `${transaction_uuid}`,
          ref3: `${bmerchant_uuid}`,
          ref4: paymentMerchantId,
        }),
        uuid: transaction_uuid.replace(/-/g, ""),
      };
      setPartnerTxnUid(transaction_uuid.replace(/-/g, ""));

      const newWindow = window.open("", "_blank");
      getGatewayPayKSher(data)
        .then((response) => {
          if (response.data.code === 0 && response.data.msg === "SUCCESS") {
            const payContentUrl = response.data.data.pay_content;
            newWindow.location.href = payContentUrl;
          } else {
            newWindow.close();
          }
        })
        .catch((error) => {
          console.error("Payment initiation error:", error);
          newWindow.close();
          closeModal();
        });
      closeModal();
    }
  }, [
    bmerchant_uuid,
    totalAmount,
    paymentMerchantId,
    payment,
    openModal,
    transaction_uuid,
    closeModal,
    merchant_id,
    bmerchant_id,
    order_uuid,
    merchantName,
  ]);

  const handleCancelOrder = () => {
    const status = {
      status: "CANCELLED",
      transaction_uuid: transaction_uuid,
    };
    updateOrdersStatus(order_uuid, status).then(() => {
      setIsCancel(true);
    });
  };

  if (timeLeft === 0) {
    const status = {
      status: "CANCELLED",
      transaction_uuid: transaction_uuid,
    };
    updateOrdersStatus(order_uuid, status);
    return <PaymentFailed isCancel={isCancel} />;
  }

  if (isCancel) {
    return <PaymentFailed isCancel={isCancel} />;
  }

  const deliver_fee =
    orders && orders.order_type === "DELIVERY" ? orders.deliver_fee : 0;
  const subtotal = service.vat
    ? (totalAmount - deliver_fee) / 1.07
    : totalAmount - deliver_fee;
  const vat = service.vat ? totalAmount - deliver_fee - subtotal : 0;

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  if (paymentStatus) {
    localStorage.removeItem(`paymentTimer_${order_uuid}`);
    return (
      <PaymentSuccess
        transaction_uuid={transaction_uuid}
        merchant_id={merchant_id}
        bmerchant_id={bmerchant_id}
        order_uuid={order_uuid}
        paymentType={payment.paymentType}
      />
    );
  }

  if (qrString === "") {
    return <LoadingScreen />;
  }
  return (
    <>
      <Helmet>
        <title>
          {t("pompkins")} - {t("payment")}
        </title>
        <meta
          name="description"
          content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
        />
        <link
          rel="canonical"
          href={`https://pompkins.com${window.location.pathname}`}
        />
        <meta property="og:url" content="https://pompkins.com/order-status" />
      </Helmet>
      <Box
        sx={{
          maxWidth: 1440,
          margin: "auto",
          mb: 6,
          padding: { xs: "2% 5%", sm: "2% 20%", md: "2% 24%" },
        }}
      >
        <Card sx={{ borderRadius: "15px" }}>
          <CardContent>
            <Box sx={{ textAlign: "center" }}>
              <Typography fontSize={"20px"} fontWeight={700}>
                {merchantName}
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Typography>
                หมายเลขคำสั่งซื้อ : {orders.order_no.slice(0, 5).toUpperCase()}
              </Typography>
            </Box>
            {/* การวนซ้ำผ่าน orders และการแสดงรายการในตะกร้า */}
            {orders.order_detail.map((menuItem, menuIndex) => (
              <OrderItem key={menuIndex} item={menuItem} />
            ))}

            {/* ส่วนสรุป */}
            <Typography variant="h6" sx={{ mt: 2 }}>
              สรุป
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
            >
              <Typography>ราคารวม</Typography>
              <Typography>{`฿ ${subtotal
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}</Typography>
            </Box>
            {service.vat && (
              <Box
                sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
              >
                <Typography>VAT (7%)</Typography>
                <Typography>{`฿ ${vat
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}</Typography>
              </Box>
            )}
            {orders.order_type === "DELIVERY" ? (
              <Box
                sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
              >
                <Typography>ค่าจัดส่ง</Typography>
                <Typography>{`฿ ${orders.deliver_fee.toFixed(2)}`}</Typography>
              </Box>
            ) : (
              <></>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                my: 1,
                fontWeight: "bold",
              }}
            >
              <Typography>ราคารวมทั้งสิ้น</Typography>
              <Typography>{`฿ ${totalAmount
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}</Typography>
            </Box>
            {/* gen qr code */}
            <Typography variant="h6" sx={{ mt: 2, mb: 3 }}>
              ชำระเงินผ่าน QR Code
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                m: "0 auto",
                border: "1px solid #000000",
                borderRadius: "10px",
                width: ["auto", "350px", "400px"],
              }}
            >
              <img
                src={thaiQrPayment}
                alt="header thai qr"
                title="header thai qr"
                loading="lazy"
                width="auto"
                height="auto"
                style={{
                  marginBottom: "8px",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              />
              <QRCodeGenerator qrString={qrString} />
              <Typography sx={{ fontSize: "18px", mt: 2, fontWeight: 600 }}>
                {merchantName}
              </Typography>
              <Typography sx={{ fontSize: "16px" }}>
                บัญชี: {bankName}
              </Typography>
              <Typography sx={{ fontSize: "16px", mb: "32px" }}>
                เลขอ้างอิง: {refId}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                my: 3,
              }}
            >
              <Typography
                sx={{ fontSize: "22px", fontWeight: 600, color: "red" }}
              >
                * เมื่อชำระเงินแล้วไม่สามารถขอเงินคืนได้
              </Typography>
              <Typography>
                กรุณาสแกน QR Code เพื่อชำระเงิน หรือบันทึกภาพ QR Code
                และนำไปสแกนชำระเงินผ่านแอปธนาคาร ภายในเวลาที่กำหนด
              </Typography>

              <Typography sx={{ fontSize: "18px", mt: 2, fontWeight: 500 }}>
                {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
              </Typography>
            </Box>
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                onClick={handleCancelOrder}
                sx={{ borderRadius: 5, width: ["100%", "50%"] }}
              >
                ยกเลิกออเดอร์
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export default PaymentPage;
