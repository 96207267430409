import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import TextHeader from "../../components/TextHeader";
import SubSectionText from "../../components/SubSectionText";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

function TermsPage() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/terms-and-conditions",
      title: "TermsPage",
    });
  }, []);
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "white",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box sx={{ p: 2, px: [4, 4, 14] }}>
        <TextHeader text={t("term.title")} sx={{ mb: 2 }} />
        <Typography sx={{ textIndent: "3em", mb: 2 }}>
          {t("term.desc")}{" "}
          <a
            target="_blank"
            rel="noreferrer"
            className="text-blue-500 underline "
            href="https://pompkins.com"
          >
            https://pompkins.com
          </a>
        </Typography>
        <Typography fontWeight={700} gutterBottom>
          {t("term.1")}
        </Typography>
        <SubSectionText text={t("term.1_1")} number={"1.1"} />
        <SubSectionText number={"1.2"} text={t("term.1_2")} />
        <SubSectionText number={"1.3"} text={t("term.1_3")} />
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("term.2")}
        </Typography>
        <Typography>{t("term.2Desc")}</Typography>

        <SubSectionText number={"2.1"} text={t("term.2_1")} />
        <SubSectionText number={"2.2"} text={t("term.2_2")} />
        <SubSectionText number={"2.3"} text={t("term.2_3")} />

        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("term.3")}{" "}
        </Typography>
        <Typography>{t("term.3Desc")}</Typography>
        <SubSectionText number={"1."} text={t("term.3_1")} />
        <SubSectionText number={"2."} text={t("term.3_2")} />
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("term.4")}{" "}
        </Typography>
        <SubSectionText number={"4.1"} text={t("term.4_1")} />
        <SubSectionText number={"4.2"} text={t("term.4_2")} />
        <SubSectionText number={"4.3"} text={t("term.4_3")} />
        <SubSectionText number={"4.4"} text={t("term.4_4")} />
        <SubSectionText number={"4.5"} text={t("term.4_5")} />
        <SubSectionText number={"4.6"} text={t("term.4_6")} />
        <SubSectionText number={"4.7"} text={t("term.4_7")} />
        <SubSectionText number={"4.8"} text={t("term.4_8")} />
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("term.5")}
        </Typography>
        <SubSectionText number={"5.1"} text={t("term.5_1")} />
        <SubSectionText number={"5.2"} text={t("term.5_2")} />
        <SubSectionText
          number={"5.2.1"}
          text={t("term.5_2_1")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"5.2.2"}
          text={t("term.5_2_2")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"5.2.3"}
          text={t("term.5_2_3")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"5.2.4"}
          text={t("term.5_2_4")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"5.2.5"}
          text={t("term.5_2_5")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"5.2.6"}
          text={t("term.5_2_6")}
          sx={{ ml: 6 }}
        />
        <SubSectionText number={"5.3"} text={t("term.5_3")} />
        <SubSectionText
          number={"5.3.1"}
          text={t("term.5_3_1")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"5.3.2"}
          text={t("term.5_3_2")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"5.3.3"}
          text={t("term.5_3_3")}
          sx={{ ml: 6 }}
        />
        <SubSectionText number={"5.4"} text={t("term.5_4")} />

        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("term.6")}{" "}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>{t("term.6Desc")}</Typography>
        <Typography sx={{ textIndent: "3em", mt: 1 }}>
          {t("term.6Desc_2")}
        </Typography>
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("term.7")}{" "}
        </Typography>
        <SubSectionText number={"7.1"} text={t("term.7_1")} />
        <SubSectionText number={"7.2"} text={t("term.7_2")} />
        <SubSectionText
          number={"7.2.1"}
          text={t("term.7_2_1")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"7.2.2"}
          text={t("term.7_2_2")}
          sx={{ ml: 6 }}
        />
        <SubSectionText number={"7.3"} text={t("term.7_3")} />
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("term.8")}
        </Typography>
        <SubSectionText number={"8.1"} text={t("term.8_1")} />
        <SubSectionText number={"8.2"} text={t("term.8_2")} />
        <SubSectionText
          number={"8.2.1"}
          text={t("term.8_2_1")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"8.2.2"}
          text={t("term.8_2_2")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"8.2.3"}
          text={t("term.8_2_3")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"8.2.4"}
          text={t("term.8_2_4")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"8.2.5"}
          text={t("term.8_2_5")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"8.2.6"}
          text={t("term.8_2_6")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"8.2.7"}
          text={t("term.8_2_7")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"8.2.8"}
          text={t("term.8_2_8")}
          sx={{ ml: 6 }}
        />
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("term.9")}{" "}
        </Typography>
        <SubSectionText number={"9.1"} text={t("term.9_1")} />
        <SubSectionText number={"9.2"} text={t("term.9_2")} />
        <SubSectionText number={"9.3"} text={t("term.9_3")} />
        <SubSectionText number={"9.4"} text={t("term.9_4")} />
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("term.10")}{" "}
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {t("term.10Desc")}
        </Typography>
      </Box>
    </Box>
  );
}

export default TermsPage;
