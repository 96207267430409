import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  Fade,
  DialogContent,
  IconButton,
  Box,
  DialogActions,
  Button,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CookieToggleItem from "./CookieToggleItem";
import { useTranslation } from "react-i18next";
import { useCookieConsent } from "../stores/useCookieConsent";

CookiesSetting.defaultProps = {
  open: false,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

function CookiesSetting({ open, onClose, onAccept }) {
  const { t } = useTranslation();
  const { setCookieSetting, cookieSetting } = useCookieConsent();
  const settingData = [
    {
      title: "คุกกี้ที่จำเป็น",
      description:
        "คุกกี้เหล่านี้จำเป็นสำหรับการทำงานกับเว็บไซต์ และไม่สามารถปิดการใช้งานในระบบของเราได้ และโดยปกติแล้ว คุกกี้เหล่านี้จะถูกกำหนดไว้เพื่อตอบสนองต่อการดำเนินการร้องขอบริการของคุณ เช่น การตั้งค่าความเป็นส่วนตัว การเข้าสู่ระบบ หรือการกรอกแบบฟอร์ม คุณสามารถกำหนดให้เบราว์เซอร์ของคุณทำการบล็อก หรือเตือนให้คุณทราบเกี่ยวกับคุกกี้เหล่านี้ แต่นั่นอาจทำให้บางส่วนของไซต์ไม่สามารถทำงานได้",
      isDisable: true,
      toggle: cookieSetting.necessaryCookie,
      setToggle: () =>
        setCookieSetting({
          ...cookieSetting,
          necessaryCookie: !cookieSetting.necessaryCookie,
        }),
    },
    {
      title: "คุกกี้ประสิทธิภาพ",
      description:
        "คุกกี้เหล่านี้ช่วยเราในการนับจำนวนและที่มาของการเข้าชม เพื่อให้เราสามารถวัดและปรับปรุงประสิทธิภาพไซต์ของเราได้ นอกจากนี้ยังช่วยให้เราทราบว่าหน้าใดได้รับความนิยมมากที่สุดและน้อยที่สุด และทราบว่าผู้เยี่ยมชมเข้าดูส่วนใดบ้าง ข้อมูลทั้งหมดที่คุกกี้เหล่านี้จัดเก็บไว้จะถูกรวมไว้ด้วยกัน จึงทำให้ไม่สามารถระบุตัวตนได้ หากคุณไม่อนุญาตให้คุกกี้เหล่านี้ทำงาน เราจะไม่ทราบว่าคุณเข้าชมไซต์ของเราเมื่อใด",
      isDisable: false,
      toggle: cookieSetting.performanceCookie,
      setToggle: () =>
        setCookieSetting({
          ...cookieSetting,
          performanceCookie: !cookieSetting.performanceCookie,
        }),
    },
    {
      title: "คุกกี้การใช้งาน",
      description:
        "คุกกี้เหล่านี้ช่วยในการทำงานของไซต์และการปรับแต่งไซต์ให้เหมาะกับผู้ใช้ เช่น วิดีโอและการสนทนาสด คุกกี้เหล่านั้นอาจได้รับการกำหนดโดยเราหรือผู้ให้บริการจากบริษัทอื่นที่เราได้เพิ่มบริการของพวกเขาลงในหน้าเพจ หากคุณไม่อนุญาตให้คุกกี้เหล่านี้ทำงาน ฟังก์ชันบางอย่างของไซต์อาจทำงานไม่ถูกต้อง",
      isDisable: false,
      toggle: cookieSetting.functionalCookie,
      setToggle: () =>
        setCookieSetting({
          ...cookieSetting,
          functionalCookie: !cookieSetting.functionalCookie,
        }),
    },
    {
      title: "คุกกี้เพื่อการโฆษณา",
      description:
        "คุกกี้เหล่านี้จะถูกกำหนดผ่านไซต์ของเราโดยพาร์ทเนอร์โฆษณาของเรา โดยบริษัทดังกล่าวอาจใช้คุกกี้เหล่านี้เพื่อสร้างโปรไฟล์เกี่ยวกับความสนใจของคุณ และแสดงโฆษณาที่คุณสนใจบนไซต์อื่นๆ คุกกี้เหล่านี้ทำงานโดยการระบุแต่ละเบราว์เซอร์และอุปกรณ์ของคุณ หากคุณไม่อนุญาตให้คุกกี้เหล่านี้ทำงาน คุณจะไม่เห็นโฆษณาที่คุณเป็นเป้าหมายบนเว็บไซต์ต่างๆ",
      isDisable: false,
      toggle: cookieSetting.adsCookie,
      setToggle: () =>
        setCookieSetting({
          ...cookieSetting,
          adsCookie: !cookieSetting.adsCookie,
        }),
    },
  ];

  return (
    <Dialog open={open} TransitionComponent={Transition}>
      <DialogTitle>ศูนย์การตั้งค่าคุกกี้</DialogTitle>
      <IconButton
        onClick={onClose}
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
      <DialogContent>
        เมื่อคุณเข้าชมเว็บไซต์ของเรา
        เว็บไซต์ของเราจะจัดเก็บหรือดึงข้อมูลจากเบราว์เซอร์ของคุณซึ่งส่วนใหญ่อยู่ในรูปแบบของ
        cookie ข้อมูลเหล่านี้อาจเกี่ยวกับคุณ การตั้งค่าของคุณ อุปกรณ์ของคุณ
        หรือเพื่อช่วยให้เว็บไซต์ทำงานอย่างที่คุณต้องการ
        ซึ่งเป็นข้อมูลที่ไม่สามารถระบุตัวตนของคุณได้โดยตรง
        แต่ช่วยให้คุณใช้งานเว็บตามความต้องการส่วนบุคคลได้มากยิ่งขึ้น
        โดยที่เราเคารพสิทธิความเป็นส่วนตัวของคุณ คุณสามารถปิดการทำงานของ cookie
        บางประเภทได้ โปรดคลิกที่หัวข้อประเภทต่างๆ
        เพื่อดูข้อมูลเพิ่มเติมและเปลี่ยนการตั้งค่าเริ่มต้นในการใช้งาน cookie
        อย่างไรก็ตาม คุณควรทราบว่าการปิดการทำงานของ cookie
        บางประเภทอาจส่งผลต่อการใช้งานเว็บไซต์และบริการของเรา
        <Box mt={2}>
          {settingData.map((item, index) => (
            <React.Fragment key={index}>
              <CookieToggleItem
                title={item.title}
                description={item.description}
                isDisable={item.isDisable}
                isToggle={item.toggle}
                setToggle={item.setToggle}
              />
              {index !== settingData.length - 1 && <Box mt={2} />}
            </React.Fragment>
          ))}
        </Box>
      </DialogContent>
      <DialogActions sx={{ my: 1 }}>
        <Divider />
        <Button
          onClick={() => {
            onClose();
            setCookieSetting({
              necessaryCookie: true,
              performanceCookie: true,
              functionalCookie: true,
              adsCookie: true,
            });
            onAccept();
            document.cookie = `cookieSetting=${JSON.stringify({
              necessaryCookie: true,
              performanceCookie: true,
              functionalCookie: true,
              adsCookie: true,
            })}`;
          }}
          variant="contained"
          color="primary"
          sx={{ borderRadius: "2rem" }}
        >
          ยอมรับทั้งหมด
        </Button>
        <Button
          onClick={() => {
            onClose();
            setCookieSetting(cookieSetting);
            onAccept();
            document.cookie = `cookieSetting=${JSON.stringify(cookieSetting)}`;
          }}
          variant="outlined"
          sx={{ borderRadius: "2rem", marginLeft: "10px" }}
        >
          ยืนยันการตั้งค่า
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CookiesSetting;
