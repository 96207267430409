import React from "react";

const LogoNav = ({ logoSrc, width = "350px", height = "70px" }) => {
  return (
    <img
      src={logoSrc}
      alt="logo"
      title="logo"
      loading="lazy"
      width={width}
      height={height}
      style={{ width: width, height: height, objectFit: "cover" }}
    />
  );
};

export default LogoNav;
