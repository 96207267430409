import React from "react";
import EmailIcon from "../../assets/icon/email.png";
import { Button, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function CheckEmail() {
  const { t } = useTranslation();
  const location = useLocation();
  const email = location.state;
  return (
    <>
      <img
        src={EmailIcon}
        alt="logo"
        title="logo"
        loading="lazy"
        width={"50px"}
        height={"50px"}
        style={{
          width: "50px",
          height: "50px",
        }}
      />
      <Typography variant="h5" className="mt-3 font-bold">
        {t("checkEmail.title")}{" "}
      </Typography>
      <Typography className="text-[#a8a8a8]">
        {t("checkEmail.instruction")}{" "}
      </Typography>
      <Link to="/login/new?step=login" state={email}>
        <Button
          variant="contained"
          fullWidth
          sx={{
            mt: 2,
            borderRadius: 6,
          }}
        >
          {t("btn.backToLogin")}{" "}
        </Button>
      </Link>
    </>
  );
}

export default CheckEmail;
