import React from "react";
import {
  Dialog,
  IconButton,
  DialogContent,
  Box,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";

ModalQrCode.defaultProps = {
  open: false,
};

function ModalQrCode({ open, setOpen }) {
  const { token, merchant_id, bmerchant_id, tableNumber, transaction_uuid } =
    useParams();
  const currentURL = window.location.href;
  const baseURL = currentURL.split("/").slice(0, 3).join("/");
  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "20px",
        },
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "end", mt: 1 }}>
        <IconButton
          color="inherit"
          onClick={() => setOpen(false)}
          aria-label="close"
        >
          <CloseIcon sx={{ color: "#FF961B" }} />
        </IconButton>
      </Box>
      <DialogContent>
        <Box
          sx={{
            mx: [0, 2, 5],
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <QRCodeSVG
            size={"300px"}
            value={`${baseURL}/table-merchant/${token}/${merchant_id}/${bmerchant_id}/${tableNumber}/${transaction_uuid}`}
          />
          <Typography fontSize="20px" sx={{ mt: 2 }}>
            Table
            <span style={{ color: "#FF961B", fontWeight: 1000 }}>
              {" "}
              No.{tableNumber}{" "}
            </span>
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ModalQrCode;
