import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import MenuShopCard from "./MenuShopCard";

const MenuSection = ({
  object,
  index,
  scrollCategoryRefs,
  cartList,
  setCartList,
  handleQuantityChange,
  openNoPayment,
  openNoService,
  openCloseModal,
}) => {
  return (
    <Box key={index} sx={{ px: 2 }}>
      <Typography
        variant="h6"
        sx={{
          mt: 2,
          mb: 2,
          fontWeight: "bold",
        }}
        ref={scrollCategoryRefs[index]}
      >
        {object.category.name}
      </Typography>
      <Grid container justifyContent="space-between">
        {object.menu.map((menus, i) => {
          if (Array.isArray(menus.options) && Array.isArray(menus.options_id)) {
            const optionsIdMap = new Map(
              menus.options_id.map((id, index) => [id, index])
            );
            menus.options = menus.options.sort(
              (a, b) => optionsIdMap.get(a.id) - optionsIdMap.get(b.id)
            );
          }
          return (
            <Grid key={menus.id} item xs={12} sm={5.85}>
              <MenuShopCard
                items={menus}
                cartList={cartList}
                setCartList={setCartList}
                onIncrement={() => handleQuantityChange(menus.id, 1)}
                onDecrement={() => handleQuantityChange(menus.id, -1)}
                openNoPayment={openNoPayment}
                openNoService={openNoService}
                openCloseModal={openCloseModal}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default MenuSection;
