import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import {
  Avatar,
  CardActionArea,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  useMediaQuery,
} from "@mui/material";
import ReactLoading from "react-loading";
import { GpsFixed, Search } from "@mui/icons-material";
import { useLocationStore } from "../stores/useLocationStore";
import { useTranslation } from "react-i18next";
import locationWithBg from "../assets/images/locationwithbg.png";
import { getAddressFromCoordinates } from "../utils/convertAddress";
import {
  getBMerchant,
  getMenuByBMerchantId,
  getMerchant,
} from "../libs/merchantAPI/merchantAPI";
import { useLocation, useNavigate } from "react-router-dom";
import { DistanceCalculator } from "../utils/calDistance";
import { useTheme } from "@emotion/react";
import DefaultProfile from "../assets/images/pompkins_food_logo.png";
import { height, width } from "@mui/system";
import { isMerchantOpen } from "../libs/isMerchantOpen";

// const GOOGLE_MAPS_API_KEY = "AIzaSyCf0iF-Wk0h6A32W7DgVDS1BjYABfanT0Q";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const CustomPaper = (props) => {
  return (
    <Paper
      {...props}
      sx={{
        position: "absolute",
        zIndex: 1,
        mt: 2,
        left: 0,
        right: 0,
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
        borderRadius: "20px",
      }}
    />
  );
};

const GoogleMaps = React.forwardRef((props, ref) => {
  const [value, setValue] = React.useState(props.propsValue);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [merchants, setMerchants] = React.useState([]);
  const navigate = useNavigate();
  const loaded = React.useRef(false);
  const { t } = useTranslation();
  const {
    currentAddress,
    setCurrentAddress,
    setCurrentLocation,
    fetchCurrentLocation,
    isLoading,
    setIsLoading,
    currentLocation,
    openModal,
  } = useLocationStore();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  React.useEffect(() => {
    const fetchMerchants = async () => {
      try {
        const [merchantsResponse, bMerchantsResponse] = await Promise.all([
          getMerchant(),
          getBMerchant(),
        ]);
        // console.log("merchantResponse", merchantsResponse);
        // console.log("bMerchantResponse", bMerchantsResponse);
        const merchants = await Promise.all(
          bMerchantsResponse.data.map(async (item) => {
            const location = { lat: item.latitude, lng: item.longitude };

            const correspondingMerchant = merchantsResponse.data.find(
              (merchant) => merchant.id === item.merchant_id
            );
            item.merchant_name = correspondingMerchant
              ? correspondingMerchant.name
              : item.name;
            item.type =
              correspondingMerchant.shop_type === ""
                ? "ร้านอาหารทั่วไป"
                : correspondingMerchant.shop_type || "ร้านอาหารทั่วไป";
            item.logo = correspondingMerchant.logo || DefaultProfile;
            item.bmerchantId = item.id;
            item.isOpen = isMerchantOpen(item.open_time, item.close_time);
            item.distance = `${
              DistanceCalculator(currentLocation, location).distance ?? "2"
            } Km`;
            item.time = DistanceCalculator(currentLocation, location).time;
            item.name = correspondingMerchant
              ? correspondingMerchant.name
              : item.name;
            item.title = correspondingMerchant
              ? correspondingMerchant.name
              : item.name;
            item.location = {
              lat: item.latitude,
              lng: item.longitude,
              address: {
                address_formatted: item.location,
              },
            };
            item.bmerchantUUID = item.uuid;
            item.rating = item.rating.toFixed(2) ?? 0;

            // const menuResponse = await getMenuByBMerchantId(item.uuid);
            // console.log("menuResponse", menuResponse);
            // item.menus = menuResponse.data.map((menu) => menu.name);
            return item;
          })
        );
        setMerchants(merchants);
      } catch (error) {
        console.error("Error fetching merchants:", error);
      }
    };

    fetchMerchants();
  }, []);

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        if (autocompleteService.current) {
          autocompleteService.current.getPlacePredictions(request, callback);
        }
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }

    if (inputValue === "") {
      setOptions(
        value ? [value, ...merchants.slice(0, 5)] : merchants.slice(0, 5)
      );
      // setOptions(value ? [value] : []);
      return undefined;
    }

    const fetchOptions = () => {
      // Filter merchants based on input
      const filteredMerchants = merchants.filter((merchant) => {
        return (
          merchant.name.toLowerCase().includes(inputValue.toLowerCase()) ||
          merchant.merchant_name
            .toLowerCase()
            .includes(inputValue.toLowerCase()) ||
          merchant.logo ||
          merchant.type.toLowerCase().includes(inputValue.toLowerCase()) ||
          merchant.contract_number
            .toLowerCase()
            .includes(inputValue.toLowerCase()) ||
          merchant.location.address.address_formatted
            .toLowerCase()
            .includes(inputValue.toLowerCase()) ||
          (merchant.menus &&
            merchant.menus.some((menu) =>
              menu.toLowerCase().includes(inputValue.toLowerCase())
            ))
        );
      });

      // Fetch Google Maps suggestions
      // fetch({ input: inputValue }, (results) => {
      //   if (active) {
      //     let newOptions = [];

      //     if (value) {
      //       newOptions = [value];
      //     }

      //     if (results) {
      //       newOptions = [...newOptions, ...results];
      //     }

      //     // Combine Google Maps suggestions with filtered merchants
      //     setOptions([...filteredMerchants, ...newOptions]);
      //   }
      // });
      setOptions([...filteredMerchants]);
    };

    fetchOptions();

    return () => {
      active = false;
    };
  }, [inputValue, fetch, value, merchants]);

  const handleSearchInMap = () => {
    if (!currentLocation) {
      openModal();
    } else {
      navigate("/explore");
    }
  };

  const sortByIsOpen = (a, b) => {
    if (a.isOpen && !b.isOpen) {
      return -1;
    }
    if (!a.isOpen && b.isOpen) {
      return 1;
    }
    return 0;
  };

  const handleSearch = (searchQuery) => {
    if (searchQuery === "") {
      props.setSearchFetch(false);
    } else {
      const filteredMerchants = merchants.filter(
        (merchant) =>
          merchant.name.toLowerCase().includes(inputValue.toLowerCase()) ||
          merchant.merchant_name
            .toLowerCase()
            .includes(inputValue.toLowerCase()) ||
          merchant.type.toLowerCase().includes(inputValue.toLowerCase()) ||
          merchant.contract_number
            .toLowerCase()
            .includes(inputValue.toLowerCase()) ||
          merchant.location.address.address_formatted
            .toLowerCase()
            .includes(inputValue.toLowerCase()) ||
          (merchant.menus &&
            merchant.menus.some((menu) =>
              menu.toLowerCase().includes(inputValue.toLowerCase())
            ))
      );
      props.setSearchFetch(true);
      const sortIsOpen = filteredMerchants.sort(sortByIsOpen);
      props.setMerchantSearchValue(sortIsOpen);
    }
    if (ref.current) {
      ref.current.blur();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      props.setSearchParams({ search: e.target.value });
      handleSearch(e.target.value);
      scrollToResults();
      if (ref.current) {
        ref.current.blur();
      }
    }
  };

  const scrollToResults = () => {
    if (props.searchResultsRef.current) {
      const yOffset = isMobile ? -130 : -100;
      const y =
        props.searchResultsRef.current.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const handleShowAll = () => {
    if (!currentLocation) {
      openModal();
    } else {
      return;
      // navigate("/food");
    }
  };

  return (
    <Autocomplete
      id="google-map-demo"
      sx={{ width: "100%", borderRadius: 4 }}
      disableClearable
      isOptionEqualToValue={(option, value) => {
        if (!option || !value) {
          return false;
        }
        return (
          option.name.toLowerCase() === value.toLowerCase() ||
          option.merchant_name.toLowerCase() === value.toLowerCase() ||
          option.contract_number.toLowerCase() === value.toLowerCase() ||
          option.type.toLowerCase() === value.toLowerCase()
        );
      }}
      PaperComponent={CustomPaper}
      getOptionLabel={(option) => {
        if (option.text) {
          return option.text;
        }
        if (option.merchant_name) {
          return option.merchant_name;
        }
        if (option.name) {
          return option.name;
        }
        return "";
      }}
      filterOptions={(options, { inputValue }) => {
        const filteredOptions = options.filter((option) => {
          if (option.key === "search_maps") return true;
          if (!option.merchant_name) return false;
          return (
            option.merchant_name
              .toLowerCase()
              .includes(inputValue.toLowerCase()) ||
            option.type.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.contract_number
              .toLowerCase()
              .includes(inputValue.toLowerCase()) ||
            option.location.address.address_formatted
              .toLowerCase()
              .includes(inputValue.toLowerCase()) ||
            (option.menus &&
              option.menus.some((menu) =>
                menu.toLowerCase().includes(inputValue.toLowerCase())
              ))
          );
        });
        return filteredOptions;
      }}
      options={[
        { key: "search_maps", text: "Search In Maps" },
        ...options,
        // { key: "show_all", text: "Show All" },
      ]}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value || null}
      noOptionsText="No locations or merchants"
      onChange={(event, newValue) => {
        props.handleAutocompleteChange();
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        // if (newValue && newValue.type) {
        //   // Handle merchant selection
        //   console.log("Selected merchant:", newValue);
        // } else if (newValue && newValue.place_id) {
        //   // Handle Google Maps location selection (existing logic)
        //   const geocoder = new window.google.maps.Geocoder();
        //   geocoder.geocode(
        //     { placeId: newValue.place_id },
        //     (results, status) => {
        //       if (status === "OK" && results[0]) {
        //         const { lat, lng } = results[0].geometry.location;
        //         setCurrentLocation({ lat: lat(), lng: lng() });
        //       }
        //     }
        //   );
        // }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t("home.searchBox")}
          variant="standard"
          onKeyDown={handleKeyDown}
          inputRef={ref}
          sx={{
            mt: 2,
            mx: 4,
            py: 1,
            px: 2,
            "@media (min-width:600px) and (max-width:700px)": {
              width: "calc(100% - 200px)",
            },
            "@media (min-width:900px) and (max-width:1400px)": {
              width: "calc(100% - 200px)",
            },
            width: [
              "calc(100% - 60px)",
              "calc(100% - 400px)",
              "calc(100% - 400px)",
              "calc(100% - 620px)",
            ],
            ".MuiInputBase-root": {
              fontSize: "1.1rem",
            },
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#FFFFFF",
            borderRadius: "24px",
            transition: "width 0.3s ease, box-shadow 0.3s ease",
            "&:focus-within": {
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
            },
          }}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            type: "search",
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  onClick={(e) => {
                    const searchQuery = document.querySelector(
                      'input[type="search"]'
                    ).value;
                    handleSearch(searchQuery);
                    props.scrollToResults();
                  }}
                >
                  <Search sx={{ color: "#f9931f" }} />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: <></>,
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <Box>
            {option.key === "search_maps" && (
              <Typography
                key={option.key}
                variant="body1"
                sx={{
                  padding: "8px 16px",
                  color: "#f9931f",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => handleSearchInMap()}
              >
                {t("searchInMap")}
              </Typography>
            )}
            {option.merchant_id && (
              <CardActionArea
                key={option.merchant_id}
                onClick={() =>
                  navigate(
                    `/merchant/${option.merchant_id}/${option.id}/${option.merchant_name}`
                  )
                }
              >
                <li {...props}>
                  <Box display="flex" alignItems="center">
                    <Box flexShrink={0}>
                      <img
                        src={option.logo}
                        alt={option.merchant_name}
                        loading="lazy"
                        title={option.merchant_name}
                        width="44px"
                        height="44px"
                        objectFit="cover"
                        style={{
                          width: "44px",
                          height: "44px",
                          marginRight: "8px",
                        }}
                      />
                    </Box>
                    <Box flexGrow={1} sx={{ wordWrap: "break-word" }}>
                      <Typography variant="body1">
                        {option.merchant_name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {option.type}
                      </Typography>
                    </Box>
                  </Box>
                </li>
              </CardActionArea>
            )}
          </Box>
        );
        // else {
        //   // Render Google Maps option (existing logic)
        //   const matches =
        //     option.structured_formatting?.main_text_matched_substrings || [];
        //   const parts = parse(
        //     option.structured_formatting?.main_text,
        //     matches.map((match) => [match.offset, match.offset + match.length])
        //   );

        //   return (
        //     <li {...props}>
        //       <Grid container alignItems="center">
        //         <Grid item sx={{ display: "flex", width: 44 }}>
        //           <LocationOnIcon sx={{ color: "text.secondary" }} />
        //         </Grid>
        //         <Grid
        //           item
        //           sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
        //         >
        //           {parts.map((part, index) => (
        //             <Box
        //               key={index}
        //               component="span"
        //               sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
        //             >
        //               {part.text}
        //             </Box>
        //           ))}
        //           <Typography variant="body2" color="text.secondary">
        //             {option.structured_formatting?.secondary_text}
        //           </Typography>
        //         </Grid>
        //       </Grid>
        //     </li>
        //   );
        // }
        if (option.key === "show_all") {
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Typography
                key={option.key}
                variant="body2"
                sx={{
                  color: "#f9931f",
                  padding: "8px 16px",
                  cursor: "pointer",
                  fontWeight: "bold",
                  bottom: 0,
                  right: 0,
                }}
                onClick={() => handleShowAll()}
              >
                {t("showAll")}
              </Typography>
            </Box>
          );
        }
      }}
    />
  );
});

export default GoogleMaps;
