import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import GeneralComponent from "../../pages/Home/GeneralComponent";

const SeeAllSection = ({ merchantData }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography
        sx={{
          width: ["95%", "95%", "90%"],
          textAlign: "start",
          fontSize: "20px",
          fontWeight: 600,
          my: 2,
        }}
      >
        {t("AllMerchant")}
      </Typography>
      <GeneralComponent merchantData={merchantData} sx={{ mb: 10 }} />
    </>
  );
};

export default SeeAllSection;
