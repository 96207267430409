import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import SuccessImage from "../../assets/images/emoji_pomkins_correct320.png";
import { useTranslation } from "react-i18next";
function SuccessStep() {
  const { t } = useTranslation();
  const navigator = useNavigate();
  return (
    <Box
      component="form"
      sx={{
        backgroundColor: "white",
        mt: 10,
        py: 6,
        px: [2, 4, 6],
        borderRadius: 3,
        boxShadow: "-1px -1px 10px 1px rgba(0, 0, 0, 0.2)",
        display: "flex",
        width: ["90%", "70%", "400px"],
        flexDirection: "column",
        gap: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={SuccessImage}
        alt="img"
        title="img"
        loading="lazy"
        width="120px"
        height="120px"
        style={{
          width: "120px",
          height: "120px",
        }}
      />
      <Typography variant="h5" className="mt-3 font-bold ">
        {t("successStep.title")}
      </Typography>
      <Typography className="text-[#a8a8a8] mb-3">
        {t("successStep.instruction")}
      </Typography>

      <Button
        variant="contained"
        fullWidth
        onClick={() => navigator("/login/new")}
        sx={{
          mt: 2,
          borderRadius: 6,
        }}
      >
        {t("btn.login")}
      </Button>
    </Box>
  );
}

export default SuccessStep;
