export const isMerchantOpen = (open_time, close_time) => {
  try {
    const currentTime = new Date();
    const [currentHours, currentMinutes] = [
      currentTime.getHours(),
      currentTime.getMinutes(),
    ];

    const [openHours, openMinutes] = open_time.split(":").map(Number);
    const [closeHours, closeMinutes] = close_time.split(":").map(Number);

    const currentTotalMinutes = currentHours * 60 + currentMinutes;
    const openTotalMinutes = openHours * 60 + openMinutes;
    let closeTotalMinutes = closeHours * 60 + closeMinutes;

    if (closeTotalMinutes < openTotalMinutes) {
      closeTotalMinutes += 24 * 60;
      return (
        currentTotalMinutes >= openTotalMinutes ||
        currentTotalMinutes < closeTotalMinutes - 24 * 60
      );
    }

    return (
      currentTotalMinutes >= openTotalMinutes &&
      currentTotalMinutes <= closeTotalMinutes
    );
  } catch (error) {
    return false;
  }
};
