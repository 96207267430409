import { Avatar, Box, Button, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getMerchantById } from "../../libs/merchantAPI/merchantAPI";
import LoadingScreen from "../../components/LoadingScreen";
import ThaiFlag from "../../assets/image/thai-flag.png";
import EnglishFlag from "../../assets/image/eng-flag.png";
import bgDefault from "../../assets/image/bg-graphic.png";
import DefaultProfile from "../../assets/images/pompkins_food_logo.png";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";

function QrMainPage() {
  const version = new Date().toISOString().split("T")[0];
  const { merchant_id, token, tableNumber, bmerchant_id, transaction_uuid } =
    useParams();
  const [merchantData, setMerchantData] = useState();
  const [isFetch, setIsFetch] = useState(false);
  const [selectedButton, setSelectedButton] = useState("ไทย");
  const navigator = useNavigate();
  const handleButtonClick = (value) => {
    setSelectedButton(value);
  };
  const fetchMerchant = useCallback(() => {
    getMerchantById(merchant_id)
      .then((res1) => {
        setMerchantData(res1.data);
        setIsFetch(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [merchant_id]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/table-merchant/${token}/${merchant_id}/${bmerchant_id}/${tableNumber}/${transaction_uuid}`,
      title: "QrMainPage",
    });
  }, []);

  const handleNext = () => {
    navigator(
      `/table-merchant/${token}/${merchant_id}/${bmerchant_id}/${tableNumber}/${transaction_uuid}/create-user`
    );
  };

  useEffect(() => {
    if (!isFetch) {
      fetchMerchant();
    }
  }, [fetchMerchant, isFetch]);

  if (!isFetch) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Helmet>
        <title>{merchantData.name} | POMPKINS Food</title>
        <meta
          name="description"
          content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
        />
        <link
          rel="canonical"
          href={`https://pompkins.com${window.location.pathname}`}
        />
        <meta
          property="og:url"
          content={`https://pompkins.com${window.location.pathname}`}
        />
        <meta
          property="og:title"
          content={`${merchantData.name} | POMPKINS Food`}
        />
        <meta
          property="og:image"
          content={`${merchantData?.logo}?v=${version}` ?? DefaultProfile}
        />
        <meta
          name="twitter:image"
          content={`${merchantData?.logo}?v=${version}` ?? DefaultProfile}
        />
      </Helmet>
      <Box
        sx={{
          backgroundImage: `url(${merchantData.background ?? bgDefault})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <Box height={"45vh"} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            height: "45vh",
            px: 3,
            position: "relative",
          }}
        >
          <Avatar
            src={merchantData.logo ?? DefaultProfile}
            sx={{ height: "90px", width: "90px", mb: 2 }}
          />
          <Typography
            variant="h6"
            color="white"
            sx={{ textShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)" }}
            fontWeight={600}
            mb={2}
          >
            ยินดีต้อนรับ
          </Typography>
          <Typography
            sx={{ textShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)" }}
            variant="h5"
            color="white"
            fontWeight={600}
          >
            {merchantData.name}
          </Typography>
          <Typography
            sx={{ textShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)" }}
            variant="h6"
            color="white"
            fontWeight={300}
          >
            กรุณาเลือกภาษา/Please select a language
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              height: ["70px", "50px", "50px"],
              my: 4,
            }}
          >
            <Button
              fullWidth
              startIcon={
                <img
                  alt="thai flag"
                  title="thai flag"
                  loading="lazy"
                  src={ThaiFlag}
                  style={{ height: "25px" }}
                  className="bg-white"
                  width="auto"
                  height="25px"
                />
              }
              onClick={() => handleButtonClick("ไทย")}
              sx={{
                fontSize: "16px",
                backgroundColor:
                  selectedButton === "ไทย"
                    ? "rgba(255, 255, 255, 1)"
                    : "rgba(218, 218, 218, 0.6)",
                color: "black",
                ":focus": {
                  backgroundColor: "#ffffff",
                },
                ":hover": {
                  backgroundColor: "#ffffff",
                },
              }}
            >
              ฉันหิวแล้ว!
            </Button>
            <Button
              fullWidth
              startIcon={
                <img
                  alt="eng flag"
                  title="eng flag"
                  loading="lazy"
                  src={EnglishFlag}
                  style={{ height: "25px" }}
                  className="bg-white"
                  width="25px"
                  height="auto"
                />
              }
              sx={{
                fontSize: "16px",
                backgroundColor:
                  selectedButton === "English"
                    ? "rgba(255, 255, 255, 1)"
                    : "rgba(218, 218, 218, 0.6)",
                color: "black",
                ":focus": {
                  backgroundColor: "#ffffff",
                },
                ":hover": {
                  backgroundColor: "#ffffff",
                },
              }}
              onClick={() => handleButtonClick("English")}
            >
              I'm hungry!
            </Button>
          </Box>
          <Button
            fullWidth
            variant="contained"
            onClick={() => handleNext()}
            sx={{ fontSize: "18px", height: ["60px", "40px", "40px"] }}
          >
            ถัดไป
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default QrMainPage;
