import { create } from "zustand";

const useSnackBar = create((set) => ({
  isOpen: false,
  message: "",
  position: { vertical: "bottom", horizontal: "center" },
  openSnack: (message, position) =>
    set({
      isOpen: true,
      message: message,
      position: position || { vertical: "bottom", horizontal: "center" },
    }),
  closeSnack: () =>
    set({
      isOpen: false,
      message: "",
      position: { vertical: "bottom", horizontal: "center" },
    }),
}));

export default useSnackBar;
