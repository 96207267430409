import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

function FaqContainer() {
  return (
    <Box sx={{ width: "100vw", backgroundColor: "white" }}>
      <Outlet />
    </Box>
  );
}

export default FaqContainer;
