import React, { useCallback, useEffect, useState, createRef } from "react";
import { Box, Typography, Grid } from "@mui/material";
import {
  getBMerchantById,
  getMenuByBMerchantId,
  getMerchantById,
  getCategoryByBMerchantId,
  getRatingByMerchantUUID,
} from "../../libs/merchantAPI/merchantAPI";
import { Navigate, useParams } from "react-router-dom";
import { useMobile } from "../../stores/useMobile";
import MenuShopCategoryTab from "../../components/MenuShopCategoryTab";
import LoadingScreen from "../../components/LoadingScreen";
import { isMerchantOpen } from "../../libs/isMerchantOpen";
import { useTranslation } from "react-i18next";
import MerchantNoMenu from "../Merchant/NoMenuModal";
import MenuCard from "./MenuCard";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
function MenuPage() {
  const { isMobile } = useMobile();
  const [isAddMenuOpen, setIsAddMenuOpen] = useState(false);
  const [merchantData, setMerchantData] = useState();
  const [bMerchantData, setBMerchantData] = useState();
  const [bMerchantMenu, setBMerchantMenu] = useState([]);
  const [bMerchantCategory, setBMerchantCategory] = useState([]);
  const [displayMenu, setDisplayMenu] = useState([]);
  const [isFetch, setIsFetch] = useState(false);
  const [cartList, setCartList] = React.useState([]);
  const [isMerchantOpenState, setIsMerchantOpenState] = useState(false);
  const [uuidProps, setUuidProps] = useState("");
  const { merchant_id, bmerchant_id, merchant_name } = useParams();
  const [rating, setRating] = useState(0);
  const { t } = useTranslation();

  const [merchantLocation, setMerchantLocation] = useState({});
  const fetchMerchant = useCallback(() => {
    setIsLoading(true);
    getMerchantById(merchant_id)
      .then((res1) => {
        setMerchantData(res1.data);
        getBMerchantById(bmerchant_id)
          .then((res2) => {
            setBMerchantData(res2.data);
            let timeTemp = isMerchantOpen(
              res2.data.open_time,
              res2.data.close_time
            );
            setIsMerchantOpenState(timeTemp);
            const location = {
              lat: parseFloat(res2.data.latitude),
              lng: parseFloat(res2.data.longitude),
            };
            getRatingByMerchantUUID(res2.data.uuid).then((res) => {
              setRating(res.data.average_rating);
            });
            setMerchantLocation(location);
            getMenuByBMerchantId(res2.data.uuid)
              .then((res3) => {
                setBMerchantMenu(res3.data);
                getCategoryByBMerchantId(res2.data.uuid)
                  .then((res4) => {
                    setBMerchantCategory(res4.data);
                    orderCategory(res3.data, res4.data);
                    setIsLoading(false);
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {
                    setIsFetch(true);
                  });
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const orderCategory = (menus, categories) => {
    let menuTemp = [];
    categories.forEach((category) => {
      let filteredMenu = menus.filter((menu) => {
        return menu.categories_id.includes(category.id);
      });
      menuTemp.push({ category: category, menu: filteredMenu });
    });
    setDisplayMenu(menuTemp);
  };
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/menu/${merchant_id}/${bmerchant_id}/${merchant_name}`,
      title: "MenuPage",
    });
  }, []);

  useEffect(() => {
    if (!isFetch) {
      fetchMerchant();
    }
  }, [fetchMerchant, isFetch]);

  const [isLoading, setIsLoading] = React.useState(false);

  const scrollCategoryRefs = displayMenu.map(() => createRef());

  const scrollToCategory = (index) => {
    if (scrollCategoryRefs[index] && scrollCategoryRefs[index].current) {
      const yOffset = isMobile ? -130 : -120;
      const element = scrollCategoryRefs[index].current;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (cartList !== null && cartList.length > 0) {
      setIsAddMenuOpen(true);
    }
  }, [cartList]);

  useEffect(() => {
    if (bMerchantData) {
      setUuidProps(bMerchantData.uuid);
    }
  }, [bMerchantData]);

  if (!merchant_id || merchant_id === "undefined") {
    return <Navigate to="/food" />;
  }

  return (
    <>
      <Helmet>
        <title>
          {merchant_name} - {t("menu")}
        </title>
        <meta
          name="description"
          content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
        />
        <link
          rel="canonical"
          href={`https://pompkins.com${window.location.pathname}`}
        />
        <meta property="og:url" content="https://pompkins.com/menu" />
      </Helmet>
      {!isFetch && <LoadingScreen />}
      {isFetch && !bMerchantMenu.length ? <MerchantNoMenu open={true} /> : null}
      {isFetch && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: 10,
          }}
          width="100%"
        >
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                position: "relative",
                backgroundColor: "white",
              }}
            >
              <Box
                sx={{
                  p: 2,
                  bottom: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: ["center", "center", "start"],
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      px: [0, 0, 2],
                      py: [0, 0, 2],
                      display: "flex",
                      flexDirection: "column",
                      gap: 0.7,
                      backgroundColor: "white",
                      alignItems: ["center", "center", "start"],
                      textAlign: ["center", "center", "start"],
                      overflow: "hidden",
                    }}
                  >
                    {/* <Box
                      alt="logo"
                      component="img"
                      src={merchantData.logo ?? DefaultProfile}
                      sx={{
                        width: ["110px", "110px", "150px"],
                        height: ["110px", "110px", "150px"],
                        borderRadius: "50%",
                        objectFit: "cover",
                        mt: 1,
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
                      }}
                    /> */}
                    <Typography
                      className="font-bold"
                      sx={{
                        // fontSize: ["26px", "30px", "32px"],
                        fontSize: ["42px", "46px", "50px"],
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                      }}
                    >
                      เมนูอาหารทั้งหมด
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: ["16px", "16px", "16px"],
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                      }}
                    >
                      ร้าน: {merchantData.name}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            {bMerchantCategory && (
              <MenuShopCategoryTab
                category={bMerchantCategory}
                scrollToCategory={scrollToCategory}
              />
            )}
            {bMerchantCategory &&
              displayMenu.map((object, index) => (
                <Box key={index} sx={{ px: 2 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      mt: 2,
                      mb: 2,
                      fontWeight: "bold",
                    }}
                    ref={scrollCategoryRefs[index]}
                  >
                    {object.category.name}
                  </Typography>
                  <Grid container justifyContent="space-between">
                    {object.menu.map((menus, i) => (
                      <Grid key={menus.id} item xs={12} sm={5.85}>
                        <MenuCard items={menus} />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ))}
          </Box>
        </Box>
      )}
    </>
  );
}

export default MenuPage;
