import { create } from "zustand";

export const useLocationStore = create((set) => ({
  isOpen: false,
  openModal: () => set({ isOpen: true }),
  closeModal: () => set({ isOpen: false }),
  currentLocation: null,
  currentAddress: null,
  isLoading: false,
  error: null,
  setCurrentLocation: (location) => set({ currentLocation: location }),
  setCurrentAddress: (address) =>
    set((state) => ({
      ...state,
      currentAddress: address,
    })),
  setIsLoading: (isLoading) => set({ isLoading }),

  setError: (error) => set({ error }),

  fetchCurrentLocation: () => {
    if (navigator.geolocation) {
      set({ isLoading: true, error: null });
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const location = { lat: latitude, lng: longitude };
          set({ currentLocation: location, isLoading: false });
        },
        (error) => {
          set({ error: error.message, isLoading: false });
        }
      );
    } else {
      set({ error: "Geolocation is not supported by your browser." });
    }
  },
  isLoadScript: false,
  setIsLoadScript: (isLoadScript) => set({ isLoadScript }),
}));
