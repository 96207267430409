import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SearchNotFound from "../../assets/image/SearchNotFound.png";
import FoodTiles from "../../components/FoodTiles";

function GeneralComponent({ merchantData, sx }) {
  const { t } = useTranslation();

  return (
    <Box sx={{ width: ["95%", "95%", "90%"], my: 1, ...sx }}>
      {merchantData.length > 0 ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {merchantData.map((items, index) => {
              return <FoodTiles key={index} item={items} />;
            })}
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mb: 4,
            mt: 1,
          }}
        >
          <img
            src={SearchNotFound}
            alt="not found img"
            title="not found img"
            loading="lazy"
            width="100px"
            height="auto"
            style={{ width: "100px" }}
          />
          <Typography fontWeight={600} sx={{ mt: 2 }}>
            {t("searchComponent.notFoundMenu")}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default GeneralComponent;
