import { Box, Typography } from "@mui/material";
import React from "react";

function MerchantTypeCard({ item, index }) {
  return (
    <Box
      data-aos="fade-up"
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        justifyContent: "start",
        alignItems: index % 2 !== 0 ? ["end", "end", "start"] : "start",
        textAlign: "left",
        mb: 4,
        px: [4, 4, 0],
        gap: 2,
        width: ["100%", "100%", "calc(50% - 16px)"],
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection:
            index % 2 !== 0
              ? ["row-reverse", "row-reverse", "column"]
              : ["row", "row", "column"],
          alignItems: ["end", "end", "start"],
          gap: 2,
        }}
      >
        <Box
          component={"img"}
          src={item.img}
          alt={item.title}
          title={item.title}
          loading="lazy"
          sx={{
            height: ["100px", "100px", "100px"],
          }}
        />
        <Typography
          fontWeight={700}
          sx={{
            fontSize: ["24px", "30px", "24px"],
            height: ["20px", "26px", "20px"],
            lineHeight: ["20px", "26px", "24px"],
          }}
        >
          {item.title}
        </Typography>
      </Box>

      <Typography sx={{ ml: [0, 0, "0"] }}>{item.description}</Typography>
    </Box>
  );
}

export default MerchantTypeCard;
