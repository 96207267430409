import axios from "axios";

const paymentUrl = process.env.REACT_APP_BASE_PAYMENT_URL;
export const getPaymentMerchantData = (
  pompkinsmerchantID,
  pompkinsBmerchantUUID,
  channel
) => {
  return axios.get(`${paymentUrl}/merchant/oauth/secret`, {
    params: {
      pompkinsmerchantID,
      pompkinsBmerchantUUID,
      status: 1,
      channel,
    },
  });
};

export const getKBankOauthToken = (data) => {
  return axios.post(`${paymentUrl}/oauth/token`, data);
};

export const getPaymentThaiQR = (data, access_token) => {
  return axios.post(`${paymentUrl}/qrpayment/thaiqr/qrcode`, data, {
    headers: {
      Authorization: access_token,
    },
  });
};

export const checkPaymentStatus = (data) => {
  return axios.post(`${paymentUrl}/qrpayment/thaiqr/checkPaymentStatus`, null, {
    params: {
      merchantId: data.merchantId,
      partnerTxnUid: data.partnerTxnUid,
    },
  });
};

export const getGatewayPayKSher = (data) => {
  return axios.post(`${paymentUrl}/ksher/get-gateway-pay`, data);
};

export const checkKsherPaymentStatus = (data) => {
  return axios.post(`${paymentUrl}/ksher/checkPaymentStatus`, null, {
    params: {
      merchantId: data.merchantId,
      partnerTxnUid: data.partnerTxnUid,
    },
  });
};
