import {
  AccountCircleOutlined,
  AppRegistrationOutlined,
  ArticleOutlined,
  DeviceUnknown,
  HelpCenterOutlined,
  HelpOutline,
  ManageAccountsOutlined,
  MapOutlined,
  PaymentsOutlined,
  ReceiptOutlined,
} from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";

function FaqData() {
  const { t } = useTranslation();
  const faqData = [
    {
      icon: <HelpOutline sx={{ mt: "-2px" }} className="text-primary" />,
      type: "General",
      data: [
        {
          question: "Pompkins ให้บริการอะไรบ้าง?",
          answer:
            "Pompkins เป็นระบบการจัดการร้านอาหารครบวงจร ตั้งแต่ระบบ จองคิว, สั่งอาหาร,ระบบจัดการครัว และระบบการชำระเงินแบบเต็มรูปแบบที่เป็น Partner ร่วมกับธนาคารกสิกรไทย โดยท่านสามารถศึกษารายละเอียดเพิ่มเติมได้ที่ https://pompkins.com/service",
        },
        {
          question: "Pompkins คิดค่าบริการยังไง?",
          answer:
            "คิดค่าบริการเป็นแพ็คเกจรายเดือน Subscription โดยท่านสามารถตรวจสอบค่าบริการได้ที่ https://pompkins.com/pricing",
        },
        {
          question: "สมัครบริการเราต้องทำอย่างไรบ้าง?",
          answer: "ศึกษาได้จากคู่มือสมัครร้านค้า",
        },
        {
          question: "เอกสารที่ต้องเตรียมให้ธนาคารมีอะไรบ้าง?",
          answer: "ศึกษาได้จากคู่มือสมัครร้านค้า",
        },
        {
          question: "แพ็คเกจแบบไหนเหมาะสำหรับคุณ?",
          answer: "",
        },
        {
          question: "มีค่าธรรมเนียบในการใช้บริการหรือไม่?",
          answer:
            "POMPKINS ไม่คิดค่าธรรมเนียมการบริการใดๆ หากลูกค้าทำการชำระผ่าน ThaiQR และ PomptPay หากลูกค้าทำการชำระเงินผ่านช่องทางอื่นๆ จะมีการเก็บค่าบริการตามเงื่อนไขของธนาคาร",
        },
        {
          question: "ผู้สั่งซื้อจำเป็นต้องสมัครสามชิกหรือไม่?",
          answer:
            "ลูกค้าสามารถสั่งสินค้าโดยไม่ต้องลงทะเบียน หรือเป็นสมาชิกได้ โดยประวัติการสั่งซื้อของท่านจะไม่ถูกจัดเก็บไว้บนระบบ ท่านจำเป็นต้องเก็บข้อมูลการสั่งซื้อไว้ทุกรณี หากเกิดข้อพิพาทใดๆ",
        },
        {
          question: "ติดต่อPompkins ในช่องทางไหนได้บ้าง?",
          answer:
            "ท่านสามารถติดต่อผ่านช่องทางดังนี้ เบอร์โทร 02-136-2930, Line OA หรือ live chat",
        },
        {
          question: "บริการมีการทดลองให้ใช้ฟรีไหม?",
          answer:
            "เมื่อท่านดำเนินการลงทะเบียนบนระบบ POMPKINS ครั้งแรก ท่านจะได้รับสิทธิ์ในการใช้งาน POMPKINS MAX ฟรี 30 วัน โดยท่านสามารถเปลี่ยนแปลง Package ได้อีกครั้ง 3 วันก่อน Package เดิมหมดอายุ",
        },
        {
          question: "Pompkins ติดตั้งบนอุปกรณ์ไหนได้บ้าง?",
          answer:
            "ท่านสามารถดาวน์โหลด Application POMPKINS ได้ผ่าน AppStore (iOS) และ PlayStore (Android) โดสามารถใช้ได้ทั้ง iPad, Tablet, Smart Phone",
        },
        {
          question: "หากต้องการแก้ไขข้อมูลฉันต้องทำอย่างไร",
          answer:
            "ให้ทางร้านค้าไปที่ ตั้ง่ค่า>ข้อมูลร้านค้า ทางร้านสามารถแก้ไข้ข้อมูลได้เลย",
        },
        {
          question: "ติดต่อ support ได้ทางไหนบ้าง",
          answer: "ผ่านช่องทาง Facebook, Line Official, Email",
        },
        {
          question: "เวลาทำการของ pompkins",
          answer:
            "เวลาทำการของพอมป์กิ้นเริ่มตั้งแต่เวลา 9:00-16:00 น. ไม่รวมวันหยุดนักขัตฤกษ์ ",
        },
        {
          question: "ร้านค้าแบบใดบ้างที่สามารถใช้งาน pompkins ได้",
          answer:
            "ร้านค้าแบบ PopUp Store, ร้านเปิดใหม่ที่เริ่มต้นลงทุน, ร้านกาแฟขนาดเล็ก ร้านอาหารขนาดเล็ก, ร้านค้าไม่มีหน้าร้า ร้านค้าขนาดกลาง ร้านค้าทีมรโต๊ะนั่งน้อย ร้านค้าขนาดใหญ่",
        },
        {
          question: "ฉันจะร้องเรียนเกี่ยวกับคุณภาพอาหารได้อย่างไร",
          answer:
            "ท่านสามารถติดต่อร้านค้าได้โดยตรง และสามารถให้รีวิวร้านค้าได้ในหน้า ประวัติการสั่งซื้อ",
        },
        {
          question: "ฉันจะร้องเรียนการบริการของร้านค้าได้อย่างไร",
          answer:
            "ท่านสามารถติดต่อร้านค้าได้โดยตรง และสามารถให้รีวิวร้านค้าได้ในหน้า ประวัติการสั่งซื้อ",
        },
        {
          question: "ฉันจะขอความเป็นธรรมจากกรณีที่ลูกค้าให้ดาว 1 ดาวอย่างไร",
          answer:
            "ท่านสามารถขออุธรณ์กรณีร้านของท่านได้รับความเสียหายอย่างไม่เป็นธรรม โดนการส่งหลักฐานการขออุธรณ์มาให้ฝ่ายสนับสนุนของเรา เอกสารที่ใช้ รูปภาพของสินค้าที่ทำการขาย รูปภาพของรีวิว รายการสั่งซื้อ คำสั่งซื้อ เอกสารอื่นๆ",
        },
        {
          question:
            "pompkins มีแนวทางในการจัดการกับลูกค้าที่ไม่ปฏิบัติตามกฎอย่างไร",
          answer: "เราจะทำการระงับสัญญาณลูกค้าที่ไม่ปฏิบัติตามกฎของเรา",
        },
        {
          question:
            "pompkins มีแนวทางในการจัดการกับร้านค้าที่ไม่ปฏิบัติตามกฎอย่างไร",
          answer:
            "เราจะทำการส่งเอกสารเตือนร้านค้าทั้งหมด 3 ครั้ง โดยครั้งแรกจะเป็นการเตือน ครั้งที่สองจะเป็นการระงับสัญญาณชั่วคราว 1 สัปดาห์ ครั้งสุดท้ายจะเป็นการระงับสัญญาณถาวร",
        },
        {
          question: "ลูกค้าทั่วไปที่จะใช้งาน pompkins ต้องโหลดแอพหรือไม่",
          answer:
            "ลูกค้าไม่จำเป็นต้องโหลดแอพพลิเคชัน Pompkins สามารถใช้งานได้ผ่าน Web Browser",
        },
        {
          question: "pompkins ให้บริการบน Appstore และ Playstore ใช่หรือไม่",
          answer:
            "Application Pompkins ให้บริการบน PlayStore และ AppStore โดยจะให้บริการบน App Gallery ในอนาคต",
        },
        {
          question:
            "pompkins จะมีวิธีในการระงับข้อผิดพลาดระหว่างร้านค้า และลูกค้าอย่างไร",
          answer:
            "พอมป์กิ้นไม่สามารถระงับข้อผิดพลาดในเรื่องดังกล่าวได้ เว้นแต่เเป็นรื่องที่เกี่ยวกับข้อบกพร่องของระบบฟอมป์กิ้น",
        },
        {
          question: "หากฉัันถูกระงับสัญญาณฉันจะสามารถขออุธรณ์ได้หรือไม่",
          answer: "ได้ ท่านสามารถส่งเอกสารการอุธรณ์ได้ผ่าน Support",
        },
        {
          question:
            "pompkins จะมีการเก็บค่าธรรมเนียมเพิ่มเติมจาก package หรือไม่",
          answer:
            "ไม่มี ยกเว้นการรับชำระเงินผ่าน Wechat, Alipay ที่อาจมีค่าธรรมเนียมจาก ธนาคาร",
        },
        {
          question: "pompkins จะมีการขายเครื่อง pos ในอนาคตหรือไม่",
          answer: "มี หากเราได้รักระแสตอบรับจากลูกค้า",
        },
        {
          question:
            "หากต้องการขยายร้านค้า pompkins พร้อมให้บริการฟังก์ชันเพิ่มเติมหรือไม่",
          answer:
            "เรามีความพร้อมในการบริการร้านค้าขนาด เล็ก กลาง ใหญ่ สามารถใช้งานระบบบริหารจัดการร้านค้าได้อย่างครบวงจร",
        },
        {
          question:
            "หากฉันใช้ Transaction จนครบแล้ว ฉันสามารถซื้อเพิ่มได้หรือไม่",
          answer:
            "ได้ แต่ทางร้านค้าต้องเปลี่ยนแพ็คเกจหรือทำการซื้อTransaction เพิ่ม",
        },
      ],
    },
    {
      icon: <ReceiptOutlined sx={{ mt: "-2px" }} className="text-primary" />,
      type: "Order",
      data: [
        {
          question: "สั่งซื้ออาหารแล้วยกเลิกคำสั่งซื้อได้หรือไม่?",
          answer:
            "กรณีที่ลูกค้ายังไม่ได้ทำการยืนยันชำระเงิน ลูกค้าสามารถยกเลิกออเดอร์ที่สั่งไปได้ หากลูกค้าทำการชำระเงินเป็นที่เรียบร้อยแล้ว จะไม่สามารถยกเลิกคำสั่งซื้อ และของเงินคืนได้ในทุกกรณี",
        },
        {
          question: "Pompkins รับสั่งอาหารจากพื้นที่ใดได้บ้าง?",
          answer:
            "ท่านสามารถสั่งสินค้าได้ทั่วประเทศ โดยระบบจะทำการจัดเรียงร้านค้าที่อยู่ใกล้ท่านมากที่สุด และท่านจำเป็นต้องตรวจสอบเงื่อนไขค่าจัดส่ง หรือการรับสินค้าด้วยตนเองในหน้าร้านนั้นๆ ซึ่งบางร้านค้าอาจไม่เปิดบริการจัดส่ง เนื่องจากเราไม่มีบริการ Rider สำหรับส่งอาหาร แต่ร้านค้าจะเป็นผู้จัดหาพนักงานจัดส่งสินค้าด้วยตนเอง",
        },
        {
          question: "หากสั่งอาหารไปแล้วออเดอร์ไม่ถูกต้องแก้ไขได้ไหม?",
          answer:
            "ท่านสามารถแก้ไขออเดอร์ได้ในกรณีที่ยังไม่ทำการชำระเงิน หากอยู่ในหน้าชำระเงินท่านสามารถกดยกเลิกเพื่อเปลี่ยนแปลงรายละเอียดได้ หากดำเนินการชำระเงินแล้ว ท่านต้องติดต่อร้านค้าโดยตรงเพื่อเปลี่ยนแปลงรายละเอียกคำสั่งซื้อนั้น",
        },
        {
          question:
            "หากฉันปิดหน้า browser ไปและฉันจะกลับไปที่คำสั่งซื้อได้อย่างไร?",
          answer:
            "ท่านสามารถเข้าไปยังคำสั่งซื้อได้โดยเข้าผ่านหน้า ประวัติคำสั่งซื้อ หากท่านไม่ทำการ Login จะไม่สามารถกลับไปยังหน้านี้ได้",
        },
        {
          question: "หากร้านค้าไม่ยอมทำอาหารให้ฉัน ฉันต้องทำอย่างไร?",
          answer:
            "ท่านสามารถติดต่อร้านค้าได้โดยตรง หากร้านค้าไม่ดำเนินการใดๆ เราจะทำการยกเลิกออเดอร์ให้ และให้ทางร้านค้าโอนเงินคืนท่านต่อไป",
        },
        {
          question:
            "หากลูกค้าสั่งสินค้าจากเว็บไซต์ แต่ฉันไม่มีข้อมูลการติดต่อลูกค้า ฉันควรทำอย่างไร?",
          answer:
            "ในกรณีที่ลูกค้าสมัครแบบไม่ได้ลงทะเบียน(Guest) ทางร้านค้าต้องรอให้ลูกค้าติดต่อกลับมาเท่านั้น",
        },
        {
          question:
            "ฉันสั่งอาหาร Delivery และร้านค้าใช้เวลานานกว่า 1 ชั่วโมง ฉันควรทำอย่างไร?",
          answer:
            "ลูกค้าต้องทำการติดต่อไปยังร้านที่สั่งออเดอร์เพื่อทำการตามออเดอร์",
        },
        {
          question:
            "ฉันทำการสั่งอาหาร pickup มาแล้วแต่ร้านค้าแจ้งว่าไม่ได้รับออเดอร์ฉันควรทำอย่างไร?",
          answer:
            "ให้ทางลูกค้าติอด่อมายังCall center pompkins หรือ Live chat เพื่อดำเนินการแก้ไข้ให้",
        },
      ],
    },
    {
      icon: <PaymentsOutlined sx={{ mt: "-2px" }} className="text-primary" />,
      type: "Payment",
      data: [
        {
          question: "มีขั้นต่ำในการชำระค่าอาหารหรือไม่?",
          answer:
            "ไม่มีขั้นต่ำ เราไม่มีนโยบายการสั่งอาหารขั้นต่ำ โดยท่านสามารถสั่งสินค้าได้ในช่วงราคา 1 - 80,000 บาท ต่อการทำรายการ 1 ครั้ง",
        },
        {
          question: "ช่องทางชำระเงินของPompkins ปลอดภัยหรือไม่?",
          answer:
            "ปลอดภัย เราเป็น Partner ร่วมกับ ธนาคารกสิกรไทย ผู้ให้บริการการรับชำระเงินผ่านช่องทางต่างๆ",
        },
        {
          question: "วิธีรับชำระเงินจากลูกค้ามีช่องทางไหนบ้าง?",
          answer:
            "ท่านสามารถรับชำระเงินได้ผ่านช่องทาง เงินสด, Thai QR, WeChat Pay, AliPay, QR Credit Card, EDC และ PromtPay",
        },
        {
          question: "สามารถออกใบกำกับภาษีได้หรือไม่?",
          answer:
            "POMPKINS มีฟังก์ชั่นในการออกใบกำกับภาษีสำหรับลูกค้าที่ต้องการ โดยร้านค้าที่สามารถใช้งานฟังก์ชันนี้ได้ต้องจดทะเบียนเป็นนิติบุคคล และใช้งาน Package Pompkins MAX เท่านั้น",
        },
        {
          question: "กรณีสั่งออนไลน์จ่ายเงินแล้ว Cancel ได้หรือไม่?",
          answer:
            "หากท่านดำเนินการชำระเงินหลังจากร้านค้ายืนยันรับออเดอร์แล้ว ท่านไม่สามารถยกเลิกออเดอร์ผ่านระบบได้ จำเป็นต้องติดต่อร้านค้าโดยตรง ซึ่งร้านค้าจะมีสิทธิ์ในการยกเลิกออเดอร์ และดำเนินการโอนเงินคืนท่าน หรือไม่ก็ได้",
        },
        {
          question: "เงินจะเข้าบัญชีธนาคารของร้านค้าอย่างไร?",
          answer:
            "โดยปกติเงินจะเข้าบัญชีของร้านค้าวันต่อวัน โดยตัดรอบการรับเงินในเวลา 23.00 น. และรับเงินเข้าบัญชีตั้งแต่เวลา 23.30 น. เป็นต้นไป",
        },
        {
          question:
            "ฉันสมัครรายเดือนไป แต่ต้องการยกเลิกการใช้งานกลางคัน สามารถขอเงินคืนได้ไหม",
          answer:
            "ไม่สามารถคืนได้ แต่ยังคงสามารถใช้งานได้จนถึงสิ้นสุดระยะเวลาของ Package",
        },
        {
          question: "นโยบายการคืนเงินของ pompkins เป็นอย่างไร?",
          answer:
            "เราไม่มีนโยบายการคืนเงิน หากลูกค้าชำระเงินค้าสินค้าแล้ว ต้องการยกเลิกหรือเกิดข้อพิพาทใดๆ ต้องทำการเจรจากับร้านค้าโดยตรง และร้านค้ามีหน้าที่โอนเงินค่าสินค้าคืนหากพิสูจน์ว่าเป็นความผิดพลาดของทางร้านค้า",
        },
        {
          question: "รับชำระเงินได้ทุกกลุ่มธุรกิจใช่หรือไม่?",
          answer:
            "เรารับชำระเงินได้เฉพาะธุรกิจที่เกี่ยวข้องกับการขายอาหาร และสินค้าต่างๆที่ลงในระบบ Pompkins",
        },
        {
          question: "กรณีที่ฉันต้องการเงินคืนแต่ร้านค้าไม่ยอมฉันต้องทำอย่างไร?",
          answer:
            "หากร้านค้าไม่ดำเนินการชำระเงินคืนท่านใน 24 ชั่วโมง เราจะทำการระงับสัญญาณของร้านค้า และดำเนินการให้ร้านค้าโอนเงินคืนท่านต่อไป",
        },
        {
          question: "กรณีที่ลูกค้าต้องการเงินคืนร้านค้าต้องดำเนินการอย่างไร?",
          answer:
            "ร้านค้าต้องดำเนินการโอนเงินคืนลูกค้าในกรณีที่ท่านยังไม่ดำเนินการทำอาหาร หากท่านดำเนินการทำอาหารเป็นที่เรียบร้อยแล้ว ท่านสามารถหาแนวทางแก้ไขปัญหาร่วมกันได้กับลูกค้า หากไม่สามารถระงับข้อพิพาทได้ ท่านสามารถติดต่อ Support เพื่อหาข้อสรุปได้",
        },
        {
          question:
            "ฉันได้รับสายจากธนาคารกสิกรไทยเรื่องการเปิดใช้บริการ Payment กับ POMPKINS ฉันต้องทำอย่างไร?",
          answer:
            "ร้านค้าต้องทำการยืนยันตัวตนกับทางธนาคาร ทางธนาคารจะส่งเอกสารผ่านทางอีเมลของร้านค้าเพื่อสมัครระบบ Payment",
        },
        {
          question: "ฉันต้องกรอกเอกสารอะไรบ้างเพื่อส่งให้ธนาคารกสิกรไทย?",
          answer:
            "ทางร้านต้องกรอกเอกสารการสมัคร QR API ของพอมป์กิ้นให้กับทางธนาคารกสิกรไทย",
        },
        {
          question: "ทำไมฉันต้องสแกนเอกสารส่งธนาคาร?",
          answer:
            "เป็นไปตามขั้นตอนของธนาคารเพื่อที่จะให้ธนาคารเช็คความถูกต้องก่อนที่จะส่งเอกสารตัวจริงให้กับทางธนาคาร",
        },
        {
          question: "ฉันต้องส่งเอกสารเป็นกระดาษให้ธนาคารหรือไม่?",
          answer:
            "ทางร้านค้าจำเป็นต้องส่งเอกสารที่เป็นกระดาษให้กับทางธนาคารเนื่องเป็นขั้นตอนของทางธนาคาร",
        },
        {
          question: "ฉันต้องกรอกข้อมูลอะไรบ้างให้ธนาคาร?",
          answer:
            "ทางร้านค้าจะต้องกรอกข้อมูลดังนี้ เลขที่บัตรประชาชน,ชื่อร้านค้า,ชื่อเจ้าของบัญชีรับเงิน (กรณีบุคคลธรรมดา),ชื่อ-สกุลผู้ติดต่อหลัก,หมายเลขโทรศัพท์,อีเมล,ที่อยู่ร้านค้า (เลขที่/ถนน/ตึก/อาคาร, ตำบล, อำเภอ, จังหวัด, รหัสไปรษณีย์)",
        },
        {
          question:
            "ทำไมแบบฟอร์มของธนาคารถึงมีรายละเอียดที่ฉันไม่จำเป็นต้องกรอก?",
          answer: "เอกสารเป็นแบบฟอร์มของทางธนาคารทางพอมป์กิ้นไม่สามารถกำหนดได้",
        },
        {
          question: "ฉันไม่มีบัญชีธนาคารกสิกรไทยฉันต้องทำอย่างไร?",
          answer:
            "ทางร้านค้าต้องทำการเปิดบัญชีธนาคารกสิกรไทยเท่านั้นเพื่อใช้ในการเชื่อมต่อ QR API กับทางธนาคาร",
        },
        {
          question:
            "ฉันไม่สามารถกรอกเอกสารได้ POMPKINS สามารถช่วยเหลือตรงนี้ได้หรือไม่?",
          answer:
            "ทางร้านค้าสามารถติดต่อเจ้าหน้าที่ของพอมป์กิ้นได้เลย หากต้องการช่วยเหลือ",
        },
        {
          question:
            "หากฉันไม่ต้องการใช้ระบบ Payment ฉันไม่ต้องส่งข้อมูลได้หรือไม่?",
          answer:
            "ได้ หากทางร้านไม่ต้องการใช้ระบบ Payment ทางร้านไม่จำเป็นต้องส่งข้อมูลให้กับทางธนาคาร",
        },
        {
          question:
            "หากธนาคารโทรติดต่อฉันตอนไม่ว่าง ธนาคารจะติดต่อกลับฉันอีกครั้งหรือไม่?",
          answer:
            "ถ้าทางร้านค้าไม่ว่างรับสาย ทางธนาคารจะเว้น 3 วัน แล้วโทรมาอีกรอบ จนครบ 4 ครั้ง หากลูกค้าไม่รับสายอีก กรุณาแจ้งพอมป์กิ้นเพื่อให้ธนาคารติดต่อมาอีกครั้ง",
        },
        {
          question: "หากฉันกรอกข้อมูลผิด ฉันจะสามารถแก้ไขได้อย่างไร?",
          answer: "ทางร้านค้าสามารถแก้ไขได้เลย",
        },
        {
          question: "ทำไมฉันต้องพิมพ์เอกสารส่งธนาคาร?",
          answer: "เป็นไปตามข้อกำหนดของธนาคารกสิกรไทย",
        },
      ],
    },
  ];
  const applicationFaqData = [
    {
      icon: <DeviceUnknown sx={{ mt: "-2px" }} className="text-primary" />,
      type: "General",
      data: [
        {
          question: "ปุ่มทานที่ร้านของ POS กรณีที่ไม่มีโต๊ะ หมายถึงอะไร",
          answer:
            "ฟังก์ันทานที่ร้านบน POS ถูกสร้างขึ้นสำหรับร้านค้าที่ต้องการระบบการขายหน้าร้าน โดยการทานที่ร้านแบ่งเป็นอีก 2 ประเภท คือ ร้านที่ต้องการระบุหมายเลขโต๊ะหมายถึงร้านที่มีการกำหนดหมายเลขโต๊ะชัดเจนไว้เรียบร้อยแล้ว และไม่ต้องการระบุหมายเลขโต๊ะหมายถึงร้านที่มีที่นั่งทานที่ให้ลูกค้าเลือกนั่งทานได้เอง หรือไม่กำหนด ร้านค้าสามารถกำหนดได้จากหน้านี้",
        },
        {
          question:
            "ปุ่มทานที่ร้านมีให้เลือกโต๊ะ แต่ฉันยังไม่มีโต๊ะต้องทำอย่างไร",
          answer:
            "หากท่านต้องการให้ระบบมีปุ่มสำหรับเลือกโต๊ะ ร้านค้าต้องทำการตั้งค่าโต๊ะเป็นอันดับแรก โดยการไปที่ หน้าหลัก > โต๊ะ/เช็คบิล > จัดโต๊ะ โดยร้านค้าสามารถเพิ่มจำนวนโต๊ะได้แบบไม่จำกัด ตามที่ร้านค้าต้องการ เมื่อดำเนินการจัดโต๊ะเรียบร้อย ร้านค้าสามารถใช้งานฟังก์ชัน POS แบบเลือกโต๊ะได้",
        },
        {
          question: "QR Code ส่วนท้ายใบเสร็จหมายถึงอะไร",
          answer: "เพื่อให้ลูกค้าสามารถสแกนเก็บใบเสร็จไว้ได้",
        },
        {
          question: "ฉันจะบันทึกรูปใบเสร็จลงโทรศัพท์อย่างไร",
          answer:
            "จะมีปุ่มดาวน์โหลดอยู่ด้านล่างของใบเสร็จทางร้านค้าสามารถกดดาวน์โหลดได้เลย",
        },
        {
          question: "ฉันสามารถเปลี่ยนแปลงรูปแบบการแสดงผลใบเสร็จได้หรือไม่",
          answer: "ได้ ทางร้านไปที่ ตั้งค่าร้าน > ออกแบบใบเสร็จ",
        },
        {
          question: "ฉันเพิ่มเมนูแล้วแต่ภาพไม่ขึ้นต้องทำอย่างไร",
          answer: "ทางร้านต้องทำการอัปโหลดรูปภาพทุกครั้งเมื่อสร้างเมนูใหม่",
        },
        {
          question: "ปุ่มเมนู ประเภท หมายถึงอะไร",
          answer:
            "เป็นการที่ทางร้านเพิ่มประเภทของอาหาร เช่น ประเภท ข้าว > ข้าวผัด",
        },
        {
          question: "ปุ่มเมนู ตัวเลือก หมายถึงอะไร",
          answer:
            "เป็นการที่ทางร้านเพิ่มตัวเลือกของอาหาร เช่น ประเภท พิเศษ > เพิ่มกับ, เพิ่มข้าว",
        },
        {
          question: "ฉันสามารถเพิ่มเมนูโดยไม่ใส่ตัวเลือกได้หรือไม่",
          answer: "ทางร้านสามารถเพิ่มเมนูโดยไม่ใส่ตัวเลือกได้",
        },
        {
          question: "ฉันสามารถเพิ่มเมนูโดยไม่ใส่ประเภทได้หรือไม่",
          answer: "ทางร้านสามารถเพิ่มเมนูโดยไม่ใส่ประเภทได้",
        },
        {
          question: "หากฉันกดปิดเมนูแล้วจะเป็นอย่างไร",
          answer: "ทางลูกค้าจะไม่สามารถเห็นเมนูที่ทางร้านปิดไว้ได้",
        },
        {
          question: "ฉันสามารถใส่ราคาเป็นทศนิยมได้หรือไม่",
          answer: "ทางร้านค้าสามารถใส่ราคาเป็นเลขทศนิยมได้",
        },
        {
          question: "ตัวเลือกย่อยในกลุ่มตัวเลือกคืออะไร",
          answer:
            "กลุ่มตัวเลือกย่อยก็คือ เช่น เราเลือกตัวเลือกว่า พิเศษ > ตัวเลือกย่อยก็จะเป็น พิเศษข้าว, พิเศษกับ",
        },
        {
          question: "ระบบคำนวณคะแนนรีวิวจากลูกค้าอย่างไร",
          answer: "คำนวณจากค่าเฉลี่ยของการรีวิว",
        },
        {
          question: "รายการวันนี้แสดงข้อมูลอะไรบ้าง",
          answer:
            "รายการวันนี้จะแสดงสถานะต่างๆ ของรายการเข้ามาในวันนี้ ประกอบด้วย รอยืนยัน, ทำอาหาร, พร้อมเสิร์ฟ และ เสร็จสิ้น",
        },
        {
          question: "จำกัดจำนวนโต๊ะหรือไม่",
          answer: "ไม่ สามารถสร้างโต๊ะได้ไม่จำกัด",
        },
        {
          question:
            "ฉันปิดแท็บที่ขึ้นรายละเอียดโต๊ะไป และหา QR Code สำหรับโต๊ะไม่เจอต้องทำอย่างไร",
          answer:
            "ตราบใดที่ยังไม่ปิดโต๊ะทางร้านสามารถไปที่ เลือกโต๊ะ > ตรวจสอบรายการอาหาร",
        },
        {
          question:
            "ฉันกดปิดโต๊ะโดยไม่ตั้งใจ และรายการออเดอร์หายทั้งหมด ต้องทำอย่างไร",
          answer:
            "ทางร้านไปที่ ตั้งค่า > ธุรกรรม เพื่อเช็คประวัติของธุรกรรมซึ่งสถานะต้องเป็นแคทเซิลและทางร้านต้องทำการสร้างออเดอร์ใหม่เพื่อให้ลูกค้าชำระเงินและต้องแจ้งฝั่งครัวห้ามทำออเดอร์นี้",
        },
        {
          question:
            "มุมมองครัวสามารถเลือกเฉพาะรายการสินค้าที่เสร็จแล้วได้หรือไม่",
          answer: "ไม่ได้ ทางครัวต้องเลือกทุกรายการที่ออเดอร์เข้ามา",
        },
        {
          question:
            "หากครัวไม่สามารถทำอาหารบางรายการได้ สามารถกดลบรายการนั้นได้หรือไม่",
          answer: "ครัวไม่สามารถลบรายการได้ แต่สามารถลบได้ตอนเช็คบิลเท่านั้น",
        },
        {
          question:
            "ฉันกดเพิ่มคิวแล้วเลขคิวที่เริ่มต้นเป็นเลขคิวที่ต่อจากคิวสุดท้ายของเมื่อวาน ฉันต้องทำอย่างไร",
          answer:
            "ทางร้านต้องกดเคลียร์คิวของเมื่อวานให้หมดแล้วก็สร้างคิวใหม่ของวันนี้",
        },
        {
          question: "EDC มีไว้ทำอะไร",
          answer:
            "มีไว้สำหรับชำระเงินโดยมีช่องทางดังนี้ Thai QR, QR Credit card, WeChat Pay และ AliPay",
        },
        {
          question: "ทำไมรายการใน EDC ถึงเป็นข้อมูล + - * / =",
          answer: "เพื่อให้สามารถคำนวณสินค้าได้ ใช้งานเหมือนเครื่องคิดเลข",
        },
        {
          question: "ปุ่มสแกนมีไว้เพื่ออะไร",
          answer: "ใช้สแกนเพื่อที่ที่ต้องการเพิ่มบทบาทพนักงานภายในร้าน",
        },
        {
          question: "ฉันสามารถกำหนดสิทธิ์ และตำแหน่งของพนักงานเองได้หรือไม่",
          answer: "เจ้าของร้านสามารถกำหนดสิทธิ์และบทบาทของพนักงานได้",
        },
        {
          question:
            "ฉันสแกน QR Code ในปุ่มสแกนแล้วไม่สามารถใช้งานได้ ต้องทำอย่างไร",
          answer: "ทางร้านค้าต้อง Log out ออกจากระบบเดิมก่อน ถึงจะสแกนใหม่ได้",
        },
        {
          question:
            "หากฉันกดปิดใช้งานพนักงานคนนั้น พนักงานจะสามารถเข้าสู่ระบบได้หรือไม่",
          answer:
            "ไม่ได้ ถ้าปิดแล้วพนักงานจะ Log in เข้าสู่ระบบไม่ได้ต้องเปิดใช้งานก่อนเท่านั้น",
        },
        {
          question: "ฉันสามารถลบบัญชี แล้วทำการสมัครใหม่ได้หรือไม่",
          answer: "ทางร้านสามารถลบบัญชีของตนเองได้",
        },
        {
          question: "หากจำนวนรับชำระถึงโควต้าของ Package แล้วฉันต้องทำอย่างไร",
          answer:
            "ทางร้านค้าสามารถอัพแพ็คเกจเพื่อเพิ่มจำนวนชำระได้ หรือถ้าจำนวนชำระไม่พอทางร้านสามารถซื้อจำนวนชำระเพิ่มได้",
        },
        {
          question: "ฉันจะเปลี่ยนแปลง Package ของฉันได้อย่างไร",
          answer:
            "ทางร้านสามารถเปลี่ยนแพ็คเกจได้ที่มุมด้านบนของอุปกรณ์ที่ใช้งาน (ควรมีรูปภาพ)",
        },
        {
          question: "หากฉันได้รับข้อความเอกสารไม่ผ่าน ต้องดำเนินการอย่างไรต่อ",
          answer:
            "ทางร้านต้องกรอกข้อมูลให้ถูกต้องตามที่เจ้าหน้าที่แจ้งมาให้ถูกต้องแล้วส่งกลับไปใหม่",
        },
        {
          question: "ฉันจะเพิ่มสาขาได้อย่างไร",
          answer: "ร้านค้าไปที่ ตั้งค่า > หัวข้อ ข้อมูล > จัดการสาขา",
        },
        {
          question:
            "หากฉันต้องการให้เว็บไซต์ไม่แสดงสินค้าบางรายการต้องทำอย่างไร",
          answer: "ทางร้านสามารถไปที่ ตั้งค่าร้าน > เปิด-ปิดเมนู",
        },
        {
          question: "หากฉันเพิ่มสินค้าในเมนูแล้ว จะแสดงบนเว็บไซต์ใช่หรือไม่",
          answer:
            "ใช่ หากทางร้านเพิ่มเมนู สินค้าจะแสดงรายการบนเว็บไซต์โดยทันที",
        },
        {
          question: "ลูกค้าจะค้นพบฉันบนเว็บไซต์ได้อย่างไร",
          answer:
            "ทางร้านสามารถคัดลอก URL ของร้านค้าหรือจากหน้าเว็บไซต์ของ Pompkins.com",
        },
        {
          question:
            "ฉันจำเป็นต้องเปิดการให้บริการ รับเอง, จัดส่ง, จองโต๊ะ รับออเดอร์อัตโนมัติทุกรายการหรือไม่",
          answer: "ไม่จำเป็น ร้านค้าสามารถเลือกเปิดหรือปิดบริการใดก็ได้",
        },
        {
          question:
            "ฉันทำการลงข้อมูลร้านค้าทุกอย่างแล้ว แต่บนเว็บไซต์ยังไม่แสดงร้านของฉัน ฉันต้องทำอย่างไร",
          answer:
            "ทางร้านยังไม่ได้รับการอนุมัติจากทางพอมป์กิ้น เนื่องจากทางร้านยังกรอกข้อมูลไม่ถูกต้องจึงทำให้ร้านค้าไม่แสดงบนเว็บไซต์พอมป์กิ้น",
        },
        {
          question: "ฉันจะส่งลิงก์เว็บไซต์ของฉันให้ลูกค้าได้อย่างไร",
          answer:
            "ทางร้านไปยังรูปคิวอาร์โค้ดมุมด้านบน และทางร้านสามารถคัดลอก URL ของทางร้านได้เลย",
        },
      ],
    },
    {
      icon: (
        <ManageAccountsOutlined sx={{ mt: "-2px" }} className="text-primary" />
      ),
      type: "Account Setting",
      data: [
        {
          question: "ฉันจะแก้ไขข้อมูลส่วนตัวได้อย่างไร",
          answer:
            "ท่านสามารถแก้ไขข้อมูลส่วนตัวได้ภายหลังจากการลงทะเบียนเสร็จสิ้น โดยสามารถไปแก้ไขรายละเอียดได้ที่ ตั้งค่า > ข้อมูลส่วนตัว",
        },
        {
          question: "การตั้งเวลาเปิดปิดหมายความว่าอย่างไร",
          answer:
            "การตั้งเวลาเปิดปิดของร้านค้า จะส่งผลต่อระบบการสั่งอาหารของลูกค้าผ่านเว็บไซต์ https://pompkins.com โดยลูกค้าจะไม่สามารถสั่งอาหารได้หากร้านของท่านปิดอยู่",
        },
        {
          question: "การเปิด-ปิดเมนู เป็นอย่างไร",
          answer:
            "การเปิด-ปิดเมนูของร้านค้า จะเป็นการแสดงเมนูที่ร้านค้าต้องการแสดงให้ลูกค้าเห็นบนหน้าเว็บไซต์ https://pompkins.com และ การสั่งออเดอร์ผ่านระบบ POS ถูกสร้างขึ้นเพื่อใช้งานในกรณีที่ร้านค้าไม่สามารถทำอาหารจานนั้นได้ วัตถุดิบหมด หรือเหตุผลอื่นๆ และต้องการปิดแค่เมนูเดียวชั่วคราว",
        },
        {
          question: "การให้บริการร้านค้าหมายความว่าอย่างไร",
          answer:
            "ระบบ POMPKINS มีการจัดการฟังก์ชันการสั่งอาหารหลากหลายรูปแบบ การให้บริการร้านค้าจะเป็นการบริหารจัดการ การรับออเดอร์ของร้านค้าโดยมีบริการ 4 แบบดังนี้ 1. รับหน้าร้าน = ลูกค้าสามารถสั่งอาหารจากเว็บไซต์ POMPKINS ได้ และชำระเงินผ่านระบบ Payment โดยร้านค้าจะเห็นออเดอร์เข้าในกรณีที่ลูกค้าสั่งซื้อ และร้านค้าต้องทำอาหารให้ลูกค้า โดยลูกค้าจะเป็นผู้มารับหน้าร้านเอง",
        },
        {
          question:
            "หากฉันกรอกข้อมูลออกแบบใบเสร็จไปแล้ว ใบเสร็จของฉันจะเป็นอย่างไร",
          answer:
            "ร้านค้าสามารถดูตัวอย่างได้ในหน้าการออกแบบใบเสร็จ จากข้อมูลที่ร้านค้าเป็นผู้ตั้งค่า และเมื่อร้านค้าทำการขายสำเร็จใบเสร็จที่ได้จะมีลักษณะเหมือนกันกับที่ร้านค้าออกแบบไว้",
        },
        {
          question:
            "หากร้านค้าของฉันไม่มีการจัดส่ง การกรอกค่าจัดส่งจะเป็นอย่างไร",
          answer:
            "หากร้านของท่านไม่พร้อมให้บริการจัดส่งสินค้า ร้านค้าไม่จำเป็นต้องกรอกค่าจัดส่งใดๆ และระบบจะทำการปิดฟังก์ชันการสั่งออเดอร์แบบจัดส่ง",
        },
        {
          question: "ฉันกดเปิดโต๊ะแล้วต้องทำอย่างไรต่อไป",
          answer:
            "หลังจากเปิดโต๊ะแล้วทางร้านค้าให้ทางลูกค้าสแกนคิวอาร์เพื่อสั่งอาหาร",
        },
        {
          question: "ฉันสามารถแก้ไขใบเสร็จในหน้า EDC ได้หรือไม่",
          answer: "ไม่ได้ จะเป็นรูปแบบของธนาคารกสิกรไทยเท่านั้น",
        },
        {
          question:
            "ฉันให้พนักงานสแกน QR Code เข้าสู่ระบบ แล้วพนักงาน Logout แล้วสแกนอีกรอบ ฉันไม่สามารถเข้าสู่ระบบได้ ต้องทำอย่างไร",
          answer: "ทางร้านค้าต้อง Log out ออกจากระบบเดิมก่อน ถึงจะสแกนใหม่ได้",
        },
        {
          question: "ฉันสามารถตรวจสอบ Package ได้ที่ไหน",
          answer:
            "ทางร้านสามารถเช็คแพ็คเกจได้ที่มุมบนด้านบนของอุปกรณ์ที่ใช้งาน",
        },
      ],
    },
    {
      icon: <ReceiptOutlined className="text-primary" sx={{ mt: "-2px" }} />,
      type: "Order",
      data: [
        {
          question:
            "ในรายการวันนี้บางครั้งออเดอร์เข้าแถบรอยืนยัน บางครั้งเข้าแถบทำอาหาร หมายความว่าอย่างไร",
          answer:
            "ในกรณีที่สั่งออนไลน์คำสั่งออเดอร์จะไปอยู่ที่แถบรอยืนยัน แต่หากลูกค้าตั้งค่ารับออเดอร์แบบอัตโนมัติหรือลูกค้าสแกนสั่งออเดอร์หน้าร้าน ออเดอร์จะไปอยู่ในแถบทำอาหาร",
        },
        {
          question:
            "หากฉันยืนยันออเดอร์ไปก่อนแล้วกำลังทำอาหาร ฉันพบว่าฉันไม่สามารถเสิร์ฟอาหารให้ลูกค้าได้ ฉันจะทำการยกเลิกอย่างไร",
          answer:
            "หากยังไม่ถึงขั้นตอนชำระเงินสามารถกดยกเลิกออเดอร์ได้ตลอดเวลา แต่หากได้มีการกดยืนยันชำระเงินแล้วทางร้านค้าต้องติดต่อไปยังลูกค้า",
        },
        {
          question:
            "หากฉันยืนยันออเดอร์ แต่ลูกค้ายังไม่ชำระเงินฉันต้องทำอย่างไร",
          answer:
            "ระบบจะยกเลิกคำสั่งซื้ออัตโนมัติหากลูกค้าไม่ชำระเงินภายในเวลาที่กำหนด",
        },
        {
          question: "พร้อมเสิร์ฟหมายถึงอะไร",
          answer:
            "พร้อมเสิร์ฟจะเกิดขึ้นได้หลังจากที่คนครัวกดทำอาหารเสร็จสิ้นแล้ว",
        },
        {
          question: "ธุรกรรมแสดงรายละเอียดอะไรบ้าง",
          answer:
            "หน้าธุรกรรมทางร้านจะสามารถเลือกช่วงเวลาของยอดเงินได้ และสามารถดูได้ว่ามาจากช่องทางไหน",
        },
        {
          question: "จำนวนรับชำระ หมายถึงอะไร",
          answer: "จำนวนรับชำระคือ เมื่อมีลูกค้ามาชำระเงินนับ 1 บิลการชำระเงิน",
        },
        {
          question: "การรับออเดอร์อัตโนมัติต่างจากรับออเดอร์ปกติอย่างไร",
          answer:
            "การรับออเดอร์อัตโนมัติเป็นการที่ร้านค้าจะรับออเดอร์ลูกค้าทันทีโดยไม่ต้องกดยืนยัน ส่วนการรับออเดอร์ปกติคือการที่ร้านต้องกดยืนยันรับออเดอร์ก่อน",
        },
        {
          question:
            "หากลูกค้าสั่งอาหารเข้ามา และฉันไม่สามารถจัดส่ง หรือทำอาหารให้ได้ ต้องทำอย่างไร",
          answer: "หากยังไม่ชำระเงินทางร้านสามารถยกเลิกออเดอร์ได้เลย",
        },
      ],
    },
    {
      icon: <PaymentsOutlined className="text-primary" sx={{ mt: "-2px" }} />,
      type: "Payment",
      data: [
        {
          question: "ฉันจะสามารถใช้งานระบบชำระเงินได้เมื่อไหร่",
          answer:
            "การใช้งานระบบชำระเงิน ร้านค้าจำเป็นต้องกรอกเอกสารผ่านระบบ POMPKINS ในหน้า ตั้งค่า > เอกสาร ร้านค้าต้องกรอกข้อมูลตามความเป็นจริง และร้านค้าจะได้รับการติดต่อจากธนาคารกสิกรไทย เพื่อเปิดใช้งานระบบ Payment ต่อไป",
        },
        {
          question:
            "เพราะอะไรต้องรับชำระเงินผ่าน ThaiQR, QR Credit Card, เงินสด",
          answer:
            "เพราะว่าระบบ Thai QR และ QR Credit Cardเราเป็นพาทเนอร์กับธนาคารกสิกรไทยเพื่อรับรองความปลอดภัยของทางร้านค้ายิ่งขึ้น และเงินสดร้านค้าสามารถตรวจสอบ/ควบคุมด้วยตนเองได้เลย",
        },
        {
          question:
            "ฉันกดปุ่มยืนยันเสร็จสิ้นออเดอร์แล้ว แต่ยังไม่ได้รับการชำระเงิน ฉันต้องทำอย่างไร",
          answer: "ทางร้านต้องติดต่อไปที่ลูกค้าโดยตรงเท่านั้น",
        },
        {
          question:
            "ในกรณีที่ลูกค้าทานที่ร้านออเดอร์อยู่ในแถบเสร็จสิ้น แต่ฉันยังไม่ได้รับการชำระเงินฉันต้องทำอย่างไร",
          answer:
            "ให้ทางร้านค้าไปยัง หน้าโต๊ะ/เช็คบิล และเช็ครายการจากโต๊ะที่ลูกค้าที่ใช้งาน เลือกดูรายการและเช็คบิลลูกค้าได้เลย",
        },
        {
          question: "ฉันเช็คบิลไปแล้ว แต่ไม่สามารถเลือกรูปแบบการชำระเงินได้",
          answer:
            "ร้านค้าสามารถที่จะให้ลกค้าชำระเป็นเงินสดได้หากร้านค้าไม่ได้สมัครช่องทางชำระแบบTHAI QR กับ QR Credit card",
        },
        {
          question: "ยอดขายวันนี้ไม่ตรงกับจำนวนธุรกรรมที่เกิดขึ้น",
          answer: "ให้แจ้งทางร้านค้าแจ้งมาที่เจ้าหน้าที่พอมป์กิ้นได้เลย",
        },
        {
          question:
            "ฉันรับชำระด้วย ThaiQR แล้วระบบจะทำการตรวจสอบยอดเงินเองหรือไม่",
          answer: "ใช่ ระบบจะตรวจสอบยอดเงินให้อัตโนมัติ",
        },
        {
          question:
            "หากฉันอัพเอกสารขึ้นไปแล้ว ฉันจะสามารถใช้งานระบบ Payment ได้เลยใช่หรือไม่",
          answer:
            "ยังไม่สามารถใช้งานได้ หากอัปโหลดเอกสารขึ้นไปแล้วต้องรอทางธนาคารอนุมัติก่อนการใช้งานPayment",
        },
        {
          question: "ฉันจะสมัครใช้งานบริการ Payment รับชำระเงินได้อย่างไร",
          answer: "ทางร้านสามารถเรียนรู้ได้จากคู่มือ",
        },
        {
          question: "ฉันสามาถดูรายงานยอดขายได้อย่างไร",
          answer:
            "ให้ร้านไปที่ ตั้งค่า> เลื่อนลงมาหัวข้อการเงิน > รายงานยอดขาย",
        },
        {
          question:
            "หากฉันทำการขอรับเงินก่อนตัดรอบเงินจะเข้าบัญชีเลยใช่หรือไม่",
          answer:
            "ทางร้านสามารถขอรับเงินก่อนตัดรอบได้ แต่จะมีค่าบริการเพิ่มเติม",
        },
        {
          question: "หากฉันไม่ชำระค่าบริการในเวลาที่กำหนดจะเป็นอย่างไร",
          answer:
            "ทางร้านจะถูกยกเลิกแพ็คเกจโดยทันทีเมื่อถึงรอบบิลเรียกเก็บเงินค่าแพ็คเกจครั้งถัดไป",
        },
      ],
    },
    {
      icon: (
        <AppRegistrationOutlined className="text-primary" sx={{ mt: "-2px" }} />
      ),
      type: "Register",
      data: [
        {
          question: "ฉันจะทำการสมัครสมาชิกได้อย่างไร",
          answer:
            "ท่านสามารถใช้หมายเลขโทรศัทพ์มือถือ เพื่อทำการสมัครสมาชิก โดยการยืนยัน OTP จากนั้นกรอกข้อมูลการสมัคร ก็สามารถใช้งาน Pompkins ได้ทันที",
        },
        {
          question: "ฉันไม่ได้รับ OTP",
          answer:
            "หากท่านไม่ได้รับ OTP ท่านสามารถกดส่ง OTP ใหม่ได้ ท่านต้องระมัดระวังในการกดปุ่ม ขอ OTP อีกครั้ง บางครั้งผู้ให้บริการ SMS อาจใช้เวลาส่งนานกว่าปกติ หากยังไม่ได้รับ OTP โปรดทิ้งระยะหว่างในการลงทะเบียน 1 - 2 ชั่วโมง แล้วดำเนินการใหม่",
        },
        {
          question: "ฉันจำรหัส Pin ไม่ได้",
          answer:
            "หากท่่านจำรหัส Pin ที่เคยตั้งไว้ไม่ได้ ท่านสามารถยืนยันตัวตนผ่าน OTP โดยการกดปุ่มลืมรหัสผ่าน จากนั้นระบบจะทำการส่ง OTP ให้ท่าน และท่านสามารถเปลี่ยนแปลงรหัสใหม่ได้",
        },
        {
          question: "ฉันจำเป็นต้องกรอกข้อมูลส่วนตัวไหม",
          answer:
            "ในการใช้งานระบบ POMPKINS ท่านจำเป็นต้องกรอกข้อมูลทุกอย่างตามจริง เนื่องจาก POMPKINS จะตรวจสอบร้านอาหารของท่าน และการใช้บริการ Payment ของธนาคารกสิกรไทยทางธนาคารจะทำการตรวจสอบข้อมูลของท่าน พร้อมกับยืนยันตัวตนของท่านอีกครั้งหลังจากท่านกรอกเอกสารครบทั้งหมดแล้ว",
        },
        {
          question: "หากฉันไม่มี Email ฉันสามารถลงทะเบียนใช้งานได้หรือไม่",
          answer:
            "ท่่านจำเป็นต้องกรอก Email เพื่อให้ธนาคารกสิกรไทยทำการส่งข้อมูลรายละเอียดการใช้งานระบบ Payment และข้อกำหนดต่างๆ",
        },
        {
          question: "ฉันไม่สามารถกรอกข้อมูลได้",
          answer:
            "หากท่านไม่สามารถกรอกข้อมูลได้ไม่ว่าจะด้วยเหตุผลอะไรก็ตาม ท่านสามารถติดต่อ Support ผ่านช่องทาง Live Chat ของเราโดยพนักงานฝ่ายสนับสนุนยินดีให้บริการท่านอย่างเต็มที่ หากต้องการบริการจากลูกค้าสัมพันธ์ในการสอนท่านใข้งาน และติดตั้งระบบ ณ ร้านอาหารของท่าน ท่านสามารถแจ้งทีมงานฝ่ายสนับสนุนที่ท่านติดต่อโดยอาจมีค่าธรรมเนียมในการบริการเพิ่มเติม",
        },
      ],
    },
    {
      icon: <ArticleOutlined sx={{ mt: "-2px" }} className="text-primary" />,
      type: "Document",
      data: [
        {
          question: "ฉันจำเป็นต้องอัพเอกสารใช่หรือไม่",
          answer:
            "ในกรณีที่ทางร้านจะใช้ระบบ Payment ทางร้านจำเป็นต้องส่งเอกสารให้กับทางเรา",
        },
        {
          question: "หากฉันไม่สามารถอัพเอกสารได้ต้องทำอย่างไร",
          answer:
            "หากทางร้านค้าไม่สามารถอัปโหลดเอกสารได้แจ้งเจ้าหน้าที่ของพอมป์กิ้นได้เลย",
        },
      ],
    },
    {
      icon: <MapOutlined sx={{ mt: "-2px" }} className="text-primary" />,
      type: "Order with Google Maps",
      data: [
        {
          question: "Google Maps คืออะไร",
          answer:
            "บริการของ Google ที่ให้บริการด้านแผนที่ผ่านระบบออนไลน์ มีประสิทธิภาพสูง ใช้งานง่าย และให้ข้อมูลของธุรกิจในท้องถิ่น",
        },
        {
          question: "Google Maps Pin คืออะไร",
          answer:
            "คือการปักหมุดสถานที่บน Google Maps ซึ่งสามารถปักหมุดได้ในทุกกลุ่มธุรกิจ ทาง POMPKINS จะดำเนินการปักในกลุ่มธุรกิจร้านอาหารให้กับร้านค้าที่ใช้บริการ POMPKINS ทุกร้านค้า",
        },
        {
          question: "ฉันต้องการปักหมุด",
          answer:
            "ท่านต้องอัพเอกสารยืนยันตัวตนร้านค้ากับทาง POMPKINS ก่อน จากนั้นท่านจึงจะสามารถส่งข้อมูลในการปักหมุดให้กับ POMPKINS ได้ที่ปุ่ม Order with Google Maps (สั่งอาหารบน Maps)",
        },
        {
          question: "หากฉันมีข้อมูลบน Google Maps อยู่แล้ว ต้องทำอย่างไร",
          answer:
            "ท่านสามารถคัดลอกลิงก์ร้านค้าของท่านในเมนู Order with Google Maps (สั่งอาหารบน Maps) แล้วนำไปใส่บน Google Maps ร้านค้าของท่านได้ทันที",
        },
        {
          question: "ทำไมฉันต้องใช้งานระบบ Google Maps Pin",
          answer:
            "บริการสั่งอาหารออนไลน์บน Google Maps ร้านค้าที่สมัครบริการ Google Pin กับ Pompkins สามารถใช้ Google Maps รับออเดอร์อาหารออนไลน์ได้ เพียงนำลิงก์จากในแอปพลิเคชัน [ในเมนู Google Maps] มาวางในช่องเว็บไซต์บน Google Maps ของคุณได้เลย เพียงเท่านี้คุณก็สามารถรับออเดอร์อาหารออนไลน์ โดยไม่ต้องเสียค่าบริการ หรือ ค่า GP เพิ่มเติมอีกด้วย",
        },
      ],
    },
  ];
  return { faqData, applicationFaqData };
}

export default FaqData;
