import {
  CheckCircleOutline,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Language,
} from "@mui/icons-material";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useLanguage } from "../../stores/useLanguage";
import Options from "../../utils/options";
import { styleMenu } from "../Styles/StyledMenu";

function LanguageMenu() {
  const [isMenuLanguageOpen, setIsMenuLanguageOpen] = useState(false);
  const languageRef = useRef(null);
  const { selectedLanguage, setSelectedLanguage } = useLanguage();
  const handleLanguageMenuClose = () => {
    setIsMenuLanguageOpen(null);
  };
  const { languageOptions } = Options();
  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    handleLanguageMenuClose();
  };
  return (
    <>
      <Button
        aria-controls="language-menu"
        aria-haspopup="true"
        className="text-gray-700"
        onClick={() => {
          setIsMenuLanguageOpen((prev) => !prev);
        }}
        sx={{
          ":hover": {
            backgroundColor: "#fff8f0",
          },
        }}
        ref={languageRef}
        startIcon={<Language />}
        endIcon={
          isMenuLanguageOpen ? (
            <KeyboardArrowUp className="text-primary" />
          ) : (
            <KeyboardArrowDown className="text-primary" />
          )
        }
        color="inherit"
      >
        {selectedLanguage}
      </Button>
      <Menu
        id="language-menu"
        anchorEl={languageRef.current}
        keepMounted
        open={isMenuLanguageOpen}
        onClose={() => setIsMenuLanguageOpen(false)}
        slotProps={styleMenu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {languageOptions.map((option) => (
          <MenuItem
            key={option.code}
            sx={{
              backgroundColor:
                selectedLanguage === option.code ? "#F8F7F7" : "transparent",
              ":hover": {
                backgroundColor: "#fff8f0",
              },
            }}
            onClick={() => handleLanguageSelect(option.code)}
          >
            <ListItemText primary={option.label} />
            <ListItemIcon>
              {selectedLanguage === option.code && (
                <CheckCircleOutline
                  sx={{
                    fontSize: 18,
                    color: "#FF961B",
                    ml: 2,
                  }}
                />
              )}
            </ListItemIcon>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default LanguageMenu;
