import React, { useEffect } from "react";
import dayjs from "dayjs";
import { Box, Typography, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import DefaultProfile from "../../utils/defaultImage";
import * as htmlToImage from "html-to-image";
import FileSaver from "file-saver";
import useModalLoading from "../../stores/useModalLoading";
import { getLineUUID, isFriendship } from "../../utils/line/checkLine";
import { sendLineBill } from "../../utils/line/sendLineMessage";

function Billitem({ billData, serviceData, bmerchantData }) {
  const billRef = React.useRef(false);
  const { transaction_uuid } = useParams();
  //Header 24px base 16px order_detail 14px Total
  const date = dayjs(billData.date);
  const thaiDay = date.format("DD/MM/YY");
  const time = date.format("HH:mm");
  const orders = billData.orders;
  const { openModal, closeModal } = useModalLoading();
  const handleDownload = async () => {
    openModal();
    await new Promise((resolve) => {
      setTimeout(resolve, 500); // Wait for any existing animations/events to resolve
    });

    await htmlToImage.toBlob(billRef.current, { cacheBust: true });
    await htmlToImage.toBlob(billRef.current, { cacheBust: true });
    await new Promise((resolve) => {
      setTimeout(resolve, 500); // Wait for any new animations/events to resolve
    });
    const blob = await htmlToImage.toBlob(billRef.current, { cacheBust: true });
    FileSaver.saveAs(blob, `bill_${transaction_uuid}.png`);
    closeModal();
  };
  const deliver_fee =
    billData &&
    billData.order_distance !== null &&
    billData.order_distance > 0 &&
    billData.delivery_fee
      ? billData.delivery_fee
      : 0;
  const subtotal = billData.total_price - billData.promotion - deliver_fee;
  const vatValues = serviceData.vat ? subtotal - subtotal / 1.07 : 0;

  const total_amount = billData.total_price - billData.promotion;
  useEffect(() => {
    const handleSendBill = async () => {
      const lineUUID = getLineUUID();
      if (!lineUUID) return;
      try {
        const accessToken = localStorage.getItem("accessToken");
        const friendFlag = await isFriendship(accessToken);
        const orderStatusKey = `transactionUUID_${transaction_uuid}`;
        const billSent = localStorage.getItem(orderStatusKey);

        if (friendFlag && !billSent) {
          // if (friendFlag) {
          sendLineBill(
            lineUUID,
            serviceData,
            billData,
            orders,
            transaction_uuid
          );
          localStorage.setItem(orderStatusKey, "true");
        }
      } catch (error) {
        console.log("Error sending bill", error);
      }
    };
    handleSendBill();
  }, [billData, orders, serviceData, transaction_uuid]);
  return (
    <>
      <Box
        ref={billRef}
        sx={{
          p: 2,
          backgroundColor: "white",
          boxShadow: "0 10px 8px rgb(0 0 0 / 0.1)",
          width: "366px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          className="text-textGray"
          sx={{
            textAlign: "center",
            fontSize: "24px",
            fontWeight: 700,
            mb: 2,
            overflowWrap: "break-word",
            wordWrap: "break-word",
            width: "90%",
          }}
        >
          {serviceData.billing_header}
        </Typography>
        <img
          src={serviceData.billing_logo ?? DefaultProfile}
          alt="img"
          title="img"
          loading="lazy"
          width="100px"
          height="100px"
          style={{ width: "100px", height: "100px", objectFit: "contain" }}
        />
        <Typography
          variant="body2"
          className="text-textGray"
          fontSize={"16px"}
          sx={{
            mt: 1,
            fontWeight: 700,
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {billData.merchant_name}
        </Typography>
        {serviceData.business_type === "Company" && billData.tax_id && (
          <Typography
            variant="body2"
            className="text-textGray"
            fontSize={"14px"}
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            เลขประจำตัวผู้เสียภาษี:{billData.tax_id ?? "-"}
          </Typography>
        )}

        <Typography
          variant="body2"
          className="text-textGray"
          sx={{
            width: "100%",
            overflowWrap: "break-word",
            wordWrap: "break-word",
            textAlign: "center",
          }}
          fontSize={"16px"}
        >
          {serviceData.billing_detail}
        </Typography>
        {serviceData.business_type === "Company" && (
          <Typography
            variant="body2"
            className="text-textGray"
            sx={{
              width: "100%",
              overflowWrap: "break-word",
              wordWrap: "break-word",
              textAlign: "center",
            }}
            fontSize={"16px"}
          >
            {bmerchantData && bmerchantData.is_main_branch
              ? "สำนักงานใหญ่"
              : "สาขาย่อย"}
          </Typography>
        )}
        {serviceData.business_type === "Company" && serviceData.vat && (
          <Typography
            variant="body2"
            className="text-textGray"
            sx={{
              width: "100%",
              overflowWrap: "break-word",
              wordWrap: "break-word",
              textAlign: "center",
            }}
            fontSize={"16px"}
          >
            (VAT INCLUDED)
          </Typography>
        )}

        <Typography
          variant="body2"
          className="text-textGray"
          sx={{
            mt: 2,
            fontWeight: 700,
            width: "100%",
            overflowWrap: "break-word",
            wordWrap: "break-word",
            textAlign: "center",
          }}
          fontSize={"16px"}
        >
          {serviceData.business_type === "Company" && serviceData.vat
            ? "ใบเสร็จรับเงิน/ใบกํากับภาษีอย่างย่อ"
            : "ใบเสร็จรับเงิน"}
        </Typography>

        <Box sx={{ mt: 2, width: "100%" }}>
          <Typography
            variant="body2"
            className="text-textGray"
            sx={{ textAlign: "left" }}
          >
            เลขที่ออเดอร์:{" "}
            {orders &&
              orders
                .map((item) => (
                  <label key={item.order_no} style={{ fontWeight: 700 }}>
                    #{item.order_no.replace(/-/g, "").slice(0, 5).toUpperCase()}{" "}
                  </label>
                ))
                .reverse()}
          </Typography>
          {billData.staff_name && billData.staff_name !== "" && (
            <Typography
              variant="body2"
              className="text-textGray"
              sx={{ textAlign: "left" }}
            >
              ชื่อพนักงาน: {billData.staff_name}
            </Typography>
          )}
          <Typography
            variant="body2"
            className="text-textGray"
            sx={{ textAlign: "left" }}
          >
            เลขที่ใบเสร็จ: {transaction_uuid.slice(0, 15).toLocaleUpperCase()}
          </Typography>

          <Typography
            variant="body2"
            className="text-textGray"
            sx={{ textAlign: "left", mb: 2 }}
          >
            วันที่: {thaiDay} {time}
          </Typography>
          {billData.table_number !== 0 && billData.table_number && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  my: "4px",
                }}
              >
                <Typography
                  variant="body2"
                  className="text-textGray"
                  sx={{ textAlign: "right" }}
                >
                  เลขที่โต๊ะ: {billData.table_number}
                </Typography>
              </Box>
            </>
          )}
          <div className="border-dashed  border-textGray border w-full" />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              py: "4px",
            }}
          >
            <Typography
              variant="body2"
              className="text-textGray"
              sx={{ textAlign: "left" }}
            >
              จำนวน
            </Typography>
            <Typography
              variant="body2"
              className="text-textGray"
              sx={{ textAlign: "center" }}
            >
              รายการ
            </Typography>
            <Typography
              variant="body2"
              className="text-textGray"
              sx={{ textAlign: "right" }}
            >
              ราคา
            </Typography>
          </Box>
          <div className="border-dashed  border-textGray border w-full" />
          {orders &&
            orders.map((item, index) => (
              <Box key={index} sx={{ my: "4px", width: "100%" }}>
                {item.customer_name !== "" && item.customer_name && (
                  <Typography
                    variant="body2"
                    className="text-textGray"
                    sx={{ textAlign: "left", fontWeight: 600 }}
                  >
                    {item.customer_name}
                  </Typography>
                )}

                {item.order_detail.map((itm) => (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "80%",
                        }}
                      >
                        <Typography
                          variant="body2"
                          className="text-textGray"
                          fontSize={"14px"}
                          sx={{ width: "24px" }}
                        >
                          x{itm.quantity}
                        </Typography>
                        <Typography
                          variant="body2"
                          fontSize={"14px"}
                          className="text-textGray"
                          sx={{
                            textAlign: "left",
                            overflowWrap: "break-word",
                            wordWrap: "break-word",
                            width: "70%",
                          }}
                        >
                          {itm.menu_name}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body2"
                        fontSize={"14px"}
                        className="text-textGray"
                        sx={{ textAlign: "right" }}
                      >
                        {(itm.price * itm.quantity).toFixed(2)}
                      </Typography>
                    </Box>
                    {itm.option &&
                      itm.option.length > 0 &&
                      itm.option.map((option, index) => (
                        <Box key={index} sx={{ width: "100%" }}>
                          <Typography
                            sx={{
                              ml: 2,
                              overflowWrap: "break-word",
                              wordWrap: "break-word",
                              width: "70%",
                            }}
                            fontSize={"14px"}
                            variant="body2"
                            className="text-textGray"
                          >
                            - {option}
                          </Typography>
                        </Box>
                      ))}
                  </>
                ))}
              </Box>
            ))}

          <div className="border-dashed  border-textGray border w-full" />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body2"
              fontSize={"14px"}
              sx={{ width: "70%" }}
              className="text-textGray"
            >
              ราคารวม
            </Typography>
            <Typography
              variant="body2"
              fontSize={"14px"}
              className="text-textGray"
            >
              {subtotal
                .toFixed(2)
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
            </Typography>
          </Box>
          {deliver_fee > 0 && (
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="body2"
                fontSize={"14px"}
                sx={{ width: "70%" }}
                className="text-textGray"
              >
                ค่าจัดส่ง
              </Typography>
              <Typography
                variant="body2"
                fontSize={"14px"}
                className="text-textGray"
              >
                {deliver_fee
                  .toFixed(2)
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
              </Typography>
            </Box>
          )}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body2"
              fontSize={"14px"}
              className="text-textGray"
            >
              ส่วนลด
            </Typography>
            <Typography
              variant="body2"
              fontSize={"14px"}
              className="text-textGray"
            >
              {billData.promotion
                ? billData.promotion
                    .toFixed(2)
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                : "0.00"}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              fontWeight={700}
              variant="body2"
              fontSize={"14px"}
              sx={{ width: "70%" }}
              className="text-textGray"
            >
              ราคารวมทั้งสิ้น
            </Typography>
            <Typography
              fontWeight={700}
              variant="body2"
              fontSize={"14px"}
              className="text-textGray"
            >
              {total_amount
                .toFixed(2)
                .toString()

                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
            </Typography>
          </Box>
        </Box>
        <div className="border-dashed  border-textGray border w-full" />
        {serviceData.vat && (
          <Box sx={{ width: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{ width: "70%" }}
                variant="body2"
                className="text-textGray"
              >
                รวมมูลค่าสินค้า
              </Typography>
              <Typography variant="body2" className="text-textGray">
                {(subtotal - vatValues)
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{ width: "70%" }}
                variant="body2"
                className="text-textGray"
              >
                ภาษีมูลค่าเพิ่ม
              </Typography>
              <Typography variant="body2" className="text-textGray">
                {vatValues.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
              </Typography>
            </Box>
            <div className="border-dashed  border-textGray border w-full" />
          </Box>
        )}

        {billData.payment_method === "CASH" && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body2" className="text-textGray">
              เงินสด{" "}
              {parseFloat(billData.amount ?? 0)
                .toFixed(2)
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
            </Typography>
            <Typography variant="body2" className="text-textGray">
              เงินทอน{" "}
              {parseFloat((billData.amount ?? 0) - billData.total_price)
                .toFixed(2)
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
            </Typography>
          </Box>
        )}

        <Typography
          textAlign={"center"}
          mt={2}
          sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          variant="body2"
          className="text-textGray"
        >
          {serviceData.billing_footer}
        </Typography>
      </Box>

      <Button
        size="large"
        sx={{
          mt: 2,
          backgroundColor: "#2e7d32",
          color: "white",
          ":hover": {
            backgroundColor: "#2e5320",
            color: "white",
          },
        }}
        onClick={handleDownload}
      >
        {" "}
        ดาวน์โหลด
      </Button>
    </>
  );
}

export default Billitem;
