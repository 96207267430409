import { Slide, Snackbar } from "@mui/material";
import React from "react";
import useSnackBar from "../stores/useSnackBar";
function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

function SnackBar({ open, closeSnack, message, position }) {
  const handleSnackClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    closeSnack();
  };
  return (
    <Snackbar
      anchorOrigin={position}
      open={open}
      autoHideDuration={3000}
      onClose={handleSnackClose}
      TransitionComponent={SlideTransition}
      message={message}
      key={`${position.vertical} + ${position.horizontal}`}
    />
  );
}

export function SnackBarComponent({ children }) {
  const { isOpen, closeSnack, message, position } = useSnackBar();
  return (
    <div>
      {children}
      <SnackBar
        open={isOpen}
        closeSnack={closeSnack}
        message={message}
        position={position}
      />
    </div>
  );
}
