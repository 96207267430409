export const checkDeviceOs = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const isIOS = /iphone|ipad|ipod/.test(userAgent);
  const isAndroid = /android/.test(userAgent);
  const isWindow = /windows/.test(userAgent);
  const isMac = /macintosh/.test(userAgent);
  return {
    isIOS,
    isAndroid,
    isWindow,
    isMac,
  };
};
