import { Box, Button, Paper, Typography } from "@mui/material";
import React from "react";
import SuccessImg from "../../assets/images/emoji_pomkins_correct320.png";
import { useNavigate } from "react-router-dom"; // Import useHistory from react-router-dom

function ReviewSuccess() {
  const navigator = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "95vh",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 2,
          margin: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: "600px",
          borderRadius: "16px",
        }}
      >
        <img
          src={SuccessImg}
          alt="Success Img"
          title="Success Img"
          loading="lazy"
          width="100px"
          height="auto"
          style={{ marginTop: "32px" }}
        />

        <Typography sx={{ mt: 2 }} variant="h5">
          ขอบคุณสำหรับการรีวิว
        </Typography>

        <Typography variant="body1">
          รีวิวของคุณถูกบันทึกเรียบร้อยแล้ว
        </Typography>

        <Button
          sx={{ mb: 4, mt: 2 }}
          variant="outlined"
          onClick={() => navigator("/food")}
        >
          สั่งอาหารเพิ่ม
        </Button>
      </Paper>
    </Box>
  );
}

export default ReviewSuccess;
