import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DefaultProfile from "../../utils/defaultImage";
import StarIcon from "@mui/icons-material/Star";
import { LocationOnOutlined, StarOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  getMerchantById,
  getRatingByMerchantUUID,
} from "../../libs/merchantAPI/merchantAPI";
import LoadingScreen from "../../components/LoadingScreen";
import ReivewOrderModal from "./ReivewOrderModal";
import { useGuestLogin } from "../../stores/useGuestLogin";

function PastOrderTiles({ order, bMerchantData }) {
  const [merchantData, setMerchantData] = useState();
  const [bmerchants, setBMerchants] = useState({});
  const [merchantId, setMerchantId] = useState();
  const [bmerchantId, setBMerchantId] = useState();
  const [rating, setRating] = useState();
  const [isFetch, setIsFetch] = useState(false);
  const [reviewOpen, setReviewOpen] = useState(false);
  const { t } = useTranslation();
  const navigator = useNavigate();
  const { isGuest } = useGuestLogin();

  useEffect(() => {
    const bmerchant = bMerchantData.find(
      (item) => item.name === order.merchant_name
    );
    setMerchantId(bmerchant.merchant_id);
    setBMerchants(bmerchant);
    setBMerchantId(bmerchant.id);
    getMerchantById(bmerchant.merchant_id)
      .then((res) => {
        setMerchantData(res.data);
        getRatingByMerchantUUID(bmerchant.uuid).then(({ data }) => {
          setRating(data.average_rating);
          //   const location = {
          //     lat: bmerchant.latitude,
          //     lng: bmerchant.longitude,
          //   };
          //   const [distance] = DistanceCalculator(currentLocation, location);
          //   setDistance(distance);
          setIsFetch(true);
        });
      })
      .catch((error) => {});
  }, [bMerchantData, order, order.merchant_name]);

  if (!isFetch) {
    return <LoadingScreen />;
  }
  return (
    <>
      {isFetch && (
        <Box sx={{ width: "100%", height: "130px" }}>
          <ReivewOrderModal
            open={reviewOpen}
            data={{
              order: order,
              bmerchantData: bmerchants,
              logo: order.merchant_logo ?? DefaultProfile,
            }}
            onClose={() => setReviewOpen(false)}
          />
          <Box
            onClick={() => {
              navigator(
                `/order/bill/${merchantId}/${bmerchantId}/${order.transaction_uuid}`
              );
            }}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              backgroundColor: "white",
              gap: "18px",
              mt: 1,
              p: 1,
              borderRadius: "15px",
              cursor: "pointer",
              position: "relative",
              boxShadow: 1,
              ":before": {
                content: "''",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                borderRadius: "15px",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                opacity: 0,
                transition: "opacity 0.3s ease-in-out",
              },
              ":hover::before": {
                opacity: 1,
                borderRadius: "15px",
              },
            }}
          >
            <Box sx={{ position: "relative", width: "100px" }}>
              <Box
                alt="food"
                component="img"
                src={order.merchant_logo ?? DefaultProfile}
                sx={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "15px",
                  cursor: "pointer",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Box width={"100%"}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  noWrap
                  fontSize={"16px"}
                  fontWeight={700}
                  sx={{ textOverflow: "ellipsis", mr: "4px" }}
                >
                  {merchantData.name}
                </Typography>
                <StarIcon
                  sx={{ color: "rgb(250, 175, 0)", fontSize: "20px" }}
                />
                <Typography fontSize={"12px"} alignSelf={"center"}>
                  {" "}
                  ({rating.toFixed(2)}){" "}
                </Typography>
              </Box>
              <Typography
                className="line-clamp-1"
                variant="body2"
                color="textSecondary"
                sx={{ width: "50%" }}
              >
                <LocationOnOutlined
                  sx={{ color: "rgba(0, 0, 0, 0.6)", ml: "-5px" }}
                />
                {bmerchants.location}
              </Typography>
              {/* <Typography variant="body2" color="textSecondary">
                <DeliveryDining
                  sx={{ color: "rgba(0, 0, 0, 0.6)", mr: "5px" }}
                />
                {distance} Km
              </Typography> */}
              <Box
                display="flex"
                flexDirection={"row"}
                alignItems={"end"}
                gap={1}
              >
                <Typography fontSize={"12px"} color="textSecondary">
                  {merchantData.shop_type ?? "ร้านอาหารทั่วไป"}
                </Typography>
              </Box>
            </Box>
          </Box>
          {!isGuest && (
            <Box
              width={"30%"}
              sx={{
                position: "relative",
                top: "-80px",
                left: "68%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
              }}
            >
              <Button
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  setReviewOpen(true);
                }}
                fullWidth
                sx={{
                  borderRadius: "10px",
                  p: "10px",
                  backgroundColor: "white",
                  fontSize: ["12px", "14px", "14px"],
                }}
                startIcon={<StarOutline />}
              >
                รีวิวร้านค้า
              </Button>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}

export default PastOrderTiles;
