import * as yup from "yup";
import i18n from "i18next";

const full_nameValidate = yup
  .string()
  .required(() => i18n.t("validation.full_nameRequired"));

const emailValidate = yup
  .string()
  .email(() => i18n.t("validation.emailInvalid"))
  .required(() => i18n.t("validation.emailRequired"));

export const mobileNumberValidate = yup
  .string()
  .required(() => i18n.t("validation.phone"))
  .matches(/^[0-9]+$/, () => i18n.t("validation.mobileOnlyNumber"))
  .length(10, () => i18n.t("validation.mobileInvalid"));

const messageValidate = yup
  .string()
  .required(() => i18n.t("validation.message"));

export const formScheme = yup.object().shape({
  full_name: full_nameValidate,
  mobileNumber: mobileNumberValidate,
  email: emailValidate,
  message: messageValidate,
});
