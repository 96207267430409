import changeImage1 from "../../assets/images/instructions/Change image 1.png";
import changeImage2 from "../../assets/images/instructions/Change image 2.png";
import merchantSetting from "../../assets/images/instructions/Merchant Detail 1.png";
import merchantDetail2 from "../../assets/images/instructions/Merchant Detail 2.png";
import merchantDetail3 from "../../assets/images/instructions/Merchant Detail 3.png";
import manageMerchant from "../../assets/images/instructions/Manage Merchant main.png";
import openCloseTime1 from "../../assets/images/instructions/Open Close time 1.png";
import openCloseTime2 from "../../assets/images/instructions/Open Close time 2.png";
import serviceMerchant1 from "../../assets/images/instructions/Manage Service 1.png";
import serviceMerchant2 from "../../assets/images/instructions/Manage Service 2.png";
import billDesign1 from "../../assets/images/instructions/Bill Design 1.png";
import billDesign2 from "../../assets/images/instructions/Bill Design 2.png";
import deliverFee1 from "../../assets/images/instructions/Deliver Fee 1.png";
import deliverFee2 from "../../assets/images/instructions/Deliver Fee 2.png";
import addStaff from "../../assets/images/instructions/Add staff 1.png";
import addStaff2 from "../../assets/images/instructions/Add staff 2.png";
import addStaff3 from "../../assets/images/instructions/Add staff 3.png";
import deleteStaff1 from "../../assets/images/instructions/Delete staff 1.png";
import deleteStaff2 from "../../assets/images/instructions/Delete staff 2.png";
import editStaff1 from "../../assets/images/instructions/Config Staff 1.png";
import editStaff2 from "../../assets/images/instructions/Config Staff 2.png";
import loginStaff1 from "../../assets/images/instructions/Login staff 1.png";
import loginStaff2 from "../../assets/images/instructions/Login staff 2.png";
import loginStaff3 from "../../assets/images/instructions/Login staff 3.png";
import addMenu1 from "../../assets/images/instructions/Add menu 1.png";
import addMenu2 from "../../assets/images/instructions/Add menu 2.png";
import addMenu3 from "../../assets/images/instructions/Add menu 3.png";
import deleteMenu1 from "../../assets/images/instructions/Remove menu 1.png";
import deleteMenu2 from "../../assets/images/instructions/Remove menu 2.png";
import addCategory1 from "../../assets/images/instructions/Add Category menu 1.png";
import addCategory2 from "../../assets/images/instructions/Add Category menu 2.png";
import deleteCategory1 from "../../assets/images/instructions/Remove category 1.png";
import addOption1 from "../../assets/images/instructions/Add option 1.png";
import addOption2 from "../../assets/images/instructions/Add option 2.png";
import deleteOption1 from "../../assets/images/instructions/Remove option 1.png";
import addTable1 from "../../assets/images/instructions/Add table 1.png";
import addTable2 from "../../assets/images/instructions/Add table 2.png";
import addTable3 from "../../assets/images/instructions/Add table 3.png";
import deleteTable1 from "../../assets/images/instructions/Remove table 1.png";
import moveTable1 from "../../assets/images/instructions/Move table 1.png";
import moveTable2 from "../../assets/images/instructions/Move table 2.png";
import moveTable3 from "../../assets/images/instructions/Move table 3.png";
import checkBill1 from "../../assets/images/instructions/Check bill table 1.png";
import checkBill2 from "../../assets/images/instructions/Check bill table 2.png";
import checkBill3 from "../../assets/images/instructions/Check bill table 3.png";
import addQueue1 from "../../assets/images/instructions/Add queue 1.png";
import addQueue2 from "../../assets/images/instructions/Add queue 2.png";
import addQueue3 from "../../assets/images/instructions/Add queue 3.png";
import deleteQueue1 from "../../assets/images/instructions/Remove queue 1.png";
import deleteQueue2 from "../../assets/images/instructions/Remove queue 2.png";
import checkQueue1 from "../../assets/images/instructions/Recieve queue 1.png";
import checkQueue2 from "../../assets/images/instructions/Recieve queue 2.png";
import confirmOrderKitchen1 from "../../assets/images/instructions/Confirm order sit in 1.png";
import confirmOrderKitchen2 from "../../assets/images/instructions/Confirm order sit in 2.png";
import confirmOrderKitchen3 from "../../assets/images/instructions/Confirm order sit in 3.png";
import manageOrder from "../../assets/images/instructions/Manage order.png";
import confirmOrder1 from "../../assets/images/instructions/Confirm order 1.png";
import confirmOrder2 from "../../assets/images/instructions/Confirm order 2.png";
import confirmOrder3 from "../../assets/images/instructions/Confirm order 3.png";
import cancelOrder1 from "../../assets/images/instructions/Cancel order 1.png";
import cancelOrder2 from "../../assets/images/instructions/Cancel order 2.png";
import saveKYC1 from "../../assets/images/instructions/Save kyc 1.png";
import saveKYC2 from "../../assets/images/instructions/Save kyc 2.png";
import sendKYC1 from "../../assets/images/instructions/Send kyc 1.png";
import sendKYC2 from "../../assets/images/instructions/Send kyc 2.png";
import cash1 from "../../assets/images/instructions/Cash 1.png";
import promptpay1 from "../../assets/images/instructions/Promptpay 1.png";
import promptpay2 from "../../assets/images/instructions/Promptpay 2.png";
import thaiQr1 from "../../assets/images/instructions/Thai qr 1.png";
import thaiQr2 from "../../assets/images/instructions/Thai qr 2.png";

export const instructionData = [
  {
    title: "เปลี่ยนโปรไฟล์ และพื้นหลังร้านค้า",
    navigate: "merchantProfile",
    data: [
      {
        img: changeImage1,
        description: "1.คลิกที่ไอค้อนโปรไฟล์",
      },
      {
        img: changeImage2,
        description:
          "2.คลิกที่ ”โปรไฟล์”และ “ภาพพื้นหลัง” เพื่ออัปโหลดรูปได้ตามใจชอบ",
      },
    ],
  },
  {
    title: "เปลี่ยนรายละเอียดข้อมูลร้านค้า",
    navigate: "merchantDetail",
    data: [
      {
        img: merchantSetting,
        description: "1.ไปที่ตั้งค่า",
      },
      {
        img: merchantDetail2,
        description: "2.เลือกไปที่ ข้อมูลร้านค้า",
      },
      {
        img: merchantDetail3,
        description: "3.ร้านค้าสามารถเปลี่ยนข้อมูลตามที่ต้องการได้เลย",
      },
    ],
  },
  {
    title: "ตั้งค่าเวลา เปิด-ปิด",
    navigate: "openCloseTime",
    data: [
      {
        img: manageMerchant,
        description: "1.ไปที่ตั้งค่าร้านค้า",
      },
      {
        img: openCloseTime1,
        description: "2.เลือกเวลาเปิด-ปิด",
      },
      {
        img: openCloseTime2,
        description: "3.สามารถเลือกเวลาเปิด-ปิด ได้ตามใจชอบ",
      },
    ],
  },
  {
    title: "ระบบให้บริการร้านค้า",
    navigate: "serviceMerchant",
    data: [
      {
        img: manageMerchant,
        description: "1.ไปที่ตั้งค่าร้านค้า",
      },
      {
        img: serviceMerchant1,
        description: "2.เลือกการให้บริการร้านค้า",
      },
      {
        img: serviceMerchant2,
        description:
          "3.ร้านสามารถเลือกเปิด-ปิดบริการของทางร้านที่ต้องการได้เลย",
      },
    ],
  },
  {
    title: "ออกแบบใบเสร็จรับเงิน",
    navigate: "billDesign",
    data: [
      {
        img: manageMerchant,
        description: "1.ไปที่ตั้งค่าร้านค้า",
      },
      {
        img: billDesign1,
        description: "2.เลือกออกแบบใบเสร็จรับเงิน",
      },
      {
        img: billDesign2,
        description: "3.ร้านสามารถออกแบบใบเสร็จได้ตามใจชอบ",
      },
    ],
  },
  {
    title: "จัดการค่าจัดส่ง",
    navigate: "deliverFee",
    data: [
      {
        img: manageMerchant,
        description: "1.ไปที่ตั้งค่าร้านค้า",
      },
      {
        img: deliverFee1,
        description: "2.เลือกค่าจัดส่ง",
      },
      {
        img: deliverFee2,
        description:
          "3.ร้านค้าสามารถตั้งค่าการจัดส่งโดยใส่ราคาตามระยะทางที่ร้านค้ากำหนดไว้",
      },
    ],
  },
  {
    title: "เพิ่มพนักงาน",
    navigate: "addStaff",
    data: [
      {
        img: merchantSetting,
        description: "1.ไปที่ตั้งค่า",
      },
      {
        img: addStaff,
        description: "2.เลือกจัดการพนักงาน",
      },
      {
        img: addStaff2,
        description: "3.กดเพิ่มพนักงาน",
      },
      {
        img: addStaff3,
        description:
          "4.ใส่ชื่อกับเลือกตำแหน่งพนักงานที่ร้านค้าต้องการ เสร็จแล้วกดสร้าง",
      },
    ],
  },
  {
    title: "ลบพนักงาน",
    navigate: "deleteStaff",
    data: [
      {
        img: deleteStaff1,
        description: "1.กดแก้ไข",
      },
      {
        img: deleteStaff2,
        description: "2.ให้ร้านกดไอค้อนถังขยะเพื่อลบพนักงาน",
      },
    ],
  },
  {
    title: "แก้ไขข้อมูลพนักงาน",
    navigate: "editStaff",
    data: [
      {
        img: editStaff1,
        description: "1.กดแก้ไข",
      },
      {
        img: editStaff2,
        description: "2.ร้านค้าสามารถแก้ไขชื่อและตำแหน่งพนักงานได้ตามใจชอบ",
      },
    ],
  },
  {
    title: "เข้าสู่ระบบด้วยพนักงาน",
    navigate: "loginWithStaff",
    data: [
      {
        img: loginStaff1,
        description: "1.เลือกQR ของพนักงาน",
      },
      {
        img: loginStaff2,
        description: "2.เปิดหน้าสแกน",
      },
      {
        img: loginStaff3,
        description: "3.ให้พนักงานสแกนผ่านหน้าล็อคอิน",
      },
    ],
  },
  {
    title: "เพิ่มเมนู",
    navigate: "addMenu",
    data: [
      {
        img: addMenu1,
        description: "1.เลือกเมนู",
      },
      {
        img: addMenu2,
        description: "2.กดเพิ่มเมนู",
      },
      {
        img: addMenu3,
        description: "3.ใส่รายละเอียดต่างๆของอาหาร",
      },
    ],
  },
  {
    title: "ลบเมนู",
    navigate: "deleteMenu",
    data: [
      {
        img: deleteMenu1,
        description: "1.กดไอคอนดินสอ",
      },
      {
        img: deleteMenu2,
        description: "2.กดลบ ",
      },
    ],
  },
  {
    title: "เพิ่มประเภท",
    navigate: "addCategory",
    data: [
      {
        img: addCategory1,
        description: "1.กดเพิ่มประเภท",
      },
      {
        img: addCategory2,
        description: "2.ใส่ชื่อประเภทที่คุณต้องการ",
      },
    ],
  },
  {
    title: "ลบประเภท",
    navigate: "deleteCategory",
    data: [
      {
        img: deleteCategory1,
        description: "1.กดที่ไอคอนถังขยะเพื่อลบประเภท",
      },
    ],
  },
  {
    title: "เพิ่มตัวเลือก",
    navigate: "addOption",
    data: [
      {
        img: addOption1,
        description: "1.กดเพิ่มตัวเลือก",
      },
      {
        img: addOption2,
        description: "2.ใส่รายละเอียดตัวเลือก",
      },
    ],
  },
  {
    title: "ลบตัวเลือก",
    navigate: "deleteOption",
    data: [
      {
        img: deleteOption1,
        description: "1.กดที่ไอคอนถังขยะเพื่อลบตัวเลือก",
      },
    ],
  },
  {
    title: "เพิ่มโต๊ะ",
    navigate: "addTable",
    data: [
      {
        img: addTable1,
        description: "1.กดโต๊ะ/เช็คบิล",
      },
      {
        img: addTable2,
        description: "2.กดจัดโต๊ะ",
      },
      {
        img: addTable3,
        description: "3.กดเพิ่มโต๊ะ ตามจำนวนที่ร้านต้องการ",
      },
    ],
  },
  {
    title: "ลบโต๊ะ",
    navigate: "deleteTable",
    data: [
      {
        img: deleteTable1,
        description: "1.กดลบที่เครื่องหมาย - เพื่อลบโต๊ะ",
      },
    ],
  },
  {
    title: "ย้ายโต๊ะ",
    navigate: "moveTable",
    data: [
      {
        img: moveTable1,
        description: "1.กดโต๊ะ",
      },
      {
        img: moveTable2,
        description: "2.กดที่เปลี่ยนโต๊ะ",
      },
      {
        img: moveTable3,
        description: "3.กดโต๊ะที่ลูกค้าต้องการเลือกไป",
      },
    ],
  },
  {
    title: "เช็คบิล",
    navigate: "checkBill",
    data: [
      {
        img: checkBill1,
        description: "1.กดที่เช็คบิล",
      },
      {
        img: checkBill2,
        description: "2.กดเช็คบิล",
      },
      {
        img: checkBill3,
        description: "3.ตรวจสอบรายการและเลือกช่องทางการชำระเงิน",
      },
    ],
  },
  {
    title: "เพิ่มคิว",
    navigate: "addQueue",
    data: [
      {
        img: addQueue1,
        description: "1.กดที่จองคิว",
      },
      {
        img: addQueue2,
        description: "2.กดที่เพิ่มคิว",
      },
      {
        img: addQueue3,
        description: "3.กรอกรายละเอียดลูกค้า",
      },
    ],
  },
  {
    title: "รับคิว",
    navigate: "receiveQueue",
    data: [
      {
        img: checkQueue1,
        description: "1.กดที่คิวลูกค้า",
      },
      {
        img: checkQueue2,
        description: "2.ให้ลูกค้าสแกนเมื่อถึงคิวลูกค้าแล้ว",
      },
    ],
  },
  {
    title: "ลบคิว",
    navigate: "deleteQueue",
    data: [
      {
        img: deleteQueue1,
        description: "1.กดที่คิวลูกค้า",
      },
      {
        img: deleteQueue2,
        description: "2.กดไอคอนถังขยะเพื่อลบ",
      },
    ],
  },
  {
    title: "ยืนยันออเดอร์",
    navigate: "confirmOrderKitchen",
    data: [
      {
        img: confirmOrderKitchen1,
        description: "1.กดที่ครัว",
      },
      {
        img: confirmOrderKitchen2,
        description: "2.กดที่ออเดอร์",
      },
      {
        img: confirmOrderKitchen3,
        description: "3.กดเลือกออเดอร์ที่ต้องการยืนยัน จากนั้นกดเสร็จ",
      },
    ],
  },
  {
    title: "ยืนยันออเดอร์",
    navigate: "confirmOrder",
    data: [
      {
        img: manageOrder,
        description: "1.กดที่ที่รายการวันนี้",
      },
      {
        img: confirmOrder1,
        description: "2.กดที่ออเดอร์ที่เข้ามา",
      },
      {
        img: confirmOrder2,
        description: "3.กดที่ยืนยันออเดอร์(เป็นกรณีที่ลูกค้าสั่งมาจากเว็ปไซต์)",
      },
      {
        img: confirmOrder3,
        description: "4.ให้ร้านเลือกเวลาที่ต้องการทำอาหารให้ลูกค้า",
      },
    ],
  },
  {
    title: "ยกเลิกออเดอร์",
    navigate: "cancelOrder",
    data: [
      {
        img: manageOrder,
        description: "1.กดที่ที่รายการวันนี้",
      },
      {
        img: cancelOrder1,
        description: "2.กดที่ออเดอร์ที่เข้ามา",
      },
      {
        img: cancelOrder2,
        description: "3.กดที่ยกเลิกออเดอร์(เป็นกรณีที่ลูกค้าสั่งมาจากเว็ปไซต์)",
      },
    ],
  },
  {
    title: "บันทึกเอกสาร",
    navigate: "saveKYC",
    data: [
      {
        img: saveKYC1,
        description: "1.กดที่อัปโหลดเอกสาร",
      },
      {
        img: saveKYC2,
        description: "2.กดบันทึกเอกสาร",
      },
    ],
  },
  {
    title: "ส่งเอกสาร",
    navigate: "sendKYC",
    data: [
      {
        img: sendKYC1,
        description: "1.กรอกเอกสารที่จำเป็นให้ครบ",
      },
      {
        img: sendKYC2,
        description: "2.กดส่งเอกสารเมื่อกรอกเอกสารเสร็จ",
      },
    ],
  },
  {
    title: "ระบบเงินสด",
    navigate: "cashSystem",
    data: [
      {
        img: cash1,
        description:
          "1.กรณีลูกค้าจ่ายเงินสด ให้ร้านค้าเลือกวิธีการชำระ “เงินสด” และใส่เงินสดตามจำนวนที่ร้านค้าได้รับ",
      },
    ],
  },
  {
    title: "ระบบ PromptPay QR",
    navigate: "promptPayQR",
    data: [
      {
        img: promptpay1,
        description:
          "1.กรณีลูกค้าจ่ายเงินด้วยพอมป์เพย์ ให้ร้านค้าเลือกวิธีการชำระ “พอมป์เพย์ ”",
      },
      {
        img: promptpay2,
        description: "2.ให้ลูกค้าสแกนคิวอาร์โค้ดเพื่อชำระเงิน",
      },
    ],
  },
  {
    title: "ระบบ Thai QR",
    navigate: "thaiQR",
    data: [
      {
        img: thaiQr1,
        description:
          "1.กรณีลูกค้าจ่ายเงินด้วยสแกน ให้ร้านค้าเลือกวิธีการชำระ “Thai QR ”",
      },
      {
        img: thaiQr2,
        description: "2.ให้ลูกค้าสแกนคิวอาร์โค้ดเพื่อชำระเงิน",
      },
    ],
  },
];
