import React from "react";
import { Box, Typography, Button } from "@mui/material";
import SuccessImg from "../../assets/images/emoji_pomkins_correct320.png";
import { useNavigate } from "react-router-dom";
function VerifyEmailSuccess() {
  const navigator = useNavigate();
  return (
    <Box
      component="form"
      sx={{
        backgroundColor: "white",
        mt: 10,
        py: 6,
        px: [2, 4, 6],
        borderRadius: 3,
        boxShadow: "-1px -1px 10px 1px rgba(0, 0, 0, 0.2)",
        display: "flex",
        width: ["90%", "70%", "400px"],
        flexDirection: "column",
        gap: 1,
      }}
    >
      <img
        src={SuccessImg}
        alt="logo"
        title="logo"
        loading="lazy"
        width="50px"
        height="50px"
        style={{
          width: "50px",
          height: "50px",
        }}
      />
      <Typography variant="h5" className="mt-3 font-bold ">
        ยืนยันอีเมลสำเร็จ
      </Typography>
      <Typography className="text-[#a8a8a8] mb-3">
        อีเมลของคุณได้รับการยืนยันเรียบร้อยแล้ว โปรดเข้าสู่ระบบใหม่
        เพื่อเข้าใช้งานบัญชีของคุณ{" "}
      </Typography>
      <Button
        variant="contained"
        fullWidth
        onClick={() => navigator("/login/new")}
        sx={{
          mt: 2,
          borderRadius: 6,
        }}
      >
        เข้าสู่ระบบ
      </Button>
    </Box>
  );
}

export default VerifyEmailSuccess;
