import React from "react";
import { Dialog, Typography, Button, Box } from "@mui/material";

function ModalCancelQueue({ open, onClose, onCancel }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ p: 2, minWidth: "300px" }}>
        <Typography variant="h6" sx={{ textAlign: "start", mb: 1 }}>
          ยกเลิกคิว
        </Typography>
        <Typography
          sx={{ textAlign: "start", mb: 2, color: "rgba(0, 0, 0, 0.6)" }}
        >
          คุณต้องการยกเลิกคิวหรือไม่?
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={onClose}
            sx={{ mr: 1, borderRadius: "10px" }}
          >
            ไม่
          </Button>
          <Button
            onClick={() => {
              onCancel();
              onClose();
            }}
            variant="contained"
            sx={{ borderRadius: "10px" }}
          >
            ใช่
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default ModalCancelQueue;
