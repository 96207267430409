import React, { useState, useEffect, useRef } from "react";
import SwiperCore from "swiper";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { IconButton } from "@mui/material";
import {
  ArrowBackIos,
  ArrowBackIosNewSharp,
  ArrowForwardIos,
} from "@mui/icons-material";
import "swiper/css";
import PromotionCards from "./PromotionCards";
import CuisineCards from "./CuisineCards";
import BrandCards from "./BrandCards";
import FoodCard from "./FoodCards";
import "./CardSlider.css";

SwiperCore.use([Navigation]);

function CardSlider({ data }) {
  const [swiper, setSwiper] = useState(null);
  // const [index, setIndex] = useState(0);
  const sliderRef = useRef(null);

  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  // const [isEnd, setIsEnd] = useState(false);

  const settings = {
    onSwiper: (swiper) => setSwiper(swiper),
    navigation: false,
    slidesPerView: "auto",
    className: "home-slider",
  };

  useEffect(() => {
    if (swiper) {
      const updateStates = () => {
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
      };

      swiper.on("slideChange", updateStates);
      swiper.on("reachEnd", updateStates); // Ensure reachEnd event is handled
      swiper.on("reachBeginning", updateStates); // Ensure reachBeginning event is handled

      updateStates();
    }
  }, [swiper]);

  const goToNextSlide = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const goToPrevSlide = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  const renderCard = (items) => {
    switch (data.name) {
      case "promotions":
        return <PromotionCards items={items} />;
      case "cuisine":
        return <CuisineCards items={items} />;
      case "brands":
        return <BrandCards items={items} />;
      case "foods":
        return <FoodCard items={items} />;
      default:
        return <CuisineCards items={items} />;
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <IconButton
        onClick={goToPrevSlide}
        disabled={isBeginning}
        sx={{
          position: "absolute",
          left: -20,
          top: "50%",
          transform: "translateY(-50%)",
          background: "#FF961B",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
          zIndex: 10,
          color: "white",
          display: isBeginning ? "none" : "",
          "&:hover": {
            background: "#FF961B",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
            color: "white",
            cursor: "pointer",
          },
          "&:disabled": {
            background: "gray",
            cursor: "not-allowed",
          },
        }}
      >
        <ArrowBackIosNewSharp />
      </IconButton>
      <Swiper ref={sliderRef} {...settings}>
        {data &&
          data.slides &&
          data.slides.map((items, i) => (
            <SwiperSlide key={i}>{renderCard(items)}</SwiperSlide>
          ))}
      </Swiper>
      <IconButton
        onClick={goToNextSlide}
        disabled={isEnd}
        sx={{
          position: "absolute",
          right: -20,
          top: "50%",
          transform: "translateY(-50%)",
          background: "#FF961B",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
          zIndex: 10,
          color: "white",
          display: isEnd ? "none" : "",
          "&:hover": {
            background: "#FF961B",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
            color: "white",
            cursor: "pointer",
          },
          "&:disabled": {
            background: "gray",
            cursor: "not-allowed",
          },
        }}
      >
        <ArrowForwardIos />
      </IconButton>
    </div>
  );
}

export default CardSlider;
