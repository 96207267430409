export function startTimer(title, order_uuid) {
  const getLocalStorageKey = () => `${title}Timer_${order_uuid}`;

  const getRemainingTime = () => {
    const timerDataJSON = localStorage.getItem(getLocalStorageKey());

    if (timerDataJSON) {
      const timerData = JSON.parse(timerDataJSON);
      // const startTime = new Date(timerData.startTime);
      const endTime = new Date(timerData.endTime);
      return endTime - new Date();
    } else {
      return null;
    }
  };

  const updateLocalStorage = (timerData) => {
    localStorage.setItem(getLocalStorageKey(), JSON.stringify(timerData));
  };

  const clearTimerAndLocalStorage = () => {
    clearInterval(countdownInterval);
    localStorage.removeItem(getLocalStorageKey());
  };

  let remainingTime = getRemainingTime();
  let countdownInterval; // Declare countdownInterval here

  if (remainingTime === null) {
    const startTime = new Date();
    const endTime = new Date(startTime.getTime() + 5 * 60000); // 5 minutes in milliseconds

    const timerData = {
      startTime: startTime.toString(),
      endTime: endTime.toString(),
    };

    updateLocalStorage(timerData);
    remainingTime = endTime - startTime;

    countdownInterval = setInterval(() => {
      const currentTime = new Date();
      remainingTime = endTime - currentTime;

      if (remainingTime <= 0) {
        clearTimerAndLocalStorage();
      }
    }, 1000);
  }

  return Math.floor(remainingTime / 1000);
}
