export function loadGoogleMapsAPI() {
  const GOOGLE_MAPS_API_KEY = "AIzaSyCf0iF-Wk0h6A32W7DgVDS1BjYABfanT0Q";
  return new Promise((resolve) => {
    if (window.google) {
      // If the google object is already available, resolve immediately
      resolve();
    } else {
      // Otherwise, load the script and resolve when it's loaded
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places,geometry,drawing`;
      script.async = true;
      script.id = "google-maps";
      script.onload = resolve;
      document.head.appendChild(script);
    }
  });
}
