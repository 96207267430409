// src/utils/timeIntervals.js

export const getTimeIntervals = (startTime, endTime, interval) => {
  const times = [];
  let start = new Date(`1970-01-01T${startTime}:00`);
  let end = new Date(`1970-01-01T${endTime}:00`);

  // If endTime is less than startTime, it means it wraps to the next day
  if (endTime <= startTime) {
    end.setDate(end.getDate() + 1);
  }

  while (start < end) {
    const endInterval = new Date(start.getTime() + interval * 60000); // Add interval in milliseconds
    if (endInterval > end) break; // Stop if the end interval exceeds the end time

    const formattedStart = formatTime(start);
    const formattedEnd = formatTime(endInterval);
    times.push(`${formattedStart}-${formattedEnd}`);

    start = endInterval; // Move to the next interval
  }

  return times;
};

// Helper function to format time in HH:MM format
export const formatTime = (date) => {
  return date.toTimeString().split(" ")[0].substring(0, 5);
};

export const filterIntervals = (intervals, currentTime) => {
  return intervals.filter((interval) => {
    const [start] = interval.split("-");
    return start > currentTime;
  });
};
