import React, { useEffect, useState, useCallback } from "react";
import QueueTicket from "./QueueTicket";
import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  getBMerchantById,
  getMerchantById,
} from "../../libs/merchantAPI/merchantAPI";
import LoadingScreen from "../../components/LoadingScreen";
import { deletePhoneQueue, getPhoneQueue } from "../../libs/queueAPI/queueAPI";
import QueueNotFound from "./QueueNotFound";
import CancelQueue from "./CancelQueue";
import QueueAccept from "./QueueAccept";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { t } from "i18next";
function QueuePage() {
  const { merchant_id, merchant_name, bmerchant_id, phone } = useParams();
  const [merchantData, setMerchantData] = useState();
  const [bmerchantData, setBMerchantData] = useState();
  const [isFetch, setIsFetch] = useState(false);
  const [queueData, setQueueData] = useState([]);
  const [isCancel, setIsCancel] = useState(false);
  const [isQueueAccept, setIsQueueAccept] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/merchat/queue/${merchant_id}/${bmerchant_id}/${merchant_name}/${phone}`,
      title: "QueuePage",
    });
  }, []);
  // if ("Notification" in window) {
  //   Notification.requestPermission().then(function (permission) {
  //     if (permission === "granted") {
  //       // Permission to show notifications has been granted
  //     }
  //   });
  // }
  // if (Notification.permission === "granted") {
  //   const notification = new Notification("Notification Title", {
  //     body: "Notification Content",
  //   });

  //   notification.onclick = function () {
  //     // Handle notification click event
  //   };
  // }

  const cancelQueue = () => {
    deletePhoneQueue(phone, bmerchantData.uuid).then(() => {
      setIsCancel(true);
    });
  };

  const fetchQueueData = useCallback(
    (bmerchant_uuid) => {
      getPhoneQueue(phone, bmerchant_uuid)
        .then((res) => {
          setQueueData(res.data);
        })
        .catch((error) => {
          getPhoneQueue(phone, bmerchant_uuid, false)
            .then(() => {
              setIsQueueAccept(true);
            })
            .catch(() => {
              setIsNotFound(true);
            });
        });
    },
    [phone]
  );

  useEffect(() => {
    if (!isFetch) {
      getMerchantById(merchant_id).then((res) => {
        setMerchantData(res.data);
      });
      getBMerchantById(bmerchant_id).then((res) => {
        setBMerchantData(res.data);
        fetchQueueData(res.data.uuid);
        setIsFetch(true);
      });
    }
  }, [bmerchant_id, fetchQueueData, isFetch, merchant_id]);

  useEffect(() => {
    const decreaseInterval = setInterval(() => {
      if (bmerchantData) {
        fetchQueueData(bmerchantData.uuid);
      }
    }, 5000);

    return () => {
      clearInterval(decreaseInterval);
    };
  }, [bmerchantData, fetchQueueData]);

  if (isQueueAccept) {
    return <QueueAccept />;
  }
  if (!isFetch) {
    return <LoadingScreen />;
  }
  if (isNotFound) {
    return <QueueNotFound />;
  }
  if (isCancel) {
    return <CancelQueue />;
  }
  return (
    <>
      <Helmet>
        <title>
          {merchant_name} | {t("your.queue")}
        </title>
        <meta
          name="description"
          content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
        />
        <link
          rel="canonical"
          href={`https://pompkins.com${window.location.pathname}`}
        />
        <meta property="og:url" content="https://pompkins.com/merchant/queue" />
      </Helmet>
      <Box sx={{ py: [8, 8, 10], textAlign: "center", height: "100vh" }}>
        <Typography fontSize={28} fontWeight={700} mb={2}>
          คิวของคุณ
        </Typography>
        {queueData.length > 0 && (
          <QueueTicket
            merchantData={merchantData}
            queueData={queueData[0]}
            onCancel={() => cancelQueue()}
            location={bmerchantData.location}
            contractNumber={bmerchantData.phone}
          />
        )}
      </Box>
    </>
  );
}

export default QueuePage;
