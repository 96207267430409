import React, { useState } from "react";
import { Box, Typography, Collapse } from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
const CustomSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function CookieToggleItem({
  title,
  description,
  setToggle,
  isToggle,
  isDisable,
}) {
  const [showDetails, setShowDetails] = useState(false);
  const handleToggleDetails = () => {
    setShowDetails(!showDetails);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        onClick={handleToggleDetails}
        sx={{
          backgroundColor: "rgb(243,243,243,1)",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "10px",
          width: "100%",
          cursor: "pointer",
          mb: "-8px",
          p: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          {showDetails ? (
            <ExpandLess sx={{ color: "black" }} />
          ) : (
            <ExpandMore sx={{ color: "black" }} />
          )}

          <Typography>{title}</Typography>
        </Box>
        <CustomSwitch
          checked={isToggle}
          disabled={isDisable}
          defaultChecked
          onClick={(e) => {
            setToggle();
            e.stopPropagation();
          }}
        />
      </Box>
      <Collapse in={showDetails}>
        <Box sx={{ p: 2, backgroundColor: "rgb(243,243,243,1)" }}>
          <Typography>{description}</Typography>
        </Box>
      </Collapse>
    </Box>
  );
}

export default CookieToggleItem;
