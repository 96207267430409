import { Box, Button } from "@mui/material";

const NavigationMenu = ({
  setCanAccess,
  navigator,
  handleFindRestaurants,
  t,
}) => {
  return (
    <Box
      sx={{
        flexWrap: "wrap",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Button
        onClick={() => {
          setCanAccess(true);
          navigator("/");
        }}
        sx={{
          color: "black",
          lineClamp: 1,

          ":hover": {
            backgroundColor: "#fff8f0",
          },
        }}
      >
        {t("nav.whatIsPompkins")}
      </Button>
      <Button
        onClick={() => {
          setCanAccess(true);
          navigator("/pompkins/package");
        }}
        sx={{
          color: "black",
          lineClamp: 1,

          ":hover": {
            backgroundColor: "#fff8f0",
          },
        }}
      >
        {t("nav.priceAndPackage")}
      </Button>
      <Button
        onClick={() => handleFindRestaurants()}
        sx={{
          color: "black",
          lineClamp: 1,

          ":hover": {
            backgroundColor: "#fff8f0",
          },
        }}
      >
        {t("nav.findRestaurants")}
      </Button>
      <Button
        onClick={() => navigator("/pompkins/FAQ")}
        sx={{
          color: "black",
          lineClamp: 1,

          ":hover": {
            backgroundColor: "#fff8f0",
          },
        }}
      >
        {t("nav.FAQ")}
      </Button>
      {/* <Button
                      onClick={() => navigator("/pompkins/store")}
                      sx={{
                        color: "black",
                        lineClamp: 1,

                        ":hover": {
                          backgroundColor: "#fff8f0",
                        },
                      }}
                    >
                      {t("nav.store")}
                    </Button> */}
    </Box>
  );
};

export default NavigationMenu;
