// CartPage.js
import React, { useState, useEffect } from "react";
import CartItem from "../../components/cart/CartItem"; // Make sure this path is correct
import SlidingButtonGroup from "../../components/cart/SlidingButtonGroup"; // Ensure this is the correct path
import {
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useLocationStore } from "../../stores/useLocationStore";
import { useAuthStore } from "../../stores/useAuthStore";
import { useGuestLogin } from "../../stores/useGuestLogin";
import {
  getBMerchantById,
  getMerchantById,
  getServiceBMerchant,
} from "../../libs/merchantAPI/merchantAPI";
import TextInput from "../../components/TextInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { qrCartDetailScheme } from "../../libs/authScheme";
import LoadingScreen from "../../components/LoadingScreen";
import ModalPriceError from "../../components/ModalPriceError";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";

const QrCartPage = () => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [orderdataResponse, setOrderdataResponse] = useState([]);
  const { user } = useAuthStore();
  const { guest_user } = useGuestLogin();
  const navigator = useNavigate();
  const { merchant_id, bmerchant_id } = useParams();
  const [bmerchantData, setBMerchantData] = useState({});
  const [isFetch, setIsFetch] = useState(false);
  const [bmerchantService, setBMerchantService] = useState({});
  const [openPriceError, setOpenPriceError] = useState(false);
  const [merchantData, setMerchantData] = useState({});

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    values: {
      name: user?.first_name || guest_user?.name,
      phone: user?.phone || guest_user?.phone,
    },
    mode: "all",
    resolver: yupResolver(qrCartDetailScheme),
  });

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/merchant-qr/${merchant_id}/${bmerchant_id}/cartorder`,
      title: "QrCartPage",
    });
  }, []);

  useEffect(() => {
    getBMerchantById(bmerchant_id).then((res) => {
      const data = res.data;
      setBMerchantData(data);
      getServiceBMerchant(res.data.uuid).then(({ data }) => {
        setBMerchantService(data);
        setIsFetch(true);
      });
    });
    getMerchantById(merchant_id).then((res) => {
      setMerchantData(res.data);
    });
  }, []);

  useEffect(() => {
    const orderDataString = localStorage.getItem(
      `order_merchant_${bmerchant_id}`
    );
    const orderData = JSON.parse(orderDataString);
    if (orderData) {
      setOrderdataResponse(orderData);
      setOrders(orderData.order_detail);
    } else {
      navigator("/food");
    }
  }, []);

  const handleGotoCheckoutPage = async (data) => {
    if (totals < 1) {
      setOpenPriceError(true);
      return;
    }
    const formattedOrders = orders.map((order) => ({
      menu_id: parseInt(order.menu.id, 10),
      menu_name: order.menu.name,
      quantity: parseInt(order.quantity, 10),
      note: order.note || "-",
      price: order.price,
      option: order.option,
      image: order.menu.image,
    }));
    const total_order = formattedOrders.reduce(
      (total, order) => total + parseInt(order.quantity, 10),
      0
    );

    const orderData = {
      customer_uuid: orderdataResponse.customer_uuid,
      customer_name: data.name,
      total_order: total_order,
      phone: data.phone, // Replace with actual customer UUID
      bmerchant_uuid: orderdataResponse.merchant_uuid, // Replace with actual merchant UUID
      time_to_get: "", // Use the selected time or null
      table_number: null, // This can be dynamic based on your application logic
      transaction_uuid: null,
      order_type: "PICKUP", // Order type in uppercase
      order_detail: formattedOrders, // The formatted order details
      deliver_fee: 0, // Delivery fee (can be dynamic)
      discount: 0, // Discount (can be dynamic)
      order_distance: 0, // Distance
      total_amount: totals, // Total amount calculated from orders
      payment_method: "", // Payment method (can be dynamic)
      deliver_address: null, // Delivery address (can be dynamic)
      deliver_note: null,
    };

    localStorage.setItem(
      `checkout_data_${bmerchant_id}`,
      JSON.stringify(orderData)
    );
    navigator(`/merchant-qr/${merchant_id}/${bmerchant_id}/checkout`);
  };

  const totals = orders.reduce(
    (acc, item) => acc + item.quantity * item.price,
    0
  );
  const subtotal = bmerchantService.vat ? totals / 1.07 : totals;
  const vat = bmerchantService.vat ? totals - subtotal : 0;

  const handleQuantityChange = (index, delta) => {
    setOrders((orders) => {
      const newOrders = [...orders];
      const currentQuantity = parseInt(newOrders[index].quantity, 10);
      const newItemQuantity = currentQuantity + delta;

      if (newItemQuantity > 0) {
        newOrders[index].quantity = newItemQuantity;
      } else {
        // Remove the item if the new quantity is 0 or less
        newOrders.splice(index, 1);
      }
      return newOrders;
    });
  };

  useEffect(() => {
    const cartDataString = localStorage.getItem(`merchant_${bmerchant_id}`);
    let cartData = cartDataString ? JSON.parse(cartDataString) : {};

    localStorage.setItem(
      `merchant_${bmerchant_id}`,
      JSON.stringify({
        ...cartData,
        totalPrice: subtotal,
        cartList: orders,
      })
    );
  }, [orders]); // Only re-run the effect if orders changes

  useEffect(() => {
    localStorage.setItem(
      `order_merchant_${bmerchant_id}`,
      JSON.stringify({
        ...orderdataResponse,
        order_detail: orders,
      })
    );
  }, [orders]); // Only re-run the effect if orders changes

  if (!isFetch) {
    return <LoadingScreen />;
  }
  if (orders.length === 0) {
    navigator(-1);
  }
  return (
    <>
      <Helmet>
        <title>{t("cart.order")}</title>
        <meta
          name="description"
          content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
        />
        <link
          rel="canonical"
          href={`https://pompkins.com${window.location.pathname}`}
        />
        <meta property="og:url" content="https://pompkins.com/merchant-qr" />
      </Helmet>
      <Box
        sx={{
          height: "100vh",
          maxWidth: 1980,
          margin: "auto",
          padding: { xs: "0", sm: "2% 20%", md: "2% 24%" },
        }}
      >
        {openPriceError && (
          <ModalPriceError
            open={openPriceError}
            handleClose={() => setOpenPriceError(false)}
          />
        )}
        <Card sx={{ borderRadius: "15px" }}>
          <CardContent>
            <Typography variant="h5" fontWeight={500} mb={2}>
              รายการอาหารของคุณ
            </Typography>

            <Typography variant="body2" color="GrayText" mb={1}>
              ชื่อร้านค้า: {merchantData.name}
            </Typography>
            <Box
              sx={{
                mb: 1,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography variant="body2" color="GrayText" sx={{ mt: "2px" }}>
                ชื่อผู้รับ:
              </Typography>
              <TextInput
                placeholder={"ชื่อผู้รับ"}
                error={errors.name?.message}
                {...register("name", { required: false })}
                sx={{
                  height: "24px",
                  ml: "7px",
                  py: "0px !important",
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#FF961B",
                    },
                  },
                }}
                InputProps={{
                  sx: {
                    height: "24px",
                    ml: "7px",
                    fontSize: "14px",
                    py: "0px !important",
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                mb: 1,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography variant="body2" color="GrayText" sx={{ mt: "2px" }}>
                เบอร์โทรศัพท์:
              </Typography>
              <TextInput
                placeholder={"เบอร์โทรศัพท์"}
                error={errors.phone?.message}
                {...register("phone", { required: false })}
                sx={{
                  height: "24px",
                  ml: "7px",
                  py: "0px !important",
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#FF961B",
                    },
                  },
                }}
                InputProps={{
                  sx: {
                    height: "24px",
                    ml: "7px",
                    fontSize: "14px",
                    py: "0px !important",
                  },
                }}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={() =>
                  navigator(
                    `/merchant-qr/${merchant_id}/${bmerchant_id}/${merchantData.name}`
                  )
                }
                sx={{
                  m: 0,
                  p: 0,
                  alignSelf: "flex-end",
                  minWidth: 0,
                  color: "#FF961B",
                  ":hover": {
                    backgroundColor: "#FFF7EB",
                  },
                }}
              >
                {t("cartOrder.addMenu")}
              </Button>
            </Box>
            {orders.map((item, index) => (
              <CartItem
                key={item.menu.id}
                item={item}
                index={index}
                handleQuantityChange={handleQuantityChange}
              />
            ))}

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 3,
              }}
            >
              <Typography fontSize={"18px"}>โค้ดส่วนลด</Typography>
              <Box>
                <TextField
                  placeholder="กรอกหรือเลือกโค้ด"
                  size="small"
                  variant="standard"
                  InputProps={{
                    disableUnderline: "true",
                    sx: {
                      borderRadius: "10px",
                      p: "4px",
                      backgroundColor: "rgba(0, 0, 0, 0.06)",
                    },
                  }}
                />
                <Button
                  sx={{
                    color: "black",
                    p: "4px",
                    pl: "10px",
                    fontSize: "18px",
                    minWidth: 0,
                  }}
                >
                  {">"}
                </Button>
              </Box>
            </Box>
            {/* ส่วนสรุป */}

            <Typography variant="h6"></Typography>
            {bmerchantService.vat && (
              <Box
                sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
              >
                <Typography> {}ราคารวมก่อนภาษีมูลค่าเพิ่ม</Typography>
                <Typography>{`฿ ${subtotal.toFixed(2)}`}</Typography>
              </Box>
            )}
            {bmerchantService.vat && (
              <Box
                sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
              >
                <Typography>VAT7%</Typography>
                <Typography>{`฿ ${vat
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}</Typography>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                my: 1,
                fontWeight: "bold",
              }}
            >
              <Typography variant="h6" sx={{ color: "black" }}>
                ราคารวมทั้งสิ้น
              </Typography>

              <Typography>{`฿ ${totals
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}</Typography>
            </Box>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2, p: "8px 22px", borderRadius: "2rem" }}
              onClick={handleSubmit(handleGotoCheckoutPage)}
            >
              เช็คเอาท์
            </Button>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default QrCartPage;
