import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import OrderItem from "./OrderItem";
import { useAuthStore } from "../../stores/useAuthStore";
import { createReview } from "../../libs/merchantAPI/merchantAPI";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

function ReivewOrderModal({ open = false, data, onClose }) {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const { user } = useAuthStore();
  const date = dayjs(data.order.date);
  const thaiDay = date.format("D MMM YYYY");
  const time = date.format("HH:mm:ss");
  const handleRatingChange = (event, newRating) => {
    setRating(newRating);
  };
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };
  const handleConfirm = () => {
    const body = {
      customer_uuid: user.uuid || "5ad29e8c-bbab-46ef-bc65-9325ad4cabbb",
      bmerchant_uuid: data.bmerchantData.uuid,
      point: rating,
      customer_comment: comment,
    };
    createReview(body).then(() => {
      onClose();
    });
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "90%",
          minHeight: "70vh",
          backgroundColor: "white",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        },
      }}
    >
      <IconButton
        color="inherit"
        onClick={onClose}
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <Close sx={{ color: "#FF961B" }} />
      </IconButton>
      <DialogTitle className="font-bold" sx={{ backgroundColor: "white" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Box sx={{ position: "relative", width: "40px" }}>
            <Box
              alt="food"
              component="img"
              src={data.logo}
              sx={{
                width: "40px",
                height: "40px",
                borderRadius: "15px",
                cursor: "pointer",
                objectFit: "cover",
              }}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography>{data.bmerchantData.name}</Typography>
            <Typography fontSize={"12px"} mt={"-4px"} color={"GrayText"}>
              {thaiDay} {time}
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          width={"90%"}
          sx={{
            m: "0 auto",
            mt: 2,
          }}
        >
          {data.order_detail &&
            data.order.menu.map((menuItem, menuIndex) => (
              <OrderItem key={menuIndex} item={menuItem} />
            ))}
        </Box>
        <Box
          width={"90%"}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: 2,
            borderRadius: "15px",
            backgroundColor: "#f1f1f1",
            m: "0 auto",
          }}
        >
          <Typography variant="h5">ออเดอร์ของคุณเป็นอย่างไร</Typography>
          <Typography variant="subtitle1">ให้คะแนนออเดอร์ของคุณ</Typography>
          <Rating
            name="rating"
            value={rating}
            onChange={handleRatingChange}
            size="large"
          />
          <Typography variant="subtitle1">เพิ่มความคิดเห็นของคุณ</Typography>
          <TextField
            fullWidth
            id="outlined-multiline-static"
            placeholder="เขียนความคิดเห็นของคุณที่นี่..."
            multiline
            rows={2}
            sx={{ backgroundColor: "white" }}
            value={comment}
            onChange={handleCommentChange}
            margin="normal"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={rating === 0 || rating === null}
          onClick={handleConfirm}
          variant="contained"
          sx={{ m: 1 }}
        >
          ยอมรับ
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ReivewOrderModal;
