import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "../locales/en.json"; // Import translations for English
import thTranslation from "../locales/th.json"; // Import translations for Thai

i18n.use(initReactI18next).init({
  resources: {
    English: {
      translation: enTranslation,
    },
    ไทย: {
      translation: thTranslation,
    },
  },
  lng: "ไทย",
  fallbackLng: "ไทย",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
