import { Box, Dialog, Fade, Typography } from "@mui/material";
import loading from "../assets/images/ld1_basket320.gif";
import React from "react";
import useModalLoading from "../stores/useModalLoading";
import { useTranslation } from "react-i18next";

ModalLoading.defaultProps = {
  open: false,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});
function ModalLoading({ open }) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          width: "90%",
          maxWidth: "400px",
          minHeight: "30vh",
          backgroundColor: "#fff",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        },
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={loading}
          alt="loading"
          title="loading"
          loading="lazy"
          width="80px"
          height="80px"
          style={{
            width: "80px",
            height: "80px",
            marginBottom: "16px",
          }}
        />
        <Typography variant="h6" sx={{ mb: 2, color: "black" }}>
          {t("modal.loading")}
        </Typography>
      </Box>
    </Dialog>
  );
}

export function ModalComponent({ children }) {
  const { isOpen } = useModalLoading();
  return (
    <div>
      {children}
      <ModalLoading open={isOpen} />
    </div>
  );
}
