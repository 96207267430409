import { Box, Button, Typography } from "@mui/material";
import React from "react";
import MerchantTypeCard from "./MerchantTypeCard";
import Popup from "../../assets/images/shop1.png";
import SmallMerchant from "../../assets/images/shop2.png";
import MediumMerchant from "../../assets/images/shop3.png";
import LargeMerchant from "../../assets/images/shop4.png";
import { ArrowForwardIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function MerchantType() {
  const navigator = useNavigate();
  const { t } = useTranslation();
  const merchantTypeList = [
    {
      id: 1,
      title: t("welcome.merchantPopup"),
      img: Popup,
      description: t("welcome.merchantPopupDesc"),
    },
    {
      id: 2,
      title: t("welcome.merchantSmall"),
      img: SmallMerchant,
      description: t("welcome.merchantSmallDesc"),
    },
    {
      id: 3,
      title: t("welcome.merchnatMedium"),
      img: MediumMerchant,
      description: t("welcome.merchantMediumDesc"),
    },
    {
      id: 4,
      title: t("welcome.merchantLarge"),
      img: LargeMerchant,
      description: t("welcome.merchantLargeDesc"),
    },
  ];
  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        textAlign: "center",

        py: 8,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: ["column", "column", "row"],
          alignItems: "center",
          gap: [1, 2, 2, 3],
          px: [1, 2, 2, 3],
          justifyContent: ["center", "center", "space-around"],
          width: "100%",
        }}
      >
        <Typography
          data-aos="fade-up"
          fontSize={["30px", "42px", "40px", "50px"]}
          textAlign={["center", "center", "start"]}
          fontWeight={700}
          sx={{ color: "#F19A3C" }}
        >
          {t("welcome.merchantTypeTitle")}
          <Typography
            data-aos="fade-up"
            fontSize={["35px", "52px", "50px", "60px"]}
            textAlign={["center", "center", "start"]}
            fontWeight={700}
            sx={{ color: "#F19A3C" }}
          >
            {t("welcome.merchantTypeTitle2")}
          </Typography>
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 2,
            alignItems: "center",
            flexWrap: "wrap",
            my: 4,
            width: ["100%", "100%", "70%", "50%"],
          }}
        >
          {merchantTypeList.map((item, index) => (
            <MerchantTypeCard key={index} item={item} index={index} />
          ))}
        </Box>
      </Box>
      <Button
        onClick={() => navigator("/pompkins/package")}
        data-aos="fade-up"
        variant="contained"
        endIcon={<ArrowForwardIos sx={{ ml: 1 }} />}
        sx={{
          backgroundColor: "#FF961B",
          color: "white",
          padding: "10px 20px",
          borderRadius: "24px ",
          my: 3,
          fontSize: ["14px", "16px", "18px", "20px"],
        }}
      >
        {t("nav.priceAndPackage")}
      </Button>
    </Box>
  );
}

export default MerchantType;
