import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

const AuthButtons = ({ setLocationFrom, location }) => {
  const { t } = useTranslation();

  return (
    <>
      <Link to="/login/new" onClick={() => setLocationFrom(location.pathname)}>
        <Button
          variant="outlined"
          className="mx-2"
          sx={{ borderRadius: 6, minWidth: "100px" }}
        >
          {t("btn.login")}
        </Button>
      </Link>
      <Link to="/login/new" onClick={() => setLocationFrom(location.pathname)}>
        <Button
          variant="contained"
          className="mx-2 bg-primary"
          sx={{ borderRadius: 6, minWidth: "100px" }}
        >
          {t("btn.register")}
        </Button>
      </Link>
    </>
  );
};

export default AuthButtons;
