import { Box, Typography } from "@mui/material";
import React from "react";
import AcceptImg from "../../assets/image/check.png";
function QueueAccept() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        p: 2,
        textAlign: "center",
        backgroundColor: "white",
        height: "100vh",
      }}
    >
      <img
        src={AcceptImg}
        alt="Accept Img"
        title="Accept Img"
        loading="lazy"
        width="100px"
        height="auto"
      />
      <Typography variant="h5">ถึงคิวของคุณแล้ว</Typography>
    </Box>
  );
}

export default QueueAccept;
