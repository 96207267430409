import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import DefaultProfile from "../utils/defaultImage";
import { Typography } from "@mui/material";
import {
  Favorite,
  FavoriteBorder,
  LocationOnOutlined,
  PedalBikeOutlined,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthStore } from "../stores/useAuthStore";
import useSnackBar from "../stores/useSnackBar";
import { useTranslation } from "react-i18next";
import { putFavorite } from "../libs/customerAPI/favoriteAPI";
import StarIcon from "@mui/icons-material/Star";

function FoodTiles({ item, sx }) {
  const location = useLocation();
  const { isAuthenticated, updateUser, user, setLocationFrom } = useAuthStore();
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    if (isAuthenticated && user.favorite) {
      if (user.favorite.includes(item.bmerchantId)) {
        setIsFavorite(true);
      } else {
        setIsFavorite(false);
      }
    }
  }, [isAuthenticated, item.bmerchantId, user]);
  const { openSnack } = useSnackBar();
  const { t } = useTranslation();
  const navigator = useNavigate();
  const handleFavClick = () => {
    if (isAuthenticated) {
      let updateFavorite = [];

      if (isFavorite) {
        updateFavorite = user.favorite.filter(
          (favId) => favId !== item.bmerchantId
        );

        openSnack(t("snack.unFav"));
      } else {
        updateFavorite = [...user.favorite, item.bmerchantId];
        openSnack(t("snack.fav"));
      }
      updateUser({ ...user, favorite: updateFavorite });
      putFavorite(updateFavorite, user.uuid);
      setIsFavorite(!isFavorite);
    } else {
      setLocationFrom(location.pathname);
      navigator("/login/new");
    }
  };

  return (
    <Box
      onClick={() =>
        navigator(`/merchant/${item.id}/${item.bmerchantId}/${item.title}`)
      }
      sx={{
        width: ["100%", "100%", "calc(50% - 8px)"],
        display: "flex",
        flexDirection: "row",
        minWidth: ["200px", "300px", "300px"],
        backgroundColor: "white",
        gap: "18px",
        mt: 1,
        p: 1,
        borderRadius: "15px",
        cursor: "pointer",
        position: "relative",
        boxShadow: 3,
        ":before": {
          content: "''",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: "15px",
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          opacity: 0,
          transition: "opacity 0.3s ease-in-out",
        },
        ":hover::before": {
          opacity: 1,
          borderRadius: "15px",
        },
        ...sx,
      }}
    >
      <Box sx={{ position: "relative", width: "100px", height: "100px" }}>
        <Box
          alt="food"
          component="img"
          //   onClick={handleImageClick}
          src={item.img ?? DefaultProfile}
          loading="lazy"
          title={item.title}
          sx={{
            width: "100px",
            height: "100px",
            borderRadius: "15px",
            cursor: "pointer",
            objectFit: "cover",
          }}
        />
        {/* Favorite Icon Container */}
        <Box
          onClick={(e) => {
            e.stopPropagation();
            handleFavClick();
          }}
          sx={{
            position: "absolute",
            bottom: 3, // Adjusted from top to bottom
            right: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "20px",
            height: "20px",
            backgroundColor: "white",
            opacity: "90%",
            borderRadius: "50%",
            cursor: "pointer",
            zIndex: 1004,
          }}
        >
          {isFavorite ? (
            <Favorite
              sx={{
                color: "#FF961B",
                width: "15px",
                height: "15px",
              }}
            />
          ) : (
            <FavoriteBorder
              sx={{
                color: "#FF961B",
                width: "15px",
                height: "15px",
                mb: "-1px",
              }}
            />
          )}
        </Box>
      </Box>
      <Box flex={1}>
        <Box sx={{ display: "flex" }}>
          <Typography
            noWrap
            fontSize={"16px"}
            fontWeight={700}
            sx={{ textOverflow: "ellipsis", mr: "4px" }}
          >
            {item.title}
          </Typography>
          <StarIcon sx={{ color: "rgb(250, 175, 0)", fontSize: "20px" }} />
          <Typography fontSize={"12px"} alignSelf={"center"}>
            {" "}
            ({item.rating}){" "}
          </Typography>
        </Box>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: "1",
          }}
        >
          <LocationOnOutlined
            sx={{ color: "rgba(0, 0, 0, 0.6)", ml: "-5px" }}
          />
          {item.location}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <PedalBikeOutlined sx={{ color: "rgba(0, 0, 0, 0.6)", mr: "5px" }} />
          {item.distance}
        </Typography>
        <Box display="flex" flexDirection={"row"} alignItems={"end"} gap={1}>
          <Typography fontSize={"12px"} color="textSecondary">
            • {item.shopType}
          </Typography>
        </Box>
        <Typography
          variant="body2"
          sx={{ color: "#FF961B", fontSize: "12px", paddingTop: "6px" }}
        >
          <span className="text-red-500">*</span> {t("actualDriving")}
        </Typography>
      </Box>
    </Box>
  );
}

export default FoodTiles;
