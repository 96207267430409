import { create } from "zustand";
export const useCookieConsent = create((set) => ({
  cookieSetting: {
    necessaryCookie: true,
    performanceCookie: true,
    functionalCookie: true,
    adsCookie: true,
  },
  setCookieSetting: (settings) => {
    set({ cookieSetting: settings });
  },
}));
