import { useState, forwardRef } from "react";
import {
  IconButton,
  InputAdornment,
  TextField,
  FormHelperText,
} from "@mui/material";
import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";

const PasswordInput = forwardRef(({ error, placeholder, ...register }, ref) => {
  const [isShowPassword, setIsShowPassword] = useState(false);

  return (
    <div>
      <TextField
        fullWidth
        {...register}
        size="small"
        type={isShowPassword ? "text" : "password"}
        variant="outlined"
        placeholder={placeholder}
        inputRef={ref}
        InputProps={{
          endAdornment: (
            <InputAdornment
              onClick={() => setIsShowPassword(!isShowPassword)}
              position="end"
            >
              <IconButton aria-label="toggle password visibility">
                {isShowPassword ? (
                  <VisibilityOutlined className="text-primary" />
                ) : (
                  <VisibilityOffOutlined className="text-primary" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {error?.error && <FormHelperText error>{error?.error}</FormHelperText>}
    </div>
  );
});

export default PasswordInput;
