import { Box, Button, Dialog, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useLocationStore } from "../stores/useLocationStore";
import { useLocation, useNavigate } from "react-router-dom";
import customerLogo from "../assets/images/pompkins_food_logo.png";
import merchantLogo from "../assets/images/logo_512.png";
import { useTranslation } from "react-i18next";

function ModalMerchantCustomer({ open = false, setOpenWhoAmI }) {
  const { currentAddress, openModal } = useLocationStore();
  const whoAmI = sessionStorage.getItem("whoAmI");
  const navigator = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (whoAmI === "customer" && !currentAddress) {
      openModal();
    } else if (whoAmI === "customer" && currentAddress) {
      return;
    }
  }, [currentAddress, navigator, openModal, whoAmI]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpenWhoAmI(false)}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: "20px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          m: 3,
          px: [2, 5, 10],
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: 20,
            color: "#231F20",
            my: 2,
            textAlign: "center",
          }}
        >
          {t("choose.whoAmI")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: ["column", "row"],
            gap: 2,
            width: "100%",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              setOpenWhoAmI(false);
              sessionStorage.setItem("whoAmI", "merchant");
            }}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 2,
              width: "100%",
              color: "#231F20",
              fontWeight: 500,
              fontSize: 18,
              borderRadius: "20px",
              borderColor: "#231F20",
              border: "2px solid",
            }}
          >
            <Box
              component="img"
              src={merchantLogo}
              sx={{
                width: { xs: "20vw", sm: "15vw", md: "10vw", lg: "8vw" },
                height: { xs: "16vw", sm: "12vw", md: "8vw", lg: "6.5vw" },
                mb: 1,
                objectFit: "cover",
              }}
            />
            {t("choose.merchant")}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setOpenWhoAmI(false);
              sessionStorage.setItem("whoAmI", "customer");
              if (currentAddress) {
                navigator("/food");
              }
            }}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 2,
              width: "100%",
              color: "#231F20",
              fontWeight: 500,
              fontSize: 18,
              borderRadius: "20px",
              borderColor: "#231F20",
              border: "2px solid",
            }}
          >
            <Box
              component="img"
              src={customerLogo}
              sx={{
                width: { xs: "20vw", sm: "15vw", md: "10vw", lg: "8vw" },
                height: { xs: "16vw", sm: "12vw", md: "8vw", lg: "6.5vw" },
                mb: 1,
                objectFit: "contain",
              }}
            />
            {t("choose.customer")}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default ModalMerchantCustomer;
