import { Box, Button, Typography } from "@mui/material";
import React from "react";
import CancelImg from "../../assets/images/emoji_pomkins_x320.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function OrderCancelled({ orderType, contract }) {
  const navigator = useNavigate();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        p: 2,
        textAlign: "center",
        backgroundColor: "white",
      }}
    >
      <img
        src={CancelImg}
        alt="Cancel Img"
        title="Cancel Img"
        loading="lazy"
        width="100px"
        height="auto"
      />
      {orderType === "reserve" ? (
        <>
          <Typography variant="h5">
            {t("orderStatus.reserveNotSuccessful")}
          </Typography>
          <Typography variant="body1">
            {t("orderStatus.reservationNotCompleted")} {contract}
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h5">{t("orderStatus.orderCancel")}</Typography>
          <Typography variant="body1">
            {t("orderStatus.orderCancelDescription")} {contract}
          </Typography>
        </>
      )}

      <Button variant="outlined" onClick={() => navigator("/food")}>
        {t("btn.letOrderAgain")}
      </Button>
    </Box>
  );
}

export default OrderCancelled;
