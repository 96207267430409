import React, { useState } from "react";
import { Typography, Box, Card, CardActionArea } from "@mui/material";
import ModalImage from "../../components/ModalImage";
import DefaultProfile from "../../utils/defaultImage";
import ModalMenuCard from "./ModalMenuCard";
import { Info } from "@mui/icons-material";

function MenuCard({ items }) {
  const [open, setOpen] = useState(false);
  const [imageModal, setImageModal] = useState(false);

  const handleOpenModal = (items) => {
    setOpen(true);
  };

  const handleCloseModal = (event, reason) => {
    if (reason && reason === "backdropClick") {
      setOpen(false);
    }
  };

  return (
    <>
      <ModalImage
        open={imageModal}
        onClose={() => setImageModal(false)}
        imageUrl={items.image === "" ? DefaultProfile : items.image}
      />
      <ModalMenuCard
        items={items}
        open={open}
        setOpen={setOpen}
        handleCloseModal={handleCloseModal}
      />
      <Card
        sx={{
          position: "relative",
          boxShadow: 3,
          mb: 2,
          borderRadius: "15px",
        }}
      >
        <Box sx={{ p: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <CardActionArea
              sx={{
                ":hover": {
                  borderRadius: "15px",
                },
              }}
              onClick={() => handleOpenModal(items)}
            >
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box
                  alt={items.name}
                  component="img"
                  src={
                    items.image === "" || items.image === null
                      ? DefaultProfile
                      : items.image
                  }
                  sx={{
                    width: ["80px", "80px", "80px"],
                    height: ["80px", "80px", "80px"],
                    borderRadius: "20px",
                    objectFit: "cover",
                    maxWidth: "none !important",
                  }}
                />

                <Box
                  sx={{
                    flex: 1,
                    ml: 2,
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "calc(100% - 80px)",
                  }}
                >
                  <Typography
                    noWrap
                    className="font-bold"
                    sx={{
                      typography: ["subtitle1", "subtitle1", "subtitle1"],
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {items.name}
                  </Typography>
                  <Typography
                    sx={{
                      typography: ["h6", "h6", "h6"],
                    }}
                    className="font-bold text-primary"
                  >
                    {items.price
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                    {items.price === "" ? "" : " ฿"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                ></Box>
              </Box>
            </CardActionArea>
            <Info
              onClick={() => handleOpenModal(items)}
              sx={{
                color: "#FF961B",
                fontSize: "30px",
                position: "absolute",
                bottom: 30,
                right: 10,
                cursor: "pointer",
              }}
            />
          </Box>
        </Box>
      </Card>
    </>
  );
}

export default MenuCard;
