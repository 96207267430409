import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import GuestIcon from "../../assets/image/guest-icon.png";
import TextInput from "../../components/TextInput";
import { MuiTelInput } from "mui-tel-input";
import { useForm } from "react-hook-form"; // You need to import useForm
import { yupResolver } from "@hookform/resolvers/yup"; // You need to import yupResolver
import * as yup from "yup"; // You need to import yup and define 'nameScheme'
import { first_nameValidate } from "../../libs/authScheme";
import { isPhoneValid } from "../../libs/validatePhone";
import { useGuestLogin } from "../../stores/useGuestLogin";
import { useAuthStore } from "../../stores/useAuthStore";
import { useNavigate } from "react-router-dom";
import { mobileNumberValidate } from "../ContactSales/formScheme";

// Define your 'nameScheme' schema using Yup
const GuestValidate = yup.object().shape({
  name: first_nameValidate,
  phone: mobileNumberValidate,
});

function GuestLogin() {
  const { setGuest } = useGuestLogin();
  const { locationFrom } = useAuthStore();
  const navigator = useNavigate();
  function formatPhoneNumber(phoneNumber) {
    let formattedNumber = phoneNumber.replace(/\s/g, "");

    if (formattedNumber.startsWith("+")) {
      formattedNumber = formattedNumber.substring(3);
    }
    return formattedNumber;
  }
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
    resolver: yupResolver(GuestValidate),
  });

  const onSubmit = (data) => {
    const formatPhone = formatPhoneNumber(data.phone);
    const guestData = { name: data.name, phone: formatPhone };
    setGuest(guestData);
    localStorage.setItem("guest", JSON.stringify(guestData));
    navigator(locationFrom);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)} // Attach handleSubmit to your form
      sx={{
        backgroundColor: "white",
        mt: 10,
        py: 6,
        px: [2, 4, 6],
        borderRadius: 3,
        boxShadow: "-1px -1px 10px 1px rgba(0, 0, 0, 0.2)",
        display: "flex",
        width: ["90%", "70%", "400px"],
        flexDirection: "column",
        gap: 1,
      }}
    >
      <img
        src={GuestIcon}
        alt="logo"
        title="logo"
        loading="lazy"
        width="50px"
        height="50px"
        style={{
          width: "50px",
          height: "50px",
        }}
      />
      <Typography variant="h5" className="mt-3 font-bold">
        เข้าสู่ระบบด้วย Guest
      </Typography>
      <Typography className="text-[#a8a8a8] mb-3">
        การเข้าสู่ระบบด้วย Guest
        ระบบจะไม่สามารถบันทึกข้อมูลออเดอร์ต่างๆที่คุณเคยสั่ง
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          mb: 1,
        }}
      >
        <TextInput
          label={"ชื่อ"}
          placeholder={"กรุณากรอกชื่อของคุณ"}
          error={errors.name?.message}
          {...register("name", { required: true })}
        />
        <TextInput
          size="small"
          label={"เบอร์โทรศัพท์"}
          placeholder={"กรุณากรอกเบอร์โทรศัพท์ของคุณ"}
          error={errors.phone?.message}
          {...register("phone", { required: true })}
        />
      </Box>
      <Button
        type="submit"
        variant="contained"
        disabled={!isValid}
        sx={{
          mt: 2,
          borderRadius: 6,
        }}
      >
        ยืนยัน
      </Button>{" "}
      {/* Add a submit button */}
    </Box>
  );
}

export default GuestLogin;
