import { Search } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FaqData from "./FaqData";
import LoadingScreen from "../../components/LoadingScreen";
import ListFaq from "./ListFaq";
import ModalFeedback from "./ModalFeedback";
import CorrectImage from "../../assets/images/emoji_pomkins_correct320.png";
import SearchableFAQ from "./SearchFaq";
import PackageComponent from "../Welcome/PackageComponent";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

function FaqArticlesPage() {
  const { t } = useTranslation();
  const { categories, articles, sections } = useParams();
  const navigator = useNavigate();
  const [data, setData] = useState([]);
  const [isFetch, setIsFetch] = useState(false);
  const [displayData, setDisplayData] = useState([]);
  const { faqData, applicationFaqData } = FaqData();
  const [otherArticles, setOtherArticles] = useState([]);
  const [isPosFeedback, setIsPosFeedback] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const currentURL = window.location.href;
  const baseURL = currentURL.split("/").slice(0, 3).join("/");

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/pompkins/FAQ/categories/${categories}/sections/${sections}/${articles}`,
      title: "FaqArticalesPage",
    });
  }, []);
  useEffect(() => {
    if (categories === "Application") {
      setData(applicationFaqData);
    } else if (categories === "General") {
      setData(faqData);
    }
  }, [isFetch]);

  useEffect(() => {
    if (data.length > 0) {
      const filterSections = data.find((item) => item.type === sections);
      const filteredData = filterSections.data.find((item) =>
        item.question.replace("?", "").includes(articles.replace("?", ""))
      );
      if (filterSections.data.length > 0) {
        setOtherArticles(filterSections.data.slice(0, 10));
      }
      setDisplayData(filteredData);
      setIsFetch(true);
    }
  }, [data, sections]);
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      sx={{
        color: "#333333",
        cursor: "pointer",
      }}
      onClick={() => navigator(`/pompkins/FAQ`)}
    >
      Pompkins FAQ
    </Link>,
    <Link
      underline="hover"
      key="2"
      sx={{
        color: "#333333",
        cursor: "pointer",
      }}
      onClick={() => navigator(`/pompkins/FAQ/categories/${categories}`)}
    >
      {categories}
    </Link>,
    <Link
      underline="hover"
      key="3"
      sx={{
        color: "#666666",
        cursor: "pointer",
      }}
      onClick={() =>
        navigator(`/pompkins/FAQ/categories/${categories}/sections/${sections}`)
      }
    >
      {sections}
    </Link>,
  ];

  if (!isFetch) {
    return <LoadingScreen />;
  }
  return (
    <>
      <Helmet>
        <title>{t("title.faqpage")}</title>
        <meta
          name="description"
          content="แอปเดียว ครบ ง่าย คุ้ม เปลี่ยนทุกความไม่ง่ายในชีวิต ให้ง่ายขึ้นด้วยแอปเดียว POS สั่งอาหาร ชำระเงิน จัดการพนักงาน ดูยอดขาย ดาวน์โหลด ฟรี ได้วันนี้"
        />
        <link
          rel="canonical"
          href={`https://pompkins.com${window.location.pathname}`}
        />
        <meta property="og:url" content="https://pompkins.com/faq" />
      </Helmet>
      <Box sx={{ minHeight: "100vh", maxWidth: "1160px", mx: "auto" }}>
        <Box
          sx={{
            backgroundColor: "white",

            display: "flex",
            flexDirection: ["column", "column", "row-reverse"],

            mx: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: ["10vh", "10vh", "auto"],
              minWidth: "350px",
              maxWidth: ["1160px", "1160px", "350px"],
              backgroundColor: ["#FAFAFA", "#FAFAFA", "transparent"],
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: ["row", "row", "column"],
                justifyContent: "start",
                width: "100%",
                height: ["auto", "auto", "100%"],
                gap: 3,
                p: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  mt: [0, 0, "40px"],
                  mb: [0, 0, "43px"],
                  width: ["100%", "100%", "100%"],
                }}
              >
                <SearchableFAQ />
              </Box>
              <Box sx={{ display: ["none", "none", "unset"] }}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "24px",
                    mb: 2,
                  }}
                >
                  คำถามอื่นๆในหัวข้อนี้
                </Typography>
                {otherArticles.length > 0 &&
                  otherArticles.map((item, index) => (
                    <div key={index}>
                      <ListFaq
                        title={item.question}
                        sx={{ px: 0 }}
                        onClick={() => {
                          navigator(
                            `/pompkins/FAQ/categories/${categories}/sections/${sections}/${item.question}`
                          );
                          setIsPosFeedback(false);
                          setIsModalOpen(false);
                          setIsSubmit(false);
                          setIsFetch(false);
                        }}
                      />
                    </div>
                  ))}
                {otherArticles.length > 5 && (
                  <Box
                    onClick={() => {
                      navigator(
                        `/pompkins/FAQ/categories/${categories}/sections/${sections}`
                      );
                    }}
                    sx={{
                      display: "flex",
                      py: 1,
                      width: "fit-content",
                      color: "#666666",
                      cursor: "pointer",
                      ":hover": {
                        color: "#f9931f",
                        transition: " color 0.2s",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "500",
                      }}
                    >
                      {"ดูเพิ่มเติม >"}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box sx={{ p: 4, width: "100%" }}>
            <Breadcrumbs separator="›">{breadcrumbs}</Breadcrumbs>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: ["32px", "36px", "40px"],
                mt: 2,
              }}
            >
              {displayData.question}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                rowGap: "80px",
                columnGap: "70px",
                my: 6,
              }}
            >
              {articles === "แพ็คเกจแบบไหนเหมาะสำหรับคุณ" && (
                <Typography sx={{ fontSize: ["16px", "18px", "20px"] }}>
                  สามารถดูราคาแพ็คเกจได้ที่{" "}
                  <Link href={`${baseURL}/pompkins/package`} target="_blank">
                    https://pompkins.com/pompkins/package
                  </Link>
                </Typography>
              )}
              <Typography sx={{ fontSize: ["16px", "18px", "20px"] }}>
                {displayData.answer}
              </Typography>
            </Box>
          </Box>
        </Box>
        {!isSubmit && (
          <Box
            sx={{
              mx: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              maxWidth: "500px",
              backgroundColor: "white",
              border: "1px solid #f9931f",
              borderRadius: "12px",
              p: 3,
            }}
          >
            <Typography>{t("ourAnswerUseful")}</Typography>
            {!isSubmit ? (
              <Box
                sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mx: 1 }}
                  onClick={() => {
                    setIsPosFeedback(true);
                    setIsModalOpen(true);
                    setIsSubmit(true);
                  }}
                >
                  {t("helpfulAnswer")}
                </Button>
                <Button
                  variant="outlined"
                  sx={{ mx: 1 }}
                  onClick={() => {
                    setIsPosFeedback(false);
                    setIsModalOpen(true);
                  }}
                >
                  {t("UselessAnswer")}
                </Button>
              </Box>
            ) : (
              <Box
                sx={{ mt: 2, width: "100px" }}
                component={"img"}
                src={CorrectImage}
              />
            )}
          </Box>
        )}

        <ModalFeedback
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          isPositive={isPosFeedback}
        />
      </Box>
    </>
  );
}

export default FaqArticlesPage;
