import React, { useEffect, useState } from "react";
import { Typography, Box, Card, CardActionArea, Avatar } from "@mui/material";
import DefaultProfile from "../assets/images/pompkins_food_logo.png";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MenuShopCardModal from "./MenuShopCardModal";
import ModalImage from "./ModalImage";

function MenuShopCard({
  items,
  options,
  cartList,
  setCartList,
  onIncrement,
  onDecrement,
  openNoPayment,
  openNoService = true,
  openCloseModal = true,
}) {
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [imageModal, setImageModal] = useState(false);
  const [isAddDisabled, setIsAddDisabled] = useState(false);

  useEffect(() => {
    const totalQuantity = cartList?.reduce((total, cartItem) => {
      if (cartItem.menu.id === items.id) {
        return total + parseInt(cartItem.quantity, 10);
      }
      return total;
    }, 0);

    setCount(totalQuantity);
  }, [cartList, items.id, items.limited, items.existed]);

  useEffect(() => {
    setIsAddDisabled(items.limited && count >= items.existed);
  }, [count, isAddDisabled, items.existed, items.limited]);

  const handleOpenModal = (items) => {
    setOpen(true);
  };

  const handleCloseModal = (event, reason) => {
    if (reason && reason === "backdropClick") {
      setOpen(false);
    }
  };

  const isActionDisabled = () =>
    isAddDisabled || !openNoPayment || !openNoService || !openCloseModal;

  return (
    <>
      <ModalImage
        open={imageModal}
        onClose={() => setImageModal(false)}
        imageUrl={items.image === "" ? DefaultProfile : items.image}
      />
      <MenuShopCardModal
        items={items}
        options={options}
        open={open}
        setOpen={setOpen}
        handleCloseModal={handleCloseModal}
        cartList={cartList}
        setCartList={setCartList}
        isAddDisabled={isAddDisabled}
        setIsAddDisabled={setIsAddDisabled}
        count={count}
      />
      <Card
        sx={{
          position: "relative",
          boxShadow: 3,
          mb: 2,
          borderRadius: "15px",
        }}
      >
        <Box sx={{ p: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <CardActionArea
              sx={{
                ":hover": {
                  borderRadius: "15px",
                },
                cursor: isActionDisabled() ? "not-allowed" : "pointer",
              }}
              disableRipple
              onClick={isActionDisabled() ? null : () => handleOpenModal(items)}
            >
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box
                  alt={items.name}
                  component="img"
                  src={
                    items.image === "" || items.image === null
                      ? DefaultProfile
                      : items.image + "?"
                  }
                  sx={{
                    width: ["80px", "80px", "80px"],
                    height: ["80px", "80px", "80px"],
                    borderRadius: "20px",
                    objectFit: items.image === "" ? "contain" : "cover",
                    maxWidth: "none !important",
                  }}
                />

                <Box
                  sx={{
                    flex: 1,
                    ml: 2,
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "calc(100% - 80px)",
                  }}
                >
                  <Typography
                    noWrap
                    className="font-bold"
                    sx={{
                      typography: ["subtitle1", "subtitle1", "subtitle1"],
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {items.name}
                  </Typography>
                  <Typography
                    className="font-bold text-primary"
                    sx={{
                      typography: ["h6", "h6", "h6"],
                    }}
                    color="textSecondary"
                  >
                    {items.price
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                    {items.price === "" ? "" : " ฿"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                ></Box>
              </Box>
            </CardActionArea>
            {count !== 0 ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 1,
                  position: "absolute",
                  bottom: 30,
                  right: 10,
                }}
              >
                <Avatar
                  onClick={onDecrement}
                  sx={{
                    backgroundColor: "#F1F1F1",
                    fontSize: "30px",
                    width: "25px",
                    height: "25px",
                    cursor: "pointer",
                    color: "black",
                  }}
                >
                  -
                </Avatar>
                <Typography>{count}</Typography>

                <AddCircleIcon
                  onClick={isActionDisabled() ? null : onIncrement}
                  sx={{
                    color: isActionDisabled() ? "#E0E0E0" : "#FF961B",
                    fontSize: "30px",
                    cursor: isActionDisabled() ? "not-allowed" : "pointer",
                  }}
                />
              </Box>
            ) : (
              <AddCircleIcon
                onClick={
                  isActionDisabled() ? () => {} : () => handleOpenModal(items)
                }
                sx={{
                  color: isActionDisabled() ? "#E0E0E0" : "#FF961B",
                  fontSize: "30px",
                  position: "absolute",
                  bottom: 30,
                  right: 10,
                  cursor: isActionDisabled() ? "not-allowed" : "pointer",
                }}
              />
            )}
          </Box>
        </Box>
      </Card>
    </>
  );
}

export default MenuShopCard;
