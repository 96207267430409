import React from "react";
import { Box, Button } from "@mui/material";
import { Typography } from "@mui/material";
// import LandingStatic from "../../assets/GIF/main_pompkins1.gif";
// import NewBanner from "../../assets/images/NewBanner.png";
import { useTranslation } from "react-i18next";
import { useMobile } from "../../stores/useMobile";
import { useLocationStore } from "../../stores/useLocationStore";
import AutoCompleteMapField from "../../components/AutoCompleteMapField";
import AutoCompleteMerchantField from "../../components/AutoCompleteMerchantField";
import PompkinsDukduk from "../../assets/GIF/main_pompkins1.gif";
import Banner2 from "../../assets/images/splash2_edc.gif";
import Banner3 from "../../assets/images/splash2_tablet.gif";
import Banner4 from "../../assets/images/splash2_ggmap.gif";
import BannerSlider from "./BannerSlider";

function HeroBanner() {
  const { t } = useTranslation();
  const { isMobile } = useMobile();
  const { openModal } = useLocationStore();
  const bannerImg = [
    {
      img: PompkinsDukduk,
    },
    {
      img: Banner2,
    },
    {
      img: Banner3,
    },
    {
      img: Banner4,
    },
  ];

  return (
    <Box
      sx={{
        mt: "-64px",
        background:
          "linear-gradient(to bottom, #FFBF7A 3%, #FDF3E4 52%, #ffffff 82%)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: ["column", "column", "row"],
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",

          height: ["auto", "auto", "calc(100vh - 64px)"],
          pt: ["100px", "100px", "0"],
          // pb: "20px",
          px: 6,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: ["column", "column", "row"],
            width: ["100%", "100%", "45%", "40%"],
            zIndex: 102,
          }}
        >
          <Box
            data-aos={isMobile ? "fade-up" : "fade-right"}
            sx={{
              width: "100%",
              textAlign: ["center", "center", "start"],
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: ["30px", "40px", "50px", "60px"],
                fontWeight: 700,
                background:
                  "-webkit-linear-gradient(45deg, #F7B56E 0%, #FF903F 75%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              POMPKINS <br />
              NEW GEN POS
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                component={"span"}
                variant="h1"
                sx={{
                  fontSize: ["25px", "30px", "35px"],
                  fontWeight: 700,
                }}
              >
                {t("newWelcome.Concept")}
              </Typography>

              {/* <Typography
                sx={{
                  fontSize: ["16px", "18px", "20px"],
                  textAlign: "left",
                  mt: 2,
                }}
              >
                {t("nav.findRestaurants")}
              </Typography>
              <Box
                className="drop-shadow-lg"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",

                  backgroundColor: "#ffffff",
                  borderRadius: 4,
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: 4,
                    display: "flex",

                    gap: 2,
                    width: "100%",
                    flexDirection: ["column", "column", "row"],
                    justifyItems: ["center", "center", "space-between"],
                    alignItems: ["center", "center", "center"],
                  }}
                >
                  <AutoCompleteMapField onClickSearch={openModal} />
                  <AutoCompleteMerchantField onClickSearch={openModal} />
                </Box>
              </Box> */}
            </Box>
          </Box>
        </Box>

        <BannerSlider data={bannerImg} />
      </Box>
    </Box>
  );
}

export default HeroBanner;
