import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FaqData from "./FaqData";
import {
  Box,
  Breadcrumbs,
  Button,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import ListFaq from "./ListFaq";
import { Search } from "@mui/icons-material";
import FaqItem from "./FaqItem";
import SearchableFAQ from "./SearchFaq";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
function FaqCategoryPage() {
  const { t } = useTranslation();
  const { categories } = useParams();
  const [data, setData] = useState([]);
  const [isFetch, setIsFetch] = useState(false);
  const { faqData, applicationFaqData } = FaqData();
  let header = "";
  const navigator = useNavigate();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/pompkins/FAQ/categories/${categories}`,
      title: "FaqCategoryPage",
    });
  }, []);
  useEffect(() => {
    if (categories === "Application") {
      console.log("application");
      setData(applicationFaqData);
    } else if (categories === "General") {
      console.log("General");
      setData(faqData);
    }
  }, []);

  switch (categories) {
    case "Application":
      header = "คำถามเกี่ยวกับแอปพลิเคชั่น Pompkins";
      break;
    case "General":
      header = "คำถามทั่วไป";
      break;
    default:
      break;
  }

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      sx={{
        color: "#333333",
        cursor: "pointer",
      }}
      onClick={() => navigator(`/pompkins/FAQ`)}
    >
      Pompkins FAQ
    </Link>,
    <Link
      underline="hover"
      key="2"
      sx={{
        color: "#333333",
        cursor: "pointer",
      }}
      onClick={() => navigator(`/pompkins/FAQ/categories/${categories}`)}
    >
      {categories}
    </Link>,
  ];

  return (
    <>
      <Helmet>
        <title>{t("title.faqpage")}</title>
        <meta
          name="description"
          content="แอปเดียว ครบ ง่าย คุ้ม เปลี่ยนทุกความไม่ง่ายในชีวิต ให้ง่ายขึ้นด้วยแอปเดียว POS สั่งอาหาร ชำระเงิน จัดการพนักงาน ดูยอดขาย ดาวน์โหลด ฟรี ได้วันนี้"
        />
        <link
          rel="canonical"
          href={`https://pompkins.com${window.location.pathname}`}
        />
        <meta property="og:url" content="https://pompkins.com/faq" />
      </Helmet>
      <Box
        sx={{
          backgroundColor: "white",
          minHeight: "100vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "10vh",

            backgroundColor: "#FAFAFA",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              width: "100%",
              maxWidth: "1160px",
              gap: 3,
              p: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                width: ["100%", "100%", "50%"],
              }}
            >
              <SearchableFAQ />
            </Box>
          </Box>
        </Box>
        <Box sx={{ p: 4, maxWidth: "1160px", mx: "auto" }}>
          <Breadcrumbs separator="›">{breadcrumbs}</Breadcrumbs>
          <Typography
            sx={{ fontWeight: 700, fontSize: ["32px", "36px", "40px"], mt: 2 }}
          >
            {header}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              rowGap: "80px",
              columnGap: "70px",
              my: 6,
            }}
          >
            {data.map((item, index) => (
              <FaqItem key={index} item={item} category={categories} />
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default FaqCategoryPage;
