import React from "react";
import SuccessImg from "../../assets/images/emoji_pomkins_correct320.png";
import { Box } from "@mui/system";
import { Card, CardContent, Typography } from "@mui/material";
import {
  updateOrderPaymentStatus,
  updateOrderStatus,
} from "../../libs/orderAPI/orderAPI";

function QrPaidSuccessPage({ orders }) {
  for (const order of orders) {
    updateOrderStatus(order.order_uuid, "PURCHASED");
  }

  return (
    <Box
      sx={{
        maxWidth: 1440,
        margin: "auto",
        padding: "2% 5%",
        height: "100vh",
      }}
    >
      <Card sx={{ borderRadius: "15px" }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
              p: 2,
              textAlign: "center",
            }}
          >
            <img
              src={SuccessImg}
              alt="Success Img"
              title="Success Img"
              loading="lazy"
              width="100px"
              height="auto"
            />

            <Typography variant="h5">ชำระเงินสำเร็จ</Typography>
            <Typography variant="body1">
              ขอบคุณที่มาใช้บริการร้านของเรา
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default QrPaidSuccessPage;
