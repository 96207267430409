import React, { useState } from "react";
import { Box, Typography, Card } from "@mui/material";
import DefaultProfile from "../../utils/defaultImage";
import ModalImage from "../../components/ModalImage";

const CheckOutItem = ({ item }) => {
  const [imageModal, setImageModal] = useState(false);
  const calculateTotalPrice = (item) => {
    const total = parseInt(item.quantity, 10) * parseInt(item.price, 10);
    return parseFloat(total.toFixed(2)); // Returns number
  };
  return (
    <>
      <ModalImage
        open={imageModal}
        onClose={() => setImageModal(false)}
        imageUrl={
          item.image === "" || !item.image ? DefaultProfile : item.image
        }
      />
      <Card
        key={item.menu_id}
        sx={{ mt: 2, boxShadow: 0, borderRadius: 2, overflow: "hidden" }}
      >
        <Box sx={{ p: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setImageModal(!imageModal);
              }}
              className="hover:cursor-pointer"
            >
              <Box
                alt={item.menu_name}
                component="img"
                src={
                  item.image === "" || !item.image ? DefaultProfile : item.image
                }
                sx={{
                  width: ["80px", "80px", "80px"],
                  height: ["80px", "80px", "80px"],
                  borderRadius: "20px",
                  objectFit: "cover",
                  maxWidth: "none !important",
                }}
              />
            </div>
            <Box
              sx={{
                flex: 1,
                ml: 2,
                display: "flex",
                flexDirection: "column",
                maxWidth: "calc(100% - 80px)",
              }}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                {item.menu_name} x{item.quantity}
              </Typography>
              {item.option.map((option, index) => (
                <Typography
                  key={index} // Make sure to specify a unique key for each element when mapping
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    width: "80%",
                    height: "50%",
                    fontWeight: "bold",
                  }}
                >
                  {option}
                </Typography>
              ))}

              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: "14px", mt: "4px" }}
              >
                Note : {item.note}
              </Typography>
              <Typography
                className="font-bold text-primary"
                variant="body2"
                color="text.secondary"
                sx={{ mt: "4px" }}
              >
                {`฿ ${item.price
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default CheckOutItem;
