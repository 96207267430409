import * as React from "react";
import AccordionMUI from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { accordionData } from "./accordionData.js";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Box, Divider } from "@mui/material";
import "./HelpCenterPage.css";
import TawkTo from "../../components/TawkTo.js";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export default function HelpCenterPage() {
  const { t } = useTranslation();
  React.useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/helpcenter",
      title: "HelpCenterPage",
    });
  }, []);
  const [expanded, setExpanded] = React.useState(false);
  // const [expand, setExpand] = React.useState(false)
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  React.useEffect(() => {
    if (window.Tawk_API) {
      window.Tawk_API.showWidget();
    }
    return () => {
      if (window.Tawk_API) {
        window.Tawk_API.hideWidget();
      }
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {t("pompkins")} - {t("options.helpCenter")}
        </title>
        <meta
          name="description"
          content="แอปเดียว ครบ ง่าย คุ้ม เปลี่ยนทุกความไม่ง่ายในชีวิต ให้ง่ายขึ้นด้วยแอปเดียว POS สั่งอาหาร ชำระเงิน จัดการพนักงาน ดูยอดขาย ดาวน์โหลด ฟรี ได้วันนี้"
        />
        <link
          rel="canonical"
          href={`https://pompkins.com${window.location.pathname}`}
        />
        <meta property="og:url" content="https://pompkins.com/helpcenter" />
      </Helmet>
      <Box sx={{ width: "100vw", py: "48px" }}>
        <Box
          sx={{
            mx: [0, "90px", "150px", "300px"],
            px: "15px",
          }}
        >
          <TawkTo />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                backgroundColor: "white",
                px: "24px",
                py: "16px",
                borderRadius: "15px",
              }}
            >
              <Typography fontSize={28} fontWeight={700}>
                Help Center
              </Typography>
              {accordionData.map((item, index) => (
                <>
                  <AccordionMUI
                    key={index}
                    expanded={expanded === item.id}
                    onChange={handleChange(item.id)}
                    sx={{
                      boxShadow: 0,
                      position: "initial",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === item.id ? (
                          <RemoveCircleIcon className="text-primary" />
                        ) : (
                          <AddCircleIcon className="text-primary" />
                        )
                      }
                      sx={{ p: 0 }}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography
                        className={
                          expanded === item.id
                            ? "expanded-title"
                            : "collapsed-title"
                        }
                      >
                        <b>{item.sectionTitle}</b>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{item.sectionContent}</Typography>
                    </AccordionDetails>
                  </AccordionMUI>
                  <Divider
                    sx={{
                      margin: "9px 0 20px",
                      marginTop: ".25rem",
                      borderTop: "1px solid #c1c7c9",
                      borderBottom: "2px solid #e2e5ea!important",
                    }}
                  />
                </>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
