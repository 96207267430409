import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const OptoutThankYou = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box sx={{ bgcolor: "#f9931f", minHeight: "100vh", p: 4 }}>
      <Box
        sx={{
          bgcolor: "white",
          borderRadius: 2,
          p: 4,
          maxWidth: 600,
          mx: "auto",
          textAlign: "center",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{ color: "#f9931f", fontWeight: "bold" }}
        >
          {t("thank-you")}
        </Typography>
        <Typography variant="subtitle1" gutterBottom sx={{ color: "#4a4a4a" }}>
          {t("preferenceUpdate")}
        </Typography>
        <Box
          sx={{
            mt: 4,
            p: 3,
            bgcolor: "#f5f5f5",
            borderRadius: 1,
            border: "1px solid #e0e0e0",
          }}
        >
          <Typography variant="body1" sx={{ color: "#4a4a4a" }}>
            {t("appreciateTakingTime")}
          </Typography>
        </Box>
        <Button
          variant="contained"
          onClick={() => navigate("/")}
          sx={{
            mt: 4,
            bgcolor: "#f9931f",
            color: "white",
            fontWeight: "bold",
            px: 4,
            py: 1.5,
            "&:hover": {
              bgcolor: "#e88c1c",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
            },
            textTransform: "none",
            borderRadius: 2,
          }}
        >
          {t("returnHome")}
        </Button>
      </Box>
    </Box>
  );
};

export default OptoutThankYou;
