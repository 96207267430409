import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuthStore } from "../stores/useAuthStore";

function ProtectRoute() {
  const { isAuthenticated } = useAuthStore();

  return (
    <div>
      <main>
        <main>{isAuthenticated ? <Outlet /> : <Navigate to="/" />}</main>
      </main>
    </div>
  );
}

export default ProtectRoute;
