import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import SearchNotFound from "../../assets/image/SearchNotFound.png";

const NoMerchantFoundSection = ({ openModal }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        mb: 4,
      }}
    >
      <img
        src={SearchNotFound}
        alt="not found img"
        title="not found img"
        loading="lazy"
        width="100px"
        height="auto"
        style={{ width: "100px" }}
      />
      <Typography fontWeight={600} fontSize={"18px"}>
        {t("sorry")}
      </Typography>
      <Typography fontSize={"16px"}>{t("NoMerchant")}</Typography>
      <Button
        variant="outlined"
        sx={{ mt: "24px" }}
        onClick={() => openModal()}
      >
        {t("other.address")}
      </Button>
    </Box>
  );
};

export default NoMerchantFoundSection;
