import React from "react";
import QRCode from "qrcode.react";
import logoPK from "../assets/images/logo_512.png";

const QRCodeGenerator = ({ qrString }) => {
  return (
    <div>
      <QRCode
        imageSettings={{
          src: logoPK,
          style: {
            borderRadius: "100%",
          },
          x: undefined,
          y: undefined,
          height: 24,
          width: 24,
          excavate: true,
        }}
        value={qrString}
        style={{ height: "200px", width: "200px" }}
      />
    </div>
  );
};

export default QRCodeGenerator;
