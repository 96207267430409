import { axiosInstance } from "../axios";

export const createOrder = (orderData) => {
  return axiosInstance.post("/web/order", orderData);
};

export const paymentQr = (data) => {
  return axiosInstance.post("/web/payment/qr_code", data);
};

export const updateOrderPaymentStatus = (
  transactionUUID,
  status,
  paymentType
) => {
  const data = {
    payment_status: status,
    payment_type: paymentType,
    note: "online",
    // staff_name: "online",
  };
  return axiosInstance.patch(`/web/order/payments/${transactionUUID}`, data);
};

export const updateOrderStatus = (orderUUID, status) => {
  const data = {
    status: status,
  };
  return axiosInstance.patch(`/web/orders/${orderUUID}`, data);
};

export const getBill = (orderUUID) => {
  return axiosInstance.get(`/web/order/billing/${orderUUID}`);
};
