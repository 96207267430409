import React from "react";
import {
  Box,
  Typography,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";
import InstructionItem from "./InstructionItem";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { instructionData } from "./InstructionData";
import LoadingScreen from "../../components/LoadingScreen";
function Instruction({ state }) {
  const navigator = useNavigate();
  const [data, setData] = React.useState();
  const [isFetch, setIsFetch] = React.useState(false);
  React.useEffect(() => {
    setIsFetch(false);
    instructionData.map((item) => {
      if (item.navigate === state) {
        setData(item);
        setIsFetch(true);
      }
    });
    // switch (state) {
    //   case "merchantProfile":
    //     setData(instructionData[0]);
    //     setIsFetch(true);
    //     break;
    //   case "merchantDetail":
    //     setData(instructionData[1]);
    //     setIsFetch(true);
    //     break;
    //   case "openingClosingTime":
    //     setData(instructionData[2]);
    //     setIsFetch(true);
    //     break;
    //   default:
    //     return <Instruction />;
    // }
  }, [state]);

  const handleChange = () => {
    navigator(`?page=${data.navigate}`);
  };

  if (!isFetch) {
    return <LoadingScreen />;
  }
  return (
    <>
      <Box
        sx={{
          position: "sticky",
          width: "100%",
          display: "flex",

          justifyContent: "space-between",
          bgcolor: "#f9931f",
          zIndex: 10000,
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => navigator(-1)}
          sx={{ color: "white", backgroundColor: "#ef8a17" }}
        >
          <ArrowBackIosNewIcon />
        </Button>
        <Box
          sx={{
            color: "white",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {/* <Typography>หัวข้อ :</Typography>
          <Autocomplete
            id="grouped-demo"
            options={data.title}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                sx={{ color: "white" }}
                InputLabelProps={{
                  sx: { fontSize: "16px", top: "-12px", color: "white" },
                }}
                InputProps={{
                  sx: { height: "36px", mt: "0 !important" },
                  disableUnderline: true,
                }}
              />
            )}
          /> */}
        </Box>
      </Box>
      <Box sx={{ width: "100%", height: "100%", py: 4, px: 1, mt: 5 }}>
        <Box
          sx={{
            position: "relative",
            maxWidth: ["100%", "90%", "720px"],
            bgcolor: "white",
            height: "100%",
            margin: "0 auto",
            p: [2, 2, 3],
            "&::before": {
              // Note the use of single quotes to represent a pseudo-element
              content: '""',
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bgcolor: "white",
              height: "12px",
              borderRadius: "10px",
              zIndex: 2,
            },
            "&::after": {
              // Note the use of single quotes to represent a pseudo-element
              content: '""',
              position: "absolute",
              top: "-5px",
              right: 0,
              left: 0,
              background:
                "linear-gradient(109.78deg, #e4861b 4.1%, #f8a648 63%)",
              height: "12px",
              borderRadius: "10px",
              zIndex: 1,
            },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: "calc(1.325rem + 0.9vw) !important",
              fontWeight: 600,
              lineHeight: "normal",
              background:
                "-webkit-linear-gradient(45deg, #e4861b 10%, #f8a648 40%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              mb: 3,
            }}
          >
            {data.title}
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {data.data.map((itm) => (
              <InstructionItem data={itm} />
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Instruction;
