import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import TextHeader from "./TextHeader";
import ReactGA from "react-ga4";

function CookiesPolicy() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/cookies-policy",
      title: "CookiesPolicy",
    });
  }, []);
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "white",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box sx={{ p: 2, px: [4, 4, 14] }}>
        <TextHeader
          text={"นโยบายการใช้คุกกี้ (Cookies Policy) "}
          sx={{ mb: 2 }}
        />
        <Typography sx={{ textIndent: "3em", mb: 2 }}>
          <Typography component={"span"} fontWeight={700}>
            บริษัท พ้อมป์กินส์ จำกัด
          </Typography>
          หรือที่เรียกในนามว่า “บริษัทฯ“
          อาจมีการใช้งานคุกกี้หรือเทคโนโลยีอื่นใดที่มีลักษณะใกล้เคียงกัน
          (“คุกกี้”) บนเว็บไซต์และ/หรือแอปพลิเคชันของบริษัทฯ
          การใช้งานคุกกี้นี้จะช่วยให้คุณได้รับประสบการณ์ที่ดีจากการใช้บริการ
          และช่วยให้บริษัทฯ
          สามารถพัฒนาคุณภาพของบริการให้ตอบสนองต่อความต้องการของคุณมากยิ่งขึ้น
        </Typography>
        <Typography fontWeight={700} gutterBottom>
          1. คุกกี้คืออะไร
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          คุกกี้ คือ ไฟล์ข้อมูลขนาดเล็กที่ถูกดาวน์โหลดไปยังเว็บเบราว์เซอร์
          อุปกรณ์คอมพิวเตอร์ อุปกรณ์เชื่อมต่ออินเตอร์เน็ต เช่น สมาร์ทโฟน
          หรือแท็บเล็ตของคุณ เพื่อทำหน้าที่บันทึกข้อมูลและการตั้งค่าต่างๆ เช่น
          สถานะการเข้าใช้งานในปัจจุบันของคุณ ข้อมูลการตั้งค่าภาษา
          ข้อมูลเกี่ยวกับประวัติการเข้าใช้งานบนเว็บไซต์และ/หรือแอปพลิเคชันที่คุณชื่นชอบ
          เพื่อให้คุณสามารถใช้บริการที่มีการใช้งานคุกกี้หรือเทคโนโลยีอื่นใดที่มีลักษณะใกล้เคียงกันได้อย่างต่อเนื่อง
        </Typography>
        <Typography fontWeight={700} mt={2} gutterBottom>
          2. ประโยชน์ของคุกกี้
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          คุกกี้ช่วยให้บริษัทฯ
          ทราบถึงการเข้าชมส่วนต่างๆในเว็บไซต์และ/หรือแอปพลิเคชันของบริษัทฯ
          เพื่อที่บริษัทฯจะสามารถส่งมอบประสบการณ์การใช้บริการที่ดีขึ้นและตรงกับความต้องการของคุณและช่วยให้คุณสามารถใช้บริการเว็บไซต์และ/หรือแอปพลิเคชันของบริษัทฯได้อย่างต่อเนื่อง
          ทั้งนี้
          การบันทึกการตั้งค่าแรกของบริการด้วยคุกกี้จะช่วยให้คุณเข้าถึงบริการด้วยค่าที่ตั้งไว้ทุกครั้งที่ใช้งาน
          ยกเว้นในกรณีที่คุกกี้ถูกลบ
          จะทำให้การตั้งค่าทุกอย่างจะกลับไปเป็นค่าเริ่มต้น
        </Typography>
        <Typography fontWeight={700} mt={2} gutterBottom>
          3. ประเภทของคุกกี้ที่ใช้
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          <Typography component={"span"} fontWeight={700}>
            3.1 คุกกี้ประเภทจำเป็นถาวร (Strictly Necessary cookies){" "}
          </Typography>
          คุกกี้ประเภทนี้ช่วยให้คุณสามารถเข้าถึง ข้อมูลและใช้งานเว็บไซต์และ/หรือ
          แอปพลิเคชันของบริษัทฯได้อย่างปลอดภัย
          โดยคุกกี้ประเภทนี้จะถูกจัดเก็บและลบออก
          หลังจากที่คุณสิ้นสุดการใช้งานเว็บไซต์และ /หรือแอปพลิเคชัน เช่น
          SessionID, Load Balancing, User ID, Security
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          <Typography component={"span"} fontWeight={700}>
            3.2 คุกกี้ประเภทการวิเคราะห์ และวัดผลการทำงาน (Analytic/Performance
            cookie){" "}
          </Typography>
          คุกกีประเภทนี้ช่วยให้บริษัทฯสามารถ วิเคราะห์หรือวัดผลการทำงานเพื่อให้
          บริษัทฯเข้าใจถึงความสนใจของคุณ เช่นการประมวลผลจำนวนผู้เยี่ยมชม
          เว็บไซต์และ/หรือแอปพลิเคชันพฤติกรรม
          การเยี่ยมชมเว็บไซต์และ/หรือแอปพลิเคชัน
          จำนวนหน้าที่คุณเข้าใช้งานโดยบริษัทฯ
          จะใช้ข้อมูลดังกล่าวในการปรับปรุงการ
          ทำงานของเว็บไซต์และ/หรือแอปพลิเคชัน ให้ตอบสนองความต้องการและการ
          ใช้งานของคุณให้ดียิ่งขึ้น เช่น Google Analytics, Adobe
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          <Typography component={"span"} fontWeight={700}>
            3.3 คุกกี้ประเภทการโฆษณา (advertising cookies){" "}
          </Typography>
          คุกกี้ประเภทนี้จะถูกบันทึกบนอุปกรณ์
          ของคุณเพื่อเก็บข้อมูลการเข้าใช้งานและ
          ลิงก์ที่คุณติดตามหรือเยี่ยมชมเพื่อให้
          เข้าใจความต้องการของคุณและใช้ในการ ปรับปรุงและพัฒนาเว็บไซต์และ/หรือ
          แอปพลิเคชันรวมถึงแคมเปญโฆษณา ให้เหมาะสมกับความสนใจของคุณ เช่น Adobe
          Audience Manager, Adobe Target, Google Analytics, Facebook Pixels,
          Facebook API
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          <Typography component={"span"} fontWeight={700}>
            3.4 คุกกี้ประเภทการทำงาน (Functional cookies){" "}
          </Typography>
          คุกกี้ประเภทนี้จะช่วยให้บริษัทฯ"จดจำ"
          คุณระหว่างการเยี่ยมชมและตั้งค่าเว็บไซต์
          และ/หรือแอปพลิเคชันตามลักษณะการ
          ใช้งานให้สอดคล้องกับสิ่งที่คุณเลือกเพื่อ
          อำนวยความสะดวกเมื่อคุณกลับเข้ามา ใช้งานเว็บไซต์และ/หรือแอปพลิเคชันใน
          ครั้งถัดไปเช่นการจดจำชื่อผู้ใช้งาน ของคุณการปรับขนาดตัวอักษร
          ภาษาและส่วนอื่นๆบนหน้าเว็บไซต์และ /หรือแอปพลิเคชันอย่างไรก็ตามข้อมูล
          ที่นำไปใช้ประมวลผลจะไม่มีการระบุชื่อ หรือบ่งบอกความเป็นตัวตนของคุณได้
          และไม่มีการเก็บข้อมูลจำเพาะบุคคล เช่นชื่ออีเมลเป็นต้น
        </Typography>
        <Typography fontWeight={700} mt={2} gutterBottom>
          4.การตั้งค่าและการปฏิเสธคุกกี้
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          คุณสามารถเลือกปฏิเสธการทำงานของคุกกี้ได้
          โดยการตั้งค่าเบราว์เซอร์หรือการตั้งค่าความเป็นส่วนตัวของคุณ
          เพื่อระงับการเก็บรวบรวมข้อมูลโดยคุกกี้ในอนาคต
          หากคุณตั้งค่าเบราว์เซอร์หรือค่าความเป็นส่วนตัวของคุณด้วยการปฏิเสธการทำงานของคุกกี้ทั้งหมด
          คุณอาจไม่สามารถใช้งานฟังก์ชั่นบางอย่างหรือทั้งหมดบนเว็บไซต์และ/หรือแอปพลิเคชัน
          ของบริษัทฯ ได้อย่างมีประสิทธิภาพ ทั้งนี้
          คุณสามารถศึกษารายละเอียดเพิ่มเติมได้จาก​ ​www.allaboutcookies.org
        </Typography>
        <Typography fontWeight={700} mt={2} gutterBottom>
          5.การเปลี่ยนแปลงนโยบาย
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          นโยบายการใช้คุกกี้นี้มีการปรับปรุงล่าสุดเมื่อวันที่ 18/02/2024
          อาจมีการปรับปรุงแก้ไขหรือเปลี่ยนแปลงตามความเหมาะสมเป็นครั้งคราวเพื่อให้เป็นไปตามกฎระเบียบ
          บริษัทฯจึงขอแนะนำให้คุณตรวจสอบเพื่อให้แน่ใจว่าคุณเข้าใจการเปลี่ยนแปลงนโยบายดังกล่าว
          ทั้งนี้ คุณสามารถดูข้อมูลเพิ่มเติมเกี่ยวกับการเก็บรวบรวม ใช้
          และเปิดเผยข้อมูลส่วนบุคคลของคุณ สิทธิต่างๆ
          รวมถึงรายละเอียดเกี่ยวกับผู้ควบคุมข้อมูลส่วนบุคคลได้จากนโยบายการคุ้มครองข้อมูลส่วนบุคคลของบริษัทฯ
          บนเว็บไซต์{" "}
          <a
            className="text-blue-600"
            target="_blank"
            href="https://pompkins.com/privacy-policy"
            rel="noreferrer"
          >
            https://pompkins.com/privacy-policy
          </a>
        </Typography>
      </Box>
    </Box>
  );
}

export default CookiesPolicy;
