import { Box, Card, CardMedia, Typography } from "@mui/material";
import React from "react";
import DefaultProfile from "../../utils/defaultImage";

function OrderItem({ item }) {
  const calculateTotalPrice = (item) => {
    const total = parseFloat(item.quantity, 10) * parseFloat(item.price, 10);
    return parseFloat(total.toFixed(2)); // Returns number
  };
  return (
    <Card
      key={item.menu_id}
      sx={{
        padding: 2,
        mb: 2,
        borderRadius: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
        }}
      >
        <CardMedia
          component="img"
          image={item.image === "" || !item.image ? DefaultProfile : item.image}
          alt={item.menu_name}
          sx={{ width: "70px", height: "70px", borderRadius: 1 }}
        />
        <Box
          sx={{
            flex: 1,
            ml: 2,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="subtitle1"
              sx={{ width: "90%", height: "50%", fontWeight: "bold" }}
            >
              {item.menu_name}
            </Typography>
            <Typography variant="subtitle1">x{item.quantity}</Typography>
          </Box>
          {item.option &&
            item.option.map((option, index) => (
              <Typography
                key={index} // Make sure to specify a unique key for each element when mapping
                variant="body2"
                sx={{
                  fontSize: "12px",
                  width: "80%",
                  height: "50%",
                  fontWeight: "bold",
                }}
              >
                {option}
              </Typography>
            ))}
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontSize: "14px", mt: 1 }}
          >
            Note: {item.note}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            {`฿ ${calculateTotalPrice(item)
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

export default OrderItem;
