import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import TextHeader from "../../components/TextHeader";
import SubSectionText from "../../components/SubSectionText";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import ksherTos from "../../assets/images/ksher_tos.png";
import inetTos from "../../assets/images/inet_tos.png";
import kbankTos from "../../assets/images/kbank_tos.png";

function TermsMerchantPage() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/terms-and-conditions-merchant",
      title: "TermMerchantPage",
    });
  }, []);

  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "white",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box sx={{ p: 2, px: [4, 4, 14] }}>
        <TextHeader text={t("termMerchant.title")} sx={{ mb: 2 }} />
        <Typography sx={{ textIndent: "3em", mb: 2 }}>
          {t("termMerchant.desc")}
        </Typography>
        <Typography fontWeight={700} gutterBottom>
          {t("termMerchant.1")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          {t("termMerchant.1Paragraph")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          {t("termMerchant.2Paragraph")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          {t("termMerchant.3Paragraph")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          {t("termMerchant.4Paragraph")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          {t("termMerchant.5Paragraph")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          {t("termMerchant.6Paragraph")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          {t("termMerchant8Paragraph")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          {t("termMerchant.10Paragraph")}
        </Typography>
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("termMerchant.2")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          {t("termMerchant.2Desc")}
        </Typography>
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("termMerchant.3")}{" "}
        </Typography>
        <SubSectionText number={"3.1"} text={t("termMerchant.3_1")} />
        <SubSectionText number={"3.2"} text={t("termMerchant.3_2")} />
        <SubSectionText number={"3.3"} text={t("termMerchant.3_3")} />
        <SubSectionText
          number={"3.3.1"}
          text={t("termMerchant.3_3_1")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"3.3.2"}
          text={t("termMerchant.3_3_2")}
          sx={{ ml: 6 }}
        />
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {t("termMerchant.subDesc3_3")}
        </Typography>
        <SubSectionText number={"3.4"} text={t("termMerchant.3_4")} />
        <SubSectionText number={"3.5"} text={t("termMerchant.3_5")} />
        <SubSectionText number={"3.6"} text={t("termMerchant.3_6")} />
        <SubSectionText number={"3.7"} text={t("termMerchant.3_7")} />
        <SubSectionText number={"3.8"} text={t("termMerchant.3_8")} />
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {t("term.lineChat")}
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {t("term.callCenter")}
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {t("term.emailSupport")}
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {t("term.address")}
        </Typography>
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("termMerchant.4")}{" "}
        </Typography>
        <SubSectionText number={"4.1"} text={t("termMerchant.4_1")} />
        <SubSectionText number={"4.2"} text={t("termMerchant.4_2")} />
        <SubSectionText number={"4.3"} text={t("termMerchant.4_3")} />
        <SubSectionText number={"4.4"} text={t("termMerchant.4_4")} />
        <SubSectionText number={"4.5"} text={t("termMerchant.4_5")} />
        <SubSectionText number={"4.6"} text={t("termMerchant.4_6")} />
        <SubSectionText number={"4.7"} text={t("termMerchant.4_7")} />
        <SubSectionText number={"4.8"} text={t("termMerchant.4_8")} />
        <SubSectionText number={"4.9"} text={t("termMerchant.4_9")} />
        <SubSectionText number={"4.10"} text={t("termMerchant.4_10")} />
        <SubSectionText number={"4.11"} text={t("termMerchant.4_11")} />
        <SubSectionText number={"4.12"} text={t("termMerchant.4_12")} />
        <SubSectionText number={"4.13"} text={t("termMerchant.4_13")} />
        <SubSectionText number={"4.14"} text={t("termMerchant.4_14")} />
        <SubSectionText number={"4.15"} text={t("termMerchant.4_15")} />
        <SubSectionText
          number={"4.15.1"}
          text={t("termMerchant.4_8_1")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"4.15.2"}
          text={t("termMerchant.4_8_2")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"4.15.3"}
          text={t("termMerchant.4_8_3")}
          sx={{ ml: 6 }}
        />
        <SubSectionText number={"4.16"} text={t("termMerchant.4_15")} />
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("termMerchant.5")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          {t("termMerchant.5Desc")}
        </Typography>
        <SubSectionText number={"5.1"} text={t("termMerchant.5_1")} />
        <Typography sx={{ textIndent: "3em" }}>
          {t("termMerchant.5_1Desc")}
        </Typography>
        <SubSectionText number={"5.2"} text={t("termMerchant.5_2")} />
        <Typography sx={{ textIndent: "3em" }}>
          {t("termMerchant.5_2Desc")}
        </Typography>
        <SubSectionText number={"5.3"} text={t("termMerchant.5_3")} />
        <SubSectionText
          number={"5.3.1"}
          text={t("termMerchant.5_3_1")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"5.3.2"}
          text={t("termMerchant.5_3_2")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"5.3.3"}
          text={t("termMerchant.5_3_3")}
          sx={{ ml: 6 }}
        />
        <Typography sx={{ textIndent: "3em" }}>
          {t("termMerchant.5FooterDesc")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          {t("termMerchant.5FooterDesc2")}
        </Typography>
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("termMerchant.6New")}{" "}
        </Typography>
        <SubSectionText number={"6.1"} text={t("termMerchant.6_1New")} />
        <SubSectionText number={"6.2"} text={t("termMerchant.6_2New")} />
        <Typography sx={{ textIndent: "3em" }}>
          {t("termMerchant.6FooterDesc")}
        </Typography>
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("termMerchant.6")}{" "}
        </Typography>
        <SubSectionText number={"7.1"} text={t("termMerchant.6_1")} />
        <SubSectionText number={"7.2"} text={t("termMerchant.6_2")} />
        <SubSectionText number={"7.3"} text={t("termMerchant.6_3")} />
        <SubSectionText number={"7.4"} text={t("termMerchant.6_4")} />
        <SubSectionText number={"7.5"} text={t("termMerchant.6_5")} />
        <SubSectionText number={"7.6"} text={t("termMerchant.6_6")} />
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("termMerchant.7")}
        </Typography>
        <SubSectionText number={"8.1"} text={t("termMerchant.7_1")} />
        <SubSectionText number={"8.2"} text={t("termMerchant.7_2")} />
        <SubSectionText number={"8.3"} text={t("termMerchant.7_3")} />
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("termMerchant.8")}
          <span style={{ fontWeight: 400 }}>{t("termMerchant.8Desc")}</span>
        </Typography>
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("termMerchant.9")}{" "}
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {" "}
          {t("termMerchant.9Desc")}
        </Typography>
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("termMerchant.10")}{" "}
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {" "}
          {t("termMerchant.10Desc")}
        </Typography>
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("termMerchant.11")}{" "}
          <span style={{ fontWeight: 400 }}>{t("termMerchant.11Desc")}</span>
        </Typography>
        <SubSectionText number={"(1)"} text={t("termMerchant.11_1")} />
        <SubSectionText number={"(2)"} text={t("termMerchant.11_2")} />
        <SubSectionText number={"(3)"} text={t("termMerchant.11_3")} />
        <SubSectionText number={"(4)"} text={t("termMerchant.11_4")} />
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("termMerchant.12")}{" "}
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {t("termMerchant.13NewDesc")}
        </Typography>
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("termMerchant.13")}{" "}
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {t("termMerchant.13Desc")}{" "}
          <a
            target="_blank"
            rel="noreferrer"
            className="text-blue-500 underline "
            href="https://pompkins.com"
          >
            https://pompkins.com
          </a>
        </Typography>
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("termMerchant.14")}
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {t("termMerchant.14Desc")}
        </Typography>
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("termMerchant.16")}
          <span style={{ fontWeight: 400 }}>{t("termMerchant.16Desc")}</span>
        </Typography>
        <Typography fontWeight={700} mt={2} sx={{ textIndent: "3em" }}>
          {t("termMerchant.footer")}
        </Typography>

        <Typography fontWeight={700} mt={2} gutterBottom>
          ภาคผนวก 1
        </Typography>
        <Box>
          <Box>
            <Typography fontWeight={700} mt={2} gutterBottom>
              เงื่อนไขและค่าธรรมเนียมการให้บริการระบบรับชำระเงิน
            </Typography>
          </Box>
          <Box>
            <Box>
              <Typography fontWeight={700} mt={2} gutterBottom>
                เงินสด / พร้อมเพย์
              </Typography>
              <SubSectionText
                number={"-"}
                text='สามารถใช้ได้ทันที ยกเว้น "พร้อมเพย์" โดยท่านสามารถตั้งค่าเลข "พร้อมเพย์" ได้ที่หน้า "ข้อมูลร้านค้า'
              />
            </Box>
            <Box>
              <Typography fontWeight={700} mt={2} gutterBottom>
                KBANK
              </Typography>
              <Box>
                <SubSectionText
                  number={"-"}
                  text="ร้านค้าต้องทำการกรอกข้อมูลใน Application POMPKINS เพื่อให้ธนาคารกสิกรไทยติดต่อกลับ"
                />
                <SubSectionText
                  number={"-"}
                  text="ธนาคารกสิกรไทยจะติดต่อร้านค้า เพื่อให้นำส่งเอกสาร สำหรับการใช้งานระบบรับชำระเงิน"
                />
                <SubSectionText
                  number={"-"}
                  text="ระยะเวลาดำเนินการประมาณ 14-21 วันทำการ"
                />
                <SubSectionText
                  number={"-"}
                  text="วงเงินการรับชำระสูงสุดเป็นไปตามเงื่อนไขที่ธนาคารกสิกรไทยกำหนด"
                />
                <SubSectionText
                  number={"-"}
                  text="บัญชีรับเงินใช้บัญชีธนาคารกสิกรไทยเท่านั้น"
                />
                <SubSectionText
                  number={"-"}
                  text="Thai QR Payment เวลาที่เงินเข้าบัญชี 23.00 น. เป็นต้นไป ของทุกวัน"
                />
                <SubSectionText
                  number={"-"}
                  text="QR Credit Card เวลา Settlement 23.30 น. เวลาที่เงินเข้าบัญชี 04.00 น. เป็นต้นไป ของทุกวัน"
                />
                <SubSectionText
                  number={"-"}
                  text="EDC Credit Card กรณียอดขายไม่ถึง 50,000 บาท ค่าเช่า เครื่อง EDC 400 บาท /เครื่อง/เดือน"
                />
                <SubSectionText
                  number={"-"}
                  text="EDC Credit Card กรณีที่ใช้ SIM card ค่าบริการ 100 บาท /เดือน"
                />
              </Box>
              <Box>
                <img src={kbankTos} alt="kbankTos" objectFit="contain" />
              </Box>
            </Box>
            <Box>
              <Typography fontWeight={700} mt={2} gutterBottom>
                INET
              </Typography>
              <Box>
                <SubSectionText
                  number={"-"}
                  text='กรณีสมัคร "ในนามร้านค้า" ต้องจดทะเบียนพาณิชย์เท่านั้น'
                />
                <SubSectionText
                  number={"-"}
                  text="ร้านค้าต้องทำการติดตั้งและลงทะเบียนระบบพ้อมป์กินส์"
                />
                <SubSectionText
                  number={"-"}
                  text="กรอกข้อมูลในแบบฟอร์ม พร้อม Upload เอกสาร เพื่อสมัครบริการระบบรับชำระเงิน"
                />
                <SubSectionText
                  number={"-"}
                  text="INET พิจารณาเอกสาร ไม่เกิน 3 วันทำการ และอนุมัติให้ร้านค้าใช้ระบบรับชำระเงินได้ทันที"
                />
                <SubSectionText
                  number={"-"}
                  text="INET จะติดต่อร้านค้า เพื่อนำส่งเอกสารใบเสนอราคา พร้อมเอกสารสัญญาบริการระบบรับชำระเงิน"
                />
                <SubSectionText
                  number={"-"}
                  text="INET จะไม่โอนเงินให้ จนกว่าร้านค้าจะส่งเอกสารที่ลงนามแล้วให้ INET"
                />
                <SubSectionText
                  number={"-"}
                  text="บัญชีรับเงินสามารถใช้ได้ทุกบัญชีธนาคาร"
                />
                <SubSectionText
                  number={"-"}
                  text="ค่าธรรมเนียมและเงื่อนไขการให้บริการระบบรับชำระเงินเป็นไปตามที่ INET กำหนด"
                />
                <SubSectionText
                  number={"-"}
                  text="การรับเงินเข้าบัญชี คิด ค่าธรรมเนียม 10 บาท ต่อรายการ (ไม่รวมภาษีมูลค่าเพิ่ม)"
                />
                <SubSectionText
                  number={"-"}
                  text="เงินเข้าบัญชีธนาคาร 22.00 น. เป็นต้นไป ของทุกวัน"
                />
              </Box>
              <Box>
                <img
                  src={inetTos}
                  alt="tos"
                  loading="lazy"
                  objectFit="contain"
                />
              </Box>
            </Box>
            <Box>
              <Typography fontWeight={700} mt={2} gutterBottom>
                Ksher
              </Typography>
              <Box>
                <SubSectionText
                  number={"-"}
                  text="ร้านค้าต้องทําการติดตั้งและลงทะเบียนระบบพ้อมป์กินส์"
                />
                <SubSectionText
                  number={"-"}
                  text="กรอกข้อมูลในแบบฟอร์ม เพื่อสมัครบริการระบบรับชําระเงิน"
                />
                <SubSectionText
                  number={"-"}
                  text="Ksher จะทําการติดต่อร้านค้า เพื่อส่งเอกสารประกอบการสมัคร"
                />
                <SubSectionText
                  number={"-"}
                  text="ระยะเวลาดําเนินการประมาณ 7 วันทําการ"
                />
                <SubSectionText
                  number={"-"}
                  text="บัญชีรับเงินสามารถใช้ได้ทุกบัญชีธนาคาร"
                />
                <SubSectionText
                  number={"-"}
                  text="ค่าธรรมเนียมและเงื่อนไขการให้บริการระบบรับชําระเงินเป็นไปตามที่ Ksher กําหนด"
                />
                <SubSectionText
                  number={"-"}
                  text="เงินเข้าบัญชีธนาคาร 2 วันทําการ (T+2)"
                />
                <SubSectionText
                  number={"-"}
                  text="กรณี นิติบุคคล ใช้บัญชี ธนาคารกสิกรไทยและ ธนาคารกรุงเทพ เงินเข้าบัญชี 1 วันทําการ (T+1)"
                />
              </Box>
              <Box>
                <img
                  src={ksherTos}
                  alt="tos"
                  loading="lazy"
                  objectFit="contain"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default TermsMerchantPage;
