import { ThemeProvider, createTheme } from "@mui/material";
const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        sx: {
          "& label.Mui-focused": {
            color: "#757575",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#6F7E8C",
            },
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        style: {
          textTransform: "none",
          ":hover": {
            backgroundColor: "#fff8f0",
          },
        },
      },
      variants: [
        {
          props: { disabled: true },
          style: {
            backgroundColor: "#a8a8a8",
          },
        },
        {
          props: { variant: "contained" },
          style: {
            backgroundColor: "#f9931f",
            color: "#ffffff",
            "&:hover": {
              backgroundColor: "#f9931f",
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            backgroundColor: "transparent",
            color: "#f9931f",
            borderColor: "#f9931f",
            "&:hover": {
              backgroundColor: "#fff8f0",
              color: "#f9931f",
              borderColor: "#f9931f",
            },
          },
        },
      ],
    },
    MuiListItemButton: {
      defaultProps: {
        sx: {
          borderRadius: 3,
          ":hover": {
            backgroundColor: "#fff8f0",
          },
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        sx: {
          ":hover": {
            backgroundColor: "#fff8f0",
          },
        },
      },
    },
  },
  typography: {
    fontFamily: `'IBM Plex Sans Thai', sans-serif`,
  },
});

export default function ThemeStyle({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
