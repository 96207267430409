import * as yup from "yup";
import i18n from "i18next";

const emailValidate = yup
  .string()
  .email(() => i18n.t("validation.emailInvalid"))
  .required(() => i18n.t("validation.emailRequired"));

export const first_nameValidate = yup
  .string()
  .required(() => i18n.t("validation.first_nameRequired"));

const last_nameValidate = yup
  .string()
  .required(() => i18n.t("validation.last_nameRequired"));

const phoneValidate = yup
  .string()
  .required(() => i18n.t("validation.phone"))
  .matches(/^[0-9]+$/, i18n.t("validation.mobileOnlyNumber"));
const passwordValidate = yup
  .string()
  .required(() => i18n.t("validation.passwordRequired"))
  .min(8, () => i18n.t("validation.passwordMinLength"));

const confirmPasswordValidate = yup
  .string()
  .oneOf([yup.ref("password"), null], () =>
    i18n.t("validation.passwordsMustMatch")
  )
  .required(() => i18n.t("validation.confirmPasswordRequired"));

export const emailScheme = yup.object().shape({
  email: emailValidate,
});

export const registrationScheme = yup.object().shape({
  first_name: first_nameValidate,
  last_name: last_nameValidate,
  password: passwordValidate,
  confirmPassword: confirmPasswordValidate,
});

export const passwordScheme = yup.object().shape({
  password: yup.string().required(() => i18n.t("validation.passwordRequired")),
});

export const resetPasswordScheme = yup.object().shape({
  password: passwordValidate,
  confirmPassword: confirmPasswordValidate,
});

export const myProfileScheme = yup.object().shape({
  first_name: first_nameValidate,
  last_name: last_nameValidate,
  phone: phoneValidate,
});

export const newPasswordScheme = yup.object().shape({
  password: passwordValidate,
  newPassword: passwordValidate,
});

export const cartDetailScheme = yup.object().shape({
  name: first_nameValidate,
  phone: phoneValidate,
});

export const qrCartDetailScheme = yup.object().shape({
  name: yup.string(),
  phone: yup.string(),
});
