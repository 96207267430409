import { Box, Typography } from "@mui/material";
import React from "react";

function ListFaq({ title, onClick, sx }) {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        p: 1,
        color: "#666666",
        cursor: "pointer",
        ":hover": {
          color: "#f9931f",
          transition: " color 0.2s",
        },
        ...sx,
      }}
    >
      <Typography
        className="line-clamp-1"
        sx={{
          wordWrap: "break-word",
          wordBreak: "break-all",
          width: "90%",
        }}
        fontSize={"18px"}
        fontWeight={500}
      >
        {title}
      </Typography>
      <Typography fontSize={"18px"} fontWeight={500}>
        {">"}
      </Typography>
    </Box>
  );
}

export default ListFaq;
