import React from "react";
import { useTranslation } from "react-i18next";
import Warnning from "../assets/images/emoji_pomkins_exclamation320.png";
import { Box, Button, Dialog, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function ModalNoPayment({ open = false, setOpenNoPayment, phone }) {
  const { t } = useTranslation();
  const navigator = useNavigate();

  return (
    <Dialog open={open}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          m: 3,
          px: [5, 10, 15],
        }}
      >
        <img
          src={Warnning}
          alt="no-menu"
          title="no-menu"
          loading="lazy"
          width="100px"
          height="100px"
          style={{ width: "100px", height: "100px" }}
        />
        <Typography sx={{ fontWeight: 500, my: 2, textAlign: "center" }}>
          {t("merchant.noPayment")}
        </Typography>
        <Typography sx={{ fontWeight: 500, my: 2, textAlign: "center" }}>
          {t("merchant.contactMerchant")} <span>{phone}</span>
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: ["column", "row"],
            gap: 2,
          }}
        >
          <Button variant="outlined" onClick={() => navigator("/food")}>
            {t("merchant.backToHomePage")}
          </Button>
          <Button variant="outlined" onClick={() => setOpenNoPayment(false)}>
            {t("merchant.watchMenu")}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default ModalNoPayment;
