import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import warning from "../../assets/images/emo_pomkins_sweating320.png";
import ReactGA from "react-ga4";

function ExpirePage() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/table-merchant/expired",
      title: "ExpirePage",
    });
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
        textAlign: "center",
        px: 5,
      }}
    >
      <img
        src={warning}
        alt="warning"
        title="warning"
        loading="lazy"
        style={{ width: "200px" }}
        width={"200px"}
        height={"auto"}
      />
      <Typography variant="h4" sx={{ mt: 2, fontWeight: 700 }}>
        ขออภัย!
      </Typography>
      <Typography variant="body1">
        โต๊ะของคุณไม่สามารถใช้งานได้ในขณะนี้ หากต้องการสั่งอาหารเพิ่มเติม
        กรุณาติดต่อร้านค้า
      </Typography>
    </Box>
  );
}

export default ExpirePage;
