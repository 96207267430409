import React from "react";
import { Box, Typography, Button } from "@mui/material";
import EmailIcon from "../../assets/icon/email.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function SendVerifyStep() {
  const { t } = useTranslation();
  const location = useLocation();
  const email = location.state;
  const navigator = useNavigate();
  const onSubmit = () => {
    navigator("/login/new?step=registration", { state: email });
  };
  if (!location.state) {
    return <Navigate to="/" />;
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "95vh",
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          py: 6,
          mt: 10,
          px: [2, 4, 6],
          borderRadius: 3,
          boxShadow: "-1px -1px 10px 1px rgba(0, 0, 0, 0.2)",
          display: "flex",
          width: ["90%", "70%", "400px"],
          flexDirection: "column",
          gap: 1,
        }}
      >
        <img
          src={EmailIcon}
          alt="logo"
          title="logo"
          loading="lazy"
          width="50px"
          height="50px"
          style={{
            width: "50px",
            height: "50px",
          }}
        />
        <Typography variant="h5" className="mt-3 font-bold">
          {t("verifyEmail.title")}
        </Typography>
        <Typography className="text-[#a8a8a8] mb-3">
          {t("verifyEmail.instruction")}
        </Typography>

        <Button
          variant="contained"
          fullWidth
          sx={{
            mt: 5,
            borderRadius: 6,
          }}
          onClick={() => onSubmit()}
        >
          {t("btn.verifyEmail")}
        </Button>
      </Box>
    </Box>
  );
}

export default SendVerifyStep;
