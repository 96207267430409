import { useTranslation } from "react-i18next";
import kbankLogo from "../../assets/images/kbankLogoNew.png";
import orderWithGoogleLogo from "../../assets/images/order_with_google_lockup_horizontal.png";
import ksherLogo from "../../assets/images/payment/ksher.png";
import itmxLogo from "../../assets/images/payment/itmx.png";
import inetLogo from "../../assets/images/payment/inetlogo.png";
import { Box, Typography } from "@mui/material";
import HighlightSlider from "./HighlightSlider";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const PartnerCard = ({ logoSrc, description }) => (
  <Box
    data-aos="fade-up"
    sx={{
      my: 2,
      p: 3,
      border: "2px solid #F19A3C",
      borderRadius: "20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      height: ["300px", "380px", "420px"],
      transition: "transform 0.3s ease-in-out",
      "&:hover": {
        transform: "scale(1.05)",
      },
    }}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Box
        component="img"
        src={logoSrc}
        alt="Partner Logo"
        title="Partner Logo"
        loading="lazy"
        sx={{
          height: { xs: "70px", sm: "80px", md: "100px" },
          width: { xs: "180px", sm: "200px", md: "250px" },
          maxWidth: "100%",
          objectFit: "contain",
        }}
      />
      <Typography
        sx={{
          mt: 2,
          fontSize: {
            xs: "14px",
            sm: "16px",
            md: "18px",
          },
        }}
      >
        {description}
      </Typography>
    </Box>
  </Box>
);

const Partner = () => {
  const { t } = useTranslation();

  const partners = [
    {
      logoSrc: kbankLogo,
      description: t("welcome.kbankPartnerDesc"),
    },
    {
      logoSrc: orderWithGoogleLogo,
      description: t("welcome.orderWithGooglePartnerDesc"),
    },
    {
      logoSrc: ksherLogo,
      description: t("welcome.ksherPartnerDesc"),
    },
    {
      logoSrc: inetLogo,
      description: t("welcome.inetPartnerDesc"),
    },
  ];

  const options = {
    items: 4,
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      960: { items: 3 },
    },
    nav: false,
    dots: true,
    loop: true,
    margin: 20,
  };
  return (
    <Box
      sx={{
        textAlign: "center",
        py: 4,
        px: { xs: 2, sm: 4 },
      }}
    >
      <Typography
        data-aos="fade-up"
        variant="h2"
        fontWeight={700}
        mb={4}
        sx={{ fontSize: { xs: "26px", sm: "32px", md: "40px" } }}
      >
        {t("welcome.partnerTitle")}
      </Typography>

      <Box
        sx={{
          maxWidth: "1200px",
          margin: "0 auto",
          position: "relative",
        }}
      >
        <OwlCarousel className="owl-theme" {...options}>
          {partners.map((partner, index) => (
            <div key={index} className="item">
              <PartnerCard
                logoSrc={partner.logoSrc}
                description={partner.description}
              />
            </div>
          ))}
        </OwlCarousel>
      </Box>
    </Box>
  );
};

export default Partner;
