export const accordionData = [
  {
    id: 1,
    sectionTitle: "วิธีรายงานปัญหาผ่านศูนย์ช่วยเหลือ",
    sectionContent:
      "วิธีรายงานปัญหาผ่านศูนย์ช่วยเหลือของเราคือการติดต่อทีมสนับสนุนผ่านหมายเลขโทรศัพท์หรืออีเมลของเราที่ระบุไว้ในเว็บไซต์ของเรา ทีมสนับสนุนของเราจะดำเนินการตรวจสอบและแก้ไขปัญหาของคุณให้เร็วที่สุด",
  },
  {
    id: 2,
    sectionTitle: "วิธีสำรองข้อมูลและเรียกคืนข้อมูลโทรศัพท์",
    sectionContent:
      "วิธีสำรองข้อมูลและเรียกคืนข้อมูลโทรศัพท์ของเราคือการใช้บริการสำรองข้อมูลออนไลน์ที่เราให้บริการ คุณสามารถเรียกคืนข้อมูลของคุณในกรณีที่ข้อมูลสูญหายหรือถูกลบโดยไม่ตั้งใจได้โดยติดต่อทีมสนับสนุนของเรา",
  },
  {
    id: 3,
    sectionTitle: "ปัญหาการถูกระงับสัญญาณ",
    sectionContent:
      "ปัญหาการถูกระงับสัญญาณอาจเกิดขึ้นเนื่องจากปัจจัยหลายประการ เช่น ปัญหาด้านระบบเครือข่าย หรือปัญหาทางเทคนิคในอุปกรณ์ของคุณ เราขอแนะนำให้ติดต่อทีมสนับสนุนของเราเพื่อขอความช่วยเหลือในการแก้ไขปัญหานี้",
  },
  {
    id: 4,
    sectionTitle: "ส่งเอกสารเพิ่มเติม หรือเปลี่ยนแปลงข้อมูลในระบบ",
    sectionContent:
      "คุณสามารถส่งเอกสารเพิ่มเติมหรือขอเปลี่ยนแปลงข้อมูลในระบบของเราได้โดยติดต่อทีมสนับสนุนของเรา กรุณาระบุข้อมูลที่ต้องการเปลี่ยนแปลงและเอกสารที่เกี่ยวข้องเพื่อให้เราสามารถดำเนินการตามคำขอของคุณ",
  },
  {
    id: 5,
    sectionTitle: "ทำไมยอดเงินถูกหักจากกระเป๋าเงินสด",
    sectionContent:
      "ยอดเงินถูกหักจากกระเป๋าเงินสดของคุณอาจมีสาเหตุมาจากการทำธุรกรรมหรือค่าธรรมเนียมทางการเงิน กรุณาตรวจสอบประวัติธุรกรรมของคุณและติดต่อทีมสนับสนุนของเราหากคุณต้องการความช่วยเหลือในการสอบถามเพิ่มเติม",
  },
  {
    id: 6,
    sectionTitle: "การรับงานหลายจุดส่ง",
    sectionContent:
      "คุณสามารถรับงานหลายจุดส่งได้โดยติดต่อทีมสนับสนุนของเรา เรายินดีให้คำแนะนำและแจ้งข้อมูลเพิ่มเติมเกี่ยวกับกระบวนการรับงานของเรา หากคุณมีคำถามเพิ่มเติมหรือต้องการข้อมูลเพิ่มเติม",
  },
];
