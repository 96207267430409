import React from "react";
import PrompkinsIcon from "../assets/images/logo_large_size.png";
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
// import bg from "../assets/image/bg-graphic.png";
import FacebookImage from "../assets/images/facebook-logo-facebook-logo-transparent-facebook-icon-transparent-free-free-png.webp";
import LinkedInImage from "../assets/images/linkedInLogo.png";
import InstragramImage from "../assets/images/instragramLogo.png";
import LineImage from "../assets/images/linelogo.png";
import XImage from "../assets/images/X logo.png";
import { ArrowForwardIos, Mail } from "@mui/icons-material";
import TopSlideImg from "../assets/image/top-wave.png";
import { useMobile } from "../stores/useMobile";
import { useNavigate } from "react-router-dom";
import { useAccess } from "../stores/useAccess";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { isMobile } = useMobile();
  const navigator = useNavigate();
  const { setCanAccess } = useAccess();

  const { t } = useTranslation();
  return (
    <>
      {/* {!isMobile && <img src={TopSlideImg} alt="Wave img" />} */}
      <Box
        width="100%"
        sx={{
          backgroundColor: "#fff6eb",
          backgroundSize: "cover",
          backgroundPosition: "fixed",
          pt: 5,
          px: [2, 2, 10],
        }}
      >
        <Grid container gap={4}>
          <Grid item xs={12} sm={6} md={3}>
            <Link href="/">
              <img
                src={PrompkinsIcon}
                alt="Pompkins-footer"
                title="Pompkins-footer"
                loading="lazy"
                className="-ml-2"
                style={{ width: "250px", height: "auto" }}
                width={"250px"}
                height={"40px"}
              />
            </Link>

            <Typography
              paragraph
              fontSize="18px"
              fontWeight={700}
              sx={{ ml: [0, 0, 0], mt: 2, mb: "0 !important" }}
            >
              {t("privacy.companyName")}
            </Typography>
            <Typography
              paragraph
              fontSize="14px"
              fontWeight={500}
              sx={{ ml: 0 }}
            >
              {t("footer.locationPompkins")}
            </Typography>
            <Box
              sx={{
                mt: "1rem",
                ml: "-5px",
              }}
            >
              <a
                href="https://web.facebook.com/PompkinsPOS/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook profile"
              >
                <Tooltip title="Facebook">
                  <IconButton sx={{ p: 0 }}>
                    <Avatar
                      src={FacebookImage}
                      sx={{ width: "35px", height: "auto" }}
                      alt="facebook pompkins icon"
                      imgProps={{
                        title: "facebook pompkins icon",
                        loading: "lazy",
                        width: "35px",
                        height: "35px",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </a>
              <a
                href="https://line.me/R/ti/p/@pompkins"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Line"
              >
                <Tooltip title="Line">
                  <IconButton sx={{ p: 0 }}>
                    <Avatar
                      src={LineImage}
                      sx={{ width: "35px", height: "auto" }}
                      alt="Line pompkins icon"
                      imgProps={{
                        title: "Line pompkins icon",
                        loading: "lazy",
                        width: "35px",
                        height: "35px",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </a>
              {/* <Tooltip title="Twitter">
                <IconButton sx={{ p: 0 }}>
                  <Avatar
                    src={XImage}
                    sx={{ width: "35px", height: "auto" }}
                    alt="x-twitter pompkins icon"
                    imgProps={{
                      title: "twitter pompkins icon",
                      loading: "lazy",
                    }}
                  />
                </IconButton>
              </Tooltip> */}
              <a
                href="https://www.instagram.com/pompkins.pos/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram profile"
              >
                <Tooltip title="Instagram">
                  <IconButton sx={{ p: 0 }}>
                    <Avatar
                      src={InstragramImage}
                      sx={{ width: "40px", height: "auto" }}
                      alt="instagram pompkins icon"
                      imgProps={{
                        title: "instagram pompkins icon",
                        loading: "lazy",
                        width: "35px",
                        height: "35px",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </a>
              <Tooltip title="LinkedIn">
                <IconButton sx={{ p: 0 }}>
                  <Avatar
                    src={LinkedInImage}
                    sx={{ width: "35px", height: "auto" }}
                    alt="linkedin pompkins icon"
                    imgProps={{
                      title: "linkedin pompkins icon",
                      loading: "lazy",
                      width: "35px",
                      height: "35px",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography fontSize="22px" fontWeight={700} sx={{ mt: "15px" }}>
              About
            </Typography>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: 1,
              }}
            >
              <Link
                component="button"
                onClick={() => {
                  setCanAccess(true);
                  navigator("/");
                }}
                underline="none"
                textAlign={"left"}
                className="text-[#7d8a8b] hover:text-primary"
              >
                <Typography>About Us</Typography>
              </Link>

              <Link
                onClick={() => {
                  navigator("/privacy-policy");
                }}
                component="button"
                underline="none"
                textAlign={"left"}
                className="text-[#7d8a8b] hover:text-primary"
              >
                <Typography>Privacy and Policy</Typography>
              </Link>
              <Link
                onClick={() => {
                  navigator("/terms-and-conditions");
                }}
                component="button"
                underline="none"
                textAlign={"left"}
                className="text-[#7d8a8b] hover:text-primary"
              >
                <Typography>Terms and conditions</Typography>
              </Link>
              <Link
                onClick={() => {
                  navigator("/terms-and-conditions-merchant");
                }}
                component="button"
                underline="none"
                textAlign={"left"}
                className="text-[#7d8a8b] hover:text-primary"
              >
                <Typography>Terms and conditions (For Merchant)</Typography>
              </Link>
              <Link
                onClick={() => {
                  navigator("/cookies-policy");
                }}
                component="button"
                underline="none"
                textAlign={"left"}
                className="text-[#7d8a8b] hover:text-primary"
              >
                <Typography>Cookies Policy</Typography>
              </Link>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            md={5}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: 1,
            }}
          >
            <Typography
              fontSize={["22px", "24px", "28px"]}
              fontWeight={700}
              sx={{ mt: "15px" }}
            >
              {t("footer.lookingForSection")}
            </Typography>
            <Box
              sx={{ display: "flex", flexDirection: ["row", "row", "column"] }}
            >
              <Typography fontSize={["16px", "18px", "20px"]} fontWeight={500}>
                {t("pompkins")}
              </Typography>
              <Typography
                fontSize={["16px", "18px", "20px"]}
                ml={["4px", "4px", 0]}
                fontWeight={500}
              >
                {t("footer.lookingForSub")}
              </Typography>
            </Box>

            <Button
              variant="contained"
              size="large"
              onClick={() => navigator("/contactus")}
              startIcon={<Mail />}
              endIcon={<ArrowForwardIos sx={{ ml: 2 }} />}
              sx={{
                borderRadius: "2rem",
                fontSize: "17px",
                fontWeight: 500,
                px: 4,
                mt: 2,
                boxShadow: "0 3px 12px 0 rgba(255,161,9,.589)",
                ":hover": {
                  boxShadow: 0,
                },
              }}
            >
              Contact Us
            </Button>
          </Grid>
        </Grid>
        <Box
          sx={{
            mt: ["2rem", "2rem", "1rem"],
            pb: [2, 2, 10],
          }}
        >
          <Typography fontSize="16px" fontWeight={500}>
            Copyright © POMPKINS 2024 All Rights Reserved.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
