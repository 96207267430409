export const preferencesData = [
  {
    name: "otherAppsBetter",
    label: "ฉันเจอแอพพลิเคชั่นอื่นที่ใช้งานได้ดีกว่า",
  },
  {
    name: "systemHasProblems",
    label: "ระบบมีปัญหา, ใช้งานไม่ได้",
  },
  {
    name: "doesNotMeetRequirements",
    label: "ระบบไม่ตอบโจทย์ในการใช้งานของฉัน",
  },
  {
    name: "hasBetterOffer",
    label: "ฉันได้รับข้อเสนออื่นที่ดีกว่า",
  },
  {
    name: "uxUINotGood",
    label: "ฉันไม่ชอบรูปลักษณ์ของแอพ และระบบใช้งานยาก",
  },
  {
    name: "alreadyHasBoughtOtherApp",
    label: "ฉันซื้อผลิตภัณฑ์อื่นเป็นที่เรียบร้อยแล้ว",
  },
  {
    name: "tooPricey",
    label: "ค่าบริการแพงเกินไป",
  },
  {
    name: "doesNotNeedAnymore",
    label: "ฉันไม่ต้องการใช้งานอีก",
  },
];
