import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { Box, useMediaQuery } from "@mui/material";
import restaurantIcon from "../../assets/images/pin.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import AutoCompleteMerchantField from "../../components/AutoCompleteMerchantField";
import { useLocationStore } from "../../stores/useLocationStore";
import { useTheme } from "@emotion/react";
import SearchFetchSection from "./SearchFetchSection";
import zIndex from "@mui/material/styles/zIndex";

function MerchantMap({ merchantData }) {
  const navigate = useNavigate();
  const autoCompleteRef = useRef(null);
  const { currentLocation, openModal } = useLocationStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchFetch, setSearchFetch] = useState(false);
  const [searchData, setSearchData] = useState();
  const [focused, setFocused] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [merchantSearchValue, setMerchantSearchValue] = useState([]);
  const [nearMerchant, setNearMerchant] = useState([]);

  const searchResultsRef = useRef(null);

  const [center, setCenter] = useState({
    lat: Number(currentLocation?.latitude),
    lng: Number(currentLocation?.longitude),
  });

  useEffect(() => {
    setCenter({
      lat: Number(currentLocation?.latitude),
      lng: Number(currentLocation?.longitude),
    });
  }, [currentLocation]);

  useEffect(() => {
    setNearMerchant(
      merchantData?.filter((item) => {
        const distanceInKm = parseFloat(item.distance.replace(" Km", ""));
        return distanceInKm < 2;
      })
    );
  }, [merchantData]);

  const handleAutocompleteChange = (_, newValue) => {
    setSearchParams({ search: newValue });
  };

  const handleSearchChange = (e) => {
    const searchQuery = e.target.value;
    setSearchParams({ search: searchQuery });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setSearchParams({ search: e.target.value });
      handleSearch(e.target.value);
      scrollToResults();
      if (autoCompleteRef.current) {
        autoCompleteRef.current.blur();
      }
    }
  };

  const scrollToResults = () => {
    if (searchResultsRef.current) {
      const yOffset = isMobile ? -130 : -100;
      const y =
        searchResultsRef.current.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const handleSearch = (searchQuery) => {
    setSearchParams({ search: searchQuery });
    setSearchFetch(false);
    setFocused(false);
    if (searchQuery === "") {
      setSearchFetch(false);
    } else {
      const filteredData = merchantData.filter((item) =>
        item.title?.toLowerCase().includes(searchQuery?.toLowerCase())
      );
      setSearchData(filteredData);
      setSearchFetch(true);
      scrollToResults();
    }
    if (autoCompleteRef.current) {
      autoCompleteRef.current.blur();
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        mb: 1,
        mt: 4,
        overflow: "hidden",
        position: "relative",
        borderRadius: 8,
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
        height: "auto",
      }}
    >
      <Box
        sx={{
          transition: "transform 0.3s ease-in-out",
          height: searchFetch
            ? !isMobile
              ? "75vh"
              : "80vh"
            : !isMobile
            ? "50vh"
            : "80vh",
        }}
      >
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "100%",
            outline: "none",
          }}
          center={center}
          zoom={15}
          options={{
            disableDefaultUI: true,
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            clickableIcons: false,
            styles: [
              {
                featureType: "poi",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "transit",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
            ],
          }}
          onClick={() => {
            navigate("/explore");
          }}
        >
          {currentLocation && (
            <Marker
              position={{
                lat: Number(currentLocation?.latitude),
                lng: Number(currentLocation?.longitude),
              }}
            />
          )}
          {nearMerchant &&
            nearMerchant.length > 0 &&
            nearMerchant.map((item, index) => {
              const lat = Number(item.location?.latitude);
              const lng = Number(item.location?.longitude);

              // Check if lat and lng are valid numbers
              if (!isNaN(lat) && !isNaN(lng)) {
                return (
                  <Marker
                    key={index}
                    position={{ lat, lng }}
                    icon={{
                      url: restaurantIcon,
                      scaledSize: new window.google.maps.Size(32, 42),
                    }}
                  />
                );
              } else {
                console.error(
                  `Invalid latitude or longitude for item at index ${index}`
                );
                return null;
              }
            })}
          <AutoCompleteMerchantField
            ref={autoCompleteRef}
            onClickSearch={openModal}
            handleAutocompleteChange={handleAutocompleteChange}
            handleSearchChange={handleSearchChange}
            handleKeyDown={handleKeyDown}
            handleSearch={handleSearch}
            scrollToResults={scrollToResults}
            merchantSearchValue={merchantSearchValue}
            setMerchantSearchValue={setMerchantSearchValue}
            setSearchParams={setSearchParams}
            searchResultsRef={searchResultsRef}
            setSearchFetch={setSearchFetch}
          />
        </GoogleMap>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
            transform: searchFetch ? "translateY(0)" : "translateY(100%)",
            opacity: searchFetch ? 1 : 0,
            maxHeight: searchFetch ? "60vh" : 0,
            overflow: "auto",
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
          }}
        >
          {searchFetch && (
            <SearchFetchSection
              searchData={merchantSearchValue}
              searchResultsRef={searchResultsRef}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default MerchantMap;
