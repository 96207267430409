import React, { useState, useRef, useEffect, useMemo } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import ReactOwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import blurImg from "../../assets/images/blur.png";
import { useMobile } from "../../stores/useMobile";
import { useTranslation } from "react-i18next";

function HighlightSlider({ data }) {
  const { isMobile } = useMobile();
  const { t } = useTranslation();
  const sliderRef = useRef(null);

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.next();
    }
  };

  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.prev();
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
      }}
    >
      {!isMobile && (
        <Box
          component={"img"}
          src={blurImg}
          alt="blur img"
          title="blur img"
          loading="lazy"
          sx={{
            width: "323px",
            height: "323px",
            position: "absolute",
            right: "-130px",
            mt: "-70px",
          }}
        />
      )}

      <Box
        sx={{
          backgroundColor: "white",
          textAlign: "center",
          m: "0 auto",
          py: 10,
        }}
      >
        <Typography
          data-aos="fade-up"
          fontSize={["30px", "38px", "55px"]}
          fontWeight={700}
          sx={{
            color: "#F19A3C",
          }}
        >
          {t("welcome.highlightTitle")}
        </Typography>
        <Typography data-aos="fade-up" textAlign={"center"}>
          {t("welcome.highlightSub1")}
          <p>{t("welcome.highlightSub2")}</p>
          {t("welcome.highlightSub3")}
        </Typography>

        <Box data-aos="fade-up" sx={{ position: "relative", mt: 6 }}>
          <Box
            sx={{
              position: "relative",
              width: ["90%", "90%", "70%", "60%"],
              margin: "0 auto",
            }}
          >
            <ReactOwlCarousel
              ref={sliderRef}
              items={3}
              responsive={{
                0: {
                  items: 2,
                },
                768: {
                  items: 2,
                  margin: 30,
                },
                900: {
                  items: 3,
                },
                1024: {
                  items: 3,
                  margin: 20,
                },
              }}
              className="owl-theme"
              nav={false}
              dots={true}
              loop={true}
            >
              {data.map((items, i) => (
                <Box key={i} sx={{ py: 2, px: 2 }}>
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "33px",
                      padding: "16px",
                      height: ["219px", "204px", "296px"],
                      boxShadow: "0px 0px 1rem rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        justifyContent: "start",
                        height: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: ["14px", "16px", "18px", "22px"],
                          fontWeight: "700",
                          textAlign: "start",
                        }}
                      >
                        {items.subtitle}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: ["14px", "16px", "18px", "22px"],
                          fontWeight: "700",
                          textAlign: "start",
                        }}
                      >
                        {items.title}
                      </Typography>
                      <Typography
                        sx={{
                          textAlign: "start",
                          fontSize: ["10px", "12px", "14px", "16px"],
                        }}
                      >
                        {items.label}
                      </Typography>
                      <Box
                        component={"img"}
                        src={items.img}
                        alt={items.title}
                        title={items.title}
                        loading="lazy"
                        sx={{
                          height: ["100px", "125px", "150px"],
                          width: [
                            "100px !important",
                            "125px !important",
                            "150px !important",
                          ],
                          margin: "auto auto 0 auto",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
            </ReactOwlCarousel>
          </Box>
          <IconButton
            onClick={goToPrevSlide}
            sx={{
              background: "#FF961B",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
              position: "absolute",
              top: "50%",
              left: ["5%", "10%", "10%", "15%"],
              transform: "translateY(-50%)",
              width: ["30px", "40px", "40px"],
              height: ["30px", "40px", "40px"],
              zIndex: 1,
              color: "white",
              "&:hover": {
                background: "#FF961B",
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                color: "white",
                cursor: "pointer",
              },
              "&:disabled": {
                background: "gray",
                cursor: "not-allowed",
              },
            }}
          >
            <ArrowBackIos />
          </IconButton>

          <IconButton
            onClick={goToNextSlide}
            sx={{
              background: "#FF961B",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
              position: "absolute",
              top: "50%",
              right: ["5%", "10%", "10%", "15%"],
              transform: "translateY(-50%)",
              zIndex: 1,
              width: ["30px", "40px", "40px"],
              height: ["30px", "40px", "40px"],
              color: "white",
              "&:hover": {
                background: "#FF961B",
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                color: "white",
                cursor: "pointer",
              },
              "&:disabled": {
                background: "gray",
                cursor: "not-allowed",
              },
            }}
          >
            <ArrowForwardIos />
          </IconButton>
        </Box>
      </Box>
      {!isMobile && (
        <Box
          component={"img"}
          src={blurImg}
          alt="blur img"
          title="blur img"
          loading="lazy"
          sx={{
            width: "323px",
            height: "323px",
            position: "absolute",
            transform: "rotate(152deg)",
            left: "-150px",
            mt: "-300px",
          }}
        />
      )}
    </Box>
  );
}

export default HighlightSlider;
