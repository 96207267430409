import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import OTPInput from "../../components/OtpInput";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { registerV2, sendOtp, verifyOtp } from "../../libs/authAPI/registerAPI";
import useModalLoading from "../../stores/useModalLoading";
import useGenericModal from "../../stores/useGenericModal";
import { signInWithCustomToken } from "firebase/auth";
import { auth } from "../../utils/firebaseAuth";

function OtpStep() {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(false);
  const { openModal, closeModal } = useModalLoading();
  const { openFailedModal } = useGenericModal();
  const location = useLocation();
  const [otpNumber, setOtpNumber] = useState();
  const navigator = useNavigate();
  const data = location.state;

  const [otpData, setOtpData] = useState({
    phone: data.phone,
    session: data.session,
    challenge: data.challenge,
  });
  const handleOTPChange = (otpValue) => {
    setOtpNumber(otpValue);
    if (otpValue.length === 6) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const body = {
      ...otpData,
      code: otpNumber,
    };
    const registerData = {
      ...data.registerData,
    };
    const formData = new FormData();
    formData.append("challenge", body.challenge);
    formData.append("phone", body.phone);
    formData.append("session", body.session);
    formData.append("code", body.code);
    const formDataResend = new FormData();
    formDataResend.append("phone", body.phone);
    openModal();
    verifyOtp(body)
      .then(() => {
        registerV2(registerData)
          .then((res) => {
            signInWithCustomToken(auth, res.data.customToken).then(
              async (response) => {}
            );

            navigator("/login/new?step=success", { replace: true });

            closeModal();
          })
          .catch(() => {
            closeModal();
            openFailedModal(
              "เกิดข้อผิดพลาดในการลงทะเบียน กรุณาลองใหม่อีกครั้ง"
            );
          });
      })
      .catch(() => {
        sendOtp(formDataResend).then(({ data }) => {
          const newSessionData = {
            challenge: data.ChallengeName,
            phone: otpData.phone,
            session: data.Session,
          };
          setOtpData(newSessionData);
        });
        openFailedModal("รหัส OTP ไม่ถูกต้อง");
      })
      .finally(() => {
        closeModal();
      });
  };

  const handleResendCode = () => {
    const formDataResend = new FormData();
    formDataResend.append("phone", otpData.phone);
    sendOtp(formDataResend).then((res) => {
      const data = {
        challenge: res.data.ChallengeName,
        phone: otpData.phone,
        session: res.data.Session,
      };
      setOtpData(data);
    });
  };

  if (!location.state) {
    return <Navigate to="/" />;
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "95vh",
      }}
    >
      <Box
        component="form"
        onSubmit={onSubmit}
        sx={{
          backgroundColor: "white",
          mt: 10,
          py: 6,
          px: [2, 4, 6],
          borderRadius: 3,
          boxShadow: "-1px -1px 10px 1px rgba(0, 0, 0, 0.2)",
          display: "flex",
          width: ["90%", "70%", "400px"],
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Typography variant="h5" className="mt-3 font-bold">
          {t("otpStep.title")}
        </Typography>
        <Typography className="text-[#a8a8a8] mb-3">
          {t("otpStep.instructions")}
        </Typography>
        <OTPInput onOTPChange={handleOTPChange} />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={!isValid}
          sx={{
            mt: 2,
            borderRadius: 6,
          }}
        >
          {t("btn.confirmButton")}
        </Button>
        <Button
          variant="outlined"
          fullWidth
          onClick={handleResendCode}
          sx={{
            mt: 2,
            borderRadius: 6,
          }}
        >
          {t("btn.resendButton")}
        </Button>
      </Box>
    </Box>
  );
}

export default OtpStep;
