import React from "react";
import { Typography, Box } from "@mui/material";

function SubSectionText({ text, number, sx }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1,
        ml: 2,
        mb: "4px",
        ...sx,
      }}
    >
      <Typography>{number}</Typography>
      <Typography>{text}</Typography>
    </Box>
  );
}

export default SubSectionText;
