import axios from "axios";

const STORAGE_KEY = "conversionTracking";
const ATTRIBUTION_WINDOW = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

export const persistConversionData = (rwg_token, bmerchant_id) => {
  const data = {
    rwg_token,
    bmerchant_id,
    expiration: Date.now() + ATTRIBUTION_WINDOW,
  };
  localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
};

export const getPersistedConversionData = () => {
  const storedData = localStorage.getItem(STORAGE_KEY);
  if (!storedData) return null;
  const data = JSON.parse(storedData);
  if (Date.now() > data.expiration) {
    localStorage.removeItem(STORAGE_KEY);
    return null;
  }
  return data;
};

const compareMerchantId = (currentMerchantId) => {
  const persistedData = getPersistedConversionData();
  return persistedData && persistedData.bmerchant_id === currentMerchantId;
};

const sendConversionRequest = async (rwg_token, partnerId, merchantChanged) => {
  // const endpoint = "https://www.google.com/maps/conversion/debug/collect";
  // Production
  const endpoint = "https://www.google.com/maps/conversion/collect";
  try {
    const response = await axios.post(
      endpoint,
      {
        rwg_token: rwg_token,
        conversion_partner_id: partnerId,
        merchant_changed: merchantChanged,
      },
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );

    localStorage.removeItem(STORAGE_KEY);
    if (response.status !== 200) {
      console.error("Conversion tracking failed", response.data);
    } else {
      console.log("Conversion tracking successful", response.data);
    }
  } catch (error) {
    console.error("Conversion tracking failed", error);
  }
};

export const sendConversionEvent = async (currentMerchantId) => {
  const persistedData = getPersistedConversionData();
  if (!persistedData) {
    console.log("Not send conversion tracking");
    return;
  }

  const { rwg_token } = persistedData;
  const partnerId = 20003103;

  const merchantChanged = compareMerchantId(currentMerchantId) ? 2 : 1;
  await sendConversionRequest(rwg_token, partnerId, merchantChanged);
};
