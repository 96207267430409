import { Box, Skeleton, Typography } from "@mui/material";
import React from "react";

function BrandCards({ items }) {
  return (
    <div>
      <Box
        key={items.title}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          maxWidth: 242,

          alignItems: "center",
        }}
      >
        {items ? (
          <img
            src={items.image}
            alt={items.title}
            title={items.title}
            loading="lazy"
            width="96px"
            height="96px"
            style={{
              width: 96,
              height: 96,
              objectFit: "cover",
              borderRadius: 13,
              backgroundColor: "white",
              border: "1px solid lightgray",
            }}
          />
        ) : (
          <Skeleton variant="rounded" width={110} height={110} />
        )}

        <Typography sx={{ px: 2, fontWeight: 500 }}>{items.title}</Typography>
      </Box>
    </div>
  );
}

export default BrandCards;
