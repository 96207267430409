import { Box, Dialog, Fade, Typography, IconButton } from "@mui/material";
import React from "react";
import SuccessImg from "../assets/images/emoji_pomkins_correct320.png";
import FailedImg from "../assets/images/emoji_pomkins_x320.png";
import useGenericModal from "../stores/useGenericModal";
import { Close } from "@mui/icons-material";

GenericModal.defaultProps = {
  open: false,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});
function GenericModal({ open, type, description }) {
  const { closeModal } = useGenericModal();
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          width: "90%",
          maxWidth: "400px",
          minHeight: "30vh",
          backgroundColor: "#fff",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        },
      }}
    >
      <IconButton
        color="inherit"
        onClick={closeModal}
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <Close sx={{ color: "#FF961B" }} />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={type === "Failed" ? FailedImg : SuccessImg}
          alt="type"
          title="type"
          loading="lazy"
          width="80px"
          height="80px"
          style={{
            width: "80px",
            height: "80px",
            marginBottom: "16px",
          }}
        />
        <Typography variant="h5" sx={{ mb: 2, color: "black" }}>
          {type === "Failed" ? "เกิดข้อผิดพลาด" : "สำเร็จ"}
        </Typography>
        <Typography sx={{ mb: 2, color: "black", textAlign: "center" }}>
          {description}
        </Typography>
      </Box>
    </Dialog>
  );
}

export function GenericModalComponent({ children }) {
  const { isOpen, description, type } = useGenericModal();
  return (
    <div>
      {children}
      <GenericModal open={isOpen} description={description} type={type} />
    </div>
  );
}
