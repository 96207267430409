import { rest } from "msw";
import mockData from "./mockData.json"; // Import your mock data JSON here

const mockUser = {
  first_name: "Test",
  last_name: "user",
  email: "test@gmail.com",
  phone: "123-456-7890",
};

export const loginMock = rest.post("/login", async (req, res, ctx) => {
  const requestBody = await req.json();

  const matchedUser = mockData.users.find(
    (user) => user.email === requestBody.email
  );

  return res(
    ctx.status(200),
    ctx.delay(1000),
    ctx.json({
      user: matchedUser,
      accessToken:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6Ikl0J21lZSIsImlhdCI6MTUxNjIzOTAyMn0.z4FMzoWZhy02de9sPP9BGBk6eS-PvX6HiBdqXlVZltU",
    }),
    ctx.cookie(
      "refresh-token",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6ImFkZmppZGYiLCJpYXQiOjE1MTYyMzkwMjJ9.vjtfd3HTmp_EfEkU4ZV5lHCqIPZLCHF6aU_OT6Wh38c"
    )
  );
});

export const refreshMock = rest.get("/refresh", (req, res, ctx) => {
  if (req.cookies["refresh-token"] === "") {
    return res(ctx.status(401));
  }
  return res(
    ctx.json({
      user: mockUser,
      accessToken: "new Token",
    }),
    ctx.cookie("refresh-token", "new refresh")
  );
});

export const logoutMock = rest.get("/logout", (_, res, ctx) => {
  return res(ctx.cookie("refresh-token", ""));
});

export const checkEmailMock = rest.post(
  "/checkEmail",
  async (req, res, ctx) => {
    const requestBody = await req.json();
    const isMatching = mockData.users.some((item) => {
      return item.email === requestBody;
    });
    console.log(isMatching);
    if (!isMatching) {
      return res(ctx.status(404));
    }

    return res(ctx.status(200));
  }
);
