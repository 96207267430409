import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Skeleton,
  Grid,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowForwardIos } from "@mui/icons-material";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";

import { cuisineData } from "../../utils/cardData";
import CardSlider from "../CardSlider";
import FoodCard from "../FoodCards";
import MerchantMap from "./MerchantMap";

const MerchantDataSection = ({
  merchantData,
  newMerchant,
  isFetch,
  currentLocation,
  isFetchNextPage,
  fetchNextPage,
  currentPage,
  totalPages,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      {/* <Typography sx={{ mt: 2, mb: 1, fontSize: ["24px", "32px", "34px"] }}>
        {t("home.recommendCuisines")}
      </Typography>
      <CardSlider data={cuisineData} /> */}

      {newMerchant.length > 0 && (
        <>
          <Typography
            sx={{
              mt: 4,
              mb: 1,
              fontSize: ["24px", "32px", "34px"],
            }}
          >
            {t("newMerchantNearBy")}
          </Typography>
          {isFetch ? (
            <CardSlider
              data={{
                name: "foods",
                slides: newMerchant,
              }}
            />
          ) : (
            <Skeleton
              height="294px"
              sx={{
                marginTop: "-4rem",
              }}
            />
          )}
        </>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h1"
          sx={{ mt: 4, mb: 1, fontSize: ["24px", "32px", "34px"] }}
        >
          {t("home.mustTry")}
        </Typography>
        <Typography
          variant="h1"
          sx={{
            mt: 4,
            mb: 1,
            fontSize: ["14px", "16px", "18px"],
            cursor: "pointer",
            fontWeight: 600,
          }}
          onClick={() => navigate("/food/all")}
        >
          {t("home.seeAll")}
        </Typography>
      </Box>

      {isFetch ? (
        <CardSlider
          data={{
            name: "foods",
            slides: merchantData,
          }}
        />
      ) : (
        <Skeleton
          height="294px"
          sx={{
            marginTop: "-4rem",
          }}
        />
      )}
      <Box sx={{ width: "100%" }}>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            height: "auto",
            alignItems: "center",
            mt: 4,
            mb: 1,
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: "24px", sm: "32px", md: "34px" },
            }}
          >
            {t("home.explore")}
          </Typography>
          <IconButton
            onClick={() => navigate("/explore")}
            sx={{
              background: "#FF961B",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
              zIndex: 10,
              color: "white",
              "&:hover": {
                background: "#FF961B",
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                color: "white",
                cursor: "pointer",
              },
              "&:disabled": {
                background: "gray",
                cursor: "not-allowed",
              },
            }}
          >
            <ArrowForwardIos />
          </IconButton>
        </Box> */}
        <MerchantMap merchantData={merchantData} />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ mt: 6, mb: 1, fontSize: ["24px", "32px", "34px"] }}>
          {t("home.nearby")}
        </Typography>
      </Box>
      {isFetch ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid
            container
            spacing={5}
            sx={{
              m: 0,
              width: [
                "100%",
                "calc(100% + 40px)",
                "calc(100% + 40px)",
                "calc(100% + 40px)",
              ],
            }}
          >
            {merchantData &&
              merchantData.map((items, i) => (
                <FoodCard
                  items={items}
                  key={i}
                  sx={{
                    maxWidth: [
                      "calc(100%)",
                      "calc(50% - 24px)",
                      "calc(33.3333% - 24px)",
                      "calc(25% - 24px)",
                    ],
                    width: [
                      "calc(100%)",
                      "calc(50% - 24px)",
                      "calc(33.3333% - 24px)",
                      "calc(25% - 24px)",
                    ],
                    mr: [0, "18px", "18px"],
                    mb: "24px",
                  }}
                />
              ))}
          </Grid>
          {!isFetchNextPage ? (
            <Button
              variant="outlined"
              onClick={fetchNextPage}
              sx={{
                mt: 2,
                borderRadius: "20px",
                width: "30%",
                alignSelf: "center",
                display: currentPage === totalPages ? "none" : "",
              }}
            >
              See More
            </Button>
          ) : (
            <Grid
              container
              spacing={100}
              sx={{
                m: 0,
                width: [
                  "100%",
                  "calc(100% + 40px)",
                  "calc(100% + 40px)",
                  "calc(100% + 40px)",
                ],
              }}
            >
              <Skeleton
                height="294px"
                width="233px"
                sx={{
                  marginTop: "-4rem",
                }}
              />
              <Skeleton
                height="294px"
                width="233px"
                sx={{
                  marginTop: "-4rem",
                }}
              />
            </Grid>
          )}
        </Box>
      ) : (
        <Skeleton
          height="294px"
          sx={{
            marginTop: "-4rem",
          }}
        />
      )}
    </>
  );
};

export default MerchantDataSection;
