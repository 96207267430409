import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getOrderByTableNumber } from "../../libs/customerAPI/getOrders";
import LoadingScreen from "../../components/LoadingScreen";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import QRCodeGenerator from "../../components/QrGen";
import CheckBillItem from "./CheckBillItem";
import { paymentQr } from "../../libs/orderAPI/orderAPI";
import QrPaidSuccessPage from "./QrPaidSuccessPage";
import ReactGA from "react-ga4";

function QrPaymentPage() {
  const {
    order_uuid,
    merchant_uuid,
    tableNumber,
    token,
    bmerchant_id,
    transaction_uuid,
    merchant_id,
  } = useParams();
  const [orders, setOrders] = useState([]);
  const [qrString, setQrString] = useState("");
  const [paymentStatus, setPaymentStatus] = useState(false);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/table-merchant/${token}/${merchant_id}/${bmerchant_id}/${tableNumber}/${transaction_uuid}/payment/${merchant_uuid}`,
      title: "QrPaymentPage",
    });
  }, []);
  useEffect(() => {
    const fetchData = () => {
      getOrderByTableNumber(merchant_uuid, tableNumber).then((res) => {
        setOrders(res.data);
      });
    };

    const intervalId = setInterval(fetchData, 10000); // Fetch data every 10 seconds

    // Initial fetch
    fetchData();

    return () => {
      clearInterval(intervalId); // Cleanup the interval on component unmount
    };
  }, [order_uuid]);

  // useEffect(() => {
  //   if (orders && orders.payment_status === "PAID") {
  //     navigator(`/order-status/${order_uuid}`);
  //   }
  // }, [navigator, order_uuid, orders]);

  const calculateSubtotal = (menu) => {
    let subtotal = 0;

    if (menu.length > 0) {
      for (const item of menu) {
        subtotal += item.quantity * item.price;
      }
    } else {
      subtotal += menu.quantity * menu.price;
    }

    return subtotal;
  };

  // Function to calculate total for all orders
  const calculateTotal = () => {
    let total = 0;
    for (const order of orders) {
      if (
        order.order_status !== "CANCELLED" &&
        order.order_status !== "SUCCESS"
      ) {
        total += calculateSubtotal(order.order_detail);
      }
    }
    return total;
  };

  const vatValues = calculateTotal().toFixed(2) * 0.07; // ตัวอย่างการคำนวณ VAT (7% ของ subtotal)
  // Styles for the TextFields
  let totalAmount = 0;

  const filteredOrders = orders.filter(
    (order) =>
      order.order_status !== "CANCELLED" && order.order_status !== "SUCCESS"
  );

  // Iterate through each order and add its total_amount to the totalAmount
  for (const order of orders) {
    if (
      order.order_status !== "CANCELLED" &&
      order.order_status !== "SUCCESS"
    ) {
      totalAmount += order.total_amount;
    }
  }

  useEffect(() => {
    if (totalAmount !== 0) {
      const data = {
        bmerchant_uuid: merchant_uuid,
        price: `${totalAmount}`,
      };
      paymentQr(data).then((res) => {
        setQrString(res.data.qr_string);
      });
    }
  }, [merchant_uuid, totalAmount]);

  if (paymentStatus) {
    return <QrPaidSuccessPage orders={filteredOrders} />;
  }

  if (qrString === "") {
    return <LoadingScreen />;
  }
  return (
    <Box
      sx={{
        maxWidth: 1440,
        margin: "auto",
        padding: "2% 5%",
      }}
    >
      <Card sx={{ borderRadius: "15px" }}>
        <CardContent>
          <Box sx={{ textAlign: "center" }}>
            <Typography fontSize={"20px"} fontWeight={700}>
              โต๊ะที่
              <span> {tableNumber}</span>
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Typography fontWeight={500}>
              {" "}
              รายการทั้งหมด {filteredOrders.length ?? 0} รายการ
            </Typography>
          </Box>
          {orders.length !== 0 &&
            orders.map(
              (order, orderIndex) =>
                order.order_status !== "CANCELLED" &&
                order.order_status !== "SUCCESS" && (
                  <div key={orderIndex}>
                    {order.order_detail.map((menuItem, menuIndex) => (
                      <CheckBillItem
                        name={order.customer_name}
                        key={menuIndex}
                        item={menuItem}
                        index={menuIndex}
                        status={order.cooking_status}
                      />
                    ))}
                  </div>
                )
            )}

          {/* ส่วนสรุป */}
          <Typography variant="h6" sx={{ mt: 2 }}>
            สรุป
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", my: 1 }}>
            <Typography>ราคารวม</Typography>
            <Typography>{`฿ ${calculateTotal().toFixed(2)}`}</Typography>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between", my: 1 }}>
            <Typography>VAT (7%)</Typography>
            <Typography>{`฿ ${vatValues.toFixed(2)}`}</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              my: 1,
              fontWeight: "bold",
            }}
          >
            <Typography>รวมทั้งหมด</Typography>
            <Typography>{`฿ ${totalAmount.toFixed(2)}`}</Typography>
          </Box>
          {/* gen qr code */}
          <Typography variant="h6" sx={{ mt: 2 }}>
            ชำระเงินผ่าน Qr
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
            <QRCodeGenerator qrString={qrString} />
          </Box>
          <Button onClick={() => setPaymentStatus(true)}>
            {" "}
            Bypass Payment
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
}

export default QrPaymentPage;
