import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocationStore } from "../../stores/useLocationStore";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { getV2Merchant } from "../../libs/merchantAPI/merchantAPI";
import FoodCard from "../../components/FoodCards";
import FilterPanel from "./FilterPanel";
import TuneIcon from "@mui/icons-material/Tune";

function SeeAllPage() {
  const [merchantData, setMerchantData] = useState(null);
  const [isFetch, setIsFetch] = useState(false);
  const [newMerchant, setNewMerchant] = useState([]);
  const { t } = useTranslation();
  const [title, setTitle] = useState([]);
  const { currentLocation, openModal, currentAddress } = useLocationStore();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [sortBy, setSortBy] = useState("relevance");
  const [filters, setFilters] = useState({
    isOpen: true,
    rating: 0,
    shopType: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [allMerchantData, setAllMerchantData] = useState(merchantData);
  const [isFetchNextPage, setIsFetchNextPage] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [isFilterPanelModalOpen, setIsFilterPanelModalOpen] = useState(false);

  const openFilterPanelModal = () => {
    setIsFilterPanelModalOpen(true);
  };

  const closeFilterPanelModal = () => {
    setIsFilterPanelModalOpen(false);
  };

  const handleClearAll = () => {
    setSortBy("relevance");
    setFilters({
      isOpen: true,
      rating: 0,
      shopType: [],
    });
  };

  const handleRatingChange = (rating) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      rating: prevFilters.rating === rating ? null : rating,
    }));
  };

  const handleShopTypeChange = (type) => {
    setFilters((prevFilters) => {
      const newShopType = prevFilters.shopType.includes(type)
        ? prevFilters.shopType.filter((t) => t !== type)
        : [...prevFilters.shopType, type];
      return { ...prevFilters, shopType: newShopType };
    });
  };

  const filteredData = useMemo(() => {
    return (
      merchantData &&
      merchantData
        .filter((merchant) => {
          if (filters.isOpen && !merchant.isOpen) return false;
          if (
            filters.rating.length > 0 &&
            !filters.rating.includes(merchant.rating)
          )
            return false;
          if (
            filters.shopType.length > 0 &&
            !filters.shopType.includes(merchant.shopType)
          )
            return false;
          return true;
        })
        .sort((a, b) => {
          if (sortBy === "time") return a.time - b.time;
          if (sortBy === "distance")
            return parseFloat(a.distance) - parseFloat(b.distance);
          return 0;
        })
    );
  }, [merchantData, filters, sortBy]);

  const fetchMerchants = useCallback(
    (page = 1) => {
      const { shopType, isOpen, rating } = filters;
      const location = currentLocation;
      if (!location) return;

      if (location) {
        location.latitude = String(location.lat);
        location.longitude = String(location.lng);
      }

      console.log("fetchMerchant", {
        shopType,
        isOpen,
        rating,
        sortBy,
        location,
      });
      getV2Merchant(
        shopType.length !== 0 ? shopType : null,
        isOpen,
        rating,
        sortBy === "distance" ? true : false,
        sortBy === "time" ? true : false,
        location,
        10,
        page
      )
        .then((merchantRes) => {
          const { data } = merchantRes;
          setTotalPages(data.total_page);
          mapDataV2(data.merchant).then((sortedData) => {
            if (page === 1) {
              setAllMerchantData(sortedData);
            } else {
              setAllMerchantData((prevData) => [...prevData, ...sortedData]);
            }
            const sortIsOpen = sortedNewMerchant(sortedData).sort(sortByIsOpen);
            setNewMerchant(sortIsOpen);
            const allItemNames = data.merchant.map(
              (item) => item.merchant_name
            );
            setTitle(allItemNames);
          });
        })
        .catch((err) => {
          console.error("Error", err);
        })
        .finally(() => {
          setIsFetch(true);
        });
    },
    [currentLocation, filters, sortBy]
  );

  const fetchMerchant = useCallback(() => {
    fetchMerchants(1);
  }, [fetchMerchants]);

  const fetchNextPage = async () => {
    setIsFetchNextPage(true);
    fetchMerchants(currentPage + 1);
    setCurrentPage((prevPage) => prevPage + 1);
    setIsFetchNextPage(false);
  };

  const sortedNewMerchant = (merchantData) => {
    const currentDate = new Date();

    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

    const filteredData = merchantData.filter((item) => {
      const createAtDate = new Date(item.createAt);

      return createAtDate >= sevenDaysAgo && createAtDate <= currentDate;
    });

    filteredData.sort((a, b) => {
      const dateA = new Date(a.createAt);
      const dateB = new Date(b.createAt);
      return dateB - dateA;
    });
    const data = filteredData.filter((item) => {
      return parseFloat(item.distance) <= 10;
    });
    return data;
  };

  const mapDataV2 = async (merchantData) => {
    const newData = await Promise.all(
      merchantData.map(async (item) => {
        if (!item.location.latitude || !item.location.longitude) return null;
        if (!currentLocation) return null;
        return item;
      })
    );

    const filteredData = newData.filter((item) => item !== null);
    const mappedData = filteredData.map((item) => ({
      id: item.id,
      bmerchantId: item.bmerchant.id,
      bmerchantUUID: item.bmerchant.uuid,
      location: item.location,
      title: item.merchant_name,
      lat: item.location.latitude,
      lng: item.location.longitude,
      img: item.logo,
      isOpen: item.is_open,
      rating: item.rating ?? 0,
      distance: `${item.distance.toFixed(2) ?? "2"} Km`,
      time: item.time.toFixed(2) ?? 20,
      features: [],
      shopType:
        item.shop_type === ""
          ? "ร้านอาหารทั่วไป"
          : item.shop_type || "ร้านอาหารทั่วไป",
      // createAt: item.created_at,
    }));

    const sortByDistance = (a, b) => {
      const distanceA = parseFloat(a.distance.replace(" Km", ""));
      const distanceB = parseFloat(b.distance.replace(" Km", ""));
      return distanceA - distanceB;
    };

    const sortedDistanceData = mappedData.sort(sortByDistance);
    const sortedData = sortedDistanceData.sort(sortByIsOpen);
    return sortedData;
  };

  const sortByIsOpen = (a, b) => {
    if (a.isOpen && !b.isOpen) {
      return -1;
    }
    if (!a.isOpen && b.isOpen) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    fetchMerchant();
  }, [fetchMerchant]);
  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          // background:
          //   "linear-gradient(104.23deg, #FFFEFC 8.55%, #FCEDDC 104.67%, #FCE3BC 182.39%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {/* Main Content with Sidebar */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "space-between",
              p: 4,
              pl: 0,
              gap: 7,
            }}
          >
            {!isMobile && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  background: "white",
                  padding: 2,
                  borderRadius: 15,
                  height: "auto",
                  maxHeight: "84vh",
                  border: "0.5px #33333330 solid",
                  borderLeft: "none",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  position: "sticky",
                  top: "100px",
                }}
              >
                <FilterPanel
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                  filters={filters}
                  setFilters={setFilters}
                  handleRatingChange={handleRatingChange}
                  handleShopTypeChange={handleShopTypeChange}
                  handleClearAll={handleClearAll}
                  filteredData={filteredData}
                  fetchMerchant={fetchMerchant}
                />
              </Box>
            )}

            {/* Main Content Merchant */}
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                height: "auto",
                width: {
                  xs: "100%",
                  sm: "75%",
                  md: "60%",
                  lg: "65%",
                  xl: "70%",
                },
                pl: !isMobile ? 0 : 4,
              }}
            >
              {isMobile && (
                <Box
                  sx={{
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={openFilterPanelModal}
                    sx={{
                      borderRadius: "50%",
                      fontSize: "14px",
                      minWidth: isMobile ? "40px" : "auto",
                      padding: isMobile ? "6px" : "6px 16px",
                      background: "white",
                      border: "1px solid white",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    <TuneIcon />
                  </Button>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{ mt: 6, mb: 3, fontSize: ["24px", "32px", "34px"] }}
                  >
                    {t("home.mustTry")}
                  </Typography>
                </Box>
                {isFetch ? (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Grid
                      container
                      spacing={5}
                      sx={{
                        m: 0,
                        width: [
                          "100%",
                          "calc(100% + 40px)",
                          "calc(100% + 40px)",
                          "calc(100% + 40px)",
                        ],
                      }}
                    >
                      {allMerchantData &&
                        allMerchantData.map((items, i) => (
                          <FoodCard
                            items={items}
                            key={i}
                            sx={{
                              maxWidth: [
                                "calc(100%)",
                                "calc(50% - 24px)",
                                "calc(33.3333% - 24px)",
                                "calc(25% - 24px)",
                                "calc(20% - 24px)",
                                "calc(20% - 24px)",
                              ],
                              width: [
                                "calc(100%)",
                                "calc(50% - 24px)",
                                "calc(33.3333% - 24px)",
                                "calc(25% - 24px)",
                                "calc(20% - 24px)",
                              ],
                              mr: [0, "18px", "18px"],
                              mb: "24px",
                            }}
                          />
                        ))}
                    </Grid>
                    {!isFetchNextPage ? (
                      <Button
                        variant="outlined"
                        onClick={fetchNextPage}
                        sx={{
                          mt: 2,
                          borderRadius: "20px",
                          width: "30%",
                          alignSelf: "center",
                          display: currentPage === totalPages ? "none" : "",
                        }}
                      >
                        See More
                      </Button>
                    ) : (
                      <>
                        <Skeleton
                          height="294px"
                          sx={{
                            marginTop: "-4rem",
                          }}
                        />
                      </>
                    )}
                  </Box>
                ) : (
                  <Skeleton
                    height="294px"
                    sx={{
                      marginTop: "-4rem",
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Dialog
        open={isFilterPanelModalOpen}
        onClose={closeFilterPanelModal}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            // height: { xs: "100%", sm: "auto" },
            maxHeight: { sm: "70vh" },
            m: { xs: 0, sm: 2 },
            borderRadius: 4,
          },
        }}
      >
        <DialogContent sx={{ p: 0 }}>
          <FilterPanel
            onClose={closeFilterPanelModal}
            sortBy={sortBy}
            setSortBy={setSortBy}
            filters={filters}
            setFilters={setFilters}
            handleRatingChange={handleRatingChange}
            handleShopTypeChange={handleShopTypeChange}
            handleClearAll={handleClearAll}
            filteredData={filteredData}
            fetchMerchant={fetchMerchant}
          />
        </DialogContent>
        <DialogActions sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Box display="flex" justifyContent="flex-end" sx={{ gap: 2 }}>
              <Button
                variant="outlined"
                onClick={handleClearAll}
                sx={{ borderRadius: "20px" }}
              >
                Clear all
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={closeFilterPanelModal}
              >
                Apply
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SeeAllPage;
