import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

function ModalConfirmDelete({ open, onClose, onConfirm }) {
  const { t } = useTranslation();
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("modal.confirmDelete")}</DialogTitle>
      <DialogContent>
        <Typography>{t("modal.areYouSureDelete")}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t("btn.cancel")}
        </Button>
        <Button variant="contained" onClick={handleConfirm}>
          {t("btn.confirmButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalConfirmDelete;
