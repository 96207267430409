import dayjs from "dayjs";
import { sendLine } from "../../libs/lineAPI";
import DefaultProfile from "../defaultImage";

export function sendLineMessage(
  userId,
  orderData,
  merchant,
  statusDescription,
  buttonLabel,
  buttonUri
) {
  const flexMessage = [
    {
      type: "flex",
      altText: statusDescription,
      contents: {
        type: "bubble",
        body: {
          type: "box",
          layout: "vertical",
          contents: [
            {
              type: "text",
              text: "POMPKINS",
              weight: "bold",
              size: "xl",
              color: "#f9931f",
            },
            {
              type: "text",
              text: merchant.name,
              weight: "bold",
              size: "lg",
              margin: "md",
            },
            {
              type: "text",
              text: orderData.order_status,
              weight: "bold",
              size: "md",
              margin: "md",
            },
            {
              type: "text",
              text: statusDescription,
              size: "sm",
              color: "#555555",
              margin: "md",
            },
            {
              type: "separator",
              margin: "xxl",
            },
            {
              type: "box",
              layout: "horizontal",
              margin: "md",
              contents: [
                {
                  type: "text",
                  text: "Order ID:",
                  size: "xs",
                  color: "#aaaaaa",
                  flex: 0,
                },
                {
                  type: "text",
                  text: orderData.order_no,
                  size: "xs",
                  color: "#aaaaaa",
                  align: "end",
                },
              ],
            },
            {
              type: "box",
              layout: "horizontal",
              contents: [
                {
                  type: "text",
                  text: "Date:",
                  size: "xs",
                  color: "#aaaaaa",
                  flex: 0,
                },
                {
                  type: "text",
                  text: dayjs(orderData.date).format("DD/MM/YYYY HH:mm"),
                  size: "xs",
                  color: "#aaaaaa",
                  align: "end",
                },
              ],
            },
          ],
        },
        footer: {
          type: "box",
          layout: "vertical",
          spacing: "sm",
          contents: [
            {
              type: "button",
              style: "primary",
              height: "sm",
              action: {
                type: "uri",
                label: buttonLabel,
                uri: buttonUri,
              },
              color: "#f9931f",
            },
          ],
          flex: 0,
        },
      },
    },
  ];

  // write send request send line messaging api
  const body = {
    to: userId,
    messages: flexMessage,
  };

  sendLine(body)
    .then((res) => {
      // console.log(res.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

export function sendLineBill(
  userId,
  serviceData,
  billData,
  orders,
  transaction_uuid
) {
  const flexMessage = [
    {
      type: "flex",
      altText: "Bill Details",
      contents: {
        type: "bubble",
        header: {
          type: "box",
          layout: "vertical",
          contents: [
            {
              type: "text",
              text: serviceData.billing_header,
              weight: "bold",
              size: "xl",
              align: "center",
              wrap: true,
              color: "#f9931f", // Add a color to the header text
            },
          ],
        },
        hero: {
          type: "image",
          url: serviceData.bill_logo ?? "https://pompkins.com/icon-192.png",
          size: "md",
          aspectRatio: "1:1",
          aspectMode: "cover",
        },
        body: {
          type: "box",
          layout: "vertical",
          contents: [
            {
              type: "text",
              text: billData.merchant_name,
              weight: "bold",
              size: "lg", // Increase size for emphasis
              align: "center",
              margin: "sm",
            },
            {
              type: "text",
              text: `เลขประจำตัวผู้เสียภาษี: ${billData.tax_id}`,
              size: "sm",
              align: "center",
            },
            {
              type: "text",
              text: billData.merchant_location,
              size: "sm",
              align: "center",
              wrap: true,
            },
            {
              type: "separator",
              margin: "xxl",
            },
            {
              type: "text",
              text: `เลขที่ออเดอร์: #${orders[0].order_no
                .replace(/-/g, "")
                .slice(0, 5)
                .toUpperCase()}`,
              size: "sm",
              color: "#555555", // Subtle color for less important text
              margin: "sm",
            },
            {
              type: "text",
              text: `ชื่อพนักงาน: ${orders[0].staff_name}`,
              size: "sm",
              color: "#555555",
              margin: "sm",
            },
            {
              type: "text",
              text: `เลขที่ใบเสร็จ: ${transaction_uuid
                .slice(0, 15)
                .toUpperCase()}`,
              size: "sm",
              color: "#555555",
              margin: "sm",
            },
            {
              type: "text",
              text: `วันที่: ${dayjs(billData.date).format(
                "DD/MM/YYYY HH:mm"
              )}`,
              size: "sm",
              color: "#555555",
              margin: "sm",
            },
            {
              type: "separator",
              margin: "md",
            },
            {
              type: "box",
              layout: "horizontal",
              contents: [
                {
                  type: "text",
                  text: "จำนวน",
                  size: "sm",
                  flex: 1,
                  weight: "bold",
                },
                {
                  type: "text",
                  text: "รายการ",
                  size: "sm",
                  flex: 2,
                  weight: "bold",
                },
                {
                  type: "text",
                  text: "ราคา",
                  size: "sm",
                  flex: 3,
                  weight: "bold",
                  align: "end",
                },
              ],
              margin: "md",
            },
            {
              type: "separator",
              margin: "md",
            },
            {
              type: "text",
              text: billData.customer_name,
              size: "sm",
              weight: "bold",
            },
            ...orders.map((order) => ({
              type: "box",
              layout: "horizontal",
              contents: [
                {
                  type: "text",
                  text: `x${order.order_detail[0].quantity}`,
                  size: "sm",
                  flex: 1,
                },
                {
                  type: "text",
                  text: `${order.order_detail[0].menu_name}`,
                  size: "sm",
                  flex: 2,
                },
                {
                  type: "text",
                  text: `${(
                    order.order_detail[0].price * order.order_detail[0].quantity
                  ).toFixed(2)}`,
                  size: "sm",
                  flex: 3,
                  align: "end",
                },
              ],
              margin: "sm",
            })),
            {
              type: "separator",
              margin: "md",
            },
            {
              type: "box",
              layout: "horizontal",
              contents: [
                {
                  type: "text",
                  text: "ราคารวมทั้งสิ้น",
                  size: "md",
                  weight: "bold",
                  color: "#f9931f", // Highlight total with color
                },
                {
                  type: "text",
                  text: billData.total_price.toFixed(2),
                  size: "md",
                  weight: "bold",
                  align: "end",
                  color: "#f9931f",
                },
              ],
              margin: "md",
            },
          ],
        },
        footer: {
          type: "box",
          layout: "vertical",
          contents: [
            {
              type: "text",
              text:
                serviceData.billing_footer ?? "Thank you for your purchase!",
              size: "sm",
              align: "center",
              color: "#888888", // Subtle color for footer text
              margin: "md",
            },
          ],
          flex: 0,
        },
      },
    },
  ];
  const body = {
    to: userId,
    messages: flexMessage,
  };
  // console.log(body);
  sendLine(body)
    .then((res) => {
      // console.log(res.data);
    })
    .catch((error) => {
      console.log(error);
    });
}
