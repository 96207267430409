import { create } from "zustand";

export const useAuthStore = create((set) => ({
  user: {
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    uuid: "",
    favorite: [],
  },
  isAuthenticated: false,
  login: (user) => {
    set({ isAuthenticated: true, user: user });
  },
  logout: () => {
    set({
      isAuthenticated: false,
      user: {
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        uuid: "",
        favorite: [],
      },
    });
  },
  updateUser: (newData) => {
    set((state) => ({
      user: {
        ...state.user,
        ...newData,
      },
    }));
  },
  locationFrom: null,
  setLocationFrom: (from) => {
    set({ locationFrom: from });
  },
}));
