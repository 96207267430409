import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ThemeStyle from "./components/ThemeStyle";
import { I18nextProvider } from "react-i18next";
import i18n from "./libs/i18n";
import { worker } from "./mocks/browser";
import { ModalComponent } from "./hocs/ModalLoading";
import { MapPickerModal } from "./hocs/GoogleMapPickerModal";
import { SnackBarComponent } from "./hocs/SnackBar";
import { GenericModalComponent } from "./hocs/GenericModal";
import { GuestOrLoginModalComponent } from "./hocs/GuestOrLoginModal";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
// if (process.env.NODE_ENV === "development") {
//   worker.start({
//     onUnhandledRequest: "bypass",
//   });
// }

root.render(
  <I18nextProvider i18n={i18n}>
    <HelmetProvider>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}
      >
        <BrowserRouter>
          <ThemeStyle>
            <GenericModalComponent>
              <GuestOrLoginModalComponent>
                <ModalComponent>
                  <SnackBarComponent>
                    <MapPickerModal>
                      <App />
                    </MapPickerModal>
                  </SnackBarComponent>
                </ModalComponent>
              </GuestOrLoginModalComponent>
            </GenericModalComponent>
          </ThemeStyle>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </HelmetProvider>
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
