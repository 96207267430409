import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";

import MenuItem from "@mui/material/MenuItem";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  PermIdentityOutlined,
} from "@mui/icons-material";

import { Divider, ListItemIcon, Menu } from "@mui/material";
import { useAuthStore } from "../../stores/useAuthStore";
import Options from "../../utils/options";
import { useNavigate } from "react-router-dom";
import { styleMenu } from "../Styles/StyledMenu";
import { useTranslation } from "react-i18next";
import liff from "@line/liff";

function AccountMenu() {
  const { user, logout } = useAuthStore();
  const accoutRef = useRef(null);
  const [isAccountOpen, setIsAccountOpen] = useState(false);
  const { accountOptions } = Options();
  const { t } = useTranslation();
  const navigator = useNavigate();
  const [isLiffInitialized, setIsLiffInitialized] = useState(false);
  const handleMenuClick = (path) => {
    setIsAccountOpen(false);
    if (path === "logout") {
      logout();
      localStorage.removeItem("accessToken");
      if (!isLiffInitialized || !liff.isLoggedIn()) return;
      liff.logout();
      document.cookie =
        "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      localStorage.removeItem("user");
    } else {
      navigator(path);
    }
  };

  useEffect(() => {
    const initializeLiff = async () => {
      try {
        await liff.init({ liffId: process.env.REACT_APP_LIFF_ID });
        setIsLiffInitialized(true);
      } catch (error) {
        console.error("LIFF initialization failed", error);
      }
    };
    initializeLiff();
  }, []);

  return (
    <>
      <Button
        aria-controls="account-menu"
        aria-haspopup="true"
        className="text-gray-700"
        onClick={() => {
          setIsAccountOpen((prev) => !prev);
        }}
        ref={accoutRef}
        startIcon={<PermIdentityOutlined />}
        sx={{
          ":hover": {
            backgroundColor: "#fff8f0",
          },
        }}
        endIcon={
          isAccountOpen ? (
            <KeyboardArrowUp className="text-primary " />
          ) : (
            <KeyboardArrowDown className="text-primary" />
          )
        }
      >
        {user?.first_name}
      </Button>
      <Menu
        id="language-menu"
        anchorEl={accoutRef.current}
        keepMounted
        open={isAccountOpen}
        onClose={() => setIsAccountOpen(false)}
        slotProps={styleMenu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {accountOptions.map((option) => (
          <div key={option.label}>
            {option.label === t("options.helpCenter") && <Divider />}
            <MenuItem onClick={() => handleMenuClick(option.path)}>
              <ListItemIcon>{option.icon}</ListItemIcon>
              {option.label}
            </MenuItem>
          </div>
        ))}
      </Menu>
    </>
  );
}

export default AccountMenu;
