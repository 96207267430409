import React from "react";
import { Box, Typography } from "@mui/material";

function InstructionItem({ data }) {
  return (
    <Box
      sx={{
        width: "50%",
        px: ["4px", "8px", "12px"],
        mt: [1, 2, 3],
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          p: 1,
          borderRadius: "12px",
          width: "100%",
          boxShadow: "0px 0px 1rem rgba(0,0,0,.1)",
        }}
      >
        <img
          src={data.img}
          alt="1"
          title="1"
          loading="lazy"
          width="100%"
          height="auto"
          style={{ width: "100%", borderRadius: "12px" }}
        />
      </Box>
      <Box sx={{ py: 2, mt: 2, display: "flex", flexDirection: "row" }}>
        <Typography>{data.description}</Typography>
      </Box>
    </Box>
  );
}

export default InstructionItem;
