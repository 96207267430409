import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Stack,
  Box,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  IconButton,
} from "@mui/material";
import {
  AccessTime,
  RestaurantMenu,
  ArrowBackIosNew,
  HourglassTop,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Reserve from "../../assets/images/ld6_reserved_320.gif";
// Custom connector to change the color
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    height: 3,
    border: 0,
    marginTop: "12px",
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

// Styles for the step icon
const ColorlibStepIconRoot = styled("div")(({ theme, active, completed }) => ({
  backgroundColor: "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(active && {
    backgroundImage:
      "linear-gradient(136deg, rgba(252, 158, 79, 1) 0%, rgba(247, 107, 28, 1) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(completed && {
    backgroundImage:
      "linear-gradient(136deg, rgba(252, 158, 79, 1) 0%, rgba(247, 107, 28, 1) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, icon } = props;

  const icons = {
    1: <HourglassTop sx={{ width: 35, height: 35 }} />,
    2: <RestaurantMenu sx={{ width: 35, height: 35 }} />,
  };

  return (
    <ColorlibStepIconRoot active={active} completed={completed}>
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}

const ReserveStatusCard = ({
  orderData,
  handleCancel,
  contract,
  merchantName,
}) => {
  const navigator = useNavigate();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [isCancel, setIsCancel] = useState(false);
  const [reason, setReason] = useState("");
  const [selection, setSelection] = useState("");
  const status = orderData.order_status;

  useEffect(() => {
    if (status === "MERCHANT CONFIRMED") {
      setActiveStep(1);
    }
  }, [activeStep, status]);

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleCancelOrder = () => {
    setIsCancel(true);
    handleCancel();
  };
  const handleSelectionChange = (event) => {
    setSelection(event.target.value);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          width: "100%",
          height: "40px",
        }}
      >
        {activeStep === 2 && (
          <IconButton
            onClick={() => navigator(-1)}
            sx={{
              top: 0,
              left: 0,
            }}
          >
            <ArrowBackIosNew className="text-primary" />
          </IconButton>
        )}
      </Box>

      <Typography variant="h6" gutterBottom>
        {merchantName}
      </Typography>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {activeStep === 0 && (
          <img
            src={Reserve}
            style={{ width: "200px" }}
            alt="status"
            title="reserve"
            loading="lazy"
            width={"200px"}
            height={"auto"}
          />
        )}
      </Box>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
        sx={{ my: 2 }}
      >
        {[
          t("orderStatus.waitForRestaurants"),
          t("orderStatus.restaurantsConfirm"),
        ].map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Typography variant="body1">
        {t("orderStatus.orderNo")}{" "}
        <span>{orderData.order_no.slice(0, 5).toUpperCase()}</span>
      </Typography>

      <Box sx={{ my: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          justifyContent={"center"}
          textAlign={"center"}
        >
          {activeStep === 0 && (
            <>
              <AccessTime />
              <Typography variant="body2">
                {t("orderStatus.waitRestaurantDescription")}
              </Typography>{" "}
            </>
          )}
          {activeStep === 1 && (
            <Box sx={{ width: ["80%", "80%", "80%"] }}>
              <Typography variant="body2">
                {t("orderStatus.restaurantsConfirmDescription")}
              </Typography>
            </Box>
          )}
        </Stack>
      </Box>

      <Typography variant="body2" sx={{ mb: 2 }}>
        ติดต่อร้านค้าโทร. {contract}
      </Typography>
      {!isCancel ? (
        <Button
          variant="contained"
          disabled={activeStep !== 0}
          onClick={() => setIsCancel(true)}
          sx={{ borderRadius: 5, width: ["100%", "50%", "50%"] }}
        >
          ยกเลิกการจองโต๊ะ
        </Button>
      ) : (
        <Box sx={{ width: "70%", bgcolor: "background.paper" }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              ทำไมคุณถึงยกเลิก
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selection}
              label="ทำไมคุณถึงยกเลิก"
              onChange={handleSelectionChange}
            >
              <MenuItem value={10}>ร้านกำลังจะปิดแล้ว</MenuItem>
              <MenuItem value={20}>จองผิด</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            id="outlined-multiline-static"
            label={t("orderStatus.pleaseEnterReason")}
            multiline
            rows={4}
            value={reason}
            onChange={handleReasonChange}
            margin="normal"
          />
          <Stack
            direction="row-reverse"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleCancelOrder}
              sx={{ borderRadius: 5 }}
            >
              {t("orderStatus.confirmCancel")}
            </Button>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default ReserveStatusCard;
