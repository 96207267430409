import { forwardRef } from "react";
import { TextField, FormHelperText } from "@mui/material";

const TextInput = forwardRef(
  ({ error, placeholder, disable, ...register }, ref) => {
    return (
      <div>
        <TextField
          fullWidth
          {...register}
          size="small"
          disabled={disable}
          type="text"
          variant="outlined"
          placeholder={placeholder}
          inputRef={ref}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </div>
    );
  }
);

export default TextInput;
