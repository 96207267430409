import { MapRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import googleMapsLogo from "../assets/image/google-maps.png";
function MapButton({ stringAddress }) {
  const address = encodeURIComponent(stringAddress);
  const mapUrl = `https://www.google.com/maps/search/?api=1&query=${address}`;

  const openGoogleMaps = () => {
    window.open(mapUrl, "_blank");
  };

  return (
    <IconButton onClick={openGoogleMaps}>
      <img
        src={googleMapsLogo}
        width="30px"
        height="30px"
        style={{
          width: "30px",
          height: "30px",
        }}
        alt="map logo"
        title="map logo"
        loading="lazy"
      />
    </IconButton>
  );
}

export default MapButton;
