import React from "react";
import { Typography } from "@mui/material";

function TextHeader({ text, sx }) {
  return (
    <Typography
      sx={{
        textAlign: "center",
        fontSize: "24px",
        fontWeight: 700,
        pt: 2,
        ...sx,
      }}
    >
      {text}
    </Typography>
  );
}

export default TextHeader;
