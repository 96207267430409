// CartPage.js
import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  Radio,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { createOrder, updateOrderStatus } from "../../libs/orderAPI/orderAPI";
import {
  getBMerchantById,
  getMerchantById,
  getPaymentStatusList,
  getServiceBMerchant,
} from "../../libs/merchantAPI/merchantAPI";
import CheckOutItem from "../CartPage/CheckOutItem";
import { paymentData } from "../CartPage/paymentData";
import useModalLoading from "../../stores/useModalLoading";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
const MerchantQrCheckoutPage = () => {
  const { t } = useTranslation();
  const [orderData, setOrderData] = useState({});
  const [orderType, setOrderType] = useState("");
  const [orders, setOrders] = useState([]);
  const navigator = useNavigate();
  const { merchant_id, bmerchant_id } = useParams();
  const [merchantData, setMerchantData] = useState({});
  const [bmerchantService, setBMerchantService] = useState({});
  const [paymentType, setPaymentType] = useState("");
  const [availablePaymentList, setAvailablePaymentList] = useState([]);
  const { openModal, closeModal } = useModalLoading();
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/merchant-qr/${merchant_id}/${bmerchant_id}/checkout`,
      title: "MerchantQrCheckoutPage",
    });
  }, []);

  useEffect(() => {
    getBMerchantById(bmerchant_id).then((res) => {
      setIsVerified(res.data.verified);
      getServiceBMerchant(res.data.uuid).then(({ data }) => {
        setBMerchantService(data);
        getPaymentStatusList(res.data.uuid).then(({ data: paymentData }) => {
          let availablePayments = Object.keys(paymentData).filter(
            (key) => paymentData[key] !== null && key !== "bmerchant_uuid"
          );

          if (data.delivery_cash) {
            availablePayments.push("cash");
          }

          setAvailablePaymentList(availablePayments);
        });
      });
    });
    getMerchantById(merchant_id).then((res) => {
      setMerchantData(res.data);
    });
  }, []);

  useEffect(() => {
    const orderDataString = localStorage.getItem(
      `checkout_data_${bmerchant_id}`
    );
    const orderData = JSON.parse(orderDataString);
    if (orderData) {
      setOrders(orderData.order_detail);
      setOrderData(orderData);
      setOrderType(orderData.order_type);
    } else {
      navigator("/food");
    }
  }, []);

  const handleSubmit = async () => {
    openModal();
    if (!paymentType) {
      closeModal();
      return null;
    } else {
      const body = {
        ...orderData,
        payment_method: paymentType,
      };
      createOrder(body)
        .then((res) => {
          const order_uuid = res.data.order_uuid;
          if (bmerchantService.auto_accept) {
            if (paymentType === "THAI_QR") {
              updateOrderStatus(order_uuid, "MERCHANT CONFIRMED");
            } else if (paymentType === "CASH") {
              updateOrderStatus(order_uuid, "PURCHASED");
            }
          }
          navigator(
            `/order-status/${merchant_id}/${bmerchant_id}/${order_uuid}`
          );
          localStorage.removeItem(`order_merchant_${bmerchant_id}`);
          localStorage.removeItem(`merchant_${bmerchant_id}`);
          localStorage.removeItem(`checkout_data_${bmerchant_id}`);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          closeModal();
        });
    }
  };

  const totals = orders.reduce(
    (acc, item) => acc + item.quantity * item.price,
    0
  );
  const subtotal = bmerchantService.vat ? totals / 1.07 : totals;
  const vatValues = bmerchantService.vat ? totals - subtotal : 0;

  if (!orderData) {
    navigator(-1);
  }
  return (
    <>
      <Helmet>
        <title>{t("checkout")}</title>
        <meta
          name="description"
          content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
        />
        <link
          rel="canonical"
          href={`https://pompkins.com${window.location.pathname}`}
        />
        <meta property="og:url" content="https://pompkins.com/merchant-qr" />
      </Helmet>
      <Box
        sx={{
          height: "100vh",
          maxWidth: 1980,
          margin: "auto",

          padding: { xs: "0", sm: "2% 20%", md: "2% 24%" },
        }}
      >
        <Card sx={{ borderRadius: "15px" }}>
          <CardContent>
            <Typography variant="h5" fontWeight={500}>
              เช็คเอาท์
            </Typography>

            <Typography variant="h6">รายละเอียด</Typography>
            <Typography variant="body2" color="GrayText">
              ชื่อร้านค้า: {merchantData.name}
            </Typography>
            <Typography variant="body2" color="GrayText">
              ชื่อผู้รับ:{" "}
              {orderData.customer_name === "" ? "-" : orderData.customer_name}
            </Typography>
            <Typography variant="body2" color="GrayText">
              เบอร์โทรศัพท์: {orderData.phone === "" ? "-" : orderData.phone}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={() =>
                  navigator(
                    `/merchant-qr/${merchant_id}/${bmerchant_id}/${merchantData.name}`
                  )
                }
                sx={{
                  m: 0,
                  p: 0,
                  alignSelf: "flex-end",
                  minWidth: 0,
                  color: "#FF961B",
                  ":hover": {
                    backgroundColor: "#FFF7EB",
                  },
                }}
              >
                {t("cartOrder.addMenu")}
              </Button>
            </Box>
            {orders.map((item, index) => (
              <CheckOutItem key={item.menu_id} item={item} index={index} />
            ))}

            <Box sx={{ my: 2 }}>
              <Typography variant="body1" color={"GrayText"}>
                เลือกวิธีการชำระเงิน
              </Typography>
              {paymentData
                .filter((item) =>
                  availablePaymentList.includes(item.paymentType.toLowerCase())
                )
                .map((item, index) => (
                  <Box
                    onClick={() => {
                      if (
                        !(
                          item.paymentType === "CASH" &&
                          !bmerchantService.delivery_cash
                        )
                      ) {
                        setPaymentType(item.paymentType);
                      }
                    }}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      mt: 1,
                      px: 1,
                      cursor:
                        item.paymentType === "CASH" &&
                        !bmerchantService.delivery_cash
                          ? "not-allowed"
                          : "pointer",
                      ":hover": {
                        bgcolor:
                          item.paymentType === "CASH" &&
                          !bmerchantService.delivery_cash
                            ? "inherit"
                            : "#ffebcf",
                      },
                      opacity:
                        item.paymentType === "CASH" &&
                        !bmerchantService.delivery_cash
                          ? 0.5
                          : 1,
                    }}
                    key={index}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      {item.images?.map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          alt="icon"
                          loading="lazy"
                          title="icon"
                          width="auto"
                          height="24px"
                          style={{ height: "24px" }}
                        />
                      ))}
                      <Typography variant="body2">
                        {item.title}
                        {item.paymentType === "CASH" &&
                        !bmerchantService.delivery_cash
                          ? ` (${t("PaymentNotAvailable")})`
                          : ""}
                      </Typography>
                    </Box>
                    <Radio
                      checked={item.paymentType === paymentType}
                      disableRipple
                      onChange={() => {
                        if (
                          !(
                            item.paymentType === "CASH" &&
                            !bmerchantService.delivery_cash
                          )
                        ) {
                          setPaymentType(item.paymentType);
                        }
                      }}
                      value={paymentType}
                      sx={{
                        color: "#FF961B",
                        "&.Mui-checked": {
                          color: "#FF961B",
                        },
                      }}
                      disabled={
                        item.paymentType === "CASH" &&
                        !bmerchantService.delivery_cash
                      }
                    />
                  </Box>
                ))}
            </Box>
            {/* ส่วนสรุป */}
            <Typography variant="h6"></Typography>
            {bmerchantService.vat && (
              <Box
                sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
              >
                <Typography> {}ราคารวมก่อนภาษีมูลค่าเพิ่ม</Typography>
                <Typography>{`฿ ${subtotal.toFixed(2)}`}</Typography>
              </Box>
            )}

            {bmerchantService.vat && (
              <Box
                sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
              >
                <Typography>VAT7%</Typography>
                <Typography>{`฿ ${vatValues
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}</Typography>
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                my: 1,
                fontWeight: "bold",
              }}
            >
              {" "}
              <Typography variant="h6" sx={{ color: "black" }}>
                ราคารวมทั้งสิ้น
              </Typography>
              <Typography>{`฿ ${totals
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}</Typography>
            </Box>

            <Button
              variant="contained"
              color="primary"
              disabled={paymentType === ""}
              fullWidth
              sx={{ mt: 2, p: "8px 22px", borderRadius: "2rem" }}
              onClick={handleSubmit}
            >
              ยืนยัน
            </Button>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default MerchantQrCheckoutPage;
