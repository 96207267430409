import React, { useEffect } from "react";
import {
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Divider,
  RadioGroup,
  Radio,
  TextField,
  Button,
  Checkbox,
  Avatar,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Close, AddCircle, Circle } from "@mui/icons-material";
import DefaultProfile from "../../utils/defaultImage";

function ModalMenuCard({ items, open, setOpen, handleCloseModal }) {
  const { t } = useTranslation();
  const [optionList, setOptionList] = React.useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      fullWidth
      sx={{
        maxHeight: "100vh",
        "& .MuiDialog-paper": {
          borderRadius: "20px",
        },
      }}
    >
      <IconButton
        color="inherit"
        onClick={() => handleClose()}
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <Close sx={{ color: "#FF961B" }} />
      </IconButton>

      <Box
        alt={items.name}
        component="img"
        src={
          items.image === "" || items.image === null
            ? DefaultProfile
            : items.image
        }
        sx={{
          maxHeight: "30vh",
          objectFit: "cover",
        }}
      />

      <DialogTitle sx={{ typography: "h5" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ typography: "h5" }}>{items.name}</Typography>
          <Typography
            className="font-bold text-primary"
            sx={{ typography: "h6" }}
          >
            {items.price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}฿
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {items.description !== "" && (
          <>
            <Typography sx={{ typography: "body1" }}>
              {items.description}
            </Typography>
            <Divider sx={{ my: 2 }} />
          </>
        )}
        {items.options !== null &&
          items.options.map((option, mOptionIndex) => (
            <Box
              key={option.id}
              sx={{ bgcolor: "#fff5e9", p: 2, borderRadius: "10px", mb: 2 }}
            >
              <Typography sx={{ typography: "h6" }}>{option.name}</Typography>
              {option.required ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                  }}
                >
                  {option.sub_option.map((subOption) => (
                    <Box
                      key={subOption.id}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flex: 1,
                        }}
                      >
                        <Typography
                          sx={{ typography: "body1", flex: 1, ml: 2 }}
                        >
                          ● {subOption.name}
                        </Typography>
                        <Typography sx={{ typography: "body1", mr: "12px" }}>
                          {subOption.price}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                  }}
                >
                  {option.sub_option.map((subOption) => (
                    <Box
                      key={subOption.id}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flex: 1,
                        }}
                      >
                        <Typography
                          sx={{ typography: "body1", flex: 1, ml: 2 }}
                        >
                          ● {subOption.name}
                        </Typography>
                        <Typography sx={{ typography: "body1", mr: "12px" }}>
                          {subOption.price}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          ))}
        {items.options !== null && <Divider sx={{ my: 2 }} />}
      </DialogContent>
    </Dialog>
  );
}

export default ModalMenuCard;
