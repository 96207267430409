import React, { useCallback } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Box, Button, Typography } from "@mui/material";
import whyPompkins1 from "../../assets/images/whyPompkins1.png";
import whyPompkins2 from "../../assets/images/whyPompkins2.png";
import whyPompkinsBG from "../../assets/images/whyPompkinsBG.png";
import whyPompkinsMobileBG from "../../assets/images/whyUseBGMobile.png";
import { useNavigate } from "react-router-dom";
import { useMobile } from "../../stores/useMobile";
import { ArrowForwardIos } from "@mui/icons-material";
import { checkDeviceOs } from "../../utils/checkOS";
import WhyUsePompkinsVideo from "../../assets/images/splash3.mp4";
import { useTranslation } from "react-i18next";

const WhyPompkinsSlider = () => {
  const navigator = useNavigate();
  const { isMobile } = useMobile();
  const { t } = useTranslation();
  const DesktopComponent = () => {
    return (
      <OwlCarousel
        className="owl-carousel owl-theme"
        loop
        autoPlay
        dots
        items={1}
        style={{ height: "100%" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            height: "100%",
          }}
        >
          {/* <Box sx={{ height: ["300px", "300px", "300px", "350px"] }}>
              <img
                src={whyPompkinsBG}
                alt="whyPompkinsBG"
                style={{ height: "100%", width: "auto" }}
              />
            </Box> */}
          <Box sx={{ height: ["300px", "300px", "300px", "350px"] }}>
            <img
              src={whyPompkins1}
              alt="whyPompkins1"
              title="whyPompkins1"
              loading="lazy"
              width={"100%"}
              height={"100%"}
              style={{ height: "100%", width: "auto" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: ["50%", "50%", "50%", "auto"],
            }}
          >
            <Typography
              sx={{
                fontSize: ["35px", "45px", "50px", "60px"],
                fontWeight: "700",
                color: "white",
              }}
            >
              ไม่มีค่าใช้จ่ายอุปกรณ์
            </Typography>
            <Typography
              sx={{
                fontSize: ["25px", "35px", "50px"],
                fontWeight: "700",
                color: "white",
              }}
            >
              หรือเครื่อง POS
            </Typography>
            <Typography
              sx={{
                fontSize: ["18px", "20px", "24px"],
                mt: 2,
                fontWeight: 500,
                color: "white",
              }}
            >
              เพียงแค่มีสมาร์ทโฟน หรือ แท็บเล็ต
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: ["18px", "20px", "24px"],
                color: "white",
              }}
            >
              ก็สามารถดาน์โหลดและจัดการร้านอาหารได้อย่างมือโปร!
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "100%",
            flexDirection: "row",
            alignItems: "center",
            gap: 4,
            px: 2,
            py: [4, 4, 4, 2],
          }}
        >
          <Box
            sx={{
              width: ["50%", "50%", "50%", "60%"],
            }}
          >
            <img
              src={whyPompkins2}
              alt="whyPompkins2"
              title="whyPompkins2"
              loading="lazy"
              width={"100%"}
              height={"100%"}
              style={{ height: "auto", width: "100%" }}
            />
          </Box>
          <Box
            sx={{
              width: ["50%", "50%", "50%", "50%"],
              display: "flex",
              flexDirection: "column",
              height: "100%",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: ["35px", "45px", "50px", "60px"],
                  fontWeight: "700",
                  color: "white",
                }}
              >
                คุ้มค่า ราคาถูก
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: ["22px", "22px", "26px"],
                      color: "white",
                      mt: 2,
                    }}
                  >
                    แพ็คเกจเริ่มต้น
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: ["22px", "22px", "26px"],
                      color: "white",
                    }}
                  >
                    ราคาเพียง
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "end",
                    height: "94px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: ["35px", "45px", "60px", "70px"],
                      color: "white",
                      height: ["36px", "46px", "61px", "71px"],
                      lineHeight: ["35px", "45px", "60px", "70px"],
                      ml: 2,
                    }}
                  >
                    199.-
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: "white",
                      m: "auto 0 0 0",
                    }}
                  >
                    /เดือน
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  mt: 1,
                }}
              >
                <Button
                  onClick={() => navigator("/pompkins/package")}
                  endIcon={<ArrowForwardIos sx={{ ml: 1 }} />}
                  sx={{
                    borderRadius: "24px !important",
                    backgroundColor: "white",
                    color: "black",
                    px: 2,
                    fontSize: "20px",
                    ":hover": {
                      backgroundColor: "white",
                      color: "black",
                    },
                  }}
                >
                  แพ็คเกจทั้งหมด
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </OwlCarousel>
    );
  };
  const MobileComponent = () => {
    return (
      <OwlCarousel
        className="owl-carousel owl-theme"
        loop
        autoPlay
        dots
        items={1}
        style={{ height: "100%" }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            mt: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Box
              component={"img"}
              src={whyPompkins1}
              alt="whyPompkins1"
              title="whyPompkins1"
              loading="lazy"
              sx={{
                height: ["125px", "175px", "150px", "200px"],
                width: "auto !important",
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: ["20px", "24px", "28px", "32px"],
                  color: "white",
                }}
              >
                ไม่มีค่าใช้จ่ายอุปกรณ์
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: ["18px", "22px", "28px", "32px"],
                  color: "white",
                }}
              >
                หรือเครื่อง POS
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                color: "white",
                fontSize: ["16px", "18px", "28px", "32px"],
                mt: 1,
              }}
            >
              เพียงแค่มีสมาร์ทโฟน หรือ แท็บเล็ตก็สามารถดาวน์โหลด
            </Typography>
            <Typography
              sx={{
                fontSize: ["16px", "18px", "28px", "32px"],
                fontWeight: 500,
                color: "white",
              }}
            >
              และจัดการร้านอาหารได้อย่างมือโปร!
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            mt: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Box
              component={"img"}
              src={whyPompkins2}
              alt="whyPompkins2"
              sx={{
                height: ["110px", "150px", "150px", "200px"],
                width: "auto !important",
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: ["26px", "30px", "28px", "32px"],
                  color: "white",
                }}
              >
                คุ้มค่า
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: ["26px", "30px", "28px", "32px"],
                  color: "white",
                }}
              >
                ราคาถูก
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "white",
                  mt: 2,
                  textAlign: "right",
                }}
              >
                แพ็คเกจเริ่มต้น
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "white",
                  textAlign: "right",
                }}
              >
                ราคาเพียง
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "end",
                height: "64px",
              }}
            >
              <Typography
                sx={{
                  display: "inline-block",
                  fontWeight: 700,
                  fontSize: ["35px", "45px", "60px", "70px"],
                  color: "white",
                  ml: 2,
                  height: ["36px", "46px", "60px", "70px"],
                  lineHeight: ["35px", "45px", "60px", "70px"],
                }}
              >
                199.-
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  color: "white",
                  fontSize: "12px",
                }}
              >
                /เดือน
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                m: "auto 0 0 0",
                ml: 1,
              }}
            >
              <Button
                onClick={() => navigator("/pompkins/package")}
                endIcon={<ArrowForwardIos sx={{ ml: 1 }} />}
                sx={{
                  borderRadius: "24px !important",
                  backgroundColor: "white",
                  color: "black",
                  fontSize: "12px",
                  ":hover": {
                    backgroundColor: "white",
                    color: "black",
                  },
                }}
              >
                แพ็คเกจทั้งหมด
              </Button>
            </Box>
          </Box>
        </Box>
      </OwlCarousel>
    );
  };

  const renderComponent = useCallback(() => {
    return isMobile ? (
      <MobileComponent key="mobile" />
    ) : (
      <DesktopComponent key="desktop" />
    );
  }, [isMobile]);
  const onClickWhyUseVideo = () => {
    const { isIOS, isAndroid, isWindow, isMac } = checkDeviceOs();
    console.log(isIOS, isAndroid, isWindow, isMac);
    if (isIOS || isMac) {
      return window.open(
        "https://apps.apple.com/th/app/pompkins-%E0%B8%9E-%E0%B8%AD%E0%B8%A1%E0%B8%9B-%E0%B8%81-%E0%B8%99%E0%B8%AA/id6479531244?l=th",
        "_blank"
      );
    } else if (isAndroid || isWindow) {
      return window.open(
        "https://play.google.com/store/apps/details?id=com.prompkinsapp&hl=th&pli=1",
        "_blank"
      );
    }
  };
  return (
    <Box sx={{ pt: [6, 6, 5], backgroundColor: "white" }}>
      <Typography
        data-aos="fade-up"
        sx={{
          textAlign: "center",
          fontWeight: "700",
          fontSize: ["25px", "35px", "50px"],
        }}
      >
        {t("welcome.whyUsePompkins")}
      </Typography>
      <Box
        data-aos="fade-up"
        sx={{
          px: 4,
          display: "flex",
          gap: 4,
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            mt: 3,
            borderRadius: 4,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <video
            onClick={onClickWhyUseVideo}
            src={WhyUsePompkinsVideo}
            style={{ cursor: "pointer", borderRadius: "20px" }}
            playsInline
            autoPlay
            muted
            loop
            controls={false}
          />
        </Box>
      </Box>
      {/* <Box
        data-aos="fade-up"
        sx={{
          position: "relative",
          backgroundImage: `url(${
            isMobile ? whyPompkinsMobileBG : whyPompkinsBG
          })`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: "100%",
          height: ["225px", "280px", "337px", "400px"],
          mt: 2,
        }}
      >
        {renderComponent()}
      </Box> */}
    </Box>
  );
};

export default WhyPompkinsSlider;
