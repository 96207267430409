import React, { useState, useEffect, useRef } from "react";
import SwiperCore from "swiper";
import { Navigation, Scrollbar, Grid } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { IconButton } from "@mui/material";
import {
  ArrowBackIos,
  ArrowBackIosNewSharp,
  ArrowForwardIos,
} from "@mui/icons-material";
import "swiper/css";
import PromotionCards from "../../components/PromotionCards";
import CuisineCards from "../../components/CuisineCards";
import BrandCards from "../../components/BrandCards";
import FoodCard from "../../components/FoodCards";
import "swiper/css/pagination";
import { display, height, width } from "@mui/system";
import "swiper/css/scrollbar";
import FoodCardHorizontal from "../../components/FoodCardHorizontal";
import "./MerchantSearchSlider.css";
import "swiper/css/grid";

SwiperCore.use([Navigation, Scrollbar, Grid]);

function MerchantSearchSlider({ data }) {
  const [swiper, setSwiper] = useState(null);
  const sliderRef = useRef(null);

  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const settings = {
    onSwiper: (swiper) => setSwiper(swiper),
    navigation: false,
    className: "home-slider",
    scrollbar: {
      el: ".swiper-scrollbar",
      draggable: true,
    },
    spaceBetween: 10,
    pagination: false,
    slidesPerView: "auto",
  };

  useEffect(() => {
    if (swiper) {
      const updateStates = () => {
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
      };

      swiper.on("slideChange", updateStates);
      swiper.on("reachEnd", updateStates); // Ensure reachEnd event is handled
      swiper.on("reachBeginning", updateStates); // Ensure reachBeginning event is handled

      updateStates();
    }
  }, [swiper]);

  const goToNextSlide = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const goToPrevSlide = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  const renderCard = (items) => {
    switch (data.name) {
      case "promotions":
        return <PromotionCards items={items} />;
      case "cuisine":
        return <CuisineCards items={items} />;
      case "brands":
        return <BrandCards items={items} />;
      case "foods":
        return <FoodCard items={items} />;
      case "foods_horizontal":
        return <FoodCard items={items} />;
      default:
        return <CuisineCards items={items} />;
    }
  };

  return (
    <>
      {data.name === "foods_horizontal" ? (
        <div className="" style={{ position: "relative" }}>
          <Swiper ref={sliderRef} {...settings}>
            {/* <div className="MerchantSearchSlider swiper-wrapper"> */}
            {data &&
              data.slides &&
              data.slides.map((items, i) => (
                <SwiperSlide key={i}>{renderCard(items)}</SwiperSlide>
              ))}
            {/* </div> */}
            <div
              className="swiper-scrollbar"
              style={{
                backgroundColor: "#e6e6e6",
                height: "8px",
                width: "50%",
                position: "absolute",
                bottom: "0px",
                left: "0",
                right: "0",
                margin: "auto",
                borderRadius: "4px",
              }}
            >
              <div
                className="swiper-scrollbar-drag"
                style={{
                  backgroundColor: "#F19A3C",
                  width: "50%",
                }}
              />
            </div>
          </Swiper>
        </div>
      ) : (
        <div style={{ position: "relative" }}>
          <Swiper ref={sliderRef} {...settings}>
            {data &&
              data.slides &&
              data.slides.map((items, i) => (
                <SwiperSlide key={i}>{renderCard(items)}</SwiperSlide>
              ))}
            <div
              className="swiper-scrollbar"
              style={{
                backgroundColor: "#e6e6e6",
                height: "8px",
                width: "50%",
                position: "absolute",
                bottom: "0px",
                left: "0",
                right: "0",
                margin: "auto",
                borderRadius: "4px",
              }}
            >
              <div
                className="swiper-scrollbar-drag"
                style={{
                  backgroundColor: "#F19A3C",
                  width: "50%",
                }}
              />
            </div>
          </Swiper>
        </div>
      )}
    </>
  );
}

export default MerchantSearchSlider;
