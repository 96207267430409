import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getBill } from "../../libs/orderAPI/orderAPI";
import {
  getBMerchantById,
  getServiceBMerchant,
} from "../../libs/merchantAPI/merchantAPI";
import LoadingScreen from "../../components/LoadingScreen";
import { Box } from "@mui/material";
import BillEdcItem from "./BillEdcItem";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

function BillEdc() {
  const { t } = useTranslation();
  const { merchant_id, transaction_uuid, bmerchant_id } = useParams();
  const [isFetch, setIsFetch] = useState(false);
  const [billData, setBillData] = useState([]);
  const [service, setService] = useState({});
  const [bmerchant, setBmerchant] = useState({});

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/edc/bill/${merchant_id}/${bmerchant_id}/${transaction_uuid}`,
      title: "BillEdc",
    });
  }, []);
  useEffect(() => {
    getBill(transaction_uuid)
      .then((res) => {
        setBillData(res.data);
        return getBMerchantById(bmerchant_id);
      })
      .then((res) => {
        const data = res.data;
        setBmerchant(data);
        return getServiceBMerchant(data.uuid);
      })
      .then((res) => {
        setService(res.data);
        setIsFetch(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsFetch(true); // Ensure setIsFetch is called even in case of error
      });
  }, [bmerchant_id, transaction_uuid]);
  if (!isFetch) {
    return <LoadingScreen />;
  }
  return (
    <>
      <Helmet>
        <title>{t("title.billpage")}</title>
        <meta
          name="description"
          content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
        />
        <link
          rel="canonical"
          href={`https://pompkins.com${window.location.pathname}`}
        />
        <meta property="og:url" content="https://pompkins.com/edc/bill" />
      </Helmet>
      <Box
        width={"100%"}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 4,
        }}
      >
        <BillEdcItem
          billData={billData}
          serviceData={service}
          bmerchantData={bmerchant}
        />
      </Box>
    </>
  );
}

export default BillEdc;
