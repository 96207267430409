import { axiosInstance } from "../axios";

export const register = (data) => {
  return axiosInstance.post("/web/customer", data);
};

export const registerV2 = (data) => {
  return axiosInstance.post("/web/v2/auth/register", data);
};

export const sendOtp = (data) => {
  return axiosInstance.post("/auth/phone", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const verifyOtp = (data) => {
  return axiosInstance.post("/auth/web/phone-verify", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const registerPhone = (data) => {
  return axiosInstance.post("/web/v2/auth/register/phone", data);
};
