import React, { useEffect, useState } from "react";
import { Box, Typography, Card, Avatar } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DefaultProfile from "../../utils/defaultImage";
import ModalImage from "../ModalImage";
import { DeleteOutline } from "@mui/icons-material";

const CartItem = ({ item, index, handleQuantityChange }) => {
  const [imageModal, setImageModal] = useState(false);
  const [isAddDisabled, setIsAddDisabled] = useState(false);
  const calculateTotalPrice = (item) => {
    const total = parseInt(item.quantity, 10) * parseInt(item.price, 10);
    return parseFloat(total.toFixed(2)); // Returns number
  };

  const handleAddButton = () => {
    if (isAddDisabled) {
      return;
    }
    handleQuantityChange(index, 1);
  };

  useEffect(() => {
    if (item.menu.limited) {
      setIsAddDisabled(item.quantity >= item.menu.existed);
    }
  }, [item, item.quantity, item.menu.limited, item.menu.existed]);
  return (
    <>
      <ModalImage
        open={imageModal}
        onClose={() => setImageModal(false)}
        imageUrl={
          item.menu.image === "" || !item.menu.image
            ? DefaultProfile
            : item.menu.image
        }
      />
      <Card
        key={item.menu.id}
        sx={{ mt: 2, boxShadow: 0, borderRadius: 2, overflow: "hidden" }}
      >
        <Box sx={{ p: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setImageModal(!imageModal);
              }}
              className="hover:cursor-pointer"
            >
              <Box
                alt={item.menu.name}
                component="img"
                src={
                  item.menu.image === "" || !item.menu.image
                    ? DefaultProfile
                    : item.menu.image
                }
                sx={{
                  width: ["80px", "80px", "80px"],
                  height: ["80px", "80px", "80px"],
                  borderRadius: "20px",
                  objectFit: "cover",
                  maxWidth: "none !important",
                }}
              />
            </div>
            <Box
              sx={{
                flex: 1,
                ml: 2,
                display: "flex",
                flexDirection: "column",
                maxWidth: "calc(100% - 80px)",
              }}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                {item.menu.name}
              </Typography>
              {item.option.map((option, index) => (
                <Typography
                  key={index} // Make sure to specify a unique key for each element when mapping
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    width: "80%",
                    height: "50%",
                    fontWeight: "bold",
                  }}
                >
                  {option}
                </Typography>
              ))}

              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: "14px", mt: "4px" }}
              >
                Note : {item.note}
              </Typography>
              <Typography
                className="font-bold text-primary"
                variant="body2"
                color="text.secondary"
                sx={{ mt: "4px" }}
              >
                {`฿ ${item.price
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Avatar
                onClick={() => handleQuantityChange(index, -1)}
                sx={{
                  backgroundColor: "#F1F1F1",
                  fontSize: "30px",
                  width: "25px",
                  height: "25px",
                  color: "black",
                  cursor: "pointer",
                }}
              >
                {item.quantity > 1 ? (
                  "-"
                ) : (
                  <DeleteOutline sx={{ fontSize: "20px" }} />
                )}
              </Avatar>
              <Typography>{item.quantity}</Typography>

              <AddCircleIcon
                onClick={() => handleAddButton()}
                sx={{
                  color: isAddDisabled ? "#E0E0E0" : "#FF961B",
                  fontSize: "30px",
                  cursor: isAddDisabled ? "not-allowed" : "pointer",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default CartItem;
