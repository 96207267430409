import React, { useState, useEffect } from "react";
import { Box, Typography, Divider } from "@mui/material";
import ListComponent from "./ListComponent";
import SubListComponent from "./SubListComponent";
import { useSearchParams } from "react-router-dom";
import Instruction from "./Instruction";
import ReactGA from "react-ga4";

function HowToUsePage() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/pompkins/instruction",
      title: "HowToUsePage",
    });
  }, []);
  const [state, setState] = useState("");
  const [searchParams] = useSearchParams();

  const page = searchParams.get("page");

  const renderPage = () => {
    return <Instruction state={page} />;
  };

  const howToUseData = [
    {
      title: "ระบบแก้ไขรายละเอียด",
      state: "Edit Details",
      setState: () => setState("Edit Details"),
      list: [
        {
          title: "เปลี่ยนโปรไฟล์ และพื้นหลังร้านค้า",
          navigate: "?page=merchantProfile",
        },
        {
          title: "เปลี่ยนรายละเอียดข้อมูลร้านค้า",
          navigate: "?page=merchantDetail",
        },
      ],
    },
    {
      title: "ระบบจัดการร้านค้า",
      state: "Manage Merchant",
      setState: () => setState("Manage Merchant"),
      list: [
        { title: "ตั้งค่าเวลา เปิด-ปิด", navigate: "?page=openCloseTime" },
        {
          title: "ระบบให้บริการร้านค้า",
          navigate: "?page=serviceMerchant",
        },
        { title: "ออกแบบใบเสร็จรับเงิน", navigate: "?page=billDesign" },
        { title: "จัดการค่าจัดส่ง", navigate: "?page=deliverFee" },
      ],
    },
    {
      title: "ระบบจัดการพนักงาน",
      state: "Manage Staff",
      setState: () => setState("Manage Staff"),
      list: [
        { title: "เพิ่มพนักงาน", navigate: "?page=addStaff" },
        {
          title: "ลบพนักงาน",
          navigate: "?page=deleteStaff",
        },
        { title: "แก้ไขข้อมูลพนักงาน", navigate: "?page=editStaff" },
        { title: "เข้าสู่ระบบด้วยพนักงาน", navigate: "?page=loginWithStaff" },
      ],
    },
    {
      title: "ระบบจัดการโต๊ะ",
      state: "Manage Table",
      setState: () => setState("Manage Table"),
      list: [
        { title: "เพิ่มโต๊ะ", navigate: "?page=addTable" },
        {
          title: "ลบโต๊ะ",
          navigate: "?page=deleteTable",
        },
        { title: "ย้ายโต๊ะ", navigate: "?page=moveTable" },
        { title: "เช็คบิล", navigate: "?page=checkBill" },
      ],
    },
    {
      title: "ระบบจองคิว",
      state: "Manage Queue",
      setState: () => setState("Manage Queue"),
      list: [
        { title: "เพิ่มคิว", navigate: "?page=addQueue" },
        {
          title: "รับคิว",
          navigate: "?page=receiveQueue",
        },
        { title: "ลบคิว", navigate: "?page=deleteQueue" },
      ],
    },
    {
      title: "ระบบจัดการครัว",
      state: "Manage Kitchen",
      setState: () => setState("Manage Kitchen"),
      list: [{ title: "ยืนยันออเดอร์", navigate: "?page=confirmOrderKitchen" }],
    },
    {
      title: "ระบบจัดการออเดอร์",
      state: "Manage Order",
      setState: () => setState("Manage Order"),
      list: [
        { title: "ยืนยันออเดอร์", navigate: "?page=confirmOrder" },
        {
          title: "ยกเลิกออเดอร์",
          navigate: "?page=cancelOrder",
        },
      ],
    },
    {
      title: "ระบบจัดการเอกสารสมัคร",
      state: "Manage KYC",
      setState: () => setState("Manage KYC"),
      list: [
        { title: "บันทึกเอกสาร", navigate: "?page=saveKYC" },
        {
          title: "ส่งเอกสาร",
          navigate: "?page=sendKYC",
        },
      ],
    },
    {
      title: "ระบบการเงิน",
      state: "Payment System",
      setState: () => setState("Payment System"),
      list: [
        { title: "ระบบเงินสด", navigate: "?page=cashSystem" },
        {
          title: "ระบบ PromptPay QR",
          navigate: "?page=promptPayQR",
        },
        {
          title: "ระบบ Thai QR",
          navigate: "?page=thaiQR",
        },
      ],
    },
  ];

  const renderSubList = (list, setState) => {
    return (
      <>
        <SubListComponent list={list} setState={setState} />
      </>
    );
  };

  const renderMainList = () => {
    return (
      <>
        {howToUseData.map((item, index) => (
          <div key={index}>
            <ListComponent title={item.title} onClick={item.setState} />
            <Divider />
          </div>
        ))}
      </>
    );
  };

  const renderList = () => {
    if (state !== "") {
      const data = howToUseData.find((item) => item.state === state);
      return renderSubList(data.list, setState);
    } else {
      return renderMainList();
    }
  };

  if (page) {
    return renderPage();
  }
  return (
    <Box sx={{ bgcolor: "white", py: 4 }}>
      <Typography
        sx={{ fontWeight: 600, fontSize: "32px", textAlign: "center" }}
      >
        แนะนำการใช้งาน
      </Typography>
      <Box sx={{ mt: 2, width: "100%", height: "100vh" }}>{renderList()}</Box>
    </Box>
  );
}

export default HowToUsePage;
