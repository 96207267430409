import { AccessTime, Close, LocationOnOutlined } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { getAddressFromCoordinates } from "../../utils/convertAddress";
import MapNotFound from "../../assets/image/Map not found.png";
import { useTranslation } from "react-i18next";
MoreInfoModal.defaultProps = {
  open: false,
};

function MoreInfoModal({ data, open, setOpen }) {
  const { location, name, openTime, closeTime, isMerchantOpen } = data;
  const [merchantAddress, setMerchantAddress] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    const getAddress = async () => {
      const address = await getAddressFromCoordinates(location);
      setMerchantAddress(address);
    };
    if (location.lat && location.lng) {
      getAddress();
    }
  }, [location]);

  return (
    <Dialog open={open} fullWidth PaperProps={{ sx: { borderRadius: "10px" } }}>
      <IconButton
        color="inherit"
        onClick={() => setOpen(false)}
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <Close sx={{ color: "#FF961B" }} />
      </IconButton>
      <DialogTitle>{name}</DialogTitle>
      <Divider />
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
            mb: 1,
            gap: 0.7,
          }}
        >
          <AccessTime
            sx={{
              fontSize: "20px",
              marginTop: "-2px",
              color: isMerchantOpen ? "green" : "red",
            }}
          />
          <Typography
            sx={{
              color: isMerchantOpen ? "green" : "red",
            }}
            variant="body2"
          >
            {isMerchantOpen
              ? t("merchant.merchantOpen")
              : t("merchant.merchantClose")}
          </Typography>
          <Typography variant="body2">
            {openTime} - {closeTime}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
            mb: 1,
          }}
        >
          <LocationOnOutlined sx={{ color: "#FF961B", fontSize: "20px" }} />
          {merchantAddress && <Typography> {merchantAddress}</Typography>}
        </Box>
        <Box>
          {location.lat && location.lng && (
            <GoogleMap
              mapContainerStyle={{
                width: "100%",
                height: "200px",
                borderRadius: "10px",
              }}
              center={location}
              zoom={14}
              options={{
                zoomControl: true,
                streetViewControl: true,
                mapTypeControl: true,
                fullscreenControl: false,
              }}
            >
              {location && <Marker position={location} />}
            </GoogleMap>
          )}
          {!merchantAddress && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                mb: 3,
              }}
            >
              <img
                src={MapNotFound}
                alt="map not found img"
                title="map not found img"
                loading="lazy"
                width="300px"
                height="300px"
                style={{
                  height: "300px",
                  width: "300px",
                }}
              />
              <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
                {t("map.locationNotFound")}
              </Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default MoreInfoModal;
