import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import TextHeader from "../../components/TextHeader";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

function PrivacyPage() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/privacy-policy",
      title: "PrivacyPage",
    });
  }, []);
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "white",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box sx={{ p: 2, px: [4, 4, 14] }}>
        <TextHeader text={t("privacy.title")} sx={{ mb: 2 }} />
        <Typography sx={{ textIndent: "3em", mb: 2 }}>
          <Typography component={"span"} fontWeight={700}>
            {t("privacy.companyName")}
          </Typography>
          {t("privacy.desc")}
        </Typography>
        <Typography fontWeight={700} gutterBottom>
          {t("privacy.section1")}{" "}
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {t("privacy.section1Desc")}
        </Typography>
        <Typography
          sx={{
            textIndent: "3em",
            mb: 1,
            textDecoration: "underline",
            fontWeight: 700,
          }}
        >
          {t("privacy.section1_1")}
        </Typography>
        <Typography
          sx={{
            mb: 1,
            textIndent: "3em",
          }}
        >
          {t("privacy.section1_1Desc")}{" "}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          <Typography component={"span"} fontWeight={700}>
            {t("privacy.a")}{" "}
          </Typography>
          {t("privacy.1_1a")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          <Typography component={"span"} fontWeight={700}>
            {t("privacy.b")}{" "}
          </Typography>
          {t("privacy.1_1b")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          <Typography component={"span"} fontWeight={700}>
            {t("privacy.c")}{" "}
          </Typography>
          {t("privacy.1_1c")}
        </Typography>
        <Typography
          sx={{
            textIndent: "3em",
            my: 1,
            textDecoration: "underline",
            fontWeight: 700,
          }}
        >
          {t("privacy.1_2")}{" "}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          {t("privacy.1_2Desc")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          <Typography component={"span"} fontWeight={700}>
            {t("privacy.a")}{" "}
          </Typography>
          {t("privacy.1_2a")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          <Typography component={"span"} fontWeight={700}>
            {t("privacy.b")}{" "}
          </Typography>
          {t("privacy.1_2b")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          <Typography component={"span"} fontWeight={700}>
            {t("privacy.c")}{" "}
          </Typography>
          {t("privacy.1_2c")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          <Typography component={"span"} fontWeight={700}>
            {t("privacy.d")}{" "}
          </Typography>
          {t("privacy.1_2d")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          <Typography component={"span"} fontWeight={700}>
            {t("privacy.e")}{" "}
          </Typography>
          {t("privacy.1_2e")}
        </Typography>
        <Typography
          sx={{
            textIndent: "3em",
            my: 1,
            textDecoration: "underline",
            fontWeight: 700,
          }}
        >
          {t("privacy.1_3")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          {t("privacy.1_3Desc")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          <Typography component={"span"} fontWeight={700}>
            {t("privacy.a")}{" "}
          </Typography>
          {t("privacy.1_3a")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          <Typography component={"span"} fontWeight={700}>
            {t("privacy.b")}{" "}
          </Typography>
          {t("privacy.1_3b")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          <Typography component={"span"} fontWeight={700}>
            {t("privacy.c")}{" "}
          </Typography>
          {t("privacy.1_3c")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          <Typography component={"span"} fontWeight={700}>
            {t("privacy.d")}{" "}
          </Typography>
          {t("privacy.1_3d")}
        </Typography>
        <Typography
          sx={{
            textIndent: "3em",
            my: 1,
            textDecoration: "underline",
            fontWeight: 700,
          }}
        >
          {t("privacy.1_4")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          {t("privacy.1_4Desc")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          <Typography component={"span"} fontWeight={700}>
            {t("privacy.a")}{" "}
          </Typography>
          {t("privacy.1_4a")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          <Typography component={"span"} fontWeight={700}>
            {t("privacy.b")}{" "}
          </Typography>
          {t("privacy.1_4b")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          <Typography component={"span"} fontWeight={700}>
            {t("privacy.c")}{" "}
          </Typography>
          {t("privacy.1_4c")}
        </Typography>
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("privacy.section2")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>{t("privacy.2Desc")}</Typography>
        <Typography sx={{ textIndent: "3em" }} component={"li"}>
          {t("privacy.2_1")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }} component={"li"}>
          {t("privacy.2_2")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }} component={"li"}>
          {t("privacy.2_3")}
        </Typography>
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("privacy.3")}{" "}
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {t("privacy.3Desc")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }} component={"li"}>
          {t("privacy.3_1")}
        </Typography>
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("privacy.4")}{" "}
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {t("privacy.4Desc")}
        </Typography>
        <Typography
          sx={{
            textIndent: "3em",
            mb: 1,
            textDecoration: "underline",
            fontWeight: 700,
          }}
        >
          {t("privacy.4_1")}{" "}
        </Typography>
        <Typography
          sx={{
            mb: 1,
            textIndent: "3em",
          }}
        >
          {t("privacy.4_1Desc")}
        </Typography>
        <Typography
          sx={{
            textIndent: "3em",
            mb: 1,
            textDecoration: "underline",
            fontWeight: 700,
          }}
        >
          {t("privacy.4_2")}
        </Typography>
        <Typography
          sx={{
            mb: 1,
            textIndent: "3em",
          }}
        >
          {t("privacy.4_2Desc")}
        </Typography>
        <Typography
          sx={{
            textIndent: "3em",
            mb: 1,
            textDecoration: "underline",
            fontWeight: 700,
          }}
        >
          {t("privacy.4_3")}
        </Typography>
        <Typography
          sx={{
            mb: 1,
            textIndent: "3em",
          }}
        >
          {t("privacy.4_3Desc")}
        </Typography>
        <Typography
          sx={{
            textIndent: "3em",
            mb: 1,
            textDecoration: "underline",
            fontWeight: 700,
          }}
        >
          {t("privacy.4_4")}
        </Typography>
        <Typography
          sx={{
            mb: 1,
            textIndent: "3em",
          }}
        >
          {t("privacy.4_4Desc")}
        </Typography>
        <Typography
          sx={{
            textIndent: "3em",
            mb: 1,
            textDecoration: "underline",
            fontWeight: 700,
          }}
        >
          {t("privacy.4_5")}
        </Typography>
        <Typography
          sx={{
            mb: 1,
            textIndent: "3em",
          }}
        >
          {t("privacy.4_5Desc")}
        </Typography>
        <Typography
          sx={{
            textIndent: "3em",
            mb: 1,
            textDecoration: "underline",
            fontWeight: 700,
          }}
        >
          {t("privacy.4_6")}
        </Typography>
        <Typography
          sx={{
            mb: 1,
            textIndent: "3em",
          }}
        >
          {t("privacy.4_6Desc")}
        </Typography>
        <Typography
          sx={{
            textIndent: "3em",
            mb: 1,
            textDecoration: "underline",
            fontWeight: 700,
          }}
        >
          {t("privacy.4_7")}
        </Typography>
        <Typography
          sx={{
            mb: 1,
            textIndent: "3em",
          }}
        >
          {t("privacy.4_7Desc")}
        </Typography>
        <Typography
          sx={{
            textIndent: "3em",
            mb: 1,
            textDecoration: "underline",
            fontWeight: 700,
          }}
        >
          {t("privacy.4_8")}
        </Typography>
        <Typography
          sx={{
            mb: 1,
            textIndent: "3em",
          }}
        >
          {t("privacy.4_8Desc")}
        </Typography>
        <Typography mt={2} fontWeight={700} gutterBottom>
          {t("privacy.5")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>{t("privacy.5Desc")}</Typography>
        <Typography sx={{ textIndent: "3em" }}>
          <Typography component={"span"} fontWeight={700}>
            {t("privacy.a")}{" "}
          </Typography>
          {t("privacy.5_1")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          <Typography component={"span"} fontWeight={700}>
            {t("privacy.b")}
          </Typography>{" "}
          {t("privacy.5_2")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          <Typography component={"span"} fontWeight={700}>
            {t("privacy.c")}
          </Typography>{" "}
          {t("privacy.5_3")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          <Typography component={"span"} fontWeight={700}>
            {t("privacy.d")}
          </Typography>{" "}
          {t("privacy.5_4")}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>
          <Typography component={"span"} fontWeight={700}>
            {t("privacy.e")}
          </Typography>{" "}
          {t("privacy.5_5")}
        </Typography>

        <Typography mt={2} fontWeight={700} gutterBottom>
          {t("privacy.6")}{" "}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>{t("privacy.6Desc")}</Typography>

        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("privacy.7")}{" "}
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {t("privacy.7Desc")}
        </Typography>
        {/* <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {t("privacy.7_1")}
        </Typography> */}
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("privacy.8")}
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {t("privacy.8Desc")}
        </Typography>
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("privacy.9")}
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {t("privacy.9Desc")}
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {t("privacy.lineAccount")}
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {t("privacy.CallCenter")}
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {t("privacy.email")}
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {t("privacy.address")}
        </Typography>
      </Box>
    </Box>
  );
}

export default PrivacyPage;
