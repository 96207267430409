import React, { useEffect, useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  Box,
  Chip,
} from "@mui/material";
import DefaultProfile from "../../utils/defaultImage";

const CheckBillItem = ({ item, name, status }) => {
  const [color, setColor] = useState();
  // const nameColor = localStorage.getItem("nameColor");
  useEffect(() => {
    if (status === "SUCCESS") {
      setColor("#08aa08");
    } else {
      setColor("#00a7f0");
    }
  }, [status]);

  const calculateTotalPrice = (item) => {
    const total = parseFloat(item.quantity, 10) * parseFloat(item.price, 10);
    return parseFloat(total.toFixed(2)); // Returns number
  };

  return (
    <Card
      key={item.menu_id}
      sx={{ mt: 2, boxShadow: 3, borderRadius: 2, overflow: "hidden" }}
    >
      <CardContent sx={{ p: "16px !important" }}>
        <Box
          gap={2}
          sx={{
            maxWidth: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
          alignItems="flex-start"
        >
          <CardMedia
            component="img"
            image={
              item.image === "" || !item.image ? DefaultProfile : item.image
            }
            alt={item.menu_name}
            sx={{ width: "70px", height: "70px", borderRadius: "15px" }}
          />

          <Box sx={{ flex: 1 }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: ["14px", "14px", "16px"],
                width: "100%",
                height: "50%",
                fontWeight: "bold",
                overflowWrap: "break-word",
                wordWrap: "break-word",
              }}
            >
              {item.menu_name} {"x" + item.quantity}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "4px",
                flexWrap: "wrap",
              }}
            >
              {item.option &&
                item.option.map((option, index) => (
                  <Typography
                    key={index}
                    variant="body2"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      overflowWrap: "anywhere",
                      wordBreak: "break-all",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {option}
                  </Typography>
                ))}
            </Box>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                width: "90%",
                fontSize: "12px",
                overflowWrap: "anywhere",
                wordBreak: "break-all",
                whiteSpace: "pre-line",
              }}
            >
              Note : {item.note}
            </Typography>
            <Typography
              className="font-bold text-primary"
              variant="body2"
              sx={{ mt: "4px" }}
            >
              {`฿ ${calculateTotalPrice(item)
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              gap: 1,
            }}
          >
            {name && (
              <Chip
                size="small"
                sx={{ borderColor: "black", color: "black" }}
                label={name}
                variant="outlined"
              />
            )}
            {status && (
              <Chip
                size="small"
                sx={{ borderColor: color, color: color }}
                label={status}
                variant="outlined"
              />
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CheckBillItem;
