import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuList,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { forwardRef, useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import MenuShopCard from "./MenuShopCard";
import { useTranslation } from "react-i18next";
import notFoundImg from "../assets/images/emo_pomkins_dizzy320.png";
import QrMenuShopCard from "../pages/QrPage/QrMenuShopCard";

const MenuSearch = forwardRef((props, ref) => {
  const {
    isQr = false,
    handleClose,
    displayMenu,
    cartList,
    setCartList,
    handleQuantityChange,
    openNoPayment,
    openNoService,
  } = props;
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [filteredMenu, setFilteredMenu] = useState(undefined);

  useEffect(() => {
    const filterMenu = (searchValue, displayMenu) => {
      if (!searchValue) return;

      return displayMenu.filter((item) => {
        const categoryMatch = item.category.name
          .toLowerCase()
          .includes(searchValue.toLowerCase());
        const menuMatch = item.menu.some((menuItem) =>
          menuItem.name.toLowerCase().includes(searchValue.toLowerCase())
        );
        return categoryMatch || menuMatch;
      });
    };

    setFilteredMenu(filterMenu(searchValue, displayMenu));
  }, [searchValue, displayMenu]);

  const resultCount = filteredMenu ? filteredMenu.length : 0;

  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        gap: 2,
        width: "100%",
        height: filteredMenu ? "100%" : "auto",
        zIndex: 1,
        position: "absolute",
        top: "0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "white",
          padding: "8px 16px",
          borderRadius: "4px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        }}
      >
        <TextField
          id="search-menu"
          fullWidth
          variant="standard"
          placeholder={t("searchInMenu")}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          sx={{
            borderColor: "transparent",
            backgroundColor: "#f7f7f7",
            p: "8px 16px",
            my: "8px",
            borderRadius: "18px",
          }}
          InputProps={{
            disableUnderline: true,
            type: "search",
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <IconButton
          aria-label="Close"
          size="medium"
          onClick={() => handleClose()}
          sx={{
            marginLeft: "8px",
            padding: "8px",
            backgroundColor: "background.paper",
            color: "#f9931f",
          }}
        >
          <Close />
        </IconButton>
      </Box>
      {searchValue && (
        <Typography variant="subtitle1" sx={{ px: 2, mt: 2 }}>
          We found {resultCount} results for "{searchValue}"
        </Typography>
      )}
      {filteredMenu &&
        filteredMenu.map((object, index) => (
          <Box key={index} sx={{ px: 2 }}>
            <Typography
              variant="h6"
              sx={{
                mt: 2,
                mb: 2,
                fontWeight: "bold",
              }}
            >
              {object.category.name}
            </Typography>
            {isQr ? (
              <Box>
                {object.menu.map((menus, i) => (
                  <Box key={menus.id}>
                    <QrMenuShopCard
                      items={menus}
                      cartList={cartList}
                      setCartList={setCartList}
                      onIncrement={() => handleQuantityChange(menus.id, 1)}
                      onDecrement={() => handleQuantityChange(menus.id, -1)}
                    />
                  </Box>
                ))}
              </Box>
            ) : (
              <Grid container justifyContent="space-between">
                {object.menu.map((menus, i) => (
                  <Grid key={menus.id} item xs={12} sm={5.85}>
                    <MenuShopCard
                      items={menus}
                      cartList={cartList}
                      setCartList={setCartList}
                      onIncrement={() => handleQuantityChange(menus.id, 1)}
                      onDecrement={() => handleQuantityChange(menus.id, -1)}
                      openNoPayment={openNoPayment}
                      openNoService={openNoService}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        ))}
      {resultCount === 0 && searchValue !== "" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "50%",
          }}
        >
          <img
            src={notFoundImg}
            alt="not found img"
            title="not found img"
            loading="lazy"
            width={"250px"}
            height={"250px"}
          />
          <Button
            variant="contained"
            onClick={() => setSearchValue("")}
            sx={{ m: 2 }}
            size="large"
          >
            Clear search
          </Button>
        </Box>
      )}
    </Box>
  );
});

export default MenuSearch;
