import React, { useEffect, useState } from "react";
import { Fade, Paper, Button, Typography, Box } from "@mui/material";
import CookieIcon from "@mui/icons-material/Cookie";
import TrapFocus from "@mui/material/Unstable_TrapFocus";
import CookiesSetting from "../components/CookiesSetting";
import { useLocation } from "react-router-dom";

function CookieConsentDialog({ openModal }) {
  const [open, setOpen] = useState(openModal);
  const [openSetting, setOpenSetting] = useState(false);
  const handleAccept = () => {
    setOpen(false);
  };

  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname.includes("instruction") ||
      location.pathname.includes("table-merchant") ||
      location.pathname.includes("merchant-qr") ||
      location.pathname.includes("queue") ||
      location.pathname.includes("download")
    ) {
      setOpen(false);
    }
  }, [location.pathname]);
  const handleSetting = () => {
    setOpenSetting(!openSetting);
  };

  return (
    <div>
      <CookiesSetting
        open={openSetting}
        onClose={() => setOpenSetting(false)}
        onAccept={handleAccept}
      />
      <TrapFocus open={open} disableAutoFocus>
        <Fade in={open}>
          <Paper
            sx={{
              position: "fixed",
              backgroundColor: "rgb(255,255,255,1)",
              bottom: "0px",
              padding: "20px",
              mx: ["3vw", "3vw", "20vw"],
              mb: "10px",
              zIndex: 1200,
              borderRadius: "10px",
            }}
          >
            <Typography variant="h5" mb={1} fontWeight={700}>
              เว็บไซต์นี้มีการใช้คุกกี้ <CookieIcon />
            </Typography>

            <Typography variant="body2" color={"GrayText"}>
              เราใช้คุกกี้และเทคโนโลยีการติดตามอื่น ๆ
              เพื่อปรับปรุงประสบการณ์การท่องเว็บของคุณบนเว็บไซต์ของเราเพื่อแสดงความเป็นส่วนตัวของคุณ
              เนื้อหาและโฆษณาที่ตรงเป้าหมาย
              เพื่อวิเคราะห์การเข้าชมเว็บไซต์ของเรา และเพื่อ
              เข้าใจว่าผู้เยี่ยมชมของเรามาจากไหน
            </Typography>

            <Box
              sx={{
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{ borderRadius: "2rem" }}
                onClick={() => {
                  handleAccept();
                  document.cookie = `cookieSetting=${JSON.stringify({
                    necessaryCookie: true,
                    performanceCookie: true,
                    functionalCookie: true,
                    adsCookie: true,
                  })}`;
                }}
              >
                ยอมรับทั้งหมด
              </Button>
              <Button
                variant="outlined"
                onClick={handleSetting}
                sx={{ borderRadius: "2rem", marginLeft: "10px" }}
              >
                ตั้งค่าคุกกี้
              </Button>
            </Box>
          </Paper>
        </Fade>
      </TrapFocus>
    </div>
  );
}

export default CookieConsentDialog;
