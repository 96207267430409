import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import ListComponent from "../HowToUse/ListComponent";
import ListFaq from "./ListFaq";
import { useNavigate } from "react-router-dom";

function FaqItem({ item, category }) {
  const displayedFaqData = item.data.slice(0, 5);
  const navigator = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        flex: ["1 1 100%", "1 1 44%", "1 1 28%"],
        minWidth: ["100%", "44%", "28%"],
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          textAlign: "center",
          alignItems: "center",
          width: "100%",

          gap: 1,
        }}
      >
        {item.icon}
        <Typography
          onClick={() => {
            navigator(
              `/pompkins/FAQ/categories/${category}/sections/${item.type}`
            );
          }}
          sx={{
            fontSize: "24px",
            fontWeight: "700",
            ":hover": {
              textDecoration: "underline",
              cursor: "pointer",
            },
          }}
        >
          {item.type}
        </Typography>
      </Box>
      <Divider />
      {displayedFaqData.map((list, index) => (
        <div key={index}>
          <ListFaq
            title={list.question}
            onClick={() =>
              navigator(
                `/pompkins/FAQ/categories/${category}/sections/${item.type}/${list.question}`
              )
            }
          />
        </div>
      ))}
      {item.data.length > 5 && (
        <Box
          onClick={() => {
            navigator(
              `/pompkins/FAQ/categories/${category}/sections/${item.type}`
            );
          }}
          sx={{
            display: "flex",
            p: 1,
            width: "fit-content",
            color: "#666666",
            cursor: "pointer",
            ":hover": {
              color: "#f9931f",
              transition: " color 0.2s",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            {"ดูเพิ่มเติม >"}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default FaqItem;
