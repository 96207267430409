import React from "react";

import DownloadNowImg from "../../assets/images/downloadnow500.png";
import { Box, Typography } from "@mui/material";
import GooglePlay from "../../assets/images/google-play.png";
import AppStore from "../../assets/images/app-store.png";
import { useTranslation } from "react-i18next";
function AdsSection() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        py: 8,
        width: "100%",
      }}
    >
      <Box
        data-aos="fade-up"
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#FFF3E5",
          flexDirection: ["row", "row", "row"],
          borderRadius: 4,
          width: ["90%", "70%", "70%", "60%"],
          m: "0 auto",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: ["column"],
            alignItems: ["start", "start", "start"],
            p: [1, 2, 4],
            textAlign: ["start", "start", "start"],
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: ["row", "row", "column"] }}
          >
            <Typography
              sx={{
                fontSize: ["20px", "26px", "28px", "30px"],
                fontWeight: 700,
                color: "black",
              }}
            >
              {t("welcome.download")}
            </Typography>
            <Typography
              sx={{
                fontSize: ["20px", "26px", "28px", "30px"],
                fontWeight: 700,
                color: "black",
                ml: ["4px", "4px", 0],
              }}
            >
              POMPKINS{" "}
              <Typography
                component={"span"}
                sx={{
                  fontSize: ["20px", "26px", "20px", "22px"],
                  fontWeight: [700, 700, 500],
                }}
              >
                {t("welcome.downloadToday")}
              </Typography>
            </Typography>
          </Box>
          <Typography
            sx={{ fontSize: ["14px", "16px", "18px", "20px"], color: "black" }}
          >
            {t("banner.body1")}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", my: 2 }}>
            <Box
              component={"img"}
              src={GooglePlay}
              alt="GooglePlay"
              title="GooglePlay"
              loading="lazy"
              onClick={() => {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.prompkinsapp&hl=th&pli=1",
                  "_blank"
                );
              }}
              sx={{
                width: ["80px", "100px", "150px", "150px"],
                cursor: "pointer",
              }}
            />
            <Box
              component={"img"}
              src={AppStore}
              alt="AppStore"
              title="AppStore"
              loading="lazy"
              onClick={() => {
                window.open(
                  "https://apps.apple.com/th/app/pompkins-%E0%B8%9E-%E0%B8%AD%E0%B8%A1%E0%B8%9B-%E0%B8%81-%E0%B8%99%E0%B8%AA/id6479531244?l=th",
                  "_blank"
                );
              }}
              sx={{
                width: ["80px", "100px", "150px", "150px"],
                cursor: "pointer",
              }}
            />
          </Box>
        </Box>
        <Box
          component={"img"}
          src={DownloadNowImg}
          alt="AdsPhone"
          title="AdsPhone"
          loading="lazy"
          sx={{
            position: ["absolute", "absolute", "relative"],
            width: ["100px", "150px", "300px"],
            mt: ["30px", "50px", "0"],
            mr: ["-230px", "-300px", "0"],
            mb: ["-10px", "-30px", "0"],
          }}
        />
      </Box>
    </Box>
  );
}

export default AdsSection;
