import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import TextInput from "../../components/TextInput";
import { useAuthStore } from "../../stores/useAuthStore";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { myProfileScheme } from "../../libs/authScheme";
import useModalLoading from "../../stores/useModalLoading";
import { putUser } from "../../libs/profileAPi/updateUser";

function MyProfileBox() {
  const { t } = useTranslation();
  const { user, updateUser } = useAuthStore();
  const { openModal, closeModal } = useModalLoading();
  const {
    handleSubmit,
    formState: { isDirty, errors },

    register,
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      phone: "",
    },
    values: {
      first_name: user.first_name,
      last_name: user.last_name,
      phone: user.phone,
    },

    mode: "onChange",
    resolver: yupResolver(myProfileScheme),
  });
  const onSubmit = (data) => {
    openModal();
    const body = {
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
      email: user.email,
      uuid: user.uuid,
    };
    putUser(body).then(() => {
      updateUser(data);
    });
    closeModal();
  };

  return (
    <Box sx={{ width: ["90%", "70%", "550px"] }}>
      <Box sx={{ display: "flex", justifyContent: "start" }}>
        <Typography fontSize={"18px"} fontWeight={700}>
          {t("profile.myProfile")}
        </Typography>
      </Box>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          mt: 4,
          display: "flex",

          flexDirection: "column",
          gap: 2,
        }}
      >
        <TextInput
          label={t("registration.fnameLabel")}
          placeholder={t("registration.fnamePlaceholder")}
          error={errors.first_name?.message}
          {...register("first_name")}
          InputLabelProps={{ shrink: true }}
        />
        <TextInput
          label={t("registration.lnameLabel")}
          placeholder={t("registration.lnamePlaceholder")}
          error={errors.last_name?.message}
          {...register("last_name")}
          InputLabelProps={{ shrink: true }}
        />
        <TextInput
          label={t("profile.phoneLabel")}
          placeholder={t("profile.phonePlaceholder")}
          error={errors.phone?.message}
          {...register("phone")}
          InputLabelProps={{ shrink: true }}
        />
        <Box sx={{ display: "flex", justifyContent: "start", mb: 3 }}>
          <Button type="submit" variant="contained" disabled={!isDirty}>
            {t("btn.save")}
          </Button>
        </Box>
        <Divider flexItem />
      </Box>
    </Box>
  );
}

export default MyProfileBox;
