import React, { useEffect, useState } from "react";
import PackageComponent from "../Welcome/PackageComponent";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import ReactGA from "react-ga4";
import packageImg from "../../assets/images/package image.png";
import GooglePlay from "../../assets/images/google-play.png";
import AppStore from "../../assets/images/app-store.png";
import { useMobile } from "../../stores/useMobile";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

function PackagePage() {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/pompkins/package",
      title: "PackagePage",
    });
  }, []);
  const { isMobile } = useMobile();
  return (
    <>
      <Helmet>
        <title>
          {t("pompkins")} - {t("welcome.packageHeader")}
        </title>
        <meta
          name="description"
          content="แอปเดียว ครบ ง่าย คุ้ม เปลี่ยนทุกความไม่ง่ายในชีวิต ให้ง่ายขึ้นด้วยแอปเดียว POS สั่งอาหาร ชำระเงิน จัดการพนักงาน ดูยอดขาย ดาวน์โหลด ฟรี ได้วันนี้"
        />
        <link
          rel="canonical"
          href={`https://pompkins.com${window.location.pathname}`}
        />
        <meta
          property="og:image"
          content="https://pompkins.com/static/media/package%20image.33753a01c53e4f4ce55d.png"
        />
        <meta
          property="og:url"
          content="https://pompkins.com/pompkins/package"
        />
        <meta
          property="og:title"
          content={`${t("pompkins")} - ${t("welcome.packageHeader")}`}
        />

        <meta name="twitter:creator" content="POMPKINS" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content="https://pompkins.com/static/media/package%20image.33753a01c53e4f4ce55d.png"
        />
        <meta
          name="twitter:url"
          content="https://pompkins.com/pompkins/package"
        />
        <meta
          name="twitter:title"
          content={`${t("pompkins")} - ${t("welcome.packageHeader")}`}
        />
      </Helmet>
      <Box
        sx={{
          backgroundColor: "white",
          minHeight: "100vh",
          p: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          background:
            "linear-gradient(104.23deg, #FFFEFC 8.55%, #FCEDDC 104.67%, #FCE3BC 182.39%)",
          textAlign: "center",
        }}
      >
        {isMobile && (
          <Box
            sx={{
              display: "flex",
              gap: 1,
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              mb: 10,
              mt: 2,
            }}
          >
            <Button
              onClick={() => setActiveIndex(0)}
              sx={{
                color: activeIndex === 0 ? " white" : "black",
                borderRadius: "20px",
                p: 0,
                height: "24px",
                fontWeight: 300,
                background:
                  activeIndex === 0 &&
                  "linear-gradient(103.72deg, #F9881F 22.76%, #FFBF7A 78.22%)",
              }}
            >
              Starter
            </Button>
            <Button
              onClick={() => setActiveIndex(1)}
              sx={{
                color: activeIndex === 1 ? " white" : "black",
                borderRadius: "20px",
                p: 0,
                height: "24px",
                fontWeight: 300,
                background:
                  activeIndex === 1 &&
                  "linear-gradient(103.72deg, #F9881F 22.76%, #FFBF7A 78.22%)",
              }}
            >
              Basic
            </Button>
            <Button
              onClick={() => setActiveIndex(2)}
              sx={{
                color: activeIndex === 2 ? " white" : "black",
                borderRadius: "20px",
                p: 0,
                height: "24px",
                fontWeight: 300,
                background:
                  activeIndex === 2 &&
                  "linear-gradient(103.72deg, #F9881F 22.76%, #FFBF7A 78.22%)",
              }}
            >
              Plus
            </Button>
            <Button
              onClick={() => setActiveIndex(3)}
              sx={{
                color: activeIndex === 3 ? " white" : "black",
                borderRadius: "20px",
                p: 0,
                height: "24px",
                fontWeight: 300,
                background:
                  activeIndex === 3 &&
                  "linear-gradient(103.72deg, #F9881F 22.76%, #FFBF7A 78.22%)",
              }}
            >
              Max
            </Button>
          </Box>
        )}
        <Typography
          variant="h1"
          sx={{
            fontSize: ["30px", "34px", "40px"],
            color: "#FF961B",
            fontWeight: 600,
            mt: 2,
          }}
        >
          {t("package.title")}
        </Typography>
        <Typography sx={{ fontSize: ["24px", "28px", "32px"], mt: 2, mb: 10 }}>
          {t("package.subTitle")}
        </Typography>
        <PackageComponent
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        />
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Box
            sx={{
              background:
                "linear-gradient(93.02deg, #FFF1DD 8.1%, #FFDEBA 102.69%)",
              display: "flex",
              justifyContent: "space-evenly",
              flexDirection: ["column-reverse", "column-reverse", "row"],
              alignItems: "center",
              borderRadius: "20px",

              width: ["100%", "70%", "80%"],
              px: 2,
            }}
          >
            <Box
              component="img"
              src={packageImg}
              alt="packageImg"
              loading="lazy"
              title="packageImg"
              sx={{ width: ["90%", "90%", "40%"], pt: 3 }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 2,
                width: ["100%", "100%", "50%"],
                textAlign: ["center", "center", "start"],
                alignItems: ["center", "center", "start"],
              }}
            >
              {isMobile ? (
                <>
                  <Typography sx={{ fontSize: "28px", fontWeight: 500 }}>
                    {t("package.trial1")}
                  </Typography>
                  <Typography sx={{ fontSize: "28px", fontWeight: 500 }}>
                    {t("package.trial2")}{" "}
                  </Typography>
                </>
              ) : (
                <Typography sx={{ fontSize: "28px", fontWeight: 500 }}>
                  {t("package.trial3")}
                </Typography>
              )}

              <Typography sx={{ fontSize: "32px", fontWeight: 700 }}>
                {t("package.free1month")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  my: 2,
                  gap: 2,
                  justifyContent: "center",
                }}
              >
                <Box
                  component={"img"}
                  src={GooglePlay}
                  onClick={() => {
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.prompkinsapp&hl=th&pli=1",
                      "_blank"
                    );
                  }}
                  alt="GooglePlay"
                  title="GooglePlay"
                  loading="lazy"
                  sx={{
                    width: ["50%", "100px", "120px"],
                    cursor: "pointer",
                  }}
                />
                <Box
                  component={"img"}
                  src={AppStore}
                  onClick={() => {
                    window.open(
                      "https://apps.apple.com/th/app/pompkins-%E0%B8%9E-%E0%B8%AD%E0%B8%A1%E0%B8%9B-%E0%B8%81-%E0%B8%99%E0%B8%AA/id6479531244?l=th",
                      "_blank"
                    );
                  }}
                  alt="AppStore"
                  title="AppStore"
                  loading="lazy"
                  sx={{
                    width: ["50%", "100px", "120px"],
                    cursor: "pointer",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default PackagePage;
