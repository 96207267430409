import { Button, ListItemIcon, Menu, MenuItem } from "@mui/material";
import React, { useRef, useState } from "react";
import { useGuestLogin } from "../../stores/useGuestLogin";
import Options from "../../utils/options";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  PermIdentityOutlined,
} from "@mui/icons-material";
import { styleMenu } from "../Styles/StyledMenu";

function GuestAccountMenu() {
  const { guest_user, guestLogout } = useGuestLogin();
  const accoutRef = useRef(null);
  const [isAccountOpen, setIsAccountOpen] = useState(false);
  const { guestOptions } = Options();
  const { t } = useTranslation();
  const navigator = useNavigate();
  const handleMenuClick = (path) => {
    setIsAccountOpen(false);
    if (path === "logout") {
      guestLogout();
      localStorage.removeItem("guest");
    } else {
      navigator(path);
    }
  };
  return (
    <>
      <Button
        aria-controls="account-menu"
        aria-haspopup="true"
        className="text-gray-700"
        onClick={() => {
          setIsAccountOpen((prev) => !prev);
        }}
        ref={accoutRef}
        startIcon={<PermIdentityOutlined />}
        sx={{
          ":hover": {
            backgroundColor: "#fff8f0",
          },
        }}
        endIcon={
          isAccountOpen ? (
            <KeyboardArrowUp className="text-primary " />
          ) : (
            <KeyboardArrowDown className="text-primary" />
          )
        }
      >
        {guest_user?.name}
      </Button>
      <Menu
        id="language-menu"
        anchorEl={accoutRef.current}
        keepMounted
        open={isAccountOpen}
        onClose={() => setIsAccountOpen(false)}
        slotProps={styleMenu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {guestOptions.map((option) => (
          <div key={option.label}>
            <MenuItem onClick={() => handleMenuClick(option.path)}>
              <ListItemIcon>{option.icon}</ListItemIcon>
              {option.label}
            </MenuItem>
          </div>
        ))}
      </Menu>
    </>
  );
}

export default GuestAccountMenu;
