import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  Typography,
  Rating,
  IconButton,
  LinearProgress,
  Chip,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { getReviewByMerchantUUID } from "../../libs/merchantAPI/merchantAPI";
import { useTranslation } from "react-i18next";
import { Close, Star } from "@mui/icons-material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"; // Import the fromNow plugin
dayjs.extend(relativeTime);
ReviewModal.defaultProps = {
  open: false,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

function ReviewModal({ uuid, open, handleClose, isMobile, rating }) {
  const { t } = useTranslation();

  const [displayReviews, setDisplayReviews] = useState([]);
  const [pointPercentages, setPointPercentages] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  });

  const fetchReviews = useCallback(() => {
    getReviewByMerchantUUID(uuid)
      .then((res) => {
        const newPointCounts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
        res.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        res.data.forEach((item) => {
          newPointCounts[item.point.toString()]++;
        });

        const totalCount = res.data.length;
        const newPointPercentages = {};
        for (const [point, count] of Object.entries(newPointCounts)) {
          newPointPercentages[point] = (count / totalCount) * 100;
        }

        setPointPercentages(newPointPercentages);
        setDisplayReviews(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [uuid]);

  useEffect(() => {
    if (open) {
      fetchReviews();
      // setDisplayReviews(reviewMockData.reviews)
    }
  }, [open, fetchReviews]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          width: "90%",
          maxWidth: "700px",
          minHeight: "70vh",
          backgroundColor: "#f8f9fa",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        },
      }}
    >
      <IconButton
        color="inherit"
        onClick={handleClose}
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <Close sx={{ color: "#FF961B" }} />
      </IconButton>
      <DialogTitle className="font-bold" sx={{ backgroundColor: "white" }}>
        {t("merchant.reviewModalTitle")}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: "white",
            p: 2,
            mt: 2,
            borderRadius: "15px",
            border: "1px solid #e7e5e5",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "60%",
            }}
          >
            {Object.entries(pointPercentages).map(
              ([point, percentage], index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <Typography sx={{ fontSize: "14px" }}>{point}</Typography>
                  <Star
                    sx={{
                      color: "rgb(250, 175, 0)",
                      fontSize: "20px",
                      marginTop: "-2px",
                      fontSize: "14px",
                    }}
                  />
                  <Box sx={{ width: "100%", mr: 1 }}>
                    <LinearProgress
                      variant="determinate"
                      value={percentage}
                      sx={{
                        backgroundColor: "#ebebeb",
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "#FF961B",
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <Typography fontSize={"14px"} color="text.secondary">
                      {`${Math.round(percentage)}%`}
                    </Typography>
                  </Box>
                </Box>
              )
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "40%",
              alignItems: "center",
            }}
          >
            <Typography fontSize={"32px"} fontWeight={500} alignSelf={"center"}>
              {Number(rating) ?? 0}/5
            </Typography>
            <Rating
              name="read-only"
              className="text-primary"
              value={Number(rating) ?? 0}
              precision={0.5}
              sx={{ fontSize: "16px", mt: "-10px" }}
              readOnly
            />
            <Typography fontSize={"12px"} alignSelf={"center"}>
              ({displayReviews.length ?? 0} รีวิว)
            </Typography>
          </Box>
        </Box>
        {/* <Box sx={{ mt: 2 }}>
          <Chip
            label="ดูทั้งหมด"
            sx={{ backgroundColor: "#FF961B", color: "white" }}
          />
          <Chip label="1★" sx={{ backgroundColor: "white" }} />
          <Chip label="2★" sx={{ backgroundColor: "white" }} />
          <Chip label="3★" sx={{ backgroundColor: "white" }} />
          <Chip label="4★" sx={{ backgroundColor: "white" }} />
          <Chip label="5★" sx={{ backgroundColor: "white" }} />
        </Box> */}
        {displayReviews.length > 0 ? (
          displayReviews.map((review) => (
            <Box
              key={review.id}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                justifyContent: "center",
                px: !isMobile && 2,
                backgroundColor: "white",
                my: 1.5,
                p: 2,
                border: "1px solid #e7e5e5",
                borderRadius: "15px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Rating
                    sx={{ right: 5, mb: 1, flex: 1 }}
                    value={Number(review.point)}
                    readOnly
                  />
                  <Typography variant="body2" color="grey" sx={{ mb: 0.5 }}>
                    {/* {new Date(review.created_at).toLocaleDateString()} */}
                    {dayjs(review.created_at).fromNow()}
                  </Typography>
                </Box>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  {review.customer_name.charAt(0)}****
                </Typography>
              </Box>
              <Typography variant="body1" color="grey">
                {review.customer_comment}
              </Typography>
              {review.bmerchant_comment !== null && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    justifyContent: "center",
                    bgcolor: "#F1F1F1",
                    px: 3.5,
                    py: 2,
                    borderRadius: 10,
                    mt: 1.5,
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: 700 }}>
                    {t("merchant.bmerchantComment")}:
                  </Typography>
                  <Typography variant="body1">
                    {review.bmerchant_comment}
                  </Typography>
                </Box>
              )}
            </Box>
          ))
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              mt: 2,
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 700 }} color="grey">
              {t("merchant.noReview")}
            </Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default ReviewModal;
