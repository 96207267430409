import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState, useCallback, useEffect } from "react";
import FoodCard from "../../components/FoodCards";
import { useTranslation } from "react-i18next";
import { Favorite } from "@mui/icons-material";
import FavImage from "../../assets/image/Favorite-img.png";
import { useNavigate } from "react-router-dom";
import {
  getBMerchant,
  getMerchant,
  getRatingByMerchantUUID,
} from "../../libs/merchantAPI/merchantAPI";
import { useAuthStore } from "../../stores/useAuthStore";
import LoadingScreen from "../../components/LoadingScreen";
import { DistanceCalculator } from "../../utils/calDistance";
import { useLocationStore } from "../../stores/useLocationStore";
import { isMerchantOpen } from "../../libs/isMerchantOpen";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";

function FavoritePage() {
  const { user } = useAuthStore();
  const [isFetch, setIsFetch] = useState(false);
  const { currentLocation } = useLocationStore();
  const [favoriteBMerchant, setFavoriteBMerchant] = useState();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/favorite",
      title: "FavoritePage",
    });
  }, []);
  // const fetchMerchant = useCallback(() => {
  //   getMerchant()
  //     .then((res) => {
  //       getBMerchant().then(({ data }) => {
  //         console.log(data);
  //         const favoriteItems = data.filter((items) =>
  //           user.favorite.includes(items.id)
  //         );
  //         const merchantData = mapData(favoriteItems);
  //         console.log(merchantData);
  //         setFavoriteBMerchant(merchantData);
  //       });
  //     })

  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, [user.favorite]);

  const fetchMerchant = useCallback(() => {
    getMerchant()
      .then((res) => {
        getBMerchant().then(({ data }) => {
          const favoriteBMerchant = data.filter((items) =>
            user.favorite.includes(items.id)
          );
          const favoriteMerchant = res.data
            .filter((merchant) =>
              favoriteBMerchant.some((info) => merchant.id === info.merchant_id)
            )
            .map((merchant) => ({
              ...merchant,
              bmerchantData: favoriteBMerchant.find(
                (info) => merchant.id === info.merchant_id
              ),
            }));
          mapData(favoriteMerchant)
            .then((sortedData) => {
              setFavoriteBMerchant(sortedData);
            })

            .catch((error) => {});
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsFetch(true);
      });
  }, []);

  const mapData = (data) => {
    const newData = []; // Create a copy of the original data

    const locationPromises = data.map(async (item) => {
      if (item.bmerchantData.latitude) {
        const location = {
          lat: item.bmerchantData.latitude,
          lng: item.bmerchantData.longitude,
        };
        const { data: rating } = await getRatingByMerchantUUID(item.uuid);
        const { distance, time } = await DistanceCalculator(
          currentLocation,
          location
        );
        item.rating = rating.average_rating;
        item.bmerchantId = item.bmerchantData.id;
        console.log(item);
        item.location = item.bmerchantData.location;
        item.distance = distance;
        item.isOpen = isMerchantOpen(
          item.bmerchantData.open_time,
          item.bmerchantData.close_time
        );
        item.time = time;
        newData.push(item);
      }
    });
    return Promise.all(locationPromises)
      .then(() => {
        const mappedData = newData.map((item) => ({
          id: item.id,
          bmerchantId: item.bmerchantId,
          location: item.location,
          title: item.name,
          img: item.logo,
          isOpen: item.isOpen,
          category: item.business_type,
          rating: item.rating ?? 0,
          distance: `${item.distance ?? "2"} Km`,
          time: item.time ?? 20,
          shopType:
            item.shop_type === ""
              ? "ร้านอาหารทั่วไป"
              : item.shop_type || "ร้านอาหารทั่วไป",
          features: [],
        }));

        // Define a custom sorting function based on the "distance" property
        const sortByDistance = (a, b) => {
          // Extract the numerical distance values (remove " Km" and convert to number)
          const distanceA = parseFloat(a.distance.replace(" Km", ""));
          const distanceB = parseFloat(b.distance.replace(" Km", ""));

          // Compare the distances
          return distanceA - distanceB;
        };

        const sortByIsOpen = (a, b) => {
          if (a.isOpen && !b.isOpen) {
            return -1;
          }
          if (!a.isOpen && b.isOpen) {
            return 1;
          }
          return 0;
        };
        const sortedDistanceData = mappedData.sort(sortByDistance);
        const sortedData = sortedDistanceData.sort(sortByIsOpen);
        return sortedData;
      })
      .catch((error) => {
        console.error("Error in mapData:", error);
        return [];
      });
  };

  useEffect(() => {
    if (!isFetch) {
      fetchMerchant();
    }
  }, [fetchMerchant, isFetch]);

  const { t } = useTranslation();
  const navigator = useNavigate();
  if (!isFetch) {
    return <LoadingScreen />;
  }
  return (
    <>
      <Helmet>
        <title>
          {t("pompkins")} - {t("favorite.page")}
        </title>
        <meta
          name="description"
          content="แอปเดียว ครบ ง่าย คุ้ม เปลี่ยนทุกความไม่ง่ายในชีวิต ให้ง่ายขึ้นด้วยแอพเดียว POS สั่งอาหาร ชำระเงิน จัดการพนักงาน ดูยอดขาย ดาวน์โหลด ฟรี ได้วันนี้"
        />
        <link
          rel="canonical"
          href={`https://pompkins.com${window.location.pathname}`}
        />
        <meta property="og:url" content="https://pompkins.com/favorite" />
      </Helmet>
      <Box sx={{ px: [2, 8, 12], py: 4 }}>
        <Typography variant="h5" mb={2}>
          {t("fav.favTitle")}
        </Typography>

        <Grid
          container
          spacing={3}
          sx={{
            m: 0,
          }}
        >
          {favoriteBMerchant &&
            favoriteBMerchant.map((item, index) => (
              <FoodCard
                items={item}
                key={index}
                sx={{
                  maxWidth: [
                    "calc(100% - 24px)",
                    "calc(50% - 24px)",
                    "calc(33.3333% - 24px)",
                    "calc(25% - 24px)",
                  ],
                  width: [
                    "calc(100% - 24px)",
                    "calc(50% - 24px)",
                    "calc(33.3333% - 24px)",
                    "calc(25% - 24px)",
                  ],
                  mr: "18px",
                }}
              />
            ))}
        </Grid>
        {(!favoriteBMerchant || favoriteBMerchant.length === 0) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <img
              src={FavImage}
              alt="fav-img"
              title="fav-img"
              loading="lazy"
              width="159px"
              height="159px"
              style={{ height: "159px", width: "159px" }}
            />
            <Typography fontSize="18px" fontWeight={600} sx={{ mt: "32px" }}>
              {t("fav.noFavSaved")}
            </Typography>
            <Typography sx={{ pt: "8px" }}>
              {t("fav.noFavDescription")}
            </Typography>
            <Box
              sx={{
                boxShadow: 3,
                borderRadius: "50%",
                height: "24px",
                width: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Favorite
                className="text-primary"
                sx={{ height: "16px", width: "16px" }}
              />
            </Box>
            <Button
              variant="outlined"
              sx={{ mt: "24px" }}
              onClick={() => navigator("/food")}
            >
              {t("fav.letFindFavBtn")}
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
}

export default FavoritePage;
