import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import TextInput from "../../components/TextInput";
import { useAuthStore } from "../../stores/useAuthStore";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { emailScheme } from "../../libs/authScheme";
import useModalLoading from "../../stores/useModalLoading";
import { putUser } from "../../libs/profileAPi/updateUser";
function EmailBox() {
  const { t } = useTranslation();
  const { user, updateUser } = useAuthStore();
  const { openModal, closeModal } = useModalLoading();
  const {
    handleSubmit,
    formState: { isDirty, errors },

    register,
  } = useForm({
    defaultValues: {
      email: "",
    },
    values: { email: user.email },

    mode: "onChange",
    resolver: yupResolver(emailScheme),
  });
  const onSubmit = (data) => {
    openModal();
    updateUser(data);
    const body = {
      first_name: user.first_name,
      last_name: user.last_name,
      phone: user.phone,
      email: data.email,
      uuid: user.uuid,
    };
    putUser(body);
    closeModal();
  };
  return (
    <Box sx={{ width: ["90%", "70%", "550px"] }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          mt: 4,
        }}
      >
        <Typography fontSize={"18px"} fontWeight={700}>
          {t("profile.email")}
        </Typography>
      </Box>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          mt: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <TextInput
          label={t("emailStep.label")}
          placeholder={t("emailStep.placholder")}
          error={errors.email?.message}
          {...register("email")}
          InputLabelProps={{ shrink: true }}
        />
        <Box sx={{ display: "flex", justifyContent: "start", mb: 3 }}>
          <Button type="submit" variant="contained" disabled={!isDirty}>
            {t("btn.save")}
          </Button>
        </Box>
        <Divider flexItem />
      </Box>
    </Box>
  );
}

export default EmailBox;
