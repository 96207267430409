import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import ListComponent from "./ListComponent";
import { useNavigate } from "react-router-dom";

function SubListComponent({ list, setState }) {
  const navigator = useNavigate();

  return (
    <>
      <Box
        onClick={() => setState("")}
        sx={{
          display: "flex",
          p: 2,
          gap: 2,
          cursor: "pointer",
          ":hover": {
            color: "#f9931f",
            transition: " color 0.2s",
          },
        }}
      >
        <Typography fontSize={"18px"} fontWeight={600}>
          {"<"}
        </Typography>
        <Typography fontSize={"18px"} fontWeight={600}>
          กลับ
        </Typography>
      </Box>
      <Divider />
      {list.map((item, index) => (
        <div key={index}>
          <ListComponent
            title={item.title}
            onClick={() => navigator(item.navigate)}
          />
          <Divider />
        </div>
      ))}
    </>
  );
}

export default SubListComponent;
