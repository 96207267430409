import React, { useEffect, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import PastOrderItem from "../../components/order/PastOrderItem";
import ActiveOrderItem from "../../components/order/ActiveOrderItem";
import {
  getOrders,
  getOrders_history,
  getOrdersByOrders_uuid,
} from "../../libs/customerAPI/getOrders";
import { useAuthStore } from "../../stores/useAuthStore";
import { getBMerchant } from "../../libs/merchantAPI/merchantAPI";
import LoadingScreen from "../../components/LoadingScreen";
import FoodTiles from "../../components/FoodTiles";
import { useLocationStore } from "../../stores/useLocationStore";
import PastOrderTiles from "./PastOrderTiles";
import { getAddressFromCoordinates } from "../../utils/convertAddress";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { useGuestLogin } from "../../stores/useGuestLogin";

function OrderPage() {
  const { user } = useAuthStore();
  const { isGuest } = useGuestLogin();
  const { t } = useTranslation();
  const { currentLocation } = useLocationStore();
  const [bMerchantData, setBMerchantData] = useState([]);
  const [isFetch, setIsFetch] = useState(false);
  const [orders, setOrders] = useState([]); // State to store the orders
  const [orders_history, setOrders_history] = useState([]); // State to store the orders
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/order",
      title: "OrderPage",
    });
  }, []);
  // useEffect(() => {
  //   if (!currentLocation) {
  //     fetchCurrentLocation();
  //     const address = getAddressFromCoordinates(currentLocation);
  //     setCurrentAddress(address);
  //     console.log(address);
  //   }
  // }, [currentLocation, fetchCurrentLocation, setCurrentAddress]);
  useEffect(() => {
    setIsFetch(false);
    getBMerchant()
      .then((res) => {
        setBMerchantData(res.data);
        setIsFetch(true);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await getOrders(user.uuid);
        setOrders(response.data); // Update state with fetched data
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };
    const fetchGuestOrders = async () => {
      const guestOrderList = localStorage.getItem("guest_order");
      if (guestOrderList) {
        const guestOrders = JSON.parse(guestOrderList);
        let orders = [];
        for (const orderUuid of guestOrders) {
          const response = await getOrdersByOrders_uuid(orderUuid);
          console.log(response.data);
          orders.push(response.data);
        }
        setOrders(orders);
      }
    };

    if (!isGuest) {
      fetchOrders();
    } else {
      fetchGuestOrders();
    }
  }, []);

  useEffect(() => {
    const fetchOrders_history = async () => {
      try {
        const response = await getOrders_history(user.uuid);
        setOrders_history(response.data); // Update state with fetched data
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };
    fetchOrders_history();
  }, []);
  // Since your data uses 'order status' instead of 'status', update the filtering logic
  const activeOrders =
    orders?.filter((order) =>
      ["PENDING", "PURCHASED", "MERCHANT CONFIRMED"].includes(
        order["order_status"]
      )
    ) ?? [];
  const filterOrders = (orders) =>
    orders?.filter((order) =>
      ["SUCCESS", "CANCELLED"].includes(order["order_status"])
    );

  const pastOrders =
    orders_history && orders_history.length > 0
      ? filterOrders(orders_history)
      : filterOrders(orders);
  if (!isFetch) {
    return <LoadingScreen />;
  }
  return (
    <>
      <Helmet>
        <title>
          {t("pompkins")} - {t("order.page")}
        </title>
        <meta
          name="description"
          content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
        />
        <link
          rel="canonical"
          href={`https://pompkins.com${window.location.pathname}`}
        />
        <meta property="og:url" content="https://pompkins.com/order" />
      </Helmet>
      <Box
        sx={{
          maxWidth: 1440,
          margin: "auto",
          padding: { xs: "0% 5%", sm: "0% 10%", md: "2% 24%" },
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" pt={2} pb={1}>
              {t("order.activeOrder")}
            </Typography>
            {activeOrders?.length ? (
              activeOrders.map((order, index) => (
                // Use "order no" as the key since "order Number" is not a field in your data
                <ActiveOrderItem
                  key={index}
                  order={order}
                  bMerchantData={bMerchantData}
                />
              ))
            ) : (
              <Typography>{t("order.noActive")}</Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" pt={1} pb={1}>
              {t("order.pastOrder")}
            </Typography>
            {pastOrders?.length ? (
              pastOrders.map((order, index) => (
                <PastOrderTiles
                  key={index}
                  order={order}
                  // currentLocation={currentLocation}
                  bMerchantData={bMerchantData}
                />
              ))
            ) : (
              <Typography>{t("order.nopastOrder")}</Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default OrderPage;
