import React from "react";
import { Dialog, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

function ModalImage({ open, onClose, imageUrl }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        width: "auto",
        height: "auto",
        "& .MuiDialog-paper": {
          borderRadius: "20px",
        },
      }}
    >
      <IconButton
        color="inherit"
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <Close sx={{ color: "#FF961B" }} />
      </IconButton>
      <img
        src={imageUrl}
        alt="menu img"
        title="menu img"
        loading="lazy"
        width="auto"
        height="auto"
      />
    </Dialog>
  );
}

export default ModalImage;
