import { Box, Button, Dialog, Typography } from "@mui/material";
import React from "react";
import Warnning from "../../assets/images/emoji_pomkins_exclamation320.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
function MerchantNoMenu({ open = false }) {
  const navigator = useNavigate();
  const { t } = useTranslation();
  return (
    <Dialog open={open}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          m: 3,
          px: [5, 10, 15],
        }}
      >
        <img
          src={Warnning}
          alt="no-menu"
          title="no-menu"
          loading="lazy"
          width="100px"
          height="100px"
          style={{ width: "100px", height: "100px" }}
        />
        <Typography sx={{ fontWeight: 500, my: 2 }}>
          {t("merchant.noMenu")}
        </Typography>
        <Button variant="outlined" onClick={() => navigator("/food")}>
          {t("merchant.backToHomePage")}
        </Button>
      </Box>
    </Dialog>
  );
}

export default MerchantNoMenu;
