import { Box, Skeleton, Typography } from "@mui/material";
import React from "react";

function CuisineCards({ items }) {
  return (
    <Box sx={{ py: 1 }}>
      <Box
        key={items.title}
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          width: 110,
          height: 110,
          borderRadius: 5,
          overflow: "hidden",
          pt: "10px",
          boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography fontWeight={500}> {items.title}</Typography>
        </Box>

        {items ? (
          <img
            src={items.image}
            alt={items.title}
            title={items.title}
            loading="lazy"
            width="110px"
            height="110px"
            style={{
              width: 110,
              height: 110,
              objectFit: "contain",
            }}
          />
        ) : (
          <Skeleton variant="rounded" width={110} height={110} />
        )}
      </Box>
    </Box>
  );
}

export default CuisineCards;
