function degreesToRadians(degrees) {
  return degrees * (Math.PI / 180);
}

function haversineDistance(lat1, lon1, lat2, lon2) {
  const R = 6371; // Earth's radius in km
  const dLat = degreesToRadians(lat2 - lat1);
  const dLon = degreesToRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degreesToRadians(lat1)) *
      Math.cos(degreesToRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance.toFixed(2);
}

function estimateTravelTime(distance) {
  // Assuming an average speed of 50 km/h
  const estimatedTime = Math.ceil((distance / 50) * 60);
  return estimatedTime;
}

export function DistanceCalculator(location1, location2) {
  const distance = haversineDistance(
    parseFloat(location1.lat),
    parseFloat(location1.lng),
    parseFloat(location2.lat),
    parseFloat(location2.lng)
  );
  const time = estimateTravelTime(distance);
  return { distance, time };
}

export async function DistanceCalculatorDelivery(location1, location2) {
  const origin = new window.google.maps.LatLng(
    parseFloat(location1.lat),
    parseFloat(location1.lng)
  );
  const destination = new window.google.maps.LatLng(
    parseFloat(location2.lat),
    parseFloat(location2.lng)
  );

  const directionsService = new window.google.maps.DirectionsService();

  const request = {
    origin: origin,
    destination: destination,
    travelMode: window.google.maps.TravelMode.DRIVING,
    unitSystem: window.google.maps.UnitSystem.METRIC,
  };

  const result = await new Promise((resolve, reject) => {
    directionsService.route(request, (result, status) => {
      if (status === "OK") {
        resolve(result);
      } else {
        reject(status);
      }
    });
  });

  const route = result.routes[0];
  const distanceInMeters = route.legs[0].distance.value;
  // In km
  const distance = (distanceInMeters / 1000).toFixed(2);
  const timeInSeconds = route.legs[0].duration.value;
  // In minutes
  const time = Math.ceil(timeInSeconds / 60);

  return { distance, time };
}

// const distanceInKm =
//   window.google.maps.geometry.spherical.computeDistanceBetween(
//     origin,
//     destination
//   ) / 1000; // Convert meters to kilometers

// distance = distanceInKm.toFixed(2) ?? "2 Km";

// directionsService.route(
//   {
//     origin,
//     destination,
//     travelMode: "DRIVING", // You can change this to other travel modes like 'WALKING' or 'BICYCLING'
//   },
//   (response, status) => {
//     if (status === "OK") {
//       const route = response.routes[0];
//       const leg = route.legs[0];
//       const travelTimeInSeconds = leg.duration.value; // Travel time in seconds
//       const travelTimeInMinutes = Math.ceil(travelTimeInSeconds / 60); // Convert to minutes
//       time = travelTimeInMinutes;
//     } else {
//     }
//   }
// );
