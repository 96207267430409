import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import PrompkinsIcon from "../assets/images/logo_large_size.png";
import orderWithGoogleIcon from "../assets/images/order_with_google_lockup_horizontal.png";
import PompkinsGoogleIcon from "../assets/images/pompkins_google.png";
import PompkinsFoodGoogleIcon from "../assets/images/pompkins_food_google.png";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { FavoriteBorder, ShoppingCartOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { useMobile } from "../stores/useMobile";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "../stores/useAuthStore";
import LanguageMenu from "./layoutComponents/LanguageMenu";
import AccountMenu from "./layoutComponents/AccountMenu";
import LayoutMobile from "./LayoutMobile";
import { useLocationStore } from "../stores/useLocationStore";
import { useGuestLogin } from "../stores/useGuestLogin";
import GuestAccountMenu from "./layoutComponents/GuestAccountMenu";
import { useAccess } from "../stores/useAccess";
import LogoNav from "./layoutComponents/LogoNav";
import LocationNav from "./layoutComponents/LocationNav";
import NavigationMenu from "./layoutComponents/NavigationMenu";
import AuthButtons from "./layoutComponents/AuthButtons";
import CartFavButtons from "./layoutComponents/CartFavButtons";
import defaultFavicon from "../../public/favicon.ico";
import { Helmet } from "react-helmet-async";

function Layout() {
  const { isAuthenticated, setLocationFrom } = useAuthStore();
  const { isGuest } = useGuestLogin();
  const { setCanAccess } = useAccess();

  const { currentAddress, openModal } = useLocationStore();
  const location = useLocation();
  const { isMobile } = useMobile();
  const { t } = useTranslation();
  const navigator = useNavigate();
  const whoAmI = sessionStorage.getItem("whoAmI");

  const handleFindRestaurants = () => {
    if (!currentAddress) {
      openModal();
    } else {
      navigator("/food");
    }
  };
  return (
    <>
      <Helmet>
        <link rel="image/png" href={defaultFavicon} sizes="any" />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={defaultFavicon}
        />
        <link rel="apple-touch-icon" sizes="180x180" href={defaultFavicon} />
      </Helmet>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="sticky" className="bg-white" sx={{ width: "100%" }}>
          <Toolbar>
            {isMobile ? (
              <LayoutMobile />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  gap: 0.5,
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Link to="/">
                  <LogoNav logoSrc={PompkinsGoogleIcon} />
                </Link>
                <Box
                  sx={{
                    width: "40%",
                    display: "flex",
                    justifyContent: "center",
                    flexGrow: 1,
                  }}
                >
                  <NavigationMenu
                    setCanAccess={setCanAccess}
                    navigator={navigator}
                    handleFindRestaurants={handleFindRestaurants}
                    t={t}
                  />
                </Box>
                {isAuthenticated ? (
                  <AccountMenu />
                ) : isGuest ? (
                  <GuestAccountMenu />
                ) : (
                  <>
                    <AuthButtons
                      setLocationFrom={setLocationFrom}
                      location={location}
                    />
                    <Box>
                      <CartFavButtons
                        isAuthenticated={isAuthenticated}
                        navigator={navigator}
                      />
                    </Box>
                  </>
                )}
                <LanguageMenu />
              </Box>
            )}
          </Toolbar>
        </AppBar>
        <main className="flex-grow min-h-screen">
          <Outlet />
        </main>
        {!location.pathname.includes("merchant") && <Footer />}
      </Box>
    </>
  );
}

export default Layout;
