import React from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import foodFaviconIco from "../assets/images/favicon_package_pompkins_food/favicon-32x32.png";
import largeFoodPngIcon from "../assets/images/favicon_package_pompkins_food/PKFCircle-01.png";
import appleTouchFoodIcon from "../assets/images/favicon_package_pompkins_food/apple-touch-icon.png";
import imagePompkinOG from "../assets/images/coverFoodWeb-07.png";

function MobileContainer() {
  const version = new Date().toISOString().split("T")[0];
  return (
    <>
      <Helmet>
        <meta
          property="og:image"
          content={`{${imagePompkinOG}?v=${version}}`}
        />
        <meta
          name="twitter:image"
          content={`{${imagePompkinOG}?v=${version}}`}
        />
        <link rel="image/png" href={`{${foodFaviconIco}`} sizes="any" />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={`{${largeFoodPngIcon}`}
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`{${appleTouchFoodIcon}`}
        />
      </Helmet>
      <Box sx={{ width: "100%", bgcolor: "#f9f9f9" }}>
        <Box
          sx={{
            maxWidth: "440px",
            height: "100%",
            m: "0 auto",
            bgcolor: "white",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </>
  );
}

export default MobileContainer;
