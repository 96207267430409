import * as yup from "yup";
import i18n from "i18next";

const emailValidate = yup
  .string()
  .email(() => i18n.t("validation.emailInvalid"))
  .required(() => i18n.t("validation.emailRequired"));

export const formScheme = yup.object().shape({
  email: emailValidate,
});
