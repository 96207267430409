import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useModalLoading from "../../stores/useModalLoading";
import ModalDeleteAccount from "../../components/ModalDeleteAccount";
import { useAuthStore } from "../../stores/useAuthStore";
import { deleteUser } from "../../libs/profileAPi/updateUser";

function DeleteAccountBox() {
  const [isDelete, setIsDelete] = useState(false);
  const { openModal, closeModal } = useModalLoading();
  const { user, logout } = useAuthStore();
  const handleCancelDelete = () => {
    setIsDelete(!isDelete);
  };
  const handleConfirmDelete = () => {
    openModal();
    setIsDelete(!isDelete);
    deleteUser(user.uuid);
    logout();
    localStorage.clear();
    closeModal();
  };
  const { t } = useTranslation();
  return (
    <Box sx={{ width: ["90%", "70%", "550px"] }}>
      <Box
        sx={{
          mt: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: 2,
          }}
        >
          <Typography sx={{ mb: 1 }} fontSize={"18px"} fontWeight={700}>
            {t("profile.accountManagement")}
          </Typography>
          <Typography>{t("profile.deleteAccount")}</Typography>
          <Button
            className="text-primary"
            variant="outlined"
            onClick={() => setIsDelete(!isDelete)}
            style={{
              textTransform: "none",
            }}
          >
            {t("btn.deleteAccount")}
          </Button>
        </Box>
      </Box>
      <ModalDeleteAccount
        open={isDelete}
        handleClose={handleCancelDelete}
        handleConfirm={handleConfirmDelete}
      />
    </Box>
  );
}

export default DeleteAccountBox;
