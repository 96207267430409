import {
  checkEmailMock,
  loginMock,
  logoutMock,
  refreshMock,
} from "./authMocks/loginMock";
import { registerMock } from "./authMocks/registerMock";
export const handlers = [
  loginMock,
  refreshMock,
  logoutMock,
  checkEmailMock,
  registerMock,
];
