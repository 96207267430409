import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Box } from "@mui/material";
import { useMobile } from "../../stores/useMobile";

const BannerSlider = ({ data }) => {
  const { isMobile } = useMobile();
  return (
    <Box
      data-aos={isMobile ? "fade-up" : "fade-left"}
      sx={{
        width: ["100%", "100%", "55%", "60%"],
        height: "100%",
        mt: ["50px", "50px", "0"],
        display: "flex",
        alignItems: "center",
      }}
    >
      <OwlCarousel
        className="owl-carousel owl-theme"
        loop
        dots={true}
        items={1}
        autoplaySpeed={1000}
        autoplay
        margin={10}
        // autoplayTimeout={1000}
        nav={false}
      >
        {data.map((items, index) => (
          <img
            src={items.img}
            alt={index}
            title={index}
            loading="lazy"
            key={index}
            width={"auto"}
            height={"auto"}
            style={{ width: "auto", objectFit: "contain" }}
          />
        ))}
      </OwlCarousel>
    </Box>
  );
};
export default BannerSlider;
