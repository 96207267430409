import React from "react";
import {
  Dialog,
  Button,
  Box,
  Typography,
  IconButton,
  Fade,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useGuestLogin } from "../stores/useGuestLogin";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthStore } from "../stores/useAuthStore";

GuestOrLoginModal.defaultProps = {
  open: false,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

function GuestOrLoginModal({ open, onClose }) {
  const navigator = useNavigate();
  const location = useLocation();
  const { setLocationFrom } = useAuthStore();
  const handleClose = () => {
    onClose();
  };

  const handleLogin = () => {
    setLocationFrom(location.pathname);
    navigator("/login/new");
    handleClose();
  };

  const handleGusetLogin = () => {
    setLocationFrom(location.pathname);
    navigator("/login/new?step=guestLogin");
    handleClose();
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          backgroundColor: "white",
          py: 3,
          px: 3,
          borderRadius: 3,
          boxShadow: "-1px -1px 10px 1px rgba(0, 0, 0, 0.2)",
          display: "flex",
          width: ["90%", "70%", "400px"],
          flexDirection: "column",
          gap: 1,
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <IconButton
        onClick={handleClose}
        color="inherit"
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <Close sx={{ color: "#FF961B" }} />
      </IconButton>
      <Typography variant="h5" fontWeight={500}>
        คุณมีบัญชีหรือไม่
      </Typography>
      <Box>
        <Typography sx={{ mb: 2 }}>
          คุณจำเป็นที่จะต้องเข้าสู่ระบบก่อน
        </Typography>
        <Button fullWidth variant="contained" onClick={handleLogin}>
          เข้าสู่ระบบ
        </Button>
        <Typography sx={{ my: 2, color: "#a8a8a8", textAlign: "center" }}>
          หรือ
        </Typography>
        <Button
          fullWidth
          variant="outlined"
          onClick={handleGusetLogin}
          sx={{ mb: 1 }}
        >
          เข้าสู่ระบบด้วย guest
        </Button>
      </Box>
    </Dialog>
  );
}

export function GuestOrLoginModalComponent({ children }) {
  const { guestModalOpen, closeGuestModal } = useGuestLogin();
  return (
    <div>
      {children}
      <GuestOrLoginModal open={guestModalOpen} onClose={closeGuestModal} />
    </div>
  );
}
