import React, { useEffect } from "react";
import ReactGA from "react-ga4";

function StorePage() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/pompkins/store",
      title: "StorePage",
    });
  }, []);
  return <div>StorePage</div>;
}

export default StorePage;
