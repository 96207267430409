import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardActionArea,
  IconButton,
  Typography,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import {
  getBMerchantById,
  getMerchantById,
} from "../../libs/merchantAPI/merchantAPI";
import DefaultProfile from "../../utils/defaultImage";
import { useNavigate } from "react-router-dom";
import ModalConfirmDelete from "./ModalConfirmDelete";
import { useAuthStore } from "../../stores/useAuthStore";
import { useTranslation } from "react-i18next";

function CartCard({ item, id, handleDelete }) {
  const { t } = useTranslation();
  const initialTime = 5 * 60;
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const [merchant, setMerchant] = useState();
  const [bMerchantData, setBMerchantData] = useState();
  const cartList = item.cartList;
  const [isDelete, setIsDelete] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { user } = useAuthStore();
  const navigator = useNavigate();
  useEffect(() => {
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
    getBMerchantById(id)
      .then((res) => {
        setBMerchantData(res.data);
        getMerchantById(res.data.merchant_id).then((res2) => {
          setMerchant(res2.data);
        });
      })
      .catch((error) => {
        console.error("Error fetching merchant details:", error);
      });
  }, [id]);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDelete = () => {
    handleDelete(id);
    setOpenDialog(false);
    setIsDelete(true);
  };

  const totalPrice = item.totalPrice;
  if (isDelete) {
    return null;
  }
  const handleGoToCartPage = () => {
    let sendProps = {
      order_detail: cartList,
      merchant_name: bMerchantData.name,
      merchant_uuid: bMerchantData.uuid,
      customer_uuid: user?.uuid ?? "",
    };
    localStorage.setItem(`order_merchant_${id}`, JSON.stringify(sendProps));
    navigator(`/merchant/${merchant.id}/${id}/cartorder`);
  };
  return (
    <Card
      sx={{
        padding: 2,

        mb: 2,
        borderRadius: 3,
      }}
    >
      <CardActionArea onClick={() => handleGoToCartPage()}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
          }}
        >
          <img
            src={merchant?.logo ?? DefaultProfile}
            alt="merchant logo"
            title="merchant logo"
            loading="lazy"
            width="80px"
            height="80px"
            style={{
              width: "80px",
              height: "80px",
              borderRadius: "10px",
              objectFit: "cover",
              marginRight: "10px",
            }}
          />
          <Box
            sx={{
              flex: 1,
            }}
          >
            {merchant && (
              <>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {merchant.name}
                </Typography>
                {cartList &&
                  cartList.map((item, index) => (
                    <React.Fragment key={index}>
                      <Typography variant="body2">
                        - {item.menu.name} x{item.quantity}
                      </Typography>
                    </React.Fragment>
                  ))}
              </>
            )}
          </Box>
          <Box sx={{ textAlign: "right" }}>
            <Typography variant="body1" color="text.secondary">
              {totalPrice.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
              {t("thb")}
            </Typography>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleClickOpenDialog(); // Open the delete confirmation dialog
              }}
              sx={{
                backgroundColor: "#ffecec",
                color: "#e53a29",
                ":hover": {
                  backgroundColor: "#e53a29",
                  color: "white",
                },
              }}
            >
              <Delete sx={{ width: "24px", height: "24px" }} />
            </IconButton>
          </Box>
        </Box>
      </CardActionArea>
      <ModalConfirmDelete
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
      />
    </Card>
  );
}

export default CartCard;
