import React from "react";
import CancelImg from "../../assets/images/emo_pomkins_sweating320.png";
import { Typography, Box } from "@mui/material";

function QueueNotFound() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        p: 2,
        textAlign: "center",
        backgroundColor: "white",
        height: "100vh",
      }}
    >
      <img
        src={CancelImg}
        alt="Cancel Img"
        title="Cancel Img"
        loading="lazy"
        width="100px"
        height="auto"
      />
      <Typography variant="h5">ไม่มีคิวของคุณอยู่ในระบบ</Typography>
    </Box>
  );
}

export default QueueNotFound;
