// CartPage.js
import React, { useState, useEffect } from "react";
import CartItem from "../../components/cart/CartItem"; // Make sure this path is correct
import {
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  Radio,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { createOrder, updateOrderStatus } from "../../libs/orderAPI/orderAPI";

import CheckOutItem from "./CheckOutItem";
import {
  getBMerchantById,
  getMerchantById,
  getPaymentStatusList,
  getServiceBMerchant,
} from "../../libs/merchantAPI/merchantAPI";
import { paymentData } from "./paymentData";
import useModalLoading from "../../stores/useModalLoading";
import { useAuthStore } from "../../stores/useAuthStore";
import { useGuestLogin } from "../../stores/useGuestLogin";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { sendConversionEvent } from "../../utils/conversionTracking";

const CheckOutPage = () => {
  const { t } = useTranslation();
  const [orderData, setOrderData] = useState({});
  const [orderType, setOrderType] = useState("DELIVERY");
  const [orders, setOrders] = useState([]);
  const navigator = useNavigate();
  const { isAuthenticated } = useAuthStore();
  const { isGuest } = useGuestLogin();
  const { merchant_id, bmerchant_id } = useParams();
  const [showDetails, setShowDetails] = useState(false);
  const [merchantData, setMerchantData] = useState({});
  const [bmerchantService, setBMerchantService] = useState({});
  const [paymentType, setPaymentType] = useState("");
  const [availablePaymentList, setAvailablePaymentList] = useState([]);
  const { openModal, closeModal } = useModalLoading();
  const [isVerified, setIsVerified] = useState(false);
  const [searchParams] = useSearchParams();
  const utmSource = searchParams.get("utm_source");
  const utmMedium = searchParams.get("utm_medium");
  const utmCampaign = searchParams.get("utm_campaign");

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/merchant/${merchant_id}/${bmerchant_id}/checkout`,
      title: "CheckOutPage",
    });
  }, []);

  useEffect(() => {
    const orderDataString = localStorage.getItem(
      `checkout_data_${bmerchant_id}`
    );
    const orderData = JSON.parse(orderDataString);
    if (orderData) {
      setOrders(orderData.order_detail);
      setOrderData(orderData);
      setOrderType(orderData.order_type);
    } else {
      navigator("/food");
    }
  }, []);

  useEffect(() => {
    getMerchantById(merchant_id).then((res) => {
      const data = res.data;
      setMerchantData(data);
    });

    getBMerchantById(bmerchant_id).then((res) => {
      setIsVerified(res.data.verified);
      getServiceBMerchant(res.data.uuid).then(({ data }) => {
        setBMerchantService(data);
        getPaymentStatusList(res.data.uuid).then(({ data: paymentData }) => {
          let availablePayments = Object.keys(paymentData).filter(
            (key) =>
              paymentData[key] !== null &&
              key !== "bmerchant_uuid" &&
              key !== "thai_qr" &&
              key !== "prompt_pay"
          );
          if (data.delivery_cash && orderData.order_type === "PICKUP") {
            availablePayments.push("cash");
          }

          setAvailablePaymentList(availablePayments);
        });
      });
    });
  }, [bmerchant_id, merchant_id, orderData]);

  useEffect(() => {
    const orderFromGoogleStr = localStorage.getItem("orderFromGoogle");
    if (orderFromGoogleStr) {
      const orderFromGoogle = JSON.parse(orderFromGoogleStr);
      const now = new Date().getTime();
      if (now > orderFromGoogle.expiry) {
        localStorage.removeItem("orderFromGoogle");
      } else {
        if (
          orderFromGoogle.value &&
          orderFromGoogle.value.includes(
            `/merchant/${merchant_id}/${bmerchant_id}`
          )
        ) {
          const currentPathname = window.location.pathname;
          const url = new URL(orderFromGoogle.value);
          const utmSource = url.searchParams.get("utm_source");
          const utmMedium = url.searchParams.get("utm_medium");
          const utmCampaign = url.searchParams.get("utm_campaign");
          const orderType = orderData.order_type
            ? orderData.order_type.toLowerCase()
            : "";
          navigator(
            currentPathname +
              `?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&order_type=${orderType}`,
            {
              replace: true,
            }
          );
        }
      }
    }
  }, [orderData]);

  const handleToggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const handleSubmit = async () => {
    openModal();
    if (!paymentType) {
      closeModal();
      return null;
    } else {
      const body = {
        ...orderData,
        payment_method: paymentType,
      };
      createOrder(body)
        .then((res) => {
          const order_uuid = res.data.order_uuid;
          if (bmerchantService.auto_accept) {
            if (
              ["ALIPAY", "WECHAT_PAY", "SHOPEE_PAY", "THAI_QR"].some((method) =>
                paymentType.includes(method)
              )
            ) {
              updateOrderStatus(order_uuid, "MERCHANT CONFIRMED");
            } else if (paymentType === "CASH") {
              updateOrderStatus(order_uuid, "PURCHASED");
            }
          }
          navigator(
            `/order-status/${merchant_id}/${bmerchant_id}/${order_uuid}`
          );
          localStorage.removeItem(`order_merchant_${bmerchant_id}`);
          localStorage.removeItem(`merchant_${bmerchant_id}`);
          localStorage.removeItem(`checkout_data_${bmerchant_id}`);
          localStorage.removeItem("orderFromGoogle");

          const guestOrder = localStorage.getItem("guest_order")
            ? JSON.parse(localStorage.getItem("guest_order"))
            : [];

          guestOrder.push(order_uuid);

          localStorage.setItem("guest_order", JSON.stringify(guestOrder));
        })
        .catch((error) => {})
        .finally(async () => {
          const currentBMerchantId = bmerchant_id;
          await sendConversionEvent(currentBMerchantId);
          closeModal();
        });
    }
  };

  const totals = orders.reduce(
    (acc, item) => acc + item.quantity * item.price,
    0
  );

  const subtotal = bmerchantService.vat ? totals / 1.07 : totals;
  const vatValues = bmerchantService.vat ? totals - subtotal : 0;
  const deliveryFee =
    orderData && orderType === "DELIVERY" ? orderData.deliver_fee ?? 0 : 0; // ตัวอย่างค่าจัดส่ง
  const total = totals + deliveryFee;
  useEffect(() => {
    if (!isAuthenticated && !isGuest) {
      navigator(-1);
    }
  }, [isAuthenticated, isGuest, navigator]);
  if (!orderData) {
    navigator("/food");
  }
  return (
    <>
      <Helmet>
        <title>
          {t("pompkins")} - {t("checkout")}
        </title>
        <meta
          name="description"
          content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
        />
        <link
          rel="canonical"
          href={`https://pompkins.com${window.location.pathname}`}
        />
        <meta property="og:url" content="https://pompkins.com/merchant" />
      </Helmet>
      <Box
        sx={{
          height: "100vh",
          maxWidth: 1980,
          margin: "auto",
          padding: { xs: "0", sm: "2% 20%", md: "2% 24%" },
        }}
      >
        <Card sx={{ borderRadius: "15px" }}>
          <CardContent>
            <Typography variant="h5" fontWeight={500}>
              เช็คเอาท์
            </Typography>

            <Typography variant="h6">รายละเอียด</Typography>
            <Typography variant="body2" color="GrayText">
              ชื่อร้านค้า: {merchantData.name}
            </Typography>
            <Typography variant="body2" color="GrayText">
              ชื่อผู้รับ: {orderData.customer_name}
            </Typography>
            <Typography variant="body2" color="GrayText">
              เบอร์โทรศัพท์: {orderData.phone}
            </Typography>
            {orderType === "DELIVERY" && (
              <>
                <Typography variant="body2" color="GrayText">
                  ส่งที่: {orderData.deliver_address}
                </Typography>
                {orderData.deliver_note !== "" && (
                  <Typography variant="body2" color="GrayText">
                    รายละเอียดให้คนขับ: {orderData.deliver_note}
                  </Typography>
                )}
              </>
            )}
            {orderType === "PICKUP" && (
              <Typography variant="body2" color="GrayText">
                เวลาที่รับ: {orderData.time_to_get}
              </Typography>
            )}
            {orderType === "RESERVE" && (
              <Typography variant="body2" color="GrayText">
                เวลาที่จอง: {orderData.time_to_get}
              </Typography>
            )}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={() =>
                  navigator(
                    `/merchant/${merchant_id}/${bmerchant_id}/${merchantData.name}`
                  )
                }
                sx={{
                  m: 0,
                  p: 0,
                  alignSelf: "flex-end",
                  minWidth: 0,
                  color: "#FF961B",
                  ":hover": {
                    backgroundColor: "#FFF7EB",
                  },
                }}
              >
                {t("cartOrder.addMenu")}
              </Button>
            </Box>
            {orders.map((item, index) => (
              <CheckOutItem key={item.menu_id} item={item} index={index} />
            ))}

            <Box sx={{ my: 2 }}>
              <Typography variant="body1" color={"GrayText"}>
                เลือกวิธีการชำระเงิน
              </Typography>

              {availablePaymentList.length === 0 ? (
                <Typography variant="body1" color={"red"} sx={{ mt: 2 }}>
                  **ร้านค้ายังไม่ได้ตั้งค่าระบบชำระเงิน**
                </Typography>
              ) : (
                <>
                  {paymentData
                    .filter((item) =>
                      availablePaymentList.includes(
                        item.paymentType.toLowerCase()
                      )
                    )
                    .map((item, index) => (
                      <Box
                        onClick={() => {
                          if (
                            !(
                              item.paymentType === "CASH" &&
                              !bmerchantService.delivery_cash
                            )
                          ) {
                            setPaymentType(item.paymentType);
                          }
                        }}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          mt: 1,
                          px: 1,
                          cursor:
                            item.paymentType === "CASH" &&
                            !bmerchantService.delivery_cash
                              ? "not-allowed"
                              : "pointer",
                          ":hover": {
                            bgcolor:
                              item.paymentType === "CASH" &&
                              !bmerchantService.delivery_cash
                                ? "inherit"
                                : "#ffebcf",
                          },
                          opacity:
                            item.paymentType === "CASH" &&
                            !bmerchantService.delivery_cash
                              ? 0.5
                              : 1,
                        }}
                        key={index}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          {item.images?.map((image, index) => (
                            <img
                              key={index}
                              src={image}
                              alt="icon"
                              loading="lazy"
                              title="icon"
                              width="auto"
                              height="24px"
                              style={{ height: "24px" }}
                            />
                          ))}
                          <Typography variant="body2">
                            {item.title}
                            {item.paymentType === "CASH" &&
                            !bmerchantService.delivery_cash
                              ? ` (${t("PaymentNotAvailable")})`
                              : ""}
                          </Typography>
                        </Box>
                        <Radio
                          checked={item.paymentType === paymentType}
                          disableRipple
                          onChange={() => {
                            if (
                              !(
                                item.paymentType === "CASH" &&
                                !bmerchantService.delivery_cash
                              )
                            ) {
                              setPaymentType(item.paymentType);
                            }
                          }}
                          value={paymentType}
                          sx={{
                            color: "#FF961B",
                            "&.Mui-checked": {
                              color: "#FF961B",
                            },
                          }}
                          disabled={
                            item.paymentType === "CASH" &&
                            !bmerchantService.delivery_cash
                          }
                        />
                      </Box>
                    ))}
                </>
              )}
            </Box>
            {/* ส่วนสรุป */}
            {orderType === "DELIVERY" && (
              <Box
                sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
              >
                <Typography>ค่าจัดส่ง</Typography>
                <Typography>{`฿ ${deliveryFee
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}</Typography>
              </Box>
            )}
            {bmerchantService.vat && (
              <Box
                sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
              >
                <Typography> {}ราคารวมก่อนภาษีมูลค่าเพิ่ม</Typography>
                <Typography>{`฿ ${subtotal.toFixed(2)}`}</Typography>
              </Box>
            )}

            {bmerchantService.vat && (
              <Box
                sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
              >
                <Typography>VAT7%</Typography>
                <Typography>{`฿ ${vatValues
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}</Typography>
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                my: 1,
                fontWeight: "bold",
              }}
            >
              {" "}
              <Typography variant="h6" sx={{ color: "black" }}>
                ราคารวมทั้งสิ้น
              </Typography>
              <Typography>{`฿ ${total
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}</Typography>
            </Box>

            <Button
              variant="contained"
              color="primary"
              disabled={paymentType === ""}
              fullWidth
              sx={{ mt: 2, p: "8px 22px", borderRadius: "2rem" }}
              onClick={handleSubmit}
            >
              ยืนยัน
            </Button>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default CheckOutPage;
