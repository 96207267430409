import { Box, Dialog } from "@mui/material";
import React from "react";
import loading from "../assets/images/ld1_basket320.gif";
function LoadingScreen() {
  return (
    <Dialog fullScreen open={true}>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={loading}
          alt="loading img"
          title="loading img"
          loading="lazy"
          width="160px"
          height="160px"
          style={{
            width: "160px",
          }}
        />
      </Box>
    </Dialog>
  );
}

export default LoadingScreen;
