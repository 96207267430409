import React from "react";
import IconButton from "@mui/material/IconButton";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import ShoppingCartOutlined from "@mui/icons-material/ShoppingCartOutlined";

const CartFavButtons = ({ isAuthenticated, navigator }) => {
  return (
    <>
      {isAuthenticated ? (
        <>
          <IconButton color="inherit" onClick={() => navigator("/favorite")}>
            <FavoriteBorder className="text-primary" />
          </IconButton>
          <IconButton color="inherit" onClick={() => navigator("/cart")}>
            <ShoppingCartOutlined className="text-primary" />
          </IconButton>
        </>
      ) : (
        <IconButton color="inherit" onClick={() => navigator("/cart")}>
          <ShoppingCartOutlined className="text-primary" />
        </IconButton>
      )}
    </>
  );
};

export default CartFavButtons;
