import { Box, Typography, Button } from "@mui/material";
import React from "react";
import mobileIcon from "../../assets/icon/smartphone.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup"; // You need to import yup and define 'nameScheme'
import { useTranslation } from "react-i18next";
import { registerPhone, sendOtp } from "../../libs/authAPI/registerAPI";
import useModalLoading from "../../stores/useModalLoading";
import useGenericModal from "../../stores/useGenericModal";
import TextInput from "../../components/TextInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { mobileNumberValidate } from "../ContactSales/formScheme";

const MobileNumberValidate = yup.object().shape({
  phone: mobileNumberValidate,
});
function PhoneStep() {
  const { t } = useTranslation();
  const { openModal, closeModal } = useModalLoading();
  const { openFailedModal } = useGenericModal();
  const location = useLocation();
  const navigator = useNavigate();
  const userData = location.state;

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
    resolver: yupResolver(MobileNumberValidate),
  });

  // function formatPhoneNumber(phoneNumber) {
  //   let formattedNumber = phoneNumber.replace(/\s/g, "");

  //   if (formattedNumber.startsWith("+")) {
  //     formattedNumber = formattedNumber.substring(3);
  //   }
  //   if (formattedNumber.startsWith("0")) {
  //     formattedNumber = formattedNumber.substring(1);
  //   }

  //   return formattedNumber;
  // }
  const onSubmit = (data) => {
    // const formatPhone = formatPhoneNumber(data.phone);
    // const phoneNumberWithoutSpaces = data.phone.replace(/\s/g, "");
    const registerData = {
      first_name: userData.first_name,
      last_name: userData.last_name,
      email: userData.email,
      password: userData.password,
      phone: data.phone,
    };
    const formData = new FormData();
    const formatPhone = "+66" + data.phone.slice(1);
    formData.append("phone", formatPhone);
    openModal();
    const registerPhoneData = {
      email: userData.email,
      phone: formatPhone,
      password: userData.password,
    };
    registerPhone(registerPhoneData)
      .then(() => {
        sendOtp(formData)
          .then((res) => {
            const data = {
              registerData: registerData,
              challenge: res.data.ChallengeName,
              phone: formatPhone,
              session: res.data.Session,
            };
            closeModal();
            navigator("/login/new?step=otp", { state: data });
          })
          .catch((err) => {
            closeModal();
            openFailedModal("เบอร์ของคุณถูกใช้งานแล้ว กรุณาลองใหม่อีกครั้ง");
          });
      })
      .catch((error) => {
        console.log("error", error);
        closeModal();
        openFailedModal("เบอร์ของคุณถูกใช้งานแล้ว กรุณาลองใหม่อีกครั้ง");
      });
    // registerV2(registerData)
    //   .then((res) => {
    //     signInWithCustomToken(auth, res.data.customToken).then(
    //       async (response) => {}
    //     );

    //     navigator("/login/new?step=success", { replace: true });

    //     closeModal();
    //   })
    //   .catch(() => {
    //     closeModal();
    //     openFailedModal("เกิดข้อผิดพลาดในการลงทะเบียน กรุณาลองใหม่อีกครั้ง");
    //   });
  };

  if (!location.state) {
    return <Navigate to="/" />;
  }
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        backgroundColor: "white",
        mt: 10,
        py: 6,
        px: [2, 4, 6],
        borderRadius: 3,
        boxShadow: "-1px -1px 10px 1px rgba(0, 0, 0, 0.2)",
        display: "flex",
        width: ["90%", "70%", "400px"],
        flexDirection: "column",
        gap: 1,
      }}
    >
      <img
        src={mobileIcon}
        alt="logo"
        title="logo"
        loading="lazy"
        width="50px"
        height="50px"
        style={{
          width: "50px",
          height: "50px",
        }}
      />
      <Typography variant="h5" className="mt-3 font-bold">
        {t("mobileStep.title")}
      </Typography>
      <Typography className="text-[#a8a8a8] mb-3">
        {/* {t("mobileStep.instruction")} */}
        โปรดใส่เบอร์โทรศัพท์ของคุณ
      </Typography>

      <TextInput
        label={"เบอร์โทรศัพท์"}
        placeholder={"กรุณากรอกเบอร์โทรศัพท์ของคุณ"}
        error={errors.phone?.message}
        {...register("phone", { required: true })}
      />
      <Button
        type="submit"
        variant="contained"
        fullWidth
        disabled={!isValid}
        sx={{
          mt: 2,
          borderRadius: 6,
        }}
      >
        {t("btn.verifyCodeButton")}
      </Button>
    </Box>
  );
}
export default PhoneStep;
