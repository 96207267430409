import { Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import notFoundImg from "../../assets/images/emo_pomkins_dizzy320.png";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

function SessionTimeoutPage() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/timeout",
      title: "SessionTimeoutPage",
    });
  }, []);
  return (
    <div className="bg-center bg-cover h-screen flex flex-col items-center justify-center">
      <img
        src={notFoundImg}
        alt="not found img"
        title="not found img"
        loading="lazy"
        width="auto"
        height="auto"
      />
      <div className="text-black text-center mt-5">
        <Typography className="text-4xl font-bold mb-2 uppercase">
          เซสชั่นของคุณหมดอายุ
        </Typography>
        <Typography className="text-lg mb-8">
          กรุณาเข้าสู่ระบบอีกครั้ง
        </Typography>
        <Link to={"/login/new"} className="cursor-pointer">
          <Button variant="outlined">กลับไปเข้าสู่ระบบ</Button>
        </Link>
      </div>
    </div>
  );
}

export default SessionTimeoutPage;
