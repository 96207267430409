// SlidingButtonGroup.js
import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Button,
  ButtonGroup,
  TextField,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import RestaurantOutlinedIcon from "@mui/icons-material/RestaurantOutlined";
import DeliveryDiningOutlinedIcon from "@mui/icons-material/DeliveryDiningOutlined";
import { LocationOn } from "@mui/icons-material";
import { useLocationStore } from "../../stores/useLocationStore";
import { getBMerchantById } from "../../libs/merchantAPI/merchantAPI";
import { isMerchantOpen } from "../../libs/isMerchantOpen";
import { useParams } from "react-router-dom";
import MerchantCloseModal from "../../pages/Merchant/MerchantCloseModal";
import ModalNoService from "../../pages/CartPage/ModalNoService";
import {
  filterIntervals,
  formatTime,
  getTimeIntervals,
} from "../../utils/timeIntervals";

const SlidingButtonGroup = ({
  service,
  onOrderTypeChange,
  onPickupTimeSelected,
  onReserveTimeSelected,
  onPeopleCountSelected,
  bmerchantData,
  error,
  isSubmit,
  orderType,
  setOrderType,
  deliverData,
}) => {
  const { deliverNote, setDeliverNote } = deliverData;
  const { bmerchant_id } = useParams();
  const [pickupTime, setPickupTime] = useState("");
  const [reserveTime, setReserveTime] = useState();
  const [peopleCount, setPeopleCount] = useState(0);
  const { currentAddress, openModal } = useLocationStore();
  const [merchantTime, setMerchantTime] = useState({});
  const [reserveTimes, setReserveTimes] = useState([]);
  const [pickupTimes, setPickupTimes] = useState([]);
  const [isMerchantOpenState, setIsMerchantOpenState] = useState(true);
  const [modalNoService, setModalNoService] = useState(false);
  useEffect(() => {
    const checkServiceAvailability = () => {
      if (orderType === "pickup" && service.pickup) {
        return "pickup";
      } else if (service.delivery) {
        return "delivery";
      }
      // } else if (service.reserve) {
      //   return "reserve";
      // } else {
      else {
        return "";
      }
    };

    const newOrderType = checkServiceAvailability();
    setOrderType(newOrderType);

    if (newOrderType === "") {
      setModalNoService(true);
    }
  }, [service, orderType]);

  useEffect(() => {
    getBMerchantById(bmerchant_id).then((res2) => {
      setMerchantTime({
        open_time: res2.data.open_time,
        close_time: res2.data.close_time,
      });
      let timeTemp = isMerchantOpen(res2.data.open_time, res2.data.close_time);
      setIsMerchantOpenState(timeTemp);
    });
  }, [bmerchant_id]);

  useEffect(() => {
    if (orderType === "pickup" && merchantTime) {
      generatePickupTimes();
    } else if (orderType === "reserve") {
      generateReserveTimes();
    }
  }, [orderType, merchantTime]);

  const generatePickupTimes = () => {
    const now = new Date();
    const currentTime = formatTime(now);

    const timeIntervals = getTimeIntervals(
      merchantTime.open_time,
      merchantTime.close_time,
      15
    );
    const filteredIntervals = filterIntervals(timeIntervals, currentTime);
    setPickupTimes(filteredIntervals);
  };

  const generateReserveTimes = () => {
    const currentTime = new Date();
    const currentMinutes = currentTime.getMinutes();
    const currentHours = currentTime.getHours();
    const slots = [];

    // Assuming merchantTime.close_time is in "HH:MM" format
    const [closeHour, closeMinute] = merchantTime.close_time
      ? merchantTime.close_time.split(":").map(Number)
      : [23, 59]; // Default to 23:59 if no close time is set

    // Check if the current time is already past the closing time
    const isPastCloseTime =
      currentHours > closeHour ||
      (currentHours === closeHour && currentMinutes > closeMinute);

    const limitHour = isPastCloseTime ? 24 : closeHour; // Limit to the closing hour

    // Loop through the hours from the current hour to the limit hour
    for (let hour = currentHours; hour <= limitHour; hour++) {
      const formattedHour =
        hour === 24 ? "00" : hour.toString().padStart(2, "0");

      if (hour === currentHours && currentMinutes >= 1) {
      } else {
        slots.push(`${formattedHour}:00`);
      }
    }
    setReserveTimes(slots);
  };

  const handleActiveChange = (value) => {
    onOrderTypeChange(value);
    setOrderType(value);
  };

  // Enhanced button styles with transitions for smooth visual effects
  const getButtonStyle = (value) => ({
    width: "50%",
    transition: "box-shadow 0.3s ease, background-color 0.3s ease",
    boxShadow:
      orderType === value ? "0px 2px 10px rgba(255, 150, 27, 0.3)" : "none", // Shadow color adjusted to match the highlight
    bgcolor: orderType === value ? "#FF961B" : "background.paper", // Highlight color set to #FF961B
    color: orderType === value ? "common.white" : "text.primary",
    "&:hover": {
      bgcolor: orderType === value ? "#FF961B" : "#FF961B", // Darker shade of the highlight color for hover state
      boxShadow: "0px 2px 10px rgba(255, 150, 27, 0.3)", // Shadow color adjusted to match the highlight
      color: orderType === value ? "#ffffff" : "#ffffff",
    },
    borderRadius: 1,
  });

  // Styles for the TextFields
  const textFieldStyle = {
    "& label.Mui-focused": {
      color: "#FF961B", // Label color when the input is focused
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#FF961B", // Underline color when input is focused
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#FF961B", // Border color for the focused state
      },
    },
    "& .MuiButtonBase-root.MuiListItem-root.Mui-selected": {
      backgroundColor: "#FF961B", // Background color for selected item in the dropdown
      color: "common.white", // Text color for selected item in the dropdown
      "&:hover": {
        backgroundColor: "#E0851D", // On hover state color for dropdown items
      },
    },
    "& .MuiSvgIcon-root": {
      fill: "#FF961B", // Color of the dropdown icon
    },
    "& .MuiFormHelperText-root": {
      display: "flex",
      justifyContent: "start",
      alignItems: "end",
      // Change the font size of the helper text here
      fontSize: "0.6rem", // Example: smaller text
      // Add any other styles you want for the helper text
    },
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        maxWidth: 360,
        mx: "auto",
        mb: 2,
      }}
    >
      <ModalNoService
        open={modalNoService}
        location={{
          lat: parseFloat(bmerchantData.latitude),
          lng: parseFloat(bmerchantData.longitude),
        }}
      />
      {isMerchantOpenState ? null : <MerchantCloseModal />}

      <ButtonGroup fullWidth variant="outlined">
        <Button
          onClick={() => handleActiveChange("pickup")}
          sx={getButtonStyle("pickup")}
          disabled={!service.pickup}
          color={onOrderTypeChange === "pickup" ? "primary" : "inherit"}
        >
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
          >
            <Grid item>
              <ShoppingBagOutlinedIcon />
            </Grid>
            <Grid item>Pickup</Grid>
            {!service.pickup && (
              <Grid item fontSize={"12px"} color={"red"}>
                ไม่มีบริการนี้
              </Grid>
            )}
          </Grid>
        </Button>

        <Button
          onClick={() => handleActiveChange("delivery")}
          sx={getButtonStyle("delivery")}
          disabled={!service.delivery || !service.delivery_fee1}
          color={onOrderTypeChange === "delivery" ? "primary" : "inherit"}
        >
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
          >
            <Grid item>
              <DeliveryDiningOutlinedIcon />
            </Grid>
            <Grid item>Delivery</Grid>
            {!service.delivery && (
              <Grid item fontSize={"12px"} color={"red"}>
                ไม่มีบริการนี้
              </Grid>
            )}

            {!service.delivery_fee1 && (
              <Grid item fontSize={"12px"} color={"red"}>
                ร้านยังไม่ตั้งค่าส่ง
              </Grid>
            )}
          </Grid>
        </Button>
        {/* <Button
          onClick={() => handleActiveChange("reserve")}
          sx={getButtonStyle("reserve")}
          disabled={!service.reserve}
          color={onOrderTypeChange === "reserve" ? "primary" : "inherit"}
        >
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
          >
            <Grid item>
              <RestaurantOutlinedIcon />
            </Grid>
            <Grid item>Reserve</Grid>
            {!service.reserve && (
              <Grid item fontSize={"12px"} color={"red"}>
                ไม่มีบริการนี้
              </Grid>
            )}
          </Grid>
        </Button> */}
      </ButtonGroup>
      {orderType === "delivery" && (
        <>
          <Button
            sx={{
              color: "black",
              mt: 1,
              ":hover": {
                backgroundColor: "#fff8f0",
              },
            }}
            onClick={() => openModal()}
            startIcon={<LocationOn className="text-primary" />}
          >
            ตำแหน่ง
            <span variant="" className="text-primary ml-1 line-clamp-1 ">
              {currentAddress}
            </span>
          </Button>
          <TextField
            value={deliverNote}
            onChange={(e) => setDeliverNote(e.target.value)}
            fullWidth
            multiline
            rows={3}
            placeholder={"เพิ่มรายละเอียดถึงคนขับ (ถ้ามี)"}
            sx={{
              mb: 9,
              height: "24px",
              py: "0px !important",
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#FF961B",
                },
              },
            }}
            InputProps={{
              sx: {
                fontSize: "14px",
              },
            }}
          />
        </>
      )}

      {orderType === "pickup" && (
        <>
          <TextField
            select
            label="เวลาที่จะรับ"
            value={pickupTime}
            onChange={(e) => {
              setPickupTime(e.target.value);
              if (onPickupTimeSelected) {
                onPickupTimeSelected(e.target.value);
              }
            }}
            fullWidth
            margin="normal"
            size="small"
            sx={textFieldStyle}
          >
            {pickupTimes.map((time) => (
              <MenuItem key={time} value={time}>
                {time}
              </MenuItem>
            ))}
          </TextField>
          {isSubmit && error.errortimeToGet && (
            <FormHelperText error>{error.errortimeToGet}</FormHelperText>
          )}
        </>
      )}

      {/* {orderType === "reserve" && (
        <Box
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          sx={{ display: "flex" }}
        >
          <Box sx={{ width: "100%" }}>
            <TextField
              select
              label="เวลาที่จะจอง"
              value={reserveTime}
              onChange={(e) => {
                setReserveTime(e.target.value);
                if (onReserveTimeSelected) {
                  onReserveTimeSelected(e.target.value);
                }
              }}
              fullWidth
              margin="normal"
              size="small"
              sx={textFieldStyle}
            >
              {reserveTimes.map((time) => (
                <MenuItem key={time} value={time}>
                  {time}
                </MenuItem>
              ))}
            </TextField>
            {isSubmit && error.errortimeToReserve && (
              <FormHelperText error>{error.errortimeToReserve}</FormHelperText>
            )}
          </Box>
          <Grid item sx={{ padding: "10px" }}></Grid>

          <Box width={"100%"}>
            <TextField
              type="number"
              label="จำนวนคน"
              value={peopleCount}
              onChange={(e) => {
                const count = e.target.value;
                setPeopleCount(count);
                if (onPeopleCountSelected) {
                  onPeopleCountSelected(count);
                }
              }}
              fullWidth
              margin="normal"
              size="small"
              inputProps={{ min: 0 }}
              sx={textFieldStyle}
            />
            {isSubmit && error.errorPeopleCount && (
              <FormHelperText error>{error.errorPeopleCount}</FormHelperText>
            )}
          </Box>
        </Box>
      )} */}
    </Box>
  );
};

export default SlidingButtonGroup;
