import { create } from "zustand";

export const useGuestLogin = create((set) => ({
  guest_user: {
    name: "",
    phone: "",
  },
  isGuest: false,
  setGuest: (guest_user) => {
    set({ isGuest: true, guest_user: guest_user });
  },
  guestLogout: () => {
    set({
      isGuest: false,
      guset_user: {
        name: "",
        phone: "",
      },
    });
  },
  guestModalOpen: false,
  openGuestModal: () => set({ guestModalOpen: true }),
  closeGuestModal: () => set({ guestModalOpen: false }),
}));
