import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import FoodTiles from "../../components/FoodTiles";
import SearchNotFound from "../../assets/image/SearchNotFound.png";

function MerchantList({ merchants }) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{ width: "100%", overflowY: "auto", maxHeight: "100vh", padding: 2 }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: { xs: "24px", sm: "32px", md: "34px" },
        }}
        gutterBottom
      >
        {t("home.nearby")}
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        {merchants && merchants.length > 0 ? (
          merchants.map((merchant, index) => (
            <FoodTiles item={merchant} key={index} />
          ))
        ) : (
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
              padding: "20px",
              backgroundColor: "#f0f0f0",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={SearchNotFound}
              alt="not found img"
              title="not found img"
              loading="lazy"
              width="100px"
              height="auto"
              style={{ width: "100px" }}
            />
            <Typography fontWeight={600} fontSize={"18px"}>
              {t("sorry")}
            </Typography>
            <Typography fontSize={"16px"}>{t("NoMerchant")}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default MerchantList;
