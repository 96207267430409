import { Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import notFoundImg from "../../assets/images/emo_pomkins_dizzy320.png";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

function NotFoundPage() {
  const { t } = useTranslation();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "*",
      title: "NotFoundPage",
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>
          {t("pompkins")} - {t("NotFoundPage")}
        </title>
        <meta
          name="description"
          content="The page you are looking for does not exist. Use the links below to navigate back to the site."
        />
        <link rel="canonical" href="https://pompkins.com/notfound" />
      </Helmet>
      <div className="bg-center bg-cover h-screen flex flex-col items-center justify-center">
        <img
          src={notFoundImg}
          alt="not found img"
          title="not found img"
          loading="lazy"
          width={"320px"}
          height={"320px"}
        />
        <div className="text-black text-center mt-5">
          <Typography
            variant="h1"
            className="text-4xl font-bold mb-2 uppercase"
          >
            404 - Page not found
          </Typography>
          <Typography variant="h2 " className="text-lg mb-8">
            The page you are looking for might have been removed or is
            temporarily unavailable.
          </Typography>
        </div>
        <Link to={"/"} className="cursor-pointer ">
          <Button variant="outlined">Back to Home</Button>
        </Link>
      </div>
    </>
  );
}

export default NotFoundPage;
