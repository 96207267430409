import { Box, Button, Dialog, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Warnning from "../../assets/images/emoji_pomkins_exclamation320.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getAddressFromCoordinates } from "../../utils/convertAddress";
import MapButton from "../../components/MapButton";

function ModalNoService({ open = false, location }) {
  const navigator = useNavigate();
  const { t } = useTranslation();
  const [url, setUrl] = useState("");
  useEffect(() => {
    const getAddress = async () => {
      const merchantAddress = await getAddressFromCoordinates(location);
      const mapUrl = `https://www.google.com/maps/search/?api=1&query=${merchantAddress}`;
      setUrl(mapUrl);
    };
    if (location.lat && location.lng) {
      getAddress();
    }
  }, [location, url]);

  const openGoogleMaps = () => {
    window.open(url, "_blank");
  };

  return (
    <Dialog open={open}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          m: 3,
          px: [5, 10, 15],
        }}
      >
        <img
          src={Warnning}
          alt="no-menu"
          title="no-menu"
          loading="lazy"
          width="100px"
          height="100px"
          style={{ width: "100px", height: "100px" }}
        />
        <Typography sx={{ fontWeight: 500, my: 2 }}>
          {t("merchant.noServiceTitle")}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <Button variant="contained" onClick={openGoogleMaps}>
            {t("btn.viewMerchantLocation")}
          </Button>
          <Button variant="outlined" onClick={() => navigator("/food")}>
            {t("merchant.backToHomePage")}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default ModalNoService;
