import { Search } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import image from "../../assets/images/service_02_OnlineOrder_new.png";
import FaqData from "./FaqData";
import FaqItem from "./FaqItem";
import SearchableFAQ from "./SearchFaq";
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

function FaqPage() {
  const { t } = useTranslation();
  const { categories } = useParams();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/pompkins/FAQ`,
      title: "FaqPage",
    });
  }, []);
  const { faqData, applicationFaqData } = FaqData();
  return (
    <>
      <Helmet>
        <title>
          {t("pompkins")} - {t("title.faqpage")}
        </title>
        <meta
          name="description"
          content="แอปเดียว ครบ ง่าย คุ้ม เปลี่ยนทุกความไม่ง่ายในชีวิต ให้ง่ายขึ้นด้วยแอปเดียว POS สั่งอาหาร ชำระเงิน จัดการพนักงาน ดูยอดขาย ดาวน์โหลด ฟรี ได้วันนี้"
        />
        <link
          rel="canonical"
          href={`https://pompkins.com${window.location.pathname}`}
        />
        <meta property="og:url" content="https://pompkins.com/pompkins/faq" />
        <meta property="og:image" content={image} />
        <meta
          property="og:title"
          content={`${t("pompkins")} - ${t("title.faqpage")}`}
        />

        <meta name="twitter:creator" content="POMPKINS" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:url" content="https://pompkins.com/pompkins/faq" />
        <meta
          name="twitter:title"
          content={`${t("pompkins")} - ${t("title.faqpage")}`}
        />
      </Helmet>
      <Box sx={{ backgroundColor: "white" }}>
        {/*Section Header*/}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "45vh",
            backgroundColor: "#FAFAFA",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              width: "90%",
              p: 4,
              gap: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",

                gap: 2,
                width: ["100%", "100%", "50%"],
              }}
            >
              <Typography
                sx={{ fontWeight: 700, fontSize: ["32px", "36px", "40px"] }}
              >
                {" "}
                Pompkins Q&A
              </Typography>
              <SearchableFAQ />
            </Box>
            <Box
              sx={{
                width: "50%",
                display: ["none", "none", "flex"],
                justifyContent: "end",
              }}
            >
              <Box
                component="img"
                src={image}
                alt="faq-image"
                title="faq"
                loading="lazy"
              />
            </Box>
          </Box>
        </Box>
        {/*Section body*/}
        <Box sx={{ px: "5%", py: "80px" }}>
          <Typography
            sx={{ fontWeight: 700, fontSize: ["24px", "28px", "32px"], mb: 2 }}
          >
            คำถามทั่วไป
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              rowGap: "80px",
              columnGap: "70px",
            }}
          >
            {faqData.map((item, index) => (
              <FaqItem key={index} item={item} category={"General"} />
            ))}
          </Box>
        </Box>
        <Box sx={{ px: "5%", py: "80px" }}>
          <Typography
            variant="h1"
            sx={{ fontWeight: 700, fontSize: ["24px", "28px", "32px"], mb: 2 }}
          >
            คำถามเกี่ยวกับแอปพลิเคชั่น Pompkins
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              rowGap: "80px",
              columnGap: "70px",
            }}
          >
            {applicationFaqData.map((item, index) => (
              <FaqItem key={index} item={item} category={"Application"} />
            ))}
          </Box>
        </Box>
        {/* <Box
        sx={{
          textAlign: "center",
          mt: 4,
          pb: 6,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ fontWeight: 700, fontSize: ["24px", "28px", "32px"], mb: 2 }}
        >
          ไม่พบวิธีแก้ปัญหาใช่ไหม?
        </Typography>
        <Button
          onClick={() =>
            window.open(
              "https://tawk.to/chat/65b630988d261e1b5f58c95b/1hl8g1oir",
              "_blank"
            )
          }
          sx={{
            mt: 3,
            width: "200px",
            height: "50px",
            position: "relative",
            borderRadius: 0,
            fontSize: "16px",
            zIndex: 1,
            color: "white",
            bgcolor: "#f9931f",
            boxShadow: "8px 8px 0px 0px black",
            ":hover": {
              bgcolor: "black",
              color: "white",
              boxShadow: "8px 8px 0px 0px #f9931f",
            },
          }}
        >
          Live chat
        </Button>
      </Box> */}
      </Box>
    </>
  );
}

export default FaqPage;
