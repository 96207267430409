// CartPage.js
import React, { useState, useEffect, useCallback } from "react";
import { Card, CardContent, Typography, Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  getBMerchantById,
  getMerchantById,
  getServiceBMerchant,
} from "../../libs/merchantAPI/merchantAPI";
import CheckBillItem from "./CheckBillItem";
import { getOrderByTableNumber } from "../../libs/customerAPI/getOrders";
// import LoadingScreen from "../../components/LoadingScreen";
import DefaultProfile from "../../assets/images/pompkins_food_logo.png";
import { checkToken } from "../../libs/tableAPI/checkExpire";
import ReactGA from "react-ga4";

const CheckBillPage = () => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);

  const navigator = useNavigate();
  const [bmerchantService, setBMerchantService] = useState({});

  const {
    bmerchant_id,
    merchant_id,
    token,
    tableNumber,
    merchant_uuid,
    transaction_uuid,
  } = useParams();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/table-merchant/${token}/${merchant_id}/${bmerchant_id}/${tableNumber}/${transaction_uuid}/checkbill/${merchant_uuid}`,
      title: "CheckBillPage",
    });
  }, []);
  const [logo, setLogo] = useState("");
  useEffect(() => {
    getMerchantById(merchant_id).then((res) => {
      // setMerchantData(res.data);
      setLogo(res.data.logo);
    });
  }, [merchant_id]);

  useEffect(() => {
    getBMerchantById(bmerchant_id).then((res) => {
      getServiceBMerchant(res.data.uuid).then(({ data }) => {
        setBMerchantService(data);
      });
    });
  }, []);

  const fetchOrderData = useCallback(() => {
    getOrderByTableNumber(merchant_uuid, tableNumber)
      .then((res) => {
        setOrders(res.data);
      })
      .catch((error) => {
        setOrders([]);
        console.log(error);
      });
  }, [merchant_uuid, tableNumber]);

  useEffect(() => {
    fetchOrderData();
    checkToken(token);
    const fetchInterval = setInterval(() => {
      fetchOrderData();
      checkToken(token).catch(() => {
        navigator(
          `/order/bill/${merchant_id}/${bmerchant_id}/${transaction_uuid}`
        );
      });
    }, 5 * 1000);

    return () => clearInterval(fetchInterval);
  }, [fetchOrderData]);

  // Styles for the TextFields
  let totalAmount = 0;

  let filteredOrders = [];
  if (orders) {
    filteredOrders = orders.filter((order) => order.payment_status !== "PAID");

    if (filteredOrders.length > 0) {
      filteredOrders.sort((a, b) => new Date(b.date) - new Date(a.date));
      totalAmount = filteredOrders[0].total_amount;
    }
  }

  const subtotal = bmerchantService.vat ? totalAmount / 1.07 : totalAmount;
  const vatValues = bmerchantService.vat ? totalAmount - subtotal : 0;

  const getMenuCount = (orders) => {
    let menuCount = 0;
    orders.forEach((order) => {
      order.order_detail.forEach((detail) => {
        menuCount += detail.quantity;
      });
    });
    return menuCount;
  };

  // Call the function and log the result
  const menuCount = getMenuCount(filteredOrders);
  // const handleCheckBill = async () => {
  //   navigator(
  //     `/table-merchant/${token}/${merchant_id}/${bmerchant_id}/${tableNumber}/payment/${merchant_uuid}`
  //   );
  // };

  // if (!orders) {
  //   return <LoadingScreen />;
  // }
  return (
    <Box
      sx={{
        maxWidth: 1440,
        margin: "auto",
        padding: "2% 5%",
        height: "100%",
      }}
    >
      <Card sx={{ borderRadius: "15px" }}>
        <CardContent>
          <Box sx={{ textAlign: "center" }}>
            <Typography fontSize={"20px"} fontWeight={700}>
              {t("cartOrder.tableNumber")}
              <span> {tableNumber}</span>
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
            <img
              src={logo ?? DefaultProfile}
              alt="logo merchant"
              loading="lazy"
              title="logo merchant"
              style={{
                minWidth: "150px",
                minHeight: "150px",
                height: "150px",
                width: "150px",
                objectFit: "cover",
                borderRadius: "16px",
              }}
              width="150px"
              height="150px"
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography fontWeight={500} fontSize={["14px", "16px"]}>
              รายการทั้งหมด {menuCount ?? 0} รายการ
            </Typography>
            <Button
              onClick={() =>
                navigator(
                  `/table-merchant/${token}/${merchant_id}/${bmerchant_id}/${tableNumber}/${transaction_uuid}/menu`
                )
              }
              sx={{
                fontSize: ["14px", "16px"],
                m: 0,
                p: 0,
                minWidth: 0,
                color: "#FF961B",
                ":hover": {
                  backgroundColor: "#FFF7EB",
                },
              }}
            >
              {t("cartOrder.addMenu")}
            </Button>
          </Box>
          {/* การวนซ้ำผ่าน orders และการแสดงรายการในตะกร้า */}
          {filteredOrders.map((order, orderIndex) => (
            <div key={orderIndex}>
              {order.order_detail.map((menuItem, menuIndex) => (
                <CheckBillItem
                  key={menuIndex}
                  item={menuItem}
                  index={menuIndex}
                  name={order.customer_name}
                  status={order.cooking_status}
                />
              ))}
            </div>
          ))}

          {/* ส่วนสรุป */}
          <Typography variant="h6" sx={{ mt: 2 }}>
            สรุป
          </Typography>
          {bmerchantService.vat && (
            <Box
              sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
            >
              <Typography> {}ราคารวมก่อนภาษีมูลค่าเพิ่ม</Typography>
              <Typography>{`฿ ${subtotal.toFixed(2)}`}</Typography>
            </Box>
          )}
          {bmerchantService.vat && (
            <Box
              sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
            >
              <Typography>VAT7%</Typography>
              <Typography>{`฿ ${vatValues.toFixed(2)}`}</Typography>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              my: 1,
              fontWeight: "bold",
            }}
          >
            <Typography>ราคารวมทั้งสิ้น</Typography>
            <Typography>{`฿ ${totalAmount
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}</Typography>
          </Box>
          {/* <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleCheckBill}
          >
            เช็คบิล
          </Button> */}
        </CardContent>
      </Card>
    </Box>
  );
};

export default CheckBillPage;
