import React from "react";
import { Box } from "@mui/material";

import { useLocationStore } from "../../stores/useLocationStore";
import SeeAllSection from "./SeeAllSection";
import SearchFetchSection from "./SearchFetchSection";
import NoMerchantFoundSection from "./NoMerchantFoundSection";
import MerchantDataSection from "./MerchantDataSection";

const MerchantSection = ({
  merchantData,
  pageQuery,
  searchFetch,
  searchData,
  newMerchant,
  isFetch,
  searchResultsRef,
  isFetchNextPage,
  fetchNextPage,
  currentPage,
  totalPages,
}) => {
  const { currentLocation, openModal } = useLocationStore();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {pageQuery && pageQuery === "seeAll" ? (
        <SeeAllSection merchantData={merchantData} />
      ) : merchantData && merchantData.length === 0 ? (
        <NoMerchantFoundSection openModal={openModal} />
      ) : (
        <MerchantDataSection
          merchantData={merchantData}
          newMerchant={newMerchant}
          isFetch={isFetch}
          currentLocation={currentLocation}
          isFetchNextPage={isFetchNextPage}
          fetchNextPage={fetchNextPage}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      )}
    </Box>
  );
};

export default MerchantSection;
