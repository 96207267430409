import { Skeleton } from "@mui/material";
import React from "react";

function PromotionCards({ items }) {
  return (
    // <Card
    //   key={items.title}
    //   sx={{
    //     // flex: 1,
    //     width: "100%",
    //     borderRadius: "20px",
    //     // height: ["20vh", "30vh", "40vh"],
    //   }}
    // >
    <>
      {items ? (
        <img
          src={items.image}
          alt={items.title}
          title={items.title}
          loading="lazy"
          width="100%"
          height="100%"
          style={{
            display: "block",
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      ) : (
        <Skeleton variant="rounded" width={"100%"} height={"100%"} />
      )}
    </>
    // </Card>
  );
}

export default PromotionCards;
