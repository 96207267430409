import {
  ArrowForwardIos,
  EmailOutlined,
  FavoriteBorder,
  LocationOnOutlined,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import check from "../../assets/images/check.png";
import { useMobile } from "../../stores/useMobile";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Swiper.css";
import { useTranslation } from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CampaignIcon from "@mui/icons-material/Campaign";
import TuneIcon from "@mui/icons-material/Tune";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

const CheckList = ({ isChecked, text }) => {
  return (
    <Box
      sx={{
        textAlign: "start",
        display: "flex",
        flexDirection: "row",
        gap: 1,
        mt: 1,
      }}
    >
      {isChecked ? (
        <CheckCircleIcon
          sx={{ color: "green", width: "18px", height: "18px" }}
        />
      ) : (
        <CancelIcon sx={{ color: "red", width: "18px", height: "18px" }} />
      )}
      <Typography className="text-sm" sx={{ color: "black" }}>
        {text}
      </Typography>
    </Box>
  );
};

const PackageCard = ({ index, item }) => {
  const { t } = useTranslation();
  return (
    <Box
      data-aos="fade-up"
      data-aos-delay={item.delay}
      key={index}
      sx={{
        textAlign: "center",
        width: ["100%", "60%", "calc(25% - 16px)"],
        boxShadow: "7px 4px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: 4,
        backgroundColor: "white",
        minHeight: ["620px", "620px", "auto"],
        m: ["0 auto", "0 auto", "0"],
      }}
    >
      <Box sx={{ boxShadow: "7px 4px 21px rgba(0, 0, 0, 0.05)", gap: 2 }}>
        <Box
          sx={{
            backgroundColor: "#FFDEBA",
            textAlign: "center",
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            py: 1,
          }}
        >
          <Typography>{item.suitableFor}</Typography>
        </Box>
        <Typography
          sx={{
            background: item.color,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontSize: "40px",
            mt: 2,
          }}
          fontWeight={700}
        >
          {item.title}
        </Typography>

        <Typography
          fontWeight={600}
          lineHeight={"50px"}
          sx={{ color: "black", fontSize: "50px", mt: 2 }}
        >
          {item.package}
          {index !== 0 && (
            <Typography component={"span"}>{t("package.perMonth")}</Typography>
          )}
        </Typography>
        {index !== 0 ? (
          <Typography sx={{ fontSize: "10px", pr: 6 }}>
            {t("package.excludeVat")}
          </Typography>
        ) : (
          <div className="py-2"></div>
        )}

        <Typography py={2}>
          {t("package.loginLimit")} {item.login} {t("package.device")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          width: "100%",
          p: 2,
          my: 2,
        }}
      >
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <TuneIcon
            sx={{
              width: "30px",
              height: "30px",
              color: "#F19A3C",
              transform: "rotate(90deg)",
            }}
          />
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 600,
              ml: 1,
            }}
          >
            {t("package.function")}
          </Typography>
        </Box>
        {item.checkListFunctions.map((check, index) =>
          check ? (
            <Box key={index} sx={{ ml: 1 }}>
              <CheckList
                isChecked={check}
                text={
                  [
                    t("package.menu"),
                    t("package.order"),
                    t("package.transaction"),
                    t("package.google"),
                    t("package.web"),
                    t("package.qrCode"),
                    t("package.edc"),
                    t("package.employee"),
                    t("package.pos"),
                    t("package.table"),
                    t("package.kitchen"),
                    t("package.saleReport"),
                    t("package.queue"),
                  ][index]
                }
              />
            </Box>
          ) : (
            <Box key={index} sx={{ ml: 1 }}>
              <CheckList
                isChecked={check}
                text={
                  [
                    t("package.menu"),
                    t("package.order"),
                    t("package.transaction"),
                    t("package.google"),
                    t("package.web"),
                    t("package.qrCode"),
                    t("package.edc"),
                    t("package.employee"),
                    t("package.pos"),
                    t("package.table"),
                    t("package.kitchen"),
                    t("package.saleReport"),
                    t("package.queue"),
                  ][index]
                }
              />
            </Box>
          )
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mt: 2,
          }}
        >
          <AttachMoneyIcon
            sx={{
              width: "30px",
              height: "30px",
              color: "#F19A3C",
            }}
          />
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 600,
              ml: 1,
            }}
          >
            {t("package.gateWay")}
          </Typography>
        </Box>
        {item.checkListPayments.map((check, index) =>
          check ? (
            <Box key={index} sx={{ ml: 1 }}>
              <CheckList
                isChecked={check}
                text={
                  [
                    t("package.cash"),
                    "Promptpay QR",
                    "Thai QR",
                    "QR Credit Card",
                    "WeChat Pay / Alipay",
                    "ShopeePay",
                    "EDC Credit Card",
                  ][index]
                }
              />
            </Box>
          ) : (
            <Box key={index} sx={{ ml: 1 }}>
              <CheckList
                isChecked={check}
                text={
                  [
                    t("package.cash"),
                    "Promptpay QR",
                    "Thai QR",
                    "QR Credit Card",
                    "WeChat Pay / Alipay",
                    "ShopeePay",
                    "EDC Credit Card",
                  ][index]
                }
              />
            </Box>
          )
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <CampaignIcon
              sx={{
                width: "30px",
                height: "30px",
                color: "#F19A3C",
              }}
            />
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 600,
                ml: 1,
              }}
            >
              {t("package.announcement")}
            </Typography>
          </Box>
        </Box>
        {item.checkListAnnouncements.map((check, index) =>
          check ? (
            <Box key={index} sx={{ ml: 1 }}>
              <CheckList
                isChecked={check}
                text={
                  [
                    t("package.checklist12"),
                    "โฆษณาร้านของคุณบน Pompkins Food",
                    "E-mail Marketing",
                    "Social Media Marketing",
                  ][index]
                }
              />
            </Box>
          ) : (
            <Box key={index} sx={{ ml: 1 }}>
              <CheckList
                isChecked={check}
                text={
                  [
                    t("package.checklist12"),
                    "โฆษณาร้านของคุณบน Pompkins Food",
                    "E-mail Marketing",
                    "Social Media Marketing",
                  ][index]
                }
              />
            </Box>
          )
        )}
      </Box>
    </Box>
  );
};

function PackageComponent({ activeIndex, setActiveIndex }) {
  const { isMobile } = useMobile();
  const sliderRef = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.swiper.slideTo(activeIndex);
    }
  }, [activeIndex]);

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.swiper.slideNext();
    }
  };

  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.swiper.slidePrev();
    }
  };

  const packageList = [
    {
      delay: 100,
      suitableFor: t("package.suitableFree"),
      title: "Starter",
      color: "#FFBE7A",
      optional: "เหมาะสำหรับร้านอาหารข้างถนน และร้านอาหารตามอีเว้นท์",
      package: t("package.free"),
      transaction: "200",
      login: "1",
      checkListFunctions: [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      checkListPayments: [true, true, true, true, true, true, true],
      checkListAnnouncements: [true, true, false, false],
    },
    {
      delay: 200,
      suitableFor: t("package.suitableBasic"),
      title: "Basic",
      color: "#FFB361",
      optional: "เหมาะสำหรับร้านขนาดเล็กไม่มีโต๊ะ",
      package: "199.-",
      transaction: "2000",
      login: "3",
      checkListFunctions: [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        false,
        false,
        false,
        false,
        false,
      ],
      checkListPayments: [true, true, true, true, true, true, true],
      checkListAnnouncements: [true, true, false, false],
    },
    {
      delay: 300,
      suitableFor: t("package.suitablePlus"),
      title: "Plus",
      color: "-webkit-linear-gradient(21deg, #F19A3C 12.08%, #FFCE9A 72.5%)",
      optional: "เหมาะสำหรับร้านขนาดกลางที่มีโต๊ะ",
      package: "499.-",
      transaction: "6000",
      login: "5",
      checkListFunctions: [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        false,
      ],
      checkListPayments: [true, true, true, true, true, true, true],
      checkListAnnouncements: [true, true, true, false],
    },
    {
      delay: 400,
      suitableFor: t("package.suitableMax"),
      title: "Max",
      color:
        "-webkit-linear-gradient(21deg, #DF8341 30.08%, #E38D4D 20.54%, #FECF9C 108.13%)",
      optional: "เหมาะสำหรับร้านขนาดใหญ่",
      package: "999.-",
      transaction: "10000",
      login: "10",
      checkListFunctions: [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ],
      checkListPayments: [true, true, true, true, true, true, true],
      checkListAnnouncements: [true, true, true, true],
    },
  ];

  return (
    <>
      {!isMobile ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 2,
            px: [4, 4, 2],
            flexWrap: "wrap",
            width: ["100%", "100%", "100%", "90%"],
            pb: 10,
          }}
        >
          {packageList.map((item, index) => (
            <PackageCard key={index} item={item} index={index} />
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            position: "relative",
            width: ["100%", "100%", "70%", "60%"],
            margin: "0 auto",
            mb: 10,
          }}
        >
          <Swiper
            ref={sliderRef}
            slidesPerView={1}
            className="package-swiper"
            nav={false}
            dots={false}
            loop={false}
            onSlideChange={(e) => setActiveIndex(e.realIndex)}
          >
            {" "}
            {packageList.map((item, index) => (
              <SwiperSlide
                key={index}
                // style={{
                //   width: "calc(100% - 20px)",
                //   marginRight: "10px !important",
                //   marginLeft: "10px",
                //   padding: "20px",
                // }}
              >
                <PackageCard item={item} index={index} />
              </SwiperSlide>
            ))}
          </Swiper>
          {activeIndex !== 0 && (
            <IconButton
              onClick={goToPrevSlide}
              sx={{
                background: "transparent",
                border: "2px solid #F19A3C",
                position: "absolute",
                top: "50%",
                left: ["0%", "10%", "10%", "15%"],

                transform: "translateY(-50%)",
                width: ["30px", "40px", "40px"],
                height: ["30px", "40px", "40px"],
                zIndex: 1,
                color: "white",
              }}
            >
              <ArrowForwardIos
                sx={{
                  color: "#F19A3C",
                  m: "0 auto",
                  transform: "rotate(180deg)",
                  width: "20px",
                }}
              />
            </IconButton>
          )}
          {activeIndex !== packageList.length - 1 && (
            <IconButton
              onClick={goToNextSlide}
              sx={{
                background: "transparent",
                border: "2px solid #F19A3C",
                position: "absolute",
                top: "50%",
                right: ["0%", "10%", "10%", "15%"],
                transform: "translateY(-50%)",
                zIndex: 1,
                width: ["30px", "40px", "40px"],
                height: ["30px", "40px", "40px"],
                color: "white",
              }}
            >
              <ArrowForwardIos
                sx={{ color: "#F19A3C", m: "0 auto", width: "20px" }}
              />
            </IconButton>
          )}
        </Box>
      )}
    </>
  );
}

export default PackageComponent;
