import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  getBMerchant,
  getMerchant,
  getV2Merchant,
} from "../../libs/merchantAPI/merchantAPI";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLocationStore } from "../../stores/useLocationStore";
import LoadingScreen from "../../components/LoadingScreen";
import { DistanceCalculator } from "../../utils/calDistance";
import { useTypingText } from "../../utils/useTypingText";
import { isMerchantOpen } from "../../libs/isMerchantOpen";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import ModalMerchantCustomer from "../../components/ModalMerchantCustomer";
import AutoCompleteMerchantField from "../../components/AutoCompleteMerchantField";
import foodFaviconIco from "../../assets/images/favicon_package_pompkins_food/favicon-32x32.png";
import largeFoodPngIcon from "../../assets/images/favicon_package_pompkins_food/PKFCircle-01.png";
import appleTouchFoodIcon from "../../assets/images/favicon_package_pompkins_food/apple-touch-icon.png";
import imagePompkinOG from "../../assets/images/coverFoodWeb-07.png";
import MerchantSection from "../../components/FoodPage/MerchantSection";
import TuneIcon from "@mui/icons-material/Tune";
import { useMobile } from "../../stores/useMobile";
import FilterPanel from "./FilterPanel";

function HomePage() {
  const version = new Date().toISOString().split("T")[0];
  const [merchantData, setMerchantData] = useState(null);
  const [isFetch, setIsFetch] = useState(false);
  const [newMerchant, setNewMerchant] = useState([]);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchFetch, setSearchFetch] = useState(false);
  const searchQuery = searchParams.get("search");
  const pageQuery = searchParams.get("page");
  const [title, setTitle] = useState([]);
  const { currentLocation, openModal, currentAddress } = useLocationStore();
  const [focused, setFocused] = useState(false);
  const [searchData, setSearchData] = useState();
  const searchResultsRef = useRef(null);
  const autoCompleteRef = useRef(null);
  const [openWhoAmI, setOpenWhoAmI] = useState(false);
  const words = [
    "ค้นหาตามชื่อร้านค้า",
    "aroi - ซื้อ 1 แถม 1",
    "John Doe23 - ลดราคา 50%",
    "mrsuchote - ร้านอาหารยอดฮิต",
    "John Doe3 - ร้านดีๆที่ลงตัว",
  ];
  const { word } = useTypingText(words, 10, 300);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isFilterPanelModalOpen, setIsFilterPanelModalOpen] = useState(false);
  const [sortBy, setSortBy] = useState("relevance");
  const [filters, setFilters] = useState({
    isOpen: true,
    rating: 0,
    shopType: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [allMerchantData, setAllMerchantData] = useState(merchantData);
  const [isFetchNextPage, setIsFetchNextPage] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [sortBy, filters]);

  const openFilterPanelModal = () => {
    setIsFilterPanelModalOpen(true);
  };

  const closeFilterPanelModal = () => {
    setIsFilterPanelModalOpen(false);
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/food",
      title: "HomePage",
    });
  }, []);

  useEffect(() => {
    const whoAmI = sessionStorage.getItem("whoAmI");
    if (!whoAmI) {
      setOpenWhoAmI(true);
    }
  }, []);

  const sortedNewMerchant = (merchantData) => {
    const currentDate = new Date();

    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

    const filteredData = merchantData.filter((item) => {
      const createAtDate = new Date(item.createAt);

      return createAtDate >= sevenDaysAgo && createAtDate <= currentDate;
    });

    filteredData.sort((a, b) => {
      const dateA = new Date(a.createAt);
      const dateB = new Date(b.createAt);
      return dateB - dateA;
    });
    const data = filteredData.filter((item) => {
      return parseFloat(item.distance) <= 10;
    });
    return data;
  };
  const sortByIsOpen = (a, b) => {
    if (a.isOpen && !b.isOpen) {
      return -1;
    }
    if (!a.isOpen && b.isOpen) {
      return 1;
    }
    return 0;
  };
  const fetchMerchants = useCallback(
    (page = 1) => {
      const { shopType, isOpen, rating } = filters;
      const location = currentLocation;
      // if (!location) return;

      if (location) {
        location.latitude = String(location?.lat);
        location.longitude = String(location?.lng);
      }

      getV2Merchant(
        shopType.length !== 0 ? shopType : null,
        isOpen,
        rating,
        sortBy === "distance" ? true : false,
        sortBy === "time" ? true : false,
        location,
        10,
        page
      )
        .then((merchantRes) => {
          const { data } = merchantRes;
          // console.log("data", data);
          setTotalPages(data.total_page);
          mapDataV2(data.merchant).then((sortedData) => {
            if (page === 1) {
              setAllMerchantData(sortedData.sort(sortByIsOpen));
            } else {
              setAllMerchantData((prevData) =>
                [...prevData, ...sortedData].sort(sortByIsOpen)
              );
            }
            const sortIsOpen = sortedNewMerchant(sortedData).sort(sortByIsOpen);
            setNewMerchant(sortIsOpen);
            const allItemNames = data.merchant.map(
              (item) => item.merchant_name
            );
            setTitle(allItemNames);
          });
        })
        .catch((err) => {
          console.error("Error", err);
        })
        .finally(() => {
          setIsFetch(true);
        });
    },
    [currentLocation, filters, sortBy]
  );

  const fetchMerchant = useCallback(() => {
    fetchMerchants(1);
  }, [fetchMerchants]);

  const fetchNextPage = async () => {
    setIsFetchNextPage(true);
    fetchMerchants(currentPage + 1);
    setCurrentPage((prevPage) => prevPage + 1);
    setIsFetchNextPage(false);
  };

  useEffect(() => {
    fetchMerchant();
  }, [fetchMerchant]);

  const mapData = async (merchantData, bMerchantData) => {
    const filteredBMerchantData = bMerchantData.filter((bMerchant) =>
      merchantData.some((merchant) => merchant.id === bMerchant.merchant_id)
    );

    const newData = await Promise.all(
      filteredBMerchantData.map(async (item) => {
        if (!item?.latitude) return null;
        if (!currentLocation) return null;
        // if (!item.verified || item.verify_check !== "verified") return null;
        const location = { lat: item?.latitude, lng: item?.longitude };
        const { distance, time } = DistanceCalculator(
          currentLocation,
          location
        );
        const correspondingMerchant = merchantData.find(
          (merchant) => merchant.id === item.merchant_id
        );

        item.bmerchantId = item.id;
        item.isOpen = isMerchantOpen(item.open_time, item.close_time);
        item.distance = distance;
        item.time = time;
        item.name = correspondingMerchant ? correspondingMerchant.name : "";
        item.logo = correspondingMerchant ? correspondingMerchant.logo : "";
        item.shop_type = correspondingMerchant
          ? correspondingMerchant.shop_type
          : "";
        return item;
      })
    );

    const filteredData = newData.filter((item) => item !== null);
    const mappedData = filteredData.map((item) => ({
      id: item.id,
      bmerchantId: item.bmerchantId,
      location: item.location,
      title: item.name,
      lat: item?.latitude,
      lng: item?.longitude,
      img: item.logo,
      isOpen: item.isOpen,
      rating: item.rating ?? 0,
      distance: `${item.distance ?? "2"} Km`,
      time: item.time ?? 20,
      features: [],
      shopType:
        item.shop_type === ""
          ? "ร้านอาหารทั่วไป"
          : item.shop_type || "ร้านอาหารทั่วไป",
      createAt: item.created_at,
    }));

    const sortByDistance = (a, b) => {
      const distanceA = parseFloat(a.distance.replace(" Km", ""));
      const distanceB = parseFloat(b.distance.replace(" Km", ""));
      return distanceA - distanceB;
    };

    const sortedDistanceData = mappedData.sort(sortByDistance);
    const sortedData = sortedDistanceData.sort(sortByIsOpen);
    return sortedData;
  };

  const mapDataV2 = async (merchantData) => {
    const newData = await Promise.all(
      merchantData.map(async (item) => {
        if (!item.location?.latitude || !item.location?.longitude) return null;
        if (!currentLocation) return null;
        return item;
      })
    );

    const filteredData = newData.filter((item) => item !== null);
    const mappedData = filteredData.map((item) => ({
      id: item.id,
      bmerchantId: item.bmerchant.id,
      bmerchantUUID: item.bmerchant.uuid,
      location: item.location,
      title: item.merchant_name,
      lat: item.location?.latitude,
      lng: item.location?.longitude,
      img: item.logo,
      isOpen: item.is_open,
      rating: parseFloat(item.rating).toFixed(2) ?? 0,
      distance: `${item.distance.toFixed(2) ?? "2"} Km`,
      time: item.time.toFixed(2) ?? 20,
      features: [],
      shopType:
        item.shop_type === ""
          ? "ร้านอาหารทั่วไป"
          : item.shop_type || "ร้านอาหารทั่วไป",
      // createAt: item.created_at,
    }));

    const sortByDistance = (a, b) => {
      const distanceA = parseFloat(a.distance.replace(" Km", ""));
      const distanceB = parseFloat(b.distance.replace(" Km", ""));
      return distanceA - distanceB;
    };

    const sortedDistanceData = mappedData.sort(sortByDistance);
    const sortedData = sortedDistanceData.sort(sortByIsOpen);
    return sortedData;
  };

  useEffect(() => {
    if (!isFetch) {
      fetchMerchant();
    }
  }, [currentAddress, fetchMerchant, isFetch]);

  const handleSearchChange = (e) => {
    const searchQuery = e.target.value;
    setSearchParams({ search: searchQuery });
  };

  const handleAutocompleteChange = (_, newValue) => {
    setSearchParams({ search: newValue });
  };

  useEffect(() => {
    if (searchQuery === "") {
      setSearchFetch(false);
    }
    scrollToResults();
  }, [searchQuery]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setSearchParams({ search: e.target.value });
      handleSearch(e.target.value);
      scrollToResults();
      if (autoCompleteRef.current) {
        autoCompleteRef.current.blur();
      }
    }
  };

  const handleSearch = (searchQuery) => {
    setSearchParams({ search: searchQuery });
    setSearchFetch(false);
    setFocused(false);
    if (searchQuery === "") {
      setSearchFetch(false);
    } else {
      const filteredData = merchantData.filter((item) =>
        item.title?.toLowerCase().includes(searchQuery?.toLowerCase())
      );
      setSearchData(filteredData);
      setSearchFetch(true);
      scrollToResults();
    }
    if (autoCompleteRef.current) {
      autoCompleteRef.current.blur();
    }
  };
  const handleClearAll = () => {
    setCurrentPage(1);
    setSortBy("relevance");
    setFilters({
      isOpen: true,
      rating: 0,
      shopType: [],
    });
  };

  const handleShopTypeChange = (type) => {
    setCurrentPage(1);
    setFilters((prevFilters) => {
      const newShopType = prevFilters.shopType.includes(type)
        ? prevFilters.shopType.filter((t) => t !== type)
        : [...prevFilters.shopType, type];
      return { ...prevFilters, shopType: newShopType };
    });
  };

  const handleRatingChange = (rating) => {
    setCurrentPage(1);
    setFilters((prevFilters) => ({
      ...prevFilters,
      rating: prevFilters.rating === rating ? null : rating,
    }));
  };

  const filteredData = useMemo(() => {
    return (
      merchantData &&
      merchantData
        .filter((merchant) => {
          if (filters.isOpen && !merchant.isOpen) return false;
          if (
            filters.rating.length > 0 &&
            !filters.rating.includes(merchant.rating)
          )
            return false;
          if (
            filters.shopType.length > 0 &&
            !filters.shopType.includes(merchant.shopType)
          )
            return false;
          return true;
        })
        .sort((a, b) => {
          if (sortBy === "time") return a.time - b.time;
          if (sortBy === "distance")
            return parseFloat(a.distance) - parseFloat(b.distance);
          return 0;
        })
    );
  }, [merchantData, filters, sortBy]);

  const scrollToResults = () => {
    if (searchResultsRef.current) {
      const yOffset = isMobile ? -130 : -100;
      const y =
        searchResultsRef.current.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  if (!isFetch) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Helmet>
        <title>{t("title.homepage")}</title>
        <meta
          name="description"
          content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
        />
        <link
          rel="canonical"
          href={`https://pompkins.com${window.location.pathname}`}
        />
        <meta property="og:url" content="https://pompkins.com/food" />
        <meta property="og:title" content={`${t("title.homepage")}`} />
        <meta property="og:image" content={`${imagePompkinOG}?v=${version}`} />
        <meta name="twitter:image" content={`${imagePompkinOG}?v=${version}`} />
        <link
          rel="image/png"
          href={`${foodFaviconIco}?v=${version}`}
          sizes="any"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={`${largeFoodPngIcon}?v=${version}`}
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${appleTouchFoodIcon}?v=${version}`}
        />
        <meta name="twitter:creator" content="POMPKINS" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://pompkins.com/food" />
        <meta name="twitter:title" content={`${t("title.homepage")}`} />
      </Helmet>
      <ModalMerchantCustomer open={openWhoAmI} setOpenWhoAmI={setOpenWhoAmI} />
      <Box
        sx={{
          minHeight: "100vh",
          // background:
          //   "linear-gradient(104.23deg, #FFFEFC 8.55%, #FCEDDC 104.67%, #FCE3BC 182.39%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {/* Main Content with Sidebar */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "space-between",
              p: 4,
              pl: 0,
              gap: 7,
            }}
          >
            {!isMobile && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  background: "white",
                  padding: 2,
                  borderRadius: 15,
                  height: "auto",
                  maxHeight: "84vh",
                  border: "0.5px #33333330 solid",
                  borderLeft: "none",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  position: "sticky",
                  top: "100px",
                }}
              >
                <FilterPanel
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                  filters={filters}
                  setFilters={setFilters}
                  handleRatingChange={handleRatingChange}
                  handleShopTypeChange={handleShopTypeChange}
                  handleClearAll={handleClearAll}
                  filteredData={filteredData}
                  fetchMerchant={fetchMerchant}
                />
              </Box>
            )}

            {/* Main Content Merchant */}
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                height: "auto",
                width: {
                  xs: "100%",
                  sm: "75%",
                  md: "60%",
                  lg: "65%",
                  xl: "70%",
                },
                pl: !isMobile ? 0 : 4,
              }}
            >
              {isMobile && (
                <Box
                  sx={{
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={openFilterPanelModal}
                    sx={{
                      borderRadius: "50%",
                      fontSize: "14px",
                      minWidth: isMobile ? "40px" : "auto",
                      padding: isMobile ? "6px" : "6px 16px",
                      background: "white",
                      border: "1px solid white",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    <TuneIcon />
                  </Button>
                </Box>
              )}
              <MerchantSection
                merchantData={allMerchantData}
                pageQuery={pageQuery}
                searchFetch={searchFetch}
                searchData={searchData}
                newMerchant={newMerchant}
                isFetch={isFetch}
                openModal={openModal}
                searchResultsRef={searchResultsRef}
                isFetchNextPage={isFetchNextPage}
                fetchNextPage={fetchNextPage}
                currentPage={currentPage}
                totalPages={totalPages}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Dialog
        open={isFilterPanelModalOpen}
        onClose={closeFilterPanelModal}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            // height: { xs: "100%", sm: "auto" },
            maxHeight: { sm: "70vh" },
            m: { xs: 0, sm: 2 },
            borderRadius: 4,
          },
        }}
      >
        <DialogContent sx={{ p: 0 }}>
          <FilterPanel
            onClose={closeFilterPanelModal}
            sortBy={sortBy}
            setSortBy={setSortBy}
            filters={filters}
            setFilters={setFilters}
            handleRatingChange={handleRatingChange}
            handleShopTypeChange={handleShopTypeChange}
            handleClearAll={handleClearAll}
            filteredData={filteredData}
            fetchMerchant={fetchMerchant}
          />
        </DialogContent>
        <DialogActions sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Box display="flex" justifyContent="flex-end" sx={{ gap: 2 }}>
              <Button
                variant="outlined"
                onClick={handleClearAll}
                sx={{ borderRadius: "20px" }}
              >
                Clear all
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={closeFilterPanelModal}
              >
                Apply
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default HomePage;
