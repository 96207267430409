import { create } from "zustand";

const useGenericModal = create((set) => ({
  isOpen: false,
  description: "",
  type: "",
  closeModal: () => set({ isOpen: false }),
  openFailedModal: (description) =>
    set({ type: "Failed", description: description, isOpen: true }),
  openSuccessModal: (description) =>
    set({ type: "Success", description: description, isOpen: true }),
}));

export default useGenericModal;
