import axios from "axios";

// Utility function to get the address from coordinates
export async function getAddressFromCoordinates(location) {
  const apiKey = "AIzaSyCf0iF-Wk0h6A32W7DgVDS1BjYABfanT0Q";
  if (!location) {
    console.warn("No selected location.");
    return null;
  }

  const { lat, lng } = location;
  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}&language=${"en"}`;

  try {
    const response = await axios.get(apiUrl);
    if (response.data.results.length > 0) {
      const address = response.data.results[0].formatted_address;
      return address;
    }
    console.warn("No address found for the selected location.");
    return null;
  } catch (error) {
    console.error("Error fetching address:", error);
    return null;
  }
}
